import { setSideBarStudentHome } from 'actions/studentMarketPlace';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import home from "../../Assets/aulasMarket/home3.svg"
import categories from "../../Assets/aulasMarket/ic-categories.svg"
import save from "../../Assets/aulasMarket/saved.svg"
import cart from "../../Assets/aulasMarket/cart-copy.svg"
import account from "../../Assets/aulasMarket/ic-home-account.svg"
import home1 from "../../Assets/aulasMarket/home1.svg"
// import categories from "../../Assets/aulasMarket/ic-categories.svg"
import save1 from "../../Assets/aulasMarket/saved1.svg"
import cart1 from "../../Assets/aulasMarket/Cart1.svg"

import logoutImg from "../../Assets/aulasMarket/ic-home-logout.svg"
import { Api } from 'services';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { logout } from 'actions/login';
import { CloseOutlined } from '@ant-design/icons';
import { IMG_URL } from "../../env.json";
import UserAvatar from 'components/userAvatar';

function SideBarStudentHome({ user, setSideBarStudentHome, logout, sideBarStudentHome, userMarketPlaceHomeData }) {
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const handleLogout = () => {
    const reason = {
      type: 'Manual'
    }
    logout(history, reason, true);
    setSideBarStudentHome(false)
  };
  const history = useHistory()
  const menuItems = [
    {
      label: "Home",
      icon: home,
      iconWhite:home1
    },
    // {
    //   label: "Categories",
    //   icon: categories,
    // },
    {
      label: "Saved for later",
      icon: save,
      iconWhite:save1
    },
    {
      label: "Cart",
      icon: cart,
      iconWhite:cart1
    },
    {
      label: user.accessToken ? "Account" : "Log In / Register Now" ,
      icon: account,
      iconWhite:account

    }
  ]
const [isValue,setIsValue] = useState(null)

  useEffect(()=>{
    setActiveMenuItem(null)
  },[sideBarStudentHome])

  const getOS = () => {
    const platform = window.navigator.platform;
    if (platform.toLowerCase().includes('win')) {
      return 'PlayStore';
    } else if (platform.toLowerCase().includes('mac')) {
      return 'AppStore';
    } else if(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream){
      return 'Appstore'
    } else if(navigator.userAgent.toLowerCase().indexOf('android') > -1){
      return 'PlayStore'
    }else {
      return 'Store';
    }
  };
  return (
    <>
      <div className='sideBarHome'>
          <div style={{display:"flex", justifyContent:"flex-end", margin:"0px 20px 0px 0px"}}><CloseOutlined style={{fontSize:18, color:"#FFFFFF", marginTop:14,}} onClick={()=>{setSideBarStudentHome(false)}}/></div>
        <div style={{ padding: "12px", position: "relative", top: -5,display:"flex",gap:10}}>
          {/* <img src={`${IMG_URL}/${user?.image || "default.png"}`} style={{ width: 40, height: 40, marginRight: 12 }} /> */}
        <UserAvatar size={40} name={user?.name} img={user?.image ? `${IMG_URL}/${user?.image}` : `${IMG_URL}/${'default.png'}`} />

          <span style={{ color: "#191919", fontWeight: 600, fontSize: 18,marginTop:4 }}>{user?.name ? user?.name : "Guest"}</span>
        </div>
        <div className='r-c-c side-bar-divider' style={{ position: "absolute", top: 90, left: 11 }}></div>
        <div style={{ padding: "0 10px 0px 0px", width: "100%", position: "absolute", top: 100 }}>
          {menuItems?.map((menuItem, index) => (
            <div
              key={index}
              className={`menu-item ${activeMenuItem === index ? 'active' : ''}`}
              onClick={() => {
                setIsValue(index)
                setActiveMenuItem(index);
                {console.log("indexindex",index);}
                if (index == 0) {
                  history.push("/home")
                } 
                // else if (index == 1) {
                //   history.push("/mobile-search-page")
                // } 
                else if (index == 1) {
                  if(user.accessToken){
                  history.push("/saved-for-later")
                  }else{
                    history.push("/login")
                  }
                } else if (index == 2) {
                  if(user.accessToken){
                    history.push("/cart")
                    }else{
                      history.push("/login")
                    }
                } else if(index == 3){
                  if(user.accessToken){
                    if(getOS()==="PlayStore"){
                      window.location.href = userMarketPlaceHomeData?.pSLink;
                    }else{
                      window.location.href = userMarketPlaceHomeData?.aSLink;
                    }
                  }else{
                    history.push("/login")
                  }
                }
                setSideBarStudentHome(false)
              }}
            >
              <div
               style={{
        backgroundColor: isValue === index ? '#594099' : '#EFE8FF',
        border:isValue === index ? '1px solid #594099' : '1px solid #EFE8FF',
        width: '100%',
        height: 55,
        display: 'flex',
        alignItems: 'center',
        // padding: '0 1px',
        borderRadius:90,
      }}
      >
        <div style={{width:45,height:45,backgroundColor: isValue === index ? '#EFE8FF' : '#594099' ,
        border:isValue === index ? '1px solid #EFE8FF' : '1px solid #594099',
        borderRadius:'100%',
        padding:10,
        marginLeft:5

        }}>
              <img style={{ width: 24, height: 24, marginRight: 10,}} src={isValue === index ? menuItem?.icon :   menuItem?.iconWhite} alt={menuItem.label} />

        </div>
{console.log("activeMenuItem",isValue,index)}
              <span style={{ fontSize: 16, fontWeight: 500 ,marginLeft:10,
                color:isValue === index ? '#fff': '#191919'
                }}>{menuItem?.label}</span>
              </div>
            </div>
          ))}
        </div>
        <div className='r-c-c side-bar-divider' style={{ position: "absolute", bottom: 70, left: 11 }}></div>
        {user?.accessToken ?
        <div style={{ padding: "0 0px 0px 20px", width: "100%", position: "absolute", bottom: 24, display: "flex", alignItems: "center" }} >
          <img style={{ width: 24, height: 24, marginRight: 12 }} src={logoutImg} alt={logout} />
          <span style={{ fontSize: 18, fontWeight: "bold", color: "#594099" }} onClick={() => { handleLogout() }}>Logout</span>
        </div>
        :
        <></>}
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  const { sideBarStudentHome, userMarketPlaceHomeData } = state?.studentMarketPlace;
  const { user } = state.session
  return {
    sideBarStudentHome, user, userMarketPlaceHomeData
  }
};
const mapDispatchToProps = (dispatch) => ({
  logout: (history, reason) => dispatch(logout(history, reason)),
  setSideBarStudentHome: (val) => dispatch(setSideBarStudentHome(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideBarStudentHome)
