import { Api, Utils } from '../services';
import { sessionService } from 'redux-react-session';
import { setLoginDetails } from './aulasMarket';
import { getUserMarketPlaceHomeData } from './studentMarketPlace';
export const HANDLE_RESEND_OTP_TIMER = 'HANDLE_RESEND_OTP_TIMER';
export const UPDATE_ORGS_STANDARD_LIST = 'UPDATE_ORGS_STANDARD_LIST';
export const SET_SHOW_REGISTER_FORM = "SET_SHOW_REGISTER_FORM";
export const STORE_PHONE_NUMBER = "STORE_PHONE_NUMBER";
export const UPDATE_ORG_DATA = "UPDATE_ORG_DATA";
export const SET_IS_USER_REGISTERED = "SET_IS_USER_REGISTERED";
export const SET_IS_OTP_VERIFIED = "SET_IS_OTP_VERIFIED";
export const SET_SHOW_DOWNLOAD_APP_MODAL = "SET_SHOW_DOWNLOAD_APP_MODAL";
export const SET_REGISTER_SUCCESS_MODAL = "SET_REGISTER_SUCCESS_MODAL";
export const SET_SHOW_LOGIN_MODAL = "SET_SHOW_LOGIN_MODAL";
export const SET_ENROLLED_ITEM_ID = "SET_ENROLLED_ITEM_ID"
export const SET_SHOW_BOTTOM_SHEET = "SET_SHOW_BOTTOM_SHEET"
import loginSound from '../Assets/loginNew/success.mp3';


export const postGenerateOtp = (captchaToken, callBack = null, successCallBack) =>
    (dispatch, getState) => {
        const { phoneNo } = getState().loginNew;
        let formattedPhoneNumber = phoneNo.replace(/-|\s/g, "");
        Utils.stopDuration();
        return Api.post('/login/generateOtpV2')
            .params({ phoneNo: formattedPhoneNumber, hideMsg: true, token: captchaToken || undefined })
            .send((response, error) => {
                if (callBack) {
                    callBack()
                }
                if (response != undefined) {
                    if (response?.success) {
                        dispatch(setIsUserRegistered(response?.isUserRegistered))
                        dispatch(setShowRegisterForm(!response?.isUserRegistered))
                        if (response?.isUserRegistered == true) {
                            dispatch(handleResendOtpTimer(true, 30));
                            Utils.startDuation(30, (callback) => {
                                if (callback === 0) {
                                    dispatch(handleResendOtpTimer(false, ''));
                                } else {
                                    dispatch(handleResendOtpTimer(true, callback))
                                }
                            })
                        }
                        if (successCallBack) {
                            successCallBack(response?.isUserRegistered)
                        }
                    } else {
                        if (successCallBack) {
                            successCallBack(false)
                        }
                    }
                }
            });
    };

export const postVerifyOtp = (otp, history, callBack = null, successCallBack = null, invalidOtpCallBack) =>
    (dispatch, getState) => {
        const { phoneNo } = getState().loginNew;
        const { marketSettingData, isMyAccountClicked } = getState().studentMarketPlace;
        let formattedPhoneNumber = phoneNo.replace(/-|\s/g, "");
        return Api.post('/login/verifyOtpV2')
            .params({
                phoneNo: formattedPhoneNumber,
                otp,
                isWebLogin: true,
                hideMsg: true
            })
            .send((response, error) => {
                if (callBack) {
                    callBack()
                }
                if (typeof response !== 'undefined' && (response?.show?.type === 'success' || response?.success)) {
                    dispatch(setLoginDetails('accessToken', response?.data?.accessToken))
                    dispatch(setLoginDetails('userData', response?.data?.user))
                    Utils.stopDuration();
                    // if (response?.data?.status === 'Pending') {
                    //     if(marketSettingData?.active){
                    //     history.push('/home')
                    //     }else{
                    //         history.push("/login")
                    //     }
                    // }else{
                    //     if(marketSettingData?.active){
                    //         history.push('/home')
                    //         }else{
                    //             history.push("/login")
                    //         }
                    // }

                    const successAudio = new Audio(loginSound);
                    successAudio.play();

                    const token = response?.data?.accessToken;
                    if (token) {
                        response.data.user.accessToken = token;
                        sessionService.saveSession({ token }).then(() => {
                            sessionService.saveUser(response.data.user).then(() => {
                                if (response?.IsUserRegistered || (response.data.user.access.includes("schedule-teacher") || response.data.user.access.includes("schedule-admin") || response.data.user.access.includes("schedule-superadmin") || response.data.user.access.includes("schedule-dataoperator") || response.data.user.access.includes("schedule-operation"))) {
                                        history.push('/schedule');
                                    // if (marketSettingData?.active && !isMyAccountClicked) {
                                    //     history.push('/home');
                                    // } else {
                                    //     history.push('/schedule');
                                    // }
                                }
                                else if (response?.IsUserRegistered || response.data.user.access.includes("assignment")) {
                                    history.push('/assignment');
                                    // if (marketSettingData?.active && !isMyAccountClicked) {
                                    //     history.push('/home');
                                    // } else {
                                    //     history.push('/assignment');
                                    // }
                                }
                                else if (response.data.user.access.includes("schedule-student") || response.data.user.role.includes("Student")) {
                                    if (marketSettingData?.active && !isMyAccountClicked) {
                                        history.push('/home');
                                    } else {
                                        if (marketSettingData?.active) {
                                            history.push('/')
                                        } else {
                                            dispatch(getUserMarketPlaceHomeData(undefined, () => { }))
                                            dispatch(setShowDownloadAppModal(true))
                                        }
                                    }
                                }
                            }).catch(err => console.error(err));
                        }).catch(err => console.error(err));
                        Api.header(response.data.accessToken);
                    }
                    if (successCallBack) {
                        successCallBack()
                    }
                }else if ( response?.success == false){
                    invalidOtpCallBack("error2")
                }
                else if (typeof response == 'undefined') {
                    invalidOtpCallBack("error1")
                } else if (typeof response == 'object') {
                    invalidOtpCallBack("error2")
                }
            });
    };

export const postEmailLogIn = (captchaToken, userEmailRegister, history, callBack = null) => {
    return (dispatch, getState) => {
        const { marketSettingData, isMyAccountClicked } = getState().studentMarketPlace;
        return Api.post('/login/virtual-signin').params({ ...userEmailRegister,   }).send((response, error) => {
            if (callBack) {
                callBack()
            }
            console.log('responseresponse',response);
            

            if (typeof response != "undefined") {
                // if(!response.data.user.role.includes("SuperAdmin") && !response.data.user.role.includes("Admin")){
                //   history.push('/app-links');
                // }else{
                const token = response?.data?.accessToken;
                if (token) {
                    const successAudio = new Audio(loginSound);
                    successAudio.play();
                    response.data.user.accessToken = token;
                    sessionService.saveSession({ token }).then(() => {
                        sessionService.saveUser(response.data.user).then(() => {
                            if (response.data.user.access.includes("schedule-teacher") || response.data.user.access.includes("schedule-admin") || response.data.user.access.includes("schedule-superadmin") || response.data.user.access.includes("schedule-dataoperator") || response.data.user.access.includes("schedule-operation")) {
                                // if (marketSettingData?.active && !isMyAccountClicked) {
                                //     history.push('/home');
                                // } else {
                                    history.push('/schedule');
                                // }
                            }
                            else if (response.data.user.access.includes("assignment")) {
                                // if (marketSettingData?.active && !isMyAccountClicked) {
                                //     history.push('/home');
                                // } else {
                                    history.push('/assignment');
                                // }
                            }
                            else if (response.data.user.access.includes("schedule-student") || response.data.user.role.includes("Student")) {
                                if (marketSettingData?.active && !isMyAccountClicked) {
                                    history.push('/home');
                                } else {
                                    if (marketSettingData?.active) {
                                        history.push('/')
                                    } else {
                                        dispatch(getUserMarketPlaceHomeData(undefined, () => { }))
                                        dispatch(setShowDownloadAppModal(true))
                                    }
                                }
                            }
                        }).catch(err => console.error(err));
                    }).catch(err => console.error(err));
                    Api.header(response.data.accessToken);
                }
                // }
            };

            if(response?.show?.type == 'error' || typeof response == 'undefined' || response?.success == false){
            }

        });
    };
};
export const sendForgotLink = (email, history, callBack = null) => {
    return (dispatch) => {
        Api.post('/login/forgot-password').params({ email }).send((response, error) => {
            if (callBack) {
                callBack()
            }
            if (response && response.show.type === 'success') {
                //   history.push('/login');
            }
        });
    }
}

export const getOrgsStandardList = () => (dispatch, getState) => {
    return Api.get('/user/allCourses')
        .send((response, error) => {
            if (typeof response !== 'undefined') {
                dispatch(updateOrgsStandardList(response?.response?.items));
            }
        })
};

export const updateOrgDetails = (orgData) => ({
    type: UPDATE_ORG_DATA,
    orgData,
});

export const getOrgDetails = () => (dispatch, getState) => {
    return Api.get('/login').params({ defaultSite: true })
        .send((response, error) => {
            if (typeof response !== 'undefined') {
                dispatch(updateOrgDetails(response));
            } else {
                console.log(error);
            }
        })
};

export const postRegisterDetails = (userRegister, callback = null, successCallBack = null, history = null) => (
    dispatch,
    getState,
) => {
    const { phoneNo } = getState().loginNew;
    const { marketSettingData, isMyAccountClicked } = getState().studentMarketPlace;
    const param = userRegister
    let formattedPhoneNumber = phoneNo.replace(/-|\s/g, "");
    const params = {
        name: param.name,
        phone: formattedPhoneNumber,
        role: "Student",
        hideMsg: true,
        isWebLogin: true,   // as per the backend requirement 
    };
    return Api.uploadAndPost('/user/registerV2', params, (percent, response) => {
        const token = response?.data?.accessToken;
        if (token) {
            response.data.user.accessToken = token;
            sessionService.saveSession({ token }).then(() => {
                sessionService.saveUser(response.data.user).then(() => {
                }).catch(err => console.error(err));
            }).catch(err => console.error(err));
            Api.header(response.data.accessToken);
        }
        if (callback) {
            callback()
        }
        if (response && response?.data?.success) {
            if(successCallBack){
                successCallBack();
            }
            // if (history) {
            //     if (token && response?.data?.user?.role?.includes('Student')) {
            //         if (marketSettingData?.active) {
            //             history.push('/')
            //         } else {
            //             dispatch(getUserMarketPlaceHomeData(undefined, () => { }))
            //             dispatch(setShowDownloadAppModal(true))
            //         }
            //     } else if (isMyAccountClicked) {
            //         history.push('/home');
            //     }
            // }
        }
    });
};

export const postGenerateRegistrationOtp = (callback = null, successCallBack) => (
    dispatch,
    getState,
) => {
    const { phoneNo } = getState().loginNew;
    let formattedPhoneNumber = phoneNo.replace(/-|\s/g, "");
    const params = {
        phoneNo: formattedPhoneNumber,
    };
    Utils.stopDuration();
    return Api.post('/login/generateRegistrationOtp').params(params)
        .send((response, error) => {
            if (callback) {
                callback()
            }
            if (typeof response !== 'undefined') {
                if (successCallBack) {
                    successCallBack()
                }
                dispatch(handleResendOtpTimer(true, 30));
                Utils.startDuation(30, (callback) => {
                    if (callback === 0) {
                        dispatch(handleResendOtpTimer(false, ''));
                    } else {
                        dispatch(handleResendOtpTimer(true, callback))
                    }
                })
            }
        })
};
export const updateOrgsStandardList = (orgStandards) => ({
    type: UPDATE_ORGS_STANDARD_LIST,
    orgStandards,
});

export const handleResendOtpTimer = (bool, sec) => ({
    type: HANDLE_RESEND_OTP_TIMER,
    bool,
    sec,
});

export const setShowRegisterForm = (bool) => ({
    type: SET_SHOW_REGISTER_FORM,
    bool
})

export const storePhoneNumber = (val) => ({
    type: STORE_PHONE_NUMBER,
    val
})

export const setIsUserRegistered = (val) => ({
    type: SET_IS_USER_REGISTERED,
    val
})

export const setIsOtpVerified = (val) => ({
    type: SET_IS_OTP_VERIFIED,
    val
})

export const setShowDownloadAppModal = (val) => ({
    type: SET_SHOW_DOWNLOAD_APP_MODAL,
    val
})

export const setRegisterSuccessModal = (val) => ({
    type: SET_REGISTER_SUCCESS_MODAL,
    val
})

export const setShowLoginModal = (val) => ({
    type: SET_SHOW_LOGIN_MODAL,
    val
})
export const setEnrolledItemId = (val) => ({
    type: SET_ENROLLED_ITEM_ID,
    val
})
export const setShowBottomSheet = (val) => ({
    type:SET_SHOW_BOTTOM_SHEET,
    val
})