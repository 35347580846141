import { postMoveCourses, postMoveStudyMaterial, postMoveSubcategories, postMoveCourseResources, postMoveOnlineTestSeries } from 'actions/courseManagement'
import { AutoComplete, Input, Modal } from 'antd'
import AnimatedAutoComplete from 'components/AnimatedAutocompleteComp.js'
import { Button } from 'components/Button'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Api } from 'services'

function MoveModal({
    isMoveModalVisible, setIsMoveModalVisible, selectedSubCategoryCards,
    setSelectedSubCategoryCards, courseSubCategoriesData, courseCategoryData,
    isSubCategoryVisible, togglePageLoader, getCoursesSubCategories, selectedCategory,
    selectedPublishCards, setSelectedPublishCards, selectedUnpublishCards, setSelectedUnpublishCards,
    isCoursesCardsVisible, UnpublishCourseData, publishCourseData, getTotalCoursesList,
    postMoveSubcategories, postMoveCourses, getCoursesCategories, courseTypeName, getStudyMaterialsList,
    postMoveStudyMaterial, postMoveCourseResources, getTotalsVideoResource,
    postMoveOnlineTestSeries,
    getOnlineTestSeries,
}) {
    const handleCancel = () => {
        setIsMoveModalVisible(false);
        setSelectedSubCategoryCards([]);
        setSelectedPublishCards([]);
        setSelectedUnpublishCards([])
    }
    let selectedSubCategoriesName = courseSubCategoriesData
        ?.filter((item) => selectedSubCategoryCards?.includes(item?.id))
        ?.map((item) => item?.name)?.join(", ")
        ?.replace(/, ([^,]*)$/, ' and $1');
    let selectedUnpublishCoursesName = UnpublishCourseData
        ?.filter((item) => selectedUnpublishCards?.includes(item?.id))
        ?.map((item) => item?.name)?.join(", ")
        ?.replace(/, ([^,]*)$/, ' and $1');
    let selectedPublishCoursesName = publishCourseData
        ?.filter((item) => selectedPublishCards?.includes(item?.id))
        ?.map((item) => item?.name)?.join(", ")
        ?.replace(/, ([^,]*)$/, ' and $1');

    const [getCategoriesLoader, setGetCategoriesLoader] = useState(false);
    const [getSubCategoriesLoader, setGetSubCategoriesLoader] = useState(false);
    let [CategoryListToShow, setCategoryListToShow] = useState([]);
    let [SubCategoryListToShow, setSubCategoryListToShow] = useState([]);
    const [targetCategoryId, setTargetCategoryId] = useState(null);
    const [targetCategoryName, setTargetCategoryName] = useState(null)
    const [targetSubCategoryId, setTargetSubCategoryId] = useState(null);
    const [targetSubCategoryName, setTargetSubCategoryName] = useState(null);
    const [moveBtnLoading, setMoveBtnLoading] = useState(false);

    useEffect(() => {
        if (courseCategoryData?.length != 0) {
            setCategoryListToShow(courseCategoryData)
        }
        else {
            setCategoryListToShow([])
        }
    }, [courseCategoryData])

    useEffect(() => {
        if (courseSubCategoriesData?.length != 0) {
            setSubCategoryListToShow(courseSubCategoriesData)
        }
        else {
            setSubCategoryListToShow([])
        }
    }, [courseSubCategoriesData])

    useEffect(() => {
        setGetCategoriesLoader(true)
        getCoursesCategories(() => { setGetCategoriesLoader(false) })
    }, [])

    useEffect(() => {
        if (isCoursesCardsVisible && !isSubCategoryVisible) {
            setGetSubCategoriesLoader(true)
            getCoursesSubCategories(targetCategoryId, () => { targetCategoryId, setGetSubCategoriesLoader(false) })
        }
    }, [targetCategoryId])
    const onSelectCategory = (value, obj) => {
        setTargetCategoryName(value)
        if (value) {
            setTargetCategoryId(obj?.key)
        } else {
            setTargetCategoryId(null)
        }
    }

    const handleSearchCategory = (value) => {
        if (value && value.trim() != '') {
            let category;
            category = courseCategoryData.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()))
            setCategoryListToShow(category)
        }
        else {
            setCategoryListToShow(courseCategoryData)
        }
    }
    const onSelectSubCategory = (value, obj) => {
        setTargetSubCategoryName(value)
        if (value) {
            setTargetSubCategoryId(obj?.key)
        } else {
            setTargetSubCategoryId(null)
        }
    }

    const handleSearchSubCategory = (value) => {
        if (value && value.trim() != '') {
            let subcategory;
            subcategory = courseSubCategoriesData.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()))
            setSubCategoryListToShow(subcategory)
        }
        else {
            setSubCategoryListToShow(courseSubCategoriesData)
        }
    }
    const defaultCategoryImage = "defaultCategory.svg";

    return (
        <Modal
            centered
            className="modal-round-corner"
            visible={isMoveModalVisible}
            onCancel={handleCancel}
            onOk={handleCancel}
            footer={null}
            closable={false}>
            <div style={{ fontSize: "20px", color: "#191919", fontWeight: "bold", display: "flex", justifyContent: "center" }}>Move to</div>
            <center style={{ color: "#636363", fontSize: 16, marginTop: 10, padding: "0px 20px" }}>Move {isSubCategoryVisible ? "subcategory(s)" : "course(s)"} <span style={{ fontWeight: "bold" }}>{selectedPublishCoursesName ? selectedPublishCoursesName : selectedUnpublishCoursesName ? selectedUnpublishCoursesName : isSubCategoryVisible ? selectedSubCategoriesName : ""}</span> to</center>
            <div style={{ padding: "0px 20px 20px 20px" }}>
                <AnimatedAutoComplete
                    label="Course Category"
                    value={targetCategoryName}
                    onSelect={onSelectCategory}
                    onSearch={handleSearchCategory}
                    loading={getCategoriesLoader}
                    renderOption={() =>
                        CategoryListToShow.map((item) => (
                            <AutoComplete.Option key={item.id} value={item.name}>
                                <div style={{ display: 'flex', alignItems: 'center', height: "fit-content", padding: 10 }}>
                                    <div style={{ position: "relative" }}>
                                        <div className='r-c-c'>
                                            {item.path ? <img src={`${Api.dlS3Url}${item.path}`} style={{ width: '40px', height: '40px', marginRight: '10px' }} /> : <img src={`${Api.dlS3Url}${defaultCategoryImage}`} style={{ width: '40px', height: '40px', marginRight: '10px' }} />}
                                        </div>
                                        {!item.path && <div style={{ position: "absolute", top: 9, left: 17, fontSize: 8, color: "#594099", fontWeight: "bold", width: 10 }}>{item.name.charAt(0).toUpperCase()}</div>}
                                    </div>
                                    <div style={{ color: "#191919", fontWeight: 600 }}>{item.name}</div>
                                </div>
                            </AutoComplete.Option>
                        ))
                    }
                />
            </div>
            {isCoursesCardsVisible && !isSubCategoryVisible ?
                <div style={{ padding: "0px 20px 20px 20px" }}>
                    <AnimatedAutoComplete
                        label="Course subcategory"
                        value={targetSubCategoryName}
                        onSelect={onSelectSubCategory}
                        onSearch={handleSearchSubCategory}
                        loading={getSubCategoriesLoader}
                        renderOption={() =>
                            SubCategoryListToShow.map((item) => (
                                <AutoComplete.Option key={item.id} value={item.name}>
                                    <div style={{ display: 'flex', alignItems: 'center', height: "fit-content", padding: 10 }}>
                                        <div style={{ color: "#191919", fontWeight: 600 }}>{item.name}</div>
                                    </div>
                                </AutoComplete.Option>
                            ))
                        }
                    />
                </div>
                :
                <></>
            }
            <center>
                <div style={{ display: "flex", justifyContent: "space-around", width: "75%", marginTop: 30 }}>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button
                        disabled={(isCoursesCardsVisible && !isSubCategoryVisible) ? (!targetCategoryId || !targetSubCategoryId) : !targetCategoryId}
                        loading={moveBtnLoading}
                        type="primary"
                        onClick={() => {
                            setMoveBtnLoading(true);
                            if (isCoursesCardsVisible && courseTypeName == "liveCourses" && !isSubCategoryVisible) {
                                postMoveCourses(
                                    parseInt(targetSubCategoryId),
                                    selectedPublishCoursesName ? selectedPublishCards : selectedUnpublishCards,
                                    () => { setMoveBtnLoading(false) },
                                    () => { handleCancel(); togglePageLoader(true); getTotalCoursesList(() => { togglePageLoader(false) }) }
                                )
                            } else if (isCoursesCardsVisible && courseTypeName == "studyMaterials" && !isSubCategoryVisible) {
                                postMoveStudyMaterial(
                                    parseInt(targetSubCategoryId),
                                    selectedPublishCoursesName ? selectedPublishCards : selectedUnpublishCards,
                                    () => { setMoveBtnLoading(false) },
                                    () => { handleCancel(); togglePageLoader(true); getStudyMaterialsList(() => { togglePageLoader(false) }) }
                                )
                            } else if (isCoursesCardsVisible && courseTypeName == "videoResources" && !isSubCategoryVisible) {
                                postMoveCourseResources(
                                    parseInt(targetSubCategoryId),
                                    selectedPublishCoursesName ? selectedPublishCards : selectedUnpublishCards,
                                    () => { setMoveBtnLoading(false) },
                                    () => { handleCancel(); togglePageLoader(true); getTotalsVideoResource(() => { togglePageLoader(false) }) }
                                )
                            }
                            else if (isCoursesCardsVisible && courseTypeName == "onlineTestSeries" && !isSubCategoryVisible) {
                                postMoveOnlineTestSeries(
                                    parseInt(targetSubCategoryId),
                                    selectedPublishCoursesName ? selectedPublishCards : selectedUnpublishCards,
                                    () => { setMoveBtnLoading(false) },
                                    () => { handleCancel(); togglePageLoader(true); getOnlineTestSeries(() => { togglePageLoader(false) }) }
                                )
                            }
                            else {
                                postMoveSubcategories(
                                    parseInt(targetCategoryId),
                                    selectedSubCategoryCards,
                                    () => { setMoveBtnLoading(false) },
                                    () => { handleCancel(); togglePageLoader(true); getCoursesSubCategories(selectedCategory?.id, () => { togglePageLoader(false) }) }
                                )
                            }
                        }}
                    >MOVE</Button>
                </div>
            </center>
        </Modal >
    )
}
const mapStateToProps = (state) => {
    const { } = state.courseManagement;
    return {};
};

const mapDispatchToProps = (dispatch) => ({
    postMoveSubcategories: (categoryId, subcategoryIds, callBack, succesCallback) => dispatch(postMoveSubcategories(categoryId, subcategoryIds, callBack, succesCallback)),
    postMoveCourses: (subcategoryId, courseIds, callBack, succesCallback) => dispatch(postMoveCourses(subcategoryId, courseIds, callBack, succesCallback)),
    postMoveStudyMaterial: (subcategoryId, courseIds, callBack, succesCallback) => dispatch(postMoveStudyMaterial(subcategoryId, courseIds, callBack, succesCallback)),
    postMoveCourseResources: (subcategoryId, courseIds, callBack, succesCallback) => dispatch(postMoveCourseResources(subcategoryId, courseIds, callBack, succesCallback)),
    postMoveOnlineTestSeries: (subcategoryId, courseIds, callBack, succesCallback) => dispatch(postMoveOnlineTestSeries(subcategoryId, courseIds, callBack, succesCallback)),

});
export default connect(mapStateToProps, mapDispatchToProps)(MoveModal);