import { Popover } from 'antd'
import React, { useRef, useCallback, forwardRef, useMemo } from 'react'
import { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import './broadcastStyles.css'
import ActionButtons from './localComponent/actionButtons';
import RightColumn from './localComponent/rightColumn.js';
import { setRightViewType, setMuteState, setCameraState, clearGroupChat, updateUserList, 
    updateUserCount, addMessageToGroup, setUserType, setRightView, addMessageToPrivate, 
    setStreamId, postChat, clearPrivateChat,
    getVideoResourceSections, postRecordedVideoLesson, postRecordCount, getRoomData, updateUnreadCount, BroadcastRecordingEdit, setLocalRecordingName } from 'actions/broadcast'
import Canvas from './localComponent/sketchCanvas.js'
import RoomClient from './roomClient'
import { v4 as uuidv4 } from 'uuid';
import { Notification, Utils } from 'services';
import MessageNotification from './localComponent/messageNotification.js'
import PageDataLoader from 'components/pageDataLoader';
import ConfirmationModal from './localComponent/confirmationModal';
import ChatClient from './chatClient'
import { setUpChatSocket, setUpRoomClientSocket } from './utils';
import Loader from 'pages/Test/localComponent/loader';
import { BsCameraVideoFill, BsFillCameraReelsFill } from "react-icons/bs";
import { BsFillRecord2Fill } from "react-icons/bs";
import { PiProjectorScreenChartFill } from 'react-icons/pi'
import { MdNetworkWifi1Bar, MdStopScreenShare, MdNetworkWifi } from "react-icons/md";
import { VideoView } from 'components';
import VideoPlayer from './video';
import { AiFillSwitcher, AiOutlineFullscreen } from 'react-icons/ai';
import { EventRegister } from 'react-native-event-listeners';
import RecordingModal from './localComponent/recordingModal';
import { useInterval } from '../../hooks'
import audiosrc from './localComponent/assets/broadcast_stopped.m4a';
import audioDisconnectSrc from './localComponent/assets/audio_disconnect.wav';
import videoDisconnectSrc from './localComponent/assets/video_disconnect.wav';
import audiovideoDisconnectSrc from './localComponent/assets/audio_video_disconnect.wav';
import { recorder } from './browserRecorder';
import PassiveCanvas from './localComponent/passiveCanvas';



let shareType = 'screen';
let audio = null;
let audioDisconnect = null;
let videoDisconnect = null;
let audiovideoDisconnect = null;
let previousAudioList = [];
let previousVideoList = [];

const DummyVideoView = forwardRef((props, ref) => {
    const [showControls,setShowControls] = useState(false);
    const makeFullScreen = () => {
        let div = ref.current
        if (div.requestFullscreen) 
            div.requestFullscreen();
        else if (div.webkitRequestFullscreen) 
            div.webkitRequestFullscreen();
        else if (div.msRequestFullScreen) 
          div.msRequestFullScreen();
      }
    return (
        <div style={{width:props.rightView?"70vw":"100vw", height:"96vh", position:"absolute", top:'2vh', left:0, bottom:'2vh', right:0}} className='r-c-c'>
        <div style={{position:"relative", width:"100%", height:"100%"}} className='r-c-c'>
        <video onClick={()=>{
          setShowControls(!showControls)
        }} ref={ref} autoPlay playsInline 
        controls={false} 
        muted={props.muted}
        style={{width:"100%", height:"100%", objectFit:"contain", visibility:props.visible!=undefined?props.visible?"visible":"hidden":"visible",  zIndex:!props.visible?0:1}}>
    
        </video>
                  {
                    showControls?
                  <div className="r-c-fe" style={{position:"absolute", bottom:5, height:40, width:"100%", background:"black", opacity:0.5}}>
                    <div style={{color:"white", opacity:1}} className='m-r-20 r-c-c cursor-pointer' onClick={makeFullScreen}><AiOutlineFullscreen style={{color:"white", fontSize:25, color:"white", opacity:1}}/></div>
                  </div>
                    :null
                    }
                    </div>
        </div>
    )
})


const SubjectInfo = ({subjectname, standardNames})=>{
    return (
        <div className='disable-text-selection'>
            <div className="black" style={{fontSize:14, fontWeight:'600'}}>
                {subjectname}
            </div>
            <div className="black opacity50" style={{fontSize:11}}>
                {standardNames&&standardNames.length>0
                ?
                standardNames?.map((standardName, index)=>(
                    <>
                        <> { index != 0 ? <>•</> : null} </>
                        <> { standardName} </>
                    </>
                ))
                :
                null
                }
            </div>
        </div>
    )
}




const TopRow = ({setRightViewType, userCount, setRightView, 
        rightView, rightViewType, broadcastSelectedClass, 
        isPublishing, onMenuClick, isMenuVisible, isRecordingState,
        recordTimer, isInternetSlow
        })=>{
    const RecordInfo = ({timeleft})=>{
        const displayTime = parseInt(timeleft/3600).toString().padStart(2, '0')+' : '+parseInt((timeleft%3600)/60).toString().padStart(2, '0')+' : '+`${timeleft%60}`.padStart(2, '0')
        return (
            <div className="r-ac topRowCards p-l-15 p-r-15 m-r-20 disable-text-selection">
                    <div className="recRed bold-900 liveBlink m-r-5" style={{fontSize:12}}> REC • </div>
                    <div className="white bold" style={{fontSize:12}}> {displayTime} </div>
            </div>
        )
    }
    const ParticipantInfo = ()=>{
        return (
            <div className="topRowCards r-c-c cursor-pointer recBtn disable-text-selection" style={{width:60, height:30}} onClick={()=>{
                if ((rightView==false)||(rightViewType=='')){
                    setRightView(true);
                    setRightViewType('participantsView');
    
                }
                else{
                    if (rightViewType=='participantsView'){
                        setRightView(false); 
                    }
                    else{
                    setRightViewType('participantsView');}
                }
            }}>
                <div className="r-c-c m-r-10"> 
                    <img style={{height:20, width:20}}  src={require('./assets/Group 11815.svg').default} alt="" />
                </div>
                <div className="white" style={{fontSize:11}}> {userCount} </div>
            </div>
        )
    }
    const MenuButton = () => (
        <div className='topRowCards r-c-c m-l-10 cursor-pointer recBtn disable-text-selection'  style={{width:60, height:30,borderRadius:5, zIndex:10}} onClick={onMenuClick}>
        <div style={{fontSize:11, color:"white"}}>        
        {isMenuVisible?"Hide":'Menu'}</div>
        </div>
    )
    const SlowInternetWarning = () => (
        <>
        {
        isPublishing!=0 && isInternetSlow?
        <div className='r-c-fs disable-text-selection'  style={{backgroundColor: isInternetSlow?'#FF6347':'#006A4E', paddingTop:5, paddingBottom:5, paddingLeft:10, zIndex:1500}}>
            <div className="r-c-c m-r-10"> 
                {   isInternetSlow? 
                    <Loader style={{fontSize:16, color:"white"}}/>
                    :
                    <MdNetworkWifi style={{ color:"white", fontSize:20}}/>
                }
            </div>
        <div style={{fontSize:11, color:"white", fontWeight:"bold"}}>        
            {isInternetSlow ?"Your network connection is poor!":'High'}
        </div>
        </div>
        :null
        }
        </>
    )
    return(
        <>

        {isPublishing!=0 && isInternetSlow?
                <SlowInternetWarning/>
            :null
        }

            <div className={`${isMenuVisible?'r-jsb':'r-c-fe'} p-20 absolute`} style={{top:10, width:rightView==false?"100%":"75%"}}>
                {
                    isMenuVisible?
                    <>
                    <div>
                    <SubjectInfo subjectname={broadcastSelectedClass && broadcastSelectedClass.subject?.name} 
                                 standardNames={ (broadcastSelectedClass&&broadcastSelectedClass.standards?.length>0) && [...broadcastSelectedClass.standards?.map((standard, index)=>{return `${standard.std} ${standard.section}`})]}
                                    />
                    </div>
                    <div className="display-flex" style={{zIndex:105}}>
                        {
                            isPublishing==2 && isRecordingState===2?
                            <RecordInfo timeleft={recordTimer}/>
                            :null
                        }
                        <ParticipantInfo />
                        <MenuButton/>
                    </div>
                    </>
                    :
                    <div className="display-flex" style={{zIndex:105}}>
                        <MenuButton/>
                    </div>
                }

            </div>
        </>
    )
}

const VideoBoxRow = ({rightView, streamId, ifAnyOnePublishing, reloadToggler, toggleReloader, isMenuVisible, activeClass})=>{
    return(
        <div style={{position:"absolute", top:0, bottom:0, left:0, right:0}}>
                {
            ifAnyOnePublishing?
            <div style={{width:"100%", height:"100%"}} className='r-c-c'>
            <VideoPlayer streamId={streamId} 
                         contentId={activeClass?.occurance?.id}
                         style={{width:"100%", height:isMenuVisible?"60vh":"100vh"}}/>
            </div>
            :
            <div style={{height:"100%", width:"100%"}} className="r-c-c">
            <div style={{fontSize:30, textAlign:"center", width:300, fontFamily:"sans-serif", color:"black"}}>Stream will start playing automatically when it's live</div>
            </div>
                }
        </div>
    )
}

let roomClient = null;

const ShareScreenPopover = ({onShareClick, setPopover}) => {
    return (
    <div style={{color:"white"}}>
        <div className="r-c-fs m-b-10 cursor-pointer" onClick={()=>{
            shareType = 'screen'
            onShareClick();
            setPopover(false);
        }}>
            <div className="m-r-10">
                <PiProjectorScreenChartFill/>
            </div>
            <div>
                Whiteboard
            </div>
        </div>
        <div className="r-c-fs m-b-10 cursor-pointer" onClick={()=>{
            shareType = 'camera'
            onShareClick();
            setPopover(false);
        }}>
            <div className="m-r-10">
                <BsFillCameraReelsFill/>
            </div>
            <div>
                Camera
            </div>
        </div>
    </div>)
}
const ActionBoxRow = ({adapter, chat, sessionuser, isPublishing, onShareClick, ifAnyOnePublishing, 
    chatClient, onMicClick, onCameraClick, streamId, onLeave, onParticipantsClick, isCamOn, 
    micIsMuted, onPrivateChatClick, onGroupChatClick, popover, setPopover, whiteboard, setWhiteboard,
    onRecordClick, isRecordingState, groupUnreadCount, privateUnreadCount, onlineCount}) =>{
    const Wrapper = (props) => {
        if (isPublishing===0){
            return (
                <Popover open={popover} color={"#3e3e3e"} content={<ShareScreenPopover onShareClick={onShareClick} setPopover={setPopover}/>} title={null} trigger="click" onOpenChange={setPopover} style={{zIndex:10}}>
                    {props.children}
                </Popover>
            )
        }else{
            return (
                <>
                {props.children}
                </>
            )
        }
    }
    return(
        <div className="r-jc">
            <div id="broadcastButtonRow" className="r-c-sa absolute" style={{bottom:20}}>
            {!sessionuser.access.includes("schedule-student")
                ?
                <>
                
                <ActionButtons label={'Participants'} 
                                icon={<img style={{height:"40%", width:"40%"}}  src={require('./localComponent/assets/Group 11815.svg').default} alt="" />}  
                                onClick={onParticipantsClick}
                                unreadCount={onlineCount}
                                badgeStyle={{backgroundColor:"green"}}/>
                <ActionButtons label={'Private Chat'} 
                                icon={<img style={{height:"40%", width:"40%"}}  src={require('./localComponent/assets/Group 11867.svg').default} alt="" />} 
                                onClick={onPrivateChatClick}
                                unreadCount={privateUnreadCount}/>
                <ActionButtons label={'Group Chat'} 
                                icon={<img style={{height:"40%", width:"40%"}}  src={require('./localComponent/assets/Group 11830.svg').default} alt="" />} 
                                onClick={onGroupChatClick}
                                unreadCount={groupUnreadCount}/>
                {
                    shareType==='screen' && isPublishing===2?
                    <ActionButtons label={isCamOn?'Camera on':'Camera off'} 
                    icon={
                        <>{isCamOn?<BsCameraVideoFill color="white" style={{height:"40%", width:"40%"}}/>
                            :<img style={{height:"40%", width:"40%"}}  src={require('./localComponent/assets/Group 11810.svg').default} alt="" />
                        }</>} 
                    onClick={onCameraClick}/>
                    :null
                }
                {  shareType==='camera' && isPublishing===2?
                    <ActionButtons label={whiteboard?'Camera':'Whiteboard'}
                    icon={<AiFillSwitcher color="white" />} onClick={()=>{setWhiteboard(!whiteboard)}}/>
                    :null
                }
                <ActionButtons label={micIsMuted?'Mic off':'Mic on'} 
                                type={'mic'}
                                icon={
                                    <> {micIsMuted
                                        ?
                                            <img style={{height:"40%", width:"40%"}}  src={require('./localComponent/assets/Group 11818.svg').default} alt="" />
                                        :
                                            <img style={{height:"40%", width:"40%"}}  src={require('./localComponent/assets/Group 11777.svg').default} alt="" />
                                        }
                                    </>} 
                                onClick={onMicClick}/>
                    <Wrapper>
                        <ActionButtons label={isPublishing===2?'Stop sharing':'Share'} 
                                        icon={
                                            <> {
                                                isPublishing==2?
                                                <MdStopScreenShare color={"white"} size={10}/>
                                                :isPublishing==1?
                                                <Loader style={{fontSize:10}}/>
                                                :
                                                <img style={{height:"40%", width:"40%"}}  src={require('./localComponent/assets/Group11903.svg').default} alt="" />
                                                }
                                            </>} 
                                        onClick={()=>{
                                            if (isPublishing===0){
                                                setPopover(!popover)
                                            }else if(isPublishing===2){
                                                onShareClick()
                                            }
                                        }}/>
                    </Wrapper>
                    <ActionButtons label={'Leave'} 
                                icon={<img style={{height:"40%", width:"40%"}}  src={require('./localComponent/assets/Icon metro-phone.svg').default} alt="" />} 
                                onClick={onLeave}/>
                    {
                        isPublishing===2?
                        <ActionButtons label={isRecordingState===2?'Stop recording':isRecordingState===0?'Start recording':''} 
                        icon={
                            <> {
                                isRecordingState==2?
                                <BsFillRecord2Fill color={"red"} size={30}/>
                                :isRecordingState==1?
                                <Loader style={{fontSize:10}}/>
                                :
                                <div className="bold-900 rfbc0p8" style={{backgroundColor:"red", borderRadius:10, padding:2}}>REC</div>
                                }
                            </>} 
                        onClick={onRecordClick}/>
                        :null
                    }
                </>
                :
                <>
                <ActionButtons label={'Group Chat'} 
                                icon={<img style={{height:"40%", width:"40%"}}  src={require('./localComponent/assets/Group 11830.svg').default} alt="" />} 
                                onClick={onGroupChatClick}
                                unreadCount={groupUnreadCount}/>
                <ActionButtons label={'Leave'} 
                                icon={<img style={{height:"40%", width:"40%"}}  src={require('./localComponent/assets/Icon metro-phone.svg').default} alt="" />} 
                                onClick={onLeave}/>
                <ActionButtons label={'Private Chat'} 
                                icon={<img style={{height:"40%", width:"40%"}}  src={require('./localComponent/assets/Group 11867.svg').default} alt="" />} 
                                onClick={onPrivateChatClick}
                                unreadCount={privateUnreadCount}/>
                </>
                }
            </div>
        </div>
    )
}
const ChatBoxColumn = ({}) => {
    return(
        <div>

        </div>
    )
}
function useMemoizeArgs(args) {
    const ref = useRef();
    const prevArgs = ref.current;
    const argsAreEqual =
          prevArgs !== undefined && JSON.stringify(args)===JSON.stringify(prevArgs)
    useEffect(() => {
        if (!argsAreEqual) {
          ref.current = args;
        }
    });
    return argsAreEqual ? prevArgs : args;
  }
let intervalId=null;
let t1 = null
let broadcastHealthTimer = null;

function Broadcast({rightView, setRightViewType, setMuteState, 
                    setCameraState, clearGroupChat, isCamOn, 
                    userCount, updateUserCount, updateUserList, 
                    addMessageToGroup, sessionuser, setUserType, userType, setRightView, 
                    rightViewType,streamId, addMessageToPrivate, broadcastSelectedClass, 
                    history, broadcastStudentsList, postChat, broadcastLoader, setStreamId,
                    micIsMuted, clearPrivateChat,
                    activeClass, getVideoResourceSections, postRecordedVideoLesson,
                    sectionList, recordCount, postRecordCount, getRoomData, updateUnreadCount, unreadCount,
                    usersList, BroadcastRecordingEdit, setLocalRecordingName, localRecordingName, cardData
                }){
    const [inc, setInc] = useState(0);
    const callTimestampsRef = useRef([]);
    const processIdRef = useRef('0');
    const [isRecordModalVisible, setRecordModalVisible] = useState(false);
    const [isAccessModalVisible, setAccessModalVisible] = useState(false);
    const [isRecordDownloadModalVisible, setRecordDownloadModalVisible] = useState(false);
    const isRecording = useRef(0);
    const [isInternetSlow, setIsInternetSlow] = useState(false);
    const [isRecordingState, setRecording] = useState(0);
    const [isPublishing, setPublishing] = useState(0);
    const isPublishingRef = useRef(0);
    const [publishers, setPublishers] = useState({});
    const eventListeners = useRef(null);
    const [connecting, setConnecting] = useState(sessionuser?.role?.includes('Student')?false:true);
    const [whiteboard, setWhiteboard] = useState(false);
    const [isMenuVisible, setMenuVisible] = useState(false);
    const isFileCreatingRef = useRef(false);
    const [loadplayer, setloadplayer] = useState(false);
    const localVideoRef = useRef(null);
    const videoStreamRef = useRef(null)
    const ispublishingmanually = useRef(true);
    const ismanuallystoppingrecording = useRef(false);
    const ismanuallystoppedproducing = useRef(false);
    const [popover, setPopover] = useState(false);
    const [selectedSection, setSelectedSection] = useState({name:''});
    const [recordTimer, setRecordTimer] = useState(0); 
    const isAttemptingRecord = useRef(false);
    const isAttemptingShare = useRef(false);
    const micStateRef = useRef(null);
    const leaveClickedRef = useRef(false);
    const isOfflineRecordingRef = useRef(true);
    useEffect(()=>{
        micStateRef.current = micIsMuted
    }, [micIsMuted]);
    useEffect(()=>{
        if (isPublishing!=2){
            isFileCreatingRef.current = false
            if (isPublishing==0){
                shareType = 'screen'
                roomClient?.stopcamera();
                setMuteState(true)
                isAttemptingShare.current = false
            }
        }else if(isPublishing===2){
            if (isRecording.current===2 && ispublishingmanually.current===false){
                throttledRecordingReconnect.current();
            }
        }
    }, [isPublishing])

    useEffect(()=>{
        if (isRecordingState===0){
            isAttemptingRecord.current = false
        }
    }, [isRecordingState]);

    const [start, stop] = useInterval(()=>{
        if (isRecording.current===2){
            setRecordTimer(recordTimer+1)
        }
    }, 1000);
    const audioVideoNotification = (audioelement, text="Error") => {
        if (window.location.pathname==='/broadcast'){
            Notification.error("", text)
            audioelement.play();
        }
    }
    const throttledReconnect = useRef(Utils.throttleHigh((stopTrack) => {
        const now = Date.now();
        callTimestampsRef.current.push(now);
        callTimestampsRef.current = callTimestampsRef.current.filter(timestamp => now - timestamp <= 60000);
        
        // Check if the function has been called more than 10 times within 50 seconds
        if (callTimestampsRef.current?.length > 5) {
            audioVideoNotification(audio, "Connection interrupted, join again to resume the class")
            history.goBack();
            // You can add additional logic here, such as preventing further calls
        }
        ispublishingmanually.current = false
        isPublishingRef.current = 1;
        setPublishing(1);
        if (stopTrack){
            roomClient.reconnectProducer(true);
        }else{
            roomClient.reconnectProducer(false);
        }
      }, 2000, { leading: true, trailing: false }));
    
    const throttledRecordingReconnect = useRef(Utils.throttleHigh(() => {
        if (!isOfflineRecordingRef.current){
            isRecording.current = 1
            setRecording(1)
            setRecordModalVisible(true)
            roomClient.startRecord('default')
        }
      }, 2000, { leading: true, trailing: false }));

    const onAccessDeny = () => {
        setAccessModalVisible(false);
        Notification.error("", "Screen share access denied")
        isPublishingRef.current = 0
        setPublishing(0);
    }
    const onAccessGrant = () => {
        setAccessModalVisible(false);
        roomClient.produce('screenType');
    }
    const recordingStarted = () => {
        ismanuallystoppingrecording.current = false
        setRecordTimer(0);
        start();
        isRecording.current = 2
        setRecording(2)
        setRecordModalVisible(false)
    }
    const recordingStopped = () => {
        stop();
        isRecording.current = 0
        setRecording(0)
        setRecordModalVisible(false)
    }
    const eventCallback = (event, dataReceived) => {
        if (isPublishingRef.current===0 && event!='rtmpRecordStopped') return
    if (event==='trackEnded'){
        ismanuallystoppingrecording.current = true
        ismanuallystoppedproducing.current = true
        isPublishingRef.current = 1
        setPublishing(1)
        roomClient.closeProducer(shareType==='screen'?'screenType':'videoType');
        setTimeout(()=>{
            roomClient.closeProducer('audioType');
        }, 1000);
        if (isRecording.current===2&&isOfflineRecordingRef.current){
            setRecordDownloadModalVisible(true)
        }
    }else if(event==='audioTrackEnded'){
        ispublishingmanually.current = true
        isAttemptingShare.current = true
        isPublishingRef.current = 1
        setPublishing(1);
        audioVideoNotification(audioDisconnect, "An audio device has been disconnected")
    }
    else if(event==='rtmpRecordStarted'){
        recordingStarted();
    }else if(event==='rtmpRecordStopped'){
        if (ismanuallystoppingrecording.current){
            recordingStopped();
        }else{
            throttledRecordingReconnect.current();
        }
    }else if(event==='rtmpStarted'){
        if ((dataReceived && processIdRef.current!=dataReceived)) return
        if (ismanuallystoppedproducing.current){
            setMuteState(false);
        }else{
            if (micStateRef.current){
                roomClient.mute();
            }
        }
        isPublishingRef.current = 2
        setPublishing(2);
        ismanuallystoppedproducing.current = false
    }else if(event==='cameraOn'){
        setCameraState(true);
        if (localVideoRef.current){
            localVideoRef.current.srcObject = dataReceived;
            videoStreamRef.current = dataReceived;
        }
    }else if(event==='cameraOff'){
        setCameraState(false);
    }else if(event==='roomAlreadyExists'){
        // Notification.error("", "Room already exists");
    }else if(event==='rtmpRemoved'){
        if (isAttemptingShare.current===false || (dataReceived && processIdRef.current!=dataReceived)) return
        if (ismanuallystoppedproducing.current===true){
            roomClient.closeProducer('audioType');
            roomClient.closeProducer('videoType');
            roomClient.closeProducer('screenType');
            setTimeout(()=>{
                if (isRecording.current!=0){
                    if (isOfflineRecordingRef.current){
                        roomClient.stopLocalRecording();
                        setRecordDownloadModalVisible(true)
                        recordingStopped();
                    }
                }
                isPublishingRef.current = 0;
                setPublishing(0);
                setCameraState(false);
                setMuteState(true);
            }, 15000);
        }else{
            throttledReconnect.current(false);
        }
    }
    else if(event==='SharePermissionDenied'){
        isPublishingRef.current = 0;
        setPublishing(0);
        ismanuallystoppedproducing.current = true
        ismanuallystoppingrecording.current = true
        roomClient.closeProducer('audioType');
        setMuteState(true);
    }else if(event==='audioAdded'){
        if (navigator.userAgent.includes("Chrome")) {
            roomClient.produce(shareType==='screen'?'screenType':'videoType', ismanuallystoppedproducing.current?false:true)
          } else {
            // This fallback code is much less performant, but works
            setAccessModalVisible(true);
          }
    }else if(event==='fileNotCreating'){
        if (isPublishingRef.current===0||ismanuallystoppedproducing.current|| (dataReceived && processIdRef.current!=dataReceived)) return
        throttledReconnect.current(false);
    }else if(event==='consumererror'){
        if (isPublishingRef.current===0||ismanuallystoppedproducing.current) return
        throttledReconnect.current(false);
    }else if(event==='fileEndCreating'){
        if (isPublishingRef.current===0||ismanuallystoppedproducing.current||isFileCreatingRef.current||(dataReceived && processIdRef.current!=dataReceived) ) return
        throttledReconnect.current(false);
    }else if(event==='fileCreating'){
        if (isPublishingRef.current===0||(dataReceived && processIdRef.current!=dataReceived)) return
        isFileCreatingRef.current = true
    }else if(event==='producerTransportClose'){
        if (isPublishingRef.current===0||ismanuallystoppedproducing.current) return
        // throttledReconnect.current(false);
    }else if(event==='processIdGenerated'){
        processIdRef.current = dataReceived
    }else if(event==='internetQuality'){
        setIsInternetSlow(dataReceived>0.3)
    }
    };
    const socketCallback = (event, data) => {
        if (event==='muxError'){
        }else if(event==='connect'){
            clearTimeout(broadcastHealthTimer)
        }
        else if(event==='disconnect'){
            if (broadcastHealthTimer){
                clearTimeout(broadcastHealthTimer)
            }
            broadcastHealthTimer = setTimeout(() => {
                if (window.location.pathname==='/broadcast'){
                  Notification.error("", "Socket disconnected, join again to resume the class")
                  audio.play();
                  history.goBack();
                }
              }, 10000);
        }
    }
    const NotificationHandler = () => {
        if ((rightViewType=='groupChatView')&&(rightView==true)){
        }
        else{
            MessageNotification.group();     
        }
    }
    const onlineUserIds = useMemo(()=>{
        return usersList && usersList.length? usersList.filter((item)=>item.statustoshow==='online')?.map((item)=>item.user?.id?.toString()): []
      }, [usersList]);
    
    const ifAnyOnePublishing = useCallback(() => {
        return Object.keys(publishers).length && Object.keys(publishers).some((k)=>{
            return publishers[k] && (onlineUserIds.includes(k))
        })
        }, [publishers, usersList])
    const handlePublishEvent = (data) => {
        let isUpdated = false
        let newState = data.state;
        let newUser = data.user;
        if (data.state){
            setloadplayer(data.fileCreating)
        }
        if (!(newUser.id in publishers)){
            isUpdated = true
        }else{
            if(newState!=publishers[newUser.id]){
                isUpdated=true
            }
        }
        if (isUpdated){
            publishers[newUser.id] = newState
            setPublishers({...publishers})
        }
    }


    const clientChatCallback = (e) => {
        switch (e.event) {
          case "userList":
            let newUserList = [];
            e.data.forEach((item, index)=>{
            let newObj = {
                statustoshow: item.statusToShow,
            }
            newObj.user = {
                id: item.userId,
                userId: item.userId,
                role: item.role?.split(','),
                privilage: item.privilege?.split(','),
                image: item.image,
                name: item.userName??'user'
            }
            newUserList.push(newObj);
            })
            updateUserList(newUserList, broadcastStudentsList!=undefined?broadcastStudentsList:[])

              break;
          case "rcvChat":
            if(e.data.type=="Image"){
                addMessageToGroup({id:uuidv4(), 
                    type:"Image", 
                    image:e.data.data.image, 
                    createdAt: new Date(),
                    user: e.data.data.user})
                if (sessionuser.id==e.data.data.user.id){

                }else{
                    updateUnreadCount('groupIncrement')
                }
            }
            else if(e.data.type=='Text'){
                if (typeof e.data.data==='string'){
                    e.data.data = JSON.parse(e.data.data);
                  }
                const groupTextMessage = {
                                    type:e.data.type, 
                                    userId: e.data.userId, 
                                    user:e.data.data.user, 
                                    text: e.data.data.text, 
                                    createdAt: e.data.data.createdAt, 
                                    id:uuidv4()
                                    }
                if (sessionuser.id==e.data.data.user.id){
                    postChat(groupTextMessage, "Group");}
                else{
                    updateUnreadCount('groupIncrement');
                    }
                addMessageToGroup(groupTextMessage);
            }else if (e.data.type=='Event'){
                if (typeof e.data.data.user === 'string'){
                  e.data.data.user = JSON.parse(e.data.data.user);
                }
                if (!e.data.data.user?.access?.includes("schedule-student") && e.data.data.user.id!=sessionuser.id){
                  handlePublishEvent(e.data.data);
                }
              }
            if (sessionuser.id!=e.data.data.user.id && e.data.type!='Event'){
                NotificationHandler();
            }
            break;
        case 'privateChatRcv':
            if (e.data.type=="Image"){
                addMessageToPrivate( sessionuser.id==e.data.data.user.id?e.userId:e.data.data.user.id, {id:uuidv4(), 
                    type:"Image", 
                    image:e.data.data.image, 
                    createdAt: new Date(),
                    user: e.data.data.user})
                if (sessionuser.id==e.data.data.user.id){

                }else{
                    updateUnreadCount('privateIncrement', undefined, e.data.data.user.id);
                }
            }
            else if(e.data.type=='Text'){
                const privateTextMessage = {
                    type:e.data?.type, 
                    userId: e.data?.userId, 
                    user:e.data?.data?.user, 
                    text: e.data?.data?.text, 
                    createdAt: e.data?.data?.createdAt, 
                    id:uuidv4()
                }
                let reciever = sessionuser.id==e.data.data.user.id?e.userId:e.data.data.user.id;
                if (sessionuser.id==e.data.data.user.id){
                    postChat({...privateTextMessage, reciever:reciever}, "Private");
                }else{
                    updateUnreadCount('privateIncrement', undefined, e.data.data.user.id);
                  }
                addMessageToPrivate(reciever, privateTextMessage);
            }
            if (sessionuser.id!=e.data.data.user.id){
                MessageNotification.private(e.data.data.user.name);
            }
            break;    
        }
    }
    const getClient = useCallback(() => {
        return new ChatClient(setUpChatSocket(), sessionuser.id, streamId, sessionuser.name , sessionuser.privilage?.join(','), sessionuser.role?.join(',') , sessionuser.image, activeClass.occurance?.id, streamId?.split('xxxx')[0], sessionuser.org.url, clientChatCallback)
    }, [])
    const [chatClient, setChatClient] = useState(null);
    const [reloadToggler, toggleReloader] = useState(false);
    const [startPublisherInterval, stopPublisherInterval] = useInterval(()=>{
            if (isPublishing==2){
                chatClient?.sendGroupChat(sessionuser.id, streamId, 
                    {
                        type: 'Event',
                        data: {
                            state:true,
                            fileCreating: isFileCreatingRef.current,
                            user:sessionuser
                        },
                    }
                    )
            }else{
                chatClient?.sendGroupChat(sessionuser.id, streamId, 
                    {
                        type: 'Event',
                        data: {
                            state:false,
                            fileCreating: isFileCreatingRef.current,
                            user:sessionuser
                        },
                    }
                    )
            }

    }, 2000);
    const chatClientRef = useRef(null);
    const onUnload = (e) => {
        onUnmount();
    }
    const onContextMenu = (e) => {
            if (window.location.hostname == "localhost"){
                return
            }
            e.preventDefault();
    }
    const onkeydown = (e) => {
        if (e.key == 123) {
            e.preventDefault();
        }
        if (e.ctrlKey && e.shiftKey && e.key == 'I') {
            e.preventDefault();
        }
        if (e.ctrlKey && e.shiftKey && e.key == 'C') {
            e.preventDefault();
        }
        if (e.ctrlKey && e.shiftKey && e.key == 'J') {
            e.preventDefault();
        }
        if (e.ctrlKey && e.key == 'U') {
            e.preventDefault();
        }
    }
    const setUpListeners = useCallback(()=>{
        eventListeners.current = EventRegister.addEventListener('broadcast', data => {
            if (data==='connectionCompleted'){
              setConnecting(false)
            }
        });
      }, []);
    function isDeviceRemoved(plist, clist) {
        if (plist.length > clist.length) return true;
        if (plist.length < clist.length) return false;
        const ids1 = plist.map(device => device.deviceId).sort();
        const ids2 = clist.map(device => device.deviceId).sort();
        return !(ids1.every((id, index) => id === ids2[index]));
    }
    function updateDeviceList() {
        const audioList = [];
        const videoList = [];
        let count = 0;
        navigator.mediaDevices?.enumerateDevices().then((devices) => {
            console.log("878", devices)
          devices.forEach((device) => {
            const [kind, type, direction] = device.kind.match(/(\w+)(input|output)/i);
            device.direction = direction;
            if (type === "audio") {
              audioList.push(device);
            } else if (type === "video") {
              videoList.push(device);
            }
          });
          if (isDeviceRemoved(previousAudioList, audioList)) {
              count = 1
          }
          if (isDeviceRemoved(previousVideoList, videoList)) {
              count += 2
          }
          if (count===1){
              //audio removed
            //   audioVideoNotification(audioDisconnect, "An audio device has been disconnected")
          }else if(count===2){
              //video removed
              audioVideoNotification(videoDisconnect, "A video device has been disconnected")
          }else if(count===3){
              // both audio and video removed
              audioVideoNotification(audiovideoDisconnect, "An audio and a video device has been disconnected")
          }
          previousAudioList = audioList;
          previousVideoList = videoList;
        });
      }
    const throttledUpdateDeviceList = useCallback(Utils.throttleHigh(updateDeviceList, 500, {trailing:true}), []); ; 

    useEffect(() => {
        previousAudioList = [];
        previousVideoList = [];
        updateDeviceList();
        navigator.mediaDevices?.addEventListener("devicechange", throttledUpdateDeviceList);
        audio = new Audio(audiosrc)
        audioDisconnect = new Audio(audioDisconnectSrc);
        videoDisconnect = new Audio(videoDisconnectSrc);
        audiovideoDisconnect = new Audio(audiovideoDisconnectSrc);
        clearTimeout(broadcastHealthTimer);
        broadcastHealthTimer = null
        setUpListeners();
        window.addEventListener("beforeunload", onUnload);
        document.addEventListener('contextmenu', onContextMenu);
        document.addEventListener('keydown', onkeydown);
        if (!sessionuser.role.includes('Student')){
            roomClient = new RoomClient({ clientName:'aulasstudent', room_id: `${streamId}`, refer:false, user:sessionuser, name: sessionuser.name, user_id:sessionuser.id, socket:setUpRoomClientSocket(`/${Utils.getBroadcastUrls('server')}.io`, history, socketCallback), eventCallback:eventCallback, setStreamCallback: setStreamCallback})
        }
        chatClientRef.current = getClient()
        setChatClient(chatClientRef.current)
        setUserType();
        setCameraState(false);
        setMuteState(true);
        if (!sessionuser.access.includes("schedule-student")){
            startPublisherInterval();
        }
          return () => {
                onUnmount();
                window.removeEventListener("beforeunload", onUnload)
                document.removeEventListener('contextmenu', onContextMenu);
                document.removeEventListener('keydown', onkeydown);
                EventRegister.removeEventListener(eventListeners.current)
                }
    }, []);
    const onShareClick = ()=>{
        if (isRecording.current===1) return
        setWhiteboard(false);
        if(ifAnyOnePublishing()){
            Notification.error("", "Stop the existing stream, to start publishing");
            return
        }
        ismanuallystoppedproducing.current = true
        ismanuallystoppingrecording.current = true
        if (isPublishing==2){
            if (isRecording.current===2){
                if (isOfflineRecordingRef.current){
                    // roomClient.stopLocalRecording()
                    // recordingStopped();
                    // setRecordDownloadModalVisible(true)
                }else{
                    recordingStopped();
                }
            }
            roomClient.closeProducer(shareType==='screen'?'screenType':'videoType');
            setTimeout(()=>{
                roomClient.closeProducer('audioType');
            }, 1000);
            isAttemptingShare.current = true
            isPublishingRef.current = 1
            setPublishing(1)
        }else if(isPublishing==0){
            callTimestampsRef.current = [];
            ispublishingmanually.current = true
            isAttemptingShare.current = true
            isPublishingRef.current = 1
            setPublishing(1);
            roomClient.produce('audioType');
        }
    }
    const setStreamCallback = (audio, stream) => {

    }
    const onMicClick = () => {
        if (isPublishing!=2){
            return
        }
        if (micIsMuted){
            roomClient.unMute();
            setMuteState(false);
        }else{
            roomClient.mute();
            setMuteState(true);
        }
    }
    const onCameraClick = () => {
        if (isPublishing!=2){
            return
        }
        if (isCamOn){
        roomClient.stopcamera();
        }else{
            roomClient.opencamera();
        }
    }
    const onRecord = () => {
        setRecordModalVisible(true);
    }
    const onCancel = () => {
        setRecordModalVisible(false);
    }
    const registerFileName = (fileName) => {
        const params = {
            fileName: fileName,
            roomName: streamId?.split('xxxx')[1],
            hideMsg:true
          }
        if (isOfflineRecordingRef.current){
            if (!(streamId in localRecordingName)){
                if (cardData){
                    cardData.recordingName = fileName
                }
                BroadcastRecordingEdit(params)
                setLocalRecordingName(streamId, fileName);
            }
        }else{
            if (activeClass.occurance.videoResourceIds?.length && recordCount==0){
                let params = {
                    videoResourceIds:activeClass.occurance?.videoResourceIds,
                    sectionId: selectedSection.id===-1?undefined:selectedSection.id,
                    sectionName: selectedSection.name,
                    fileName: fileName,
                    streamId: streamId,
                    roomName: streamId?.split('xxxx')[1],
                    fileUrl: streamId,
                  }
                postRecordedVideoLesson(params, ()=>{}, ()=>{})
              }else{
                postRecordCount({roomId:streamId?.split('xxxx')[1]}, ()=>{}, ()=>{})
              }
              if (recordCount===0){
                if (cardData){
                    cardData.recordingName = fileName
                }
                BroadcastRecordingEdit(params)
              }
        }
    }
    const onConfirm = (fileName, isOffline=true) => {
        if (isRecording.current==1){
            return
        }
        fileName = fileName?fileName:' '
        fileName = fileName.trim(' ')?.split(' ').join('_namespace_')
        if (isRecording.current===0){
            isOfflineRecordingRef.current = isOffline
        }
        if (isOfflineRecordingRef.current){
            if (isRecording.current===0){
                registerFileName(fileName)
                roomClient.startLocalRecording(shareType==='screen'?'screenType':'videoType');
                recordingStarted();
            }else if (isRecording.current===2){
                roomClient.stopLocalRecording();
                recordingStopped();
                setRecordDownloadModalVisible(true)
            }
            return
        }else{
            if (isRecording.current==2){
                ismanuallystoppingrecording.current = true
                isRecording.current = 1
                isAttemptingRecord.current = true
                setRecording(1)
                roomClient.stopRecord()
            }else if (isRecording.current==0){
                isRecording.current = 1
                setRecording(1)
                registerFileName(fileName)
                isAttemptingRecord.current = true
                roomClient.startRecord(fileName)
            }
            toggleReloader()
        }
    }

    const onDownloadLocalRecording = () => {
        recorder.externalDownloadRecording(streamId, (streamId in localRecordingName)?localRecordingName[streamId]:'default');
        setRecordDownloadModalVisible(false)
        if (leaveClickedRef.current){
            history.goBack();
        }
    }
    
    const onDeleteLocalRecording = () => {
        recorder.externalClearData();
        setRecordDownloadModalVisible(false)
        if (leaveClickedRef.current){
            history.goBack();
        }
    }

    const onLeave = () => {
        leaveClickedRef.current = true
        ismanuallystoppedproducing.current = true
        ismanuallystoppingrecording.current = true
        if (isRecording.current===2 && isOfflineRecordingRef.current){
            roomClient.stopLocalRecording();
            setRecordDownloadModalVisible(true);
        }else{
            history.goBack();
        }
    }

    const onUnmount = () => {
        navigator.mediaDevices?.removeEventListener("devicechange", throttledUpdateDeviceList);
        chatClientRef.current?.sendGroupChat(sessionuser.id, streamId, 
            {
                type: 'Event',
                data: {
                    state:false,
                    user:sessionuser
                },
            }
            )
        stopPublisherInterval();
        clearTimeout(broadcastHealthTimer);
        broadcastHealthTimer = null
        clearGroupChat();
        clearPrivateChat();
        setRightView(false);
        setRightViewType('');
        roomClient?.stopcamera();
        roomClient?.socket?.removeAllListeners();
        roomClient?.socket?.off();
        roomClient?.exit();
        chatClient?.disconnect();
        setChatClient(null);
        if (chatClientRef.current){
            chatClientRef.current?.disconnect();
            chatClientRef.current = null;
        }
    }
    const onMenuClick = () => {
        setMenuVisible((prev)=>!prev)
        closeRightView();
    }
    const closeRightView = () => {
        setRightView(false);
        setRightViewType('');
    }

    const toggleSideMenu = (type) => {
        if ((rightView==false)||(rightViewType=='')){
            setRightView(true);
            setRightViewType(type);

        }
        else{
            if (rightViewType==type){
                setRightView(false); 
            }
            else{
            setRightViewType(type);}
        }
    }

    const onParticipantsClick = () => {
        toggleSideMenu('participantsView')
    }
    const onPrivateChatClick = () => {
        toggleSideMenu('privateChatView')
    }
    const onGroupChatClick = () => {
        toggleSideMenu('groupChatView')
    }

    useEffect(()=>{
        if (videoStreamRef.current && localVideoRef.current){
            localVideoRef.current.srcObject = videoStreamRef.current
        }
    }, [whiteboard])


    let isAnyonePublishing = ifAnyOnePublishing()

    
      const privateUnread = useMemo(()=>{
        let c = 0;
        for (let id in unreadCount.private){
          c += unreadCount.private[id]
        }
        return c
      }, [unreadCount]);

      const onlineCount = useMemo(()=>{
        return usersList? Math.max(0, usersList.filter((item)=>item.statustoshow==='online').length):0
      }, [usersList]) 

    if (connecting){
        return (
            <div className='full-height full-width r-c-c'> <Loader style={{color:"#5448b7", fontSize:40}}/> </div>
        )
    }
    const shouldRenderPassiveCanvas = shareType !== 'camera' || (shareType!='camera' && isPublishing !== 2) || whiteboard;
    return (
        <div className="display-flex relative" style={{width:"100%", height:"101%", backgroundColor:"white"}}>
            <div className="full-height r-jsb-fc" 
                 style={{width:rightView==false?"100%":"75%", 
                        }}>
                <TopRow setRightViewType={setRightViewType} 
                        userCount={onlineCount} 
                        setRightView={setRightView} 
                        rightView={rightView}
                        rightViewType={rightViewType}
                        broadcastSelectedClass={broadcastSelectedClass}
                        isPublishing={isPublishing}
                        recordTimer={recordTimer}
                        onMenuClick={onMenuClick}
                        isMenuVisible={isMenuVisible}
                        isRecordingState={isRecordingState}
                        isInternetSlow={isInternetSlow}
                        />
                {(shareType==='screen' || whiteboard) && isCamOn?
                <VideoView key="localvideo" muted={true} ref={localVideoRef} style={{top:-parseInt(window.innerHeight/2)+200, left:window.innerWidth-300}} visible={isCamOn}/>
                :
                shareType==='camera' && isCamOn?
                <DummyVideoView  key="localvideo" muted={true} ref={localVideoRef} rightView={rightView} visible={isCamOn&&isPublishing!=0} micIsMuted={micIsMuted}/>
                :null
                }
                {
                shareType==='camera' && isPublishing===2 && !whiteboard?
                null:
                !sessionuser.access.includes("schedule-student") && !isAnyonePublishing
                ?
                null
                :
                <VideoBoxRow rightView={rightView} 
                             streamId={streamId}
                             sessionuser={sessionuser}
                             ifAnyOnePublishing={isAnyonePublishing&&loadplayer}
                             reloadToggler={reloadToggler}
                             toggleReloader={toggleReloader}
                             isMenuVisible={isMenuVisible}
                             activeClass={activeClass}
                             />
                }
                <PassiveCanvas
                    reactiveCanvas={false}
                    chat={chatClient}
                    streamId={streamId}
                    closeRightView={closeRightView}
                    visible={
                        shouldRenderPassiveCanvas &&
                        !sessionuser.access.includes("schedule-student") &&
                        !isAnyonePublishing
                    }
                    />
                {
                    isMenuVisible?
                        <ActionBoxRow adapter={roomClient} 
                        onParticipantsClick={onParticipantsClick}
                        chat={chatClient}
                        onCameraClick={onCameraClick}
                        streamId={streamId}
                        sessionuser={sessionuser}
                        chatClient={chatClient}
                        isPublishing={isPublishing}
                        onShareClick={onShareClick}
                        ifAnyOnePublishing={ifAnyOnePublishing}
                        onMicClick={onMicClick}
                        onLeave={onLeave}
                        isCamOn={isCamOn}
                        micIsMuted={micIsMuted}
                        popover={popover}
                        setPopover={setPopover}
                        onPrivateChatClick={onPrivateChatClick}
                        onGroupChatClick={onGroupChatClick}
                        whiteboard={whiteboard}
                        setWhiteboard={setWhiteboard}
                        onRecordClick={onRecord}
                        isRecordingState={isRecordingState}
                        groupUnreadCount={unreadCount.group}
                        privateUnreadCount={privateUnread}
                        onlineCount={onlineCount}
                        />
                    :null
                }
                <div style={{zIndex:10, backgroundColor:"green", position:"fixed", top:0, left:0}} className='r-c-c'>
                    {/* <div onClick={()=>{roomClient.startLocalRecording(shareType==='screen'?'screenType':'videoType')}}>start recording</div>
                    <div onClick={()=>{roomClient.stopLocalRecording()}}>stop recording</div>
                    <div onClick={()=>{recorder.downloadRecording()}}>save recording</div> */}
                
                    {/* <div onClick={()=>{roomClient.mockcloseProducer('audioType')}}>close audio</div>
                    <div onClick={()=>{roomClient.mockcloseProducer('videoType')}}>close video</div>
                    <div onClick={()=>{roomClient.mockcloseProducer('screenType')}}>close screen</div> */}
                    {/* <div onClick={()=>{roomClient.exitRoom()}}>exit</div>
                    <div onClick={()=>{roomClient.exit()}}>exit</div> */}
                    {/* <div onClick={()=>{roomClient.stopRecord()}}>mock close Recording</div> */}
                </div>
            </div>
            {rightView==true && isMenuVisible
            ?
                <RightColumn inc={inc} 
                            setInc={setInc} 
                            chat={chatClient}
                            chatClient={chatClient}
                            />
            :
            null}
            {isRecordModalVisible ?  <RecordingModal visible={isRecordModalVisible} 
                                title={'Confirm'} onCancel={onCancel} onConfirm={onConfirm} 
                                recordCount={recordCount}
                                localRecordingCount={(streamId in localRecordingName)?1:0}
                                fullmodalloading={isRecording.current===1} 
                                content={isRecording.current===2?'Are you sure you want to stop recording?':'Are you sure you want to record the class?'}
                                selectedSection={selectedSection}
                                isRecording={isRecordingState}
                                setSelectedSection={setSelectedSection}
                                getVideoResourceSections={getVideoResourceSections}
                                selectedSchedule={activeClass.occurance}
                                sectionList={sectionList}
                                />:null}
            {isAccessModalVisible?  <ConfirmationModal visible={isAccessModalVisible} 
                                title={'Confirm'} onCancel={onAccessDeny} onConfirm={onAccessGrant} content={'Allow your browser to share the contents of your screen ?'}/>:null}
            {isRecordDownloadModalVisible?  <ConfirmationModal visible={isRecordDownloadModalVisible} 
                    title={'Offline Recording Video'} isNoButtonRed={true} confirmTitle={'Download'} rejectTitle={'Delete'} onCancel={onDeleteLocalRecording} onConfirm={onDownloadLocalRecording} 
                    content={"If chosen 'delete', this recording will be lost forever"} maskClosable={false}/>:null}
            <PageDataLoader visible={broadcastLoader}/>
        </div>
    )
}

const mapStateToProps = state=>{
    const {rightView, isCamOn,streamId,
            userCount, userType, rightViewType, 
            broadcastLoader, micIsMuted, 
            sectionList, unreadCount, usersList, 
            localRecordingName} = state.broadcast;
    const {user} = state.session;
    const {broadcastSelectedClass, broadcastStudentsList, activeClass, recordCount, cardData} = state.schedule;
    const sessionuser = user;
    return {
        rightView, isCamOn, userCount, 
        sessionuser, userType, rightViewType,streamId,
        broadcastSelectedClass, broadcastStudentsList,
        broadcastLoader,
        micIsMuted,
        activeClass,
        sectionList,
        recordCount,
        unreadCount,
        usersList,
        localRecordingName,
        cardData
    }
}

const mapDispatchToProps = (dispatch)=>({
    setRightViewType: (newView) => dispatch(setRightViewType(newView)),
    setMuteState: (bool)=>dispatch(setMuteState(bool)),
    setCameraState: (bool)=>dispatch(setCameraState(bool)),
    clearGroupChat: ()=>dispatch(clearGroupChat()),
    updateUserList: (usersList, broadcastList) => dispatch(updateUserList(usersList, broadcastList)),
    updateUserCount: (usercount)=>dispatch(updateUserCount(usercount)),
    addMessageToGroup: (newMessage) => dispatch(addMessageToGroup(newMessage)),
    setUserType: ()=>dispatch(setUserType()),
    setRightView: (bool)=>dispatch(setRightView(bool)),
    addMessageToPrivate: (reciever, newMessage)=>dispatch(addMessageToPrivate(reciever, newMessage)),
    setStreamId: (newId)=>dispatch(setStreamId(newId)),
    postChat: (message, chatType)=>dispatch(postChat(message, chatType)),
    clearPrivateChat: ()=>dispatch(clearPrivateChat()),
    getVideoResourceSections: (id, callback) => dispatch(getVideoResourceSections(id, callback)),
    postRecordedVideoLesson: (params, callback, successCallback) => dispatch(postRecordedVideoLesson(params, callback, successCallback)),
    postRecordCount: (params, callback, successCallback) => dispatch(postRecordCount(params, callback, successCallback)),
    getRoomData: () => dispatch(getRoomData()),
    updateUnreadCount: (key, val, id) => dispatch(updateUnreadCount(key, val, id)),
    BroadcastRecordingEdit: (p, c, sc) => dispatch(BroadcastRecordingEdit(p, c, sc)),
    setLocalRecordingName: (key, val) => dispatch(setLocalRecordingName(key, val))
})

export default connect(mapStateToProps, mapDispatchToProps)(Broadcast)