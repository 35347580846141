import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Radio,
  Input,
  Select,
  Search,
  AutoComplete,
  Typography,
  Tooltip,
  DatePicker,
  Checkbox
} from "antd";
import { connect } from "react-redux";
import {
  CloseOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Date_Picker from "./datePicker";
import {
  fetchTransaction,
  generatePayRequest,
  getAllCLasses,
  getFeeOverView,
  getUserOfClass,
  setAllActivitiesFilter,
  setFeeDetails,
  setManual,
  setStudentName
} from "actions/feeManagement";
import moment from "moment";

// import Search from "antd/lib/transfer/search";

function removeElement(array, index) {
  let tmp = [];
  for (let i = 0; i < array.length; i++) {
    if (i == index) {
    } else {
      tmp.push(array[i]);
    }
  }
  return tmp;
}
function GeneratePayRequest({
  isModalVisible,
  handleOk,
  userRequestStatus,
  handleCancel,
  data,

  name,
  role,
  setIsModalVisible,
  setShowAddDetailsModal,
  showStudents,
  getAllCLasses,
  feeMangementData,
  setFeeDetails,
  getUserOfClass,
  setIsAdditionModal,
  generatePayRequest,
  getFeeOverView,
  setAllActivitiesFilter,
  fetchTransaction,
  setSelectedBtn,
  selectedBtn,
  showReasonModal,
  setShowReasonModal,
  manualButton,
  studentName,
  setStudentName,
  successcallback
}) {
  const { Search } = Input;
  const onSearch = (value) => console.log(value);
  const [value, setValue] = React.useState(1);
  // const [selectedBtn, setSelectedBtn] = useState(1);
  const [optionStudent, setOptionStudent] = useState([]);
  const [studentId, setStudentId] = useState(null);
  // const [studentName, setStudentName] = useState(null);
  const [autoSearchLoading, setAutoSearchLoading] = useState(false);
  const [sendRemainderLdr, setSendRemainderLdr] = useState(false);
  const [type, setType] = useState("individual");
  // const [isDate, setIsDate] = useState(feeMangementData?.generatePayDate)

  const text = (
    <span>
      <div style={{ width: "200px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p>
            UPI
            {/* /Rupay Card */}
          </p>
          <p>₹ 0</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p>Credit Card/Debit Card</p>
          <p>1.8 %</p>
        </div>
        {/* Credit Card/Debit Card 1.8 % */}
      </div>
    </span>
  );
  useEffect(() => { setStudentName('') }, [])
  useEffect(() => {
    // setFeeDetails("generatePayDate", null);
    // setFeeDetails("generatePayDescription", null);
    // setIsAdditionModal(true);
    getAllCLasses();
  }, []);
  useEffect(() => {
    // setFeeDetails("selectedStudentId", null);
    // setFeeDetails("selectedClassId", null);
  }, []);

  const [sendInvoice, setSendInvoice] = useState(feeMangementData?.dashBoardDetails?.invoiceType == "automatic" ? true : false)


  const searchResult = (query) =>
    query.map((item, index) => {
      return {
        key: index,
        value: item.id,
        label: (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>{item.name}</span>
          </div>
        ),
      };
    });
  const calcSum = (temp) => {
    let sum = 0;
    for (let i = 0; i < temp?.length; i++) {
      sum = sum + parseFloat(temp[i].amount);
    }
    return sum;
  };
  useEffect(() => {
    let additionAmount = feeMangementData?.generatePayAdditions?.length
      ? calcSum(feeMangementData?.generatePayAdditions)
      : 0;
    let discountAmount = feeMangementData?.generatePayDiscount?.length
      ? calcSum(feeMangementData?.generatePayDiscount)
      : 0;
    let totalAmount = feeMangementData?.generatePayReqAmount
      ? parseFloat(feeMangementData?.generatePayReqAmount)
      : 0;
    let finalPay = totalAmount - discountAmount + additionAmount;
    if (feeMangementData?.dashBoardDetails?.gst) {
      let temp = finalPay ? finalPay * 0.18 : 0;
      temp = parseFloat(temp + finalPay);
      setFeeDetails("finalFeeRequestedWithGst", temp);
    } else {
      setFeeDetails("finalFeeRequestedWithGst", finalPay);
    }
    setFeeDetails("finalFeeRequested", finalPay);
  }, [
    feeMangementData?.generatePayAdditions?.length,
    feeMangementData?.generatePayDiscount?.length,
    feeMangementData?.generatePayReqAmount,
  ]);

  useEffect(() => {
    setOptionStudent(
      feeMangementData?.allStudents
        ? [...searchResult(feeMangementData?.allStudents)]
        : []
    );
  }, [feeMangementData?.allStudents]);
  const onSelectStudent = (val, obj) => {
    if (val) {
      setStudentId(val);
      setFeeDetails("selectedStudentId", [val]);
      setStudentName(obj?.label?.props?.children?.props?.children);
    } else {
      setStudentId(null);
      setStudentName(null);
      setFeeDetails("selectedStudentId", null);
    }
  };
  const handleChangeStudent = (val, obj) => {
    if (val) {
      setStudentName(obj?.label?.props?.children?.props?.children);
      setFeeDetails(
        "selectedStudentName",
        obj?.label?.props?.children?.props?.children
      );
    } else {
      setStudentName(null);
      setFeeDetails("selectedStudentName", null);
    }
  };
  const handleSearchStudent = (val) => {
    if (val) {
      setStudentName(val);
    } else {
      setStudentName(null);
    }
    setAutoSearchLoading(true);
    getUserOfClass(() => setAutoSearchLoading(false));
  };
  function disabledDate(current) {
    return current < moment().startOf('day');
  }
  return (
    <div style={{ fontFamily: "roboto" }}>
      <Modal
        maskClosable={false}
        className="generatePayModalB"
        okText={"SEND REQUEST"}
        cancelText={"CANCEL"}
        title="Basic Modal"
        visible={isModalVisible}
        okButtonProps={{
          loading: sendRemainderLdr,
          disabled: !(
            feeMangementData?.generatePayDate &&
            feeMangementData?.generatePayReqAmount &&
            // feeMangementData?.selectedClassId &&
            feeMangementData?.generatePayDescription &&
            // feeMangementData?.selectedClassId &&
            (selectedBtn == 1
              ? feeMangementData?.selectedStudentId?.length
                ? true
                : false
              : selectedBtn)
          ),
        }}
        onOk={() => {
          if (feeMangementData?.dashBoardDetails?.kycStatus !== 'Approved') {
            setShowReasonModal(true);
            setIsModalVisible(false);
          } else {
            setSendRemainderLdr(true);
            generatePayRequest(
              selectedBtn,
              sendInvoice,
              () => {
                setSendRemainderLdr(false);
              },
              () => {
                setIsModalVisible(false);
                setFeeDetails("generatePayDate", null);
                setFeeDetails("generatePayDescription", null);
                setFeeDetails("generatePayReqAmount", null);
                setFeeDetails("generatePayAdditions", null);
                setFeeDetails("generatePayDiscount", null);
                setFeeDetails("selectedClassId", null);
                setFeeDetails("selectedStudentName", null);
                setFeeDetails("setStudentsList", []);
                setAllActivitiesFilter("userId", null);
                setFeeDetails("selectedStudentId", null);
                setFeeDetails("finalFeeRequestedWithGst", null);
                setFeeDetails("selectedClassId", null);
                setStudentName('')
                setSelectedBtn(1);
                // tooglePageLoder(true);
                if (window.location.pathname == '/fee-management/course-details') {
                  successcallback();
                } else {
                  getFeeOverView();
                  fetchTransaction();
                }
              }
            );
          }
          // setIsModalVisible(false);
        }}
        onCancel={() => {
          setIsModalVisible(false);
          setFeeDetails("generatePayDate", null);
          setFeeDetails("generatePayDescription", null);
          setFeeDetails("generatePayReqAmount", null);
          setFeeDetails("generatePayAdditions", null);
          setFeeDetails("generatePayDiscount", null);
          setFeeDetails("selectedClassId", null);
          setFeeDetails("selectedStudentName", null);
          // setFeeDetails("setStudentsList", []);
          setFeeDetails("selectedStudentId", null);
          setFeeDetails("finalFeeRequestedWithGst", null);
          setFeeDetails("selectedClassId", null);
          setSelectedBtn(1);
          setStudentName(null)
        }}
      >
        <h2
          style={{
            fontFamily: "Roboto",
            textAlign: "center",
            color: "#594099",
            marginBottom: "0px",
            fontSize: "25px",
            fontWeight: "bold",
          }}
        >
          Generate Payment Request
        </h2>
        <div
          style={{ overflowY: "auto", fontFamily: "roboto", height: "500px" }}
        >
          {showStudents ? (
            <div style={{ marginLeft: "10px" }}>
              <p
                style={{
                  marginBottom: "0px",
                  color: "#636363",
                  marginBottom: "7px",
                  fontWeight: "bold",
                  fontFamily: "Roboto",
                  fontSize: "12px",
                }}
              >
                Student’s name
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {feeMangementData?.setStudentsList?.length != 0 &&
                  feeMangementData?.setStudentsList?.map((item1) => (
                    <div
                      style={{
                        borderRadius: "2px",
                        backgroundColor: "#594099",
                        width: "fit-content",
                        color: "white",
                        fontWeight: "bold",
                        padding: "5px",
                        margin: "3px",
                      }}
                    >
                      {item1.name}{" "}
                      <span
                        onClick={() => {
                          let temp = feeMangementData?.setStudentsList;
                          temp = temp.filter(
                            (item) => item.userId != item1?.userId
                          );
                          setFeeDetails("setStudentsList", temp);
                          let newUserIds = [];
                          for (let i = 0; i < temp?.length; i++) {
                            newUserIds?.push(temp[i]?.userId);
                          }
                          setFeeDetails("selectedStudentId", newUserIds);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <CloseOutlined />
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            <div>
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "16px",
                  marginLeft: "10px",
                }}
              >
                To whom do you want to send the request to?
              </div>
              <div>
                <Button
                  onClick={() => {
                    setSelectedBtn(1);
                  }}
                  style={
                    selectedBtn == 1
                      ? {
                        backgroundColor: "#594099",
                        color: "white",
                        fontWeight: "600",
                        height: "35px",
                        borderRadius: "20px",
                        width: "150px",
                      }
                      : {
                        color: "#636363",
                        fontWeight: "600",
                        height: "35px",
                        borderRadius: "20px",
                        width: "150px",
                        border: "1px solid #636363",
                      }
                  }
                >
                  Individual
                </Button>
                <Button
                  onClick={() => {
                    setSelectedBtn(2);
                    setFeeDetails("selectedStudentName", null);
                    setFeeDetails("selectedClassId", null);
                    setStudentName('')
                  }}
                  style={
                    selectedBtn == 2
                      ? {
                        backgroundColor: "#594099",
                        color: "white",
                        fontWeight: "600",
                        height: "35px",
                        borderRadius: "20px",
                        width: "150px",
                        marginLeft: "20px",
                      }
                      : {
                        // backgroundColor: "#594099",
                        marginLeft: "20px",
                        color: "#636363",
                        fontWeight: "600",
                        height: "35px",
                        borderRadius: "20px",
                        width: "150px",
                        border: "1px solid #636363",
                      }
                  }
                >
                  {" "}
                  Class
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "25px",
                  marginBottom: "25px",
                }}
              >
                <div style={{ width: "47%", marginLeft: "10px" }}>
                  <p
                    style={{
                      marginBottom: "0px",
                      color: "#636363",
                      marginBottom: "7px",
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                    }}
                  >
                    Class<span style={{ color: "red" }}> *</span>
                  </p>
                  <Select
                    placeholder="Select class"
                    defaultValue={null}
                    style={
                      selectedBtn == 2 ? { width: "205%" } : { width: "100%" }
                    }
                    // setFeeDetails("selectedClassId", null);
                    value={feeMangementData?.selectedClassId}
                    onChange={(e) => {
                      setFeeDetails("selectedClassId", e);
                      getUserOfClass();
                      setFeeDetails("selectedStudentName", null);
                      setStudentName('')
                    }}
                    // size="large"
                    getPopupContainer={(trigger) => trigger.parentElement}


                  >
                    {feeMangementData?.dashBoardDetails?.recentTransactions?.standards?.map(
                      (item) => (
                        <Option value={item.id}>
                          {item?.std}-{item?.section}
                        </Option>
                      )
                    )}
                  </Select>
                </div>
                {selectedBtn == 1 ? (
                  // <div style={{ marginLeft: "10px" }}>
                  // <p
                  //   style={{
                  //     marginBottom: "0px",
                  //     color: "#636363",
                  //     marginBottom: "7px",
                  //     fontFamily: "Roboto",
                  //     fontWeight: "bold",
                  //   }}
                  // >
                  // Student’s name/mobile
                  // </p>
                  //   <Search
                  // placeholder="input search text"
                  //     onSearch={onSearch}
                  //     style={{ width: 310 }}
                  //   />
                  // </div>
                  <div
                    style={{
                      marginBottom: "10px",
                      width: "320px",
                      marginLeft: "13px",
                    }}
                  >
                    {/* <Typography.Text style={{ marginBottom: "10px" }}>
                      Subject
                    </Typography.Text> */}
                    <p
                      style={{
                        marginBottom: "0px",
                        color: "#636363",
                        marginBottom: "7px",
                        fontFamily: "Roboto",
                        fontWeight: "bold",
                      }}
                    >
                      Student’s name/mobile
                      <span style={{ color: "red" }}> *</span>
                    </p>
                    {/* <br></br> */}
                    <AutoComplete
                      getPopupContainer={(trigger) => trigger.parentElement}
                      className="autoBp"
                      style={{
                        border: "1px solid #d9d9d9",
                        borderRadius: "3px",
                      }}
                      allowClear={true}
                      dropdownMatchSelectWidth={252}
                      className="autocompletetoupload"
                      options={optionStudent}
                      // value={feeMangementData}
                      value={studentName}
                      onChange={handleChangeStudent}
                      onSelect={onSelectStudent}
                      onSearch={handleSearchStudent}
                      loading={autoSearchLoading}
                      disabled={!feeMangementData?.selectedClassId}
                    >
                      <Input
                        prefix={<SearchOutlined />}
                        size="middle"
                        className="input-component"
                        style={{
                          background: "#FAFAFA 0% 0% no-repeat padding-box",
                          border: "none",
                        }}
                        placeholder="Student’s name/mobile"
                      />
                    </AutoComplete>
                  </div>
                ) : null}
              </div>
            </div>
          )}
          <div
            style={{ marginLeft: "10px", fontSize: "16px", fontWeight: "600", marginTop: 25 }}
          >
            Details
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              // backgroundColor: "red",
            }}
          >
            <div style={{ marginLeft: "10px", width: "50%" }}>
              <p
                style={{
                  marginBottom: "0px",
                  color: "#636363",
                  marginBottom: "7px",
                  fontWeight: "bold",
                  fontFamily: "Roboto",
                  fontSize: "12px",
                }}
              >
                Amount <span style={{ color: "red" }}> *</span>
              </p>

              <Input
                type="number"
                value={feeMangementData?.generatePayReqAmount}
                onChange={(e) => {
                  // if(e.target.value.indexOf("e")>=0){
                  //   setFeeDetails("generatePayReqAmount", feeMangementData?.generatePayReqAmount);

                  // }else{
                  e.target.value = e.target.value < 10000000 ? parseInt(e.target.value) : 0
                  if (Number(e.target.value) || e.target.value == "") {
                    setFeeDetails("generatePayReqAmount", e.target.value);
                  }
                  // }
                }}
                prefix="₹"
                style={{
                  width: "96%",
                  marginBottom: "20px",
                  border: "#E6E6E6 solid 1px",
                  borderRadius: "5px",
                  color: "#000000",
                  marginRight: "5px",
                }}
                placeholder="Enter amount"
              />{" "}
            </div>

            <div
              id="testHomeDropdown"
              style={{
                width: "49%",
                marginRight: "15px",
                marginTop: "-20px",
                position: "relative"
              }}


            >
              <p
                style={{
                  marginBottom: "0px",
                  color: "#636363",
                  marginBottom: "7px",
                  fontWeight: "bold",
                  fontFamily: "Roboto",
                  fontSize: "12px",
                }}
              >
                Due date<span style={{ color: "red" }}> *</span>
              </p>
              <DatePicker
                format={'DD-MM-YYYY'}
                // value={feeMangementData?.generatePayDate}
                // value={isDate}
                // selected={feeMangementData?.generatePayDate}
                getPopupContainer={(trigger) => trigger.parentElement}
                // getPopupContainer={trigger => trigger.parentElement}

                style={{
                  width: "100%",
                }}
                onChange={(e) => {
                  setFeeDetails("generatePayDate", e.format('YYYY-MM-DD'))
                  // { console.log("AA-feeManagement", feeMangementData?.generatePayDate) }

                }}
                allowClear={false}
                disabledDate={disabledDate}
              // getPopupContainer={() => document.getElementById("testHomeDropdown")}

              />
            </div>
          </div>
          <div style={{ marginLeft: "10px", width: "95%" }}>
            <p
              style={{
                marginBottom: "0px",
                color: "#636363",
                marginBottom: "7px",
                fontWeight: "bold",
                fontFamily: "Roboto",
                fontSize: "12px",
              }}
            >
              Description
              <span style={{ color: "red" }}> *</span>
            </p>
            <Input
              type="text"
              value={feeMangementData?.generatePayDescription}
              onChange={(e) => {
                if (e.target.value == " ") {
                  setFeeDetails("generatePayDescription", null);
                } else {
                  setFeeDetails("generatePayDescription", e.target.value);
                }
              }}
              style={{
                width: "102%",
                marginBottom: "20px",
                border: "#E6E6E6 solid 1px",
                borderRadius: "5px",
                color: "#000000",
                marginRight: "5px",
              }}
              placeholder="Enter description"
            />{" "}
          </div>
          <div style={{ marginLeft: "10px" }}>
            <p
              style={{
                marginBottom: "0px",
                color: "#636363",
                marginBottom: "7px",
                fontWeight: "bold",
                fontFamily: "Roboto",
                fontSize: "12px",
              }}
            >
              Amount details
            </p>
            <div
              style={{
                border: "1px solid #E6E6E6",
                borderRadius: "10px",
                padding: "10px",
                width: "98%",
              }}
            >
              <div
                style={{
                  fontFamily: "roboto",
                  fontWeight: "600",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  height: "40px",
                  justifyContent: "space-between",
                }}
              >
                <div>Fee amount</div>
                <div>
                  ₹{" "}
                  {feeMangementData?.generatePayReqAmount
                    ? feeMangementData?.generatePayReqAmount
                    : 0}
                </div>
              </div>
              {feeMangementData?.generatePayDiscount?.length != 0 &&
                feeMangementData?.generatePayDiscount?.map((item, index) => (
                  <div
                    style={{
                      fontFamily: "roboto",
                      fontWeight: "600",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: "40px",
                      color: "#1089FF",
                    }}
                  >
                    <div>
                      {/* {item?.name} */}
                      <Typography.Text
                        style={{ width: "100px" }}
                        ellipsis={{ tooltip: true }}
                      >
                        {item?.name}
                      </Typography.Text>
                      <span
                        onClick={() => {
                          let temp = feeMangementData?.generatePayDiscount
                            ?.length
                            ? feeMangementData?.generatePayDiscount
                            : [];

                          let temp1 = removeElement(temp, index);
                          setFeeDetails("generatePayDiscount", temp1);
                        }}
                        style={{
                          color: "red",
                          fontWeight: "500",
                          fontSize: "9px",
                          marginLeft: "5px",
                          border: "1px solid red",
                          borderRadius: "10px",
                          padding: "3px",
                          cursor: "pointer",
                        }}
                      >
                        remove
                      </span>{" "}
                    </div>
                    <div
                    // onClick={() => {
                    //   setIsAdditionModal(false);

                    //   setShowAddDetailsModal(true);
                    //   setIsModalVisible(false);
                    // }}
                    // style={{ cursor: "pointer" }}
                    >
                      -₹ {item?.amount}
                    </div>
                  </div>
                ))}
              <div
                style={{
                  fontFamily: "roboto",
                  fontWeight: "600",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "40px",
                  color: "#1089FF",
                }}
              >
                <div>Discount</div>
                <div
                  onClick={() => {
                    setIsAdditionModal(false);

                    setShowAddDetailsModal(true);
                    // setIsModalVisible(false);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  -₹ ADD
                </div>
              </div>
              {feeMangementData?.generatePayAdditions?.length != 0 &&
                feeMangementData?.generatePayAdditions?.map((item, index) => (
                  <div
                    style={{
                      fontFamily: "roboto",
                      fontWeight: "600",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: "40px",
                      color: "#FFA931",
                    }}
                  >
                    <div>
                      {/* {item?.name} */}
                      <Typography.Text
                        style={{ width: "100px" }}
                        ellipsis={{ tooltip: true }}
                      >
                        {item?.name}
                      </Typography.Text>
                      <span
                        onClick={() => {
                          let temp = feeMangementData?.generatePayAdditions
                            ?.length
                            ? feeMangementData?.generatePayAdditions
                            : [];

                          let temp1 = removeElement(temp, index);
                          setFeeDetails("generatePayAdditions", temp1);
                        }}
                        style={{
                          color: "red",
                          fontWeight: "500",
                          fontSize: "9px",
                          marginLeft: "5px",
                          border: "1px solid red",
                          borderRadius: "10px",
                          padding: "3px",
                          cursor: "pointer",
                        }}
                      >
                        remove
                      </span>{" "}
                    </div>
                    <div
                    // onClick={() => {
                    //   setIsAdditionModal(false);

                    //   setShowAddDetailsModal(true);
                    //   setIsModalVisible(false);
                    // }}
                    // style={{ cursor: "pointer" }}
                    >
                      +₹ {item?.amount}
                    </div>
                  </div>
                ))}
              <div
                style={{
                  fontFamily: "roboto",
                  fontWeight: "600",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "40px",
                  color: "#FFA931",
                }}
              >
                <div>Addition</div>
                <div
                  onClick={() => {
                    setIsAdditionModal(true);
                    setShowAddDetailsModal(true);
                    // setIsModalVisible(false);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  +₹ ADD
                </div>
              </div>
              {feeMangementData?.dashBoardDetails?.gst ? (
                <div
                  style={{
                    fontFamily: "roboto",
                    fontWeight: "600",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "40px",
                    color: "grey",
                  }}
                >
                  <div>GST</div>
                  <div>
                    +₹{" "}
                    {feeMangementData?.finalFeeRequested
                      ? (feeMangementData?.finalFeeRequested * 0.18).toFixed(2)
                      : 0}
                  </div>
                </div>
              ) : null}

              <div
                style={{
                  fontFamily: "roboto",
                  fontWeight: "600",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderTop: "1px solid #E6E6E6",
                  borderBottom: "1px solid #E6E6E6",
                  height: "40px",
                }}
              >
                <div>Total payable</div>
                <div>
                  ₹{" "}
                  {feeMangementData?.finalFeeRequestedWithGst
                    ? parseFloat(
                      feeMangementData?.finalFeeRequestedWithGst
                    ).toFixed(2)
                    : 0}
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginLeft: "20px", marginTop: "20px" }}>

            {

              feeMangementData?.dashBoardDetails?.invoiceType === "manual" ?


                <Radio.Group onChange={(e) => { setSendInvoice(e.target.value) }} value={sendInvoice}>
                  <p
                    style={{ fontWeight: "650", fontFamily: "roboto", fontSize: "16px", marginTop: "-12px" }}
                  >
                    Send Invoice
                  </p>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}
                  // onClick={() => { setManual(true) }}
                  >
                    No
                  </Radio>
                </Radio.Group> :
                null
            }



          </div>
          <div style={{ marginLeft: "10px", marginTop: "20px" }}>
            {/* <Button>RT</Button> */}
            <div
              style={{
                fontFamily: "roboto",
                fontWeight: "600",
              }}
            >
              Processing fee
              <Tooltip placement="rightTop" title={text}>
                <ExclamationCircleOutlined
                  style={{ marginLeft: "10px", color: "#1089FF" }}
                />
              </Tooltip>
            </div>
            <div style={{ color: "#636363", fontFamily: "roboto" }}>
              {" "}
              Processing fee payable by{" "}
              {feeMangementData?.dashBoardDetails?.feepayer}
            </div>
            <div
              style={{
                marginLeft: "440px",
                marginTop: "-25px",
                color: "#AEAEAE",
              }}
            >
              Go to settings to edit options
            </div>
          </div>
        </div>
      </Modal>
    </div >
  );
}

{
  /* export default DeleteModal; */
}
const mapStateToProps = (state) => {
  const { tab, dashBoardRole, usersByStats, userRequestStatus } =
    state.userManagement;
  const { feeMangementData, manualButton, studentName } = state.feeManagement;
  return {
    tab,
    dashBoardRole,
    usersByStats,
    userRequestStatus,
    feeMangementData,
    manualButton,
    studentName
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllCLasses: () => dispatch(getAllCLasses()),
  setFeeDetails: (key, val) => dispatch(setFeeDetails(key, val)),
  getUserOfClass: (callback) => dispatch(getUserOfClass(callback)),
  generatePayRequest: (val, sendInvoice, callback, successCallBack) => dispatch(generatePayRequest(val, sendInvoice, callback, successCallBack)),
  getFeeOverView: (callback) => dispatch(getFeeOverView(callback)),
  setAllActivitiesFilter: (key, val) => dispatch(setAllActivitiesFilter(key, val)),
  fetchTransaction: (callback) => dispatch(fetchTransaction(callback)),
  setStudentName: (val) => dispatch(setStudentName(val))
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneratePayRequest);
