import { Modal } from 'antd'
import { Font17, Heading } from 'components/Typography'
import React from 'react';
import "./userHomeStyles.css";
import appStore from '../../Assets/Footer/Appstore.svg'
import playStore from '../../Assets/Footer/googleplay.svg'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function DownloadAppModal({ showDownloadAppModal, setShowDownloadAppModal, userMarketPlaceHomeData, fromCheckoutPage, fromLoginPage }) {
    const history = useHistory()
    const handleCancel = () => {
        setShowDownloadAppModal(false)
        if (fromCheckoutPage || fromLoginPage) {
            history.push("/")
        }
    }
    return (
        <Modal
            centered
            className="modal-round-corner"
            visible={showDownloadAppModal}
            onCancel={handleCancel}
            onOk={handleCancel}
            footer={null}
            width={600}>
            <center className='m-t-20'>
                <Heading size="1.5rem" bold>Experience the learning on {userMarketPlaceHomeData?.orgName} app</Heading>
                <div className='m-t-20'><Font17>Download the app from Play Store/App Store to continue!</Font17></div>
                <div className="c-cb ashreya-app-content-child1">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <a
                            href={userMarketPlaceHomeData?.pSLink}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src={playStore}
                                alt="googlePlay"
                                loading="lazy"
                                className="playStore-image"
                            />
                        </a>
                        {userMarketPlaceHomeData?.orgName == "ashreya" &&
                            <img
                                loading="lazy"
                                alt="googlestoreQr"
                                className="qrcode-image"
                                src={require("../../Assets/aulasMarket/googlestoreQr.png").default}
                                style={{ border: 'none', borderRadius: 8 }}
                            />
                        }
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <a
                            href={userMarketPlaceHomeData?.aSLink}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src={appStore}
                                alt="appStore"
                                loading="lazy"
                                className="playStore-image"
                            />
                        </a>
                        {userMarketPlaceHomeData?.orgName == "ashreya" &&
                            <img
                                alt="appstoreQr"
                                loading="lazy"
                                className="qrcode-image"
                                src={require("../../Assets/aulasMarket/appstoreQr.png").default}
                                style={{ border: 'none', borderRadius: 8 }}
                            />
                        }
                    </div>
                </div>
            </center>
        </Modal>
    )
}

export default DownloadAppModal