import { setShowDownloadAppModal } from 'actions/loginNew';
import { Modal } from 'antd'
import { Button } from 'components/Button';
import React from 'react'
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const successImage = require("Assets/user/ill-success.svg").default;

function SuccessModal({ showRegisterSuccessModal, setRegisterSuccessModal, setShowDownloadAppModal }) {
    const handleCancel = () => {
        setRegisterSuccessModal(false)
    }
    const history = useHistory()
    return (
        <Modal
            centered
            className="modal-round-corner"
            visible={showRegisterSuccessModal}
            onCancel={handleCancel}
            onOk={handleCancel}
            footer={null}
            closable={false}>
            <div className='p-20'>
                <div className='r-c-c-c' style={{ gap: 20 }}>
                    <img src={successImage} alt="" style={{ height: 130, width: 130 }} />
                    <div style={{ fontSize: 20, fontWeight: 500 }}>Success!</div>
                    <div className='r-c-c-c'>
                        <div style={{ fontSize: 16, color: "#636363" }}>Your registration is complete.</div>
                        <div style={{ fontSize: 16, color: "#636363" }}>Download the mobile app to continue?</div>
                    </div>
                </div>
                <center>
                    <div className='r-c-sb m-t-40' style={{ width: "100%" }}>
                        <div style={{ fontSize: "0.87rem", cursor: "pointer", color: "#594099", fontWeight: 700 }} onClick={() => { handleCancel(); setShowDownloadAppModal(true) }}>DOWNLOAD APP</div>
                        <Button type="primary" onClick={() => { setRegisterSuccessModal(false); history.push("/") }}>BROWSE COURSES</Button>
                    </div>
                </center>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    const { } = state.loginNew;
    return {}
}

const mapDispatchToProps = (dispatch) => ({
    setShowDownloadAppModal: (val) => dispatch(setShowDownloadAppModal(val))
})

export default connect(mapStateToProps, mapDispatchToProps)(SuccessModal);