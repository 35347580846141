import React, { useEffect, useState } from 'react'
import { FiChevronDown, FiChevronRight } from 'react-icons/fi';
import { newCourseCreationCardStyle } from './courseCreationPage';
import { connect } from 'react-redux';
import { UploadCourseDocument, deleteCourseDocument, getCourseLanguages, setCreateCourseObj } from 'actions/courseManagement';
import AnimatedTextField from 'components/AnimatedTextField';
import ReactQuill from 'react-quill';
import { Typography, Upload, message } from 'antd';
import AddThumbnail from './addThumbnail';
import { CaretDownOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Api, Notification } from 'services';
import dragFile from '../../Assets/aulasMarket/Group 16705.svg'
import dragImage from '../../Assets/aulasMarket/Image 97.png'
import { IsJsonString } from './courseInformationPage';
import HtmlParser from 'react-html-parser';
import { stripHtmlTags } from './courseDetailsComp';
import { Select } from "antd";
import CropModal from './components/cropModal';
const { Option } = Select;

const { Dragger } = Upload;
const modeStyle = {
    fontWeight: 600,
    borderRadius: 50,
    height: 30,
    width: 80,
    marginRight: 10,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 12
}

const modules = {
    toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['link'],
    ],
}

function BasicInformationComp({ setCreateCourseObj, courseCreateObj, deleteCourseDocument, UploadCourseDocument, courseTypeName, isExpanded, setIsExpanded, location, clearValues, expandedSection, getCourseLanguages, courseLanguagesData, isBelowSeats, setIsBelowSeats }) {
    const [defualThumbnail, setDefaultThumbnail] = useState(null)
    const [isType, setIsType] = useState('');
    const [basicInfoFilledCount, setBasicInfoFilledCount] = useState(0)
    const [selectedOption, setSelectedOption] = useState(1);
    const [seatsValue, setSeatsValue] = useState('');
    const [isCropModalVisible, setIsCropModalVisible] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);
    const [imageFileName, setImageFileName] = useState('');
    const [isCrop, setIsCrop] = useState(false)




    useEffect(() => {
        setCreateCourseObj("langId", selectedOption)
        getCourseLanguages(() => { });
    }, [])


    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        const parsedValue = parseInt(inputValue, 10);


        // Check if the input value is below the enrolled count + 1
        if (parsedValue < courseCreateObj?.enrolledCount && inputValue !== '') {
            setIsBelowSeats(true);
            setSeatsValue('');  // Reset input if below threshold
        } else {
            setIsBelowSeats(false);
            setSeatsValue(inputValue); // Otherwise, set the input normally
        }

        // Update course object
        setCreateCourseObj("totalSeats", inputValue);
    };

    // const   handleInputBlur = () => {
    //     const value = parseInt(seatsValue, 10);
    //     if (value < courseCreateObj?.enrolledCount + 1 && value !== '') {
    //         setIsBelowSeats(true);   
    //         setSeatsValue('');       
    //     } else {
    //         setIsBelowSeats(false);  
    //     }
    // };


    const handleChange = (value) => {
        const selectedItem = courseLanguagesData?.items?.find(item => item.id === value);
        setSelectedOption(selectedItem?.id);
        setCreateCourseObj("langId", selectedItem?.id)
    };

    let quillReff = null;
    var details = {}
    const handleDescription = (content, delta, source, editor) => {
        if (source == 'user') {
            details = {
                mobile: quillReff.getEditorContents(),
                web: quillReff.editor.getContents()
            }
            setCreateCourseObj("courseDescription", JSON.stringify(details))
        }
    }
    let quillBrief = null
    var briefDetails = {}

    const handleBrief = (content, delta, source, editor) => {
        if (source == 'user') {
            briefDetails = {
                mobile: quillBrief.getEditorContents(),
                web: quillBrief.editor.getContents()
            }
            setCreateCourseObj("brief", JSON.stringify(briefDetails))
        }
    }
    const handleDescriptionkeyUp = (e) => {
        if (quillReff.getEditorContents() != '<p><br></p>' && quillReff.editor.getText().trim() != '') {
            details = {
                mobile: quillReff.getEditorContents(),
                web: quillReff.editor.getContents()
            }
            setCreateCourseObj("courseDescription", JSON.stringify(details))
        }
        else {
            setCreateCourseObj("courseDescription", '')
        }
    }
    const handleCourseBrief = (e) => {
        if (quillBrief.getEditorContents() != '<p><br></p>' && quillBrief.editor.getText().trim() != '') {
            details = {
                mobile: quillBrief.getEditorContents(),
                web: quillBrief.editor.getContents()
            }
            setCreateCourseObj("brief", JSON.stringify(details))
        }
        else {
            setCreateCourseObj("brief", '')
        }
    }

    const handleCropComplete = (croppedArea, croppedAreaPixels) => {
        // Logic when cropping is complete
    };

    const getCroppedImageBlob = (imageSrc, croppedAreaPixels) => {

        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = imageSrc;
            image.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                // Set canvas size to cropped area size
                canvas.width = croppedAreaPixels.width;
                canvas.height = croppedAreaPixels.height;

                // Draw the cropped image on the canvas
                ctx.drawImage(
                    image,
                    croppedAreaPixels.x,
                    croppedAreaPixels.y,
                    croppedAreaPixels.width,
                    croppedAreaPixels.height,
                    0,
                    0,
                    croppedAreaPixels.width,
                    croppedAreaPixels.height
                );

                // Convert the canvas to blob (image data)
                canvas.toBlob((blob) => {
                    if (blob) {
                        resolve(blob); // Resolve with the blob data
                    } else {
                        reject(new Error('Failed to create cropped image blob'));
                    }
                }, 'image/jpeg');
            };
        });
    }


    const handleCrop = async (croppedAreaPixels) => {
        try {



            
            if (croppedAreaPixels) {
                const croppedBlob = await getCroppedImageBlob(imageSrc, croppedAreaPixels);
                const croppedImageFile = new File([croppedBlob], imageFileName, {
                    type: 'image/jpeg', // Ensure type is correct
                });

                UploadCourseDocument(
                    croppedImageFile,
                    isType,
                    courseTypeName === 'liveCourses'
                        ? 'course'
                        : courseTypeName === 'onlineTestSeries'
                            ? 'testSeries'
                            : courseTypeName === 'videoResources'
                                ? 'videoResource'
                                : 'studyMaterial',
                    (key) => {
                        setCreateCourseObj('image', key);
                    }
                );
            } else {

                const originalImageFile = new File([await (await fetch(imageSrc)).blob()], imageFileName, {
                    type: 'image/jpeg',
                });
              
                console.log('Original image file:', originalImageFile);

                UploadCourseDocument(
                    originalImageFile,
                    isType,
                    courseTypeName === 'liveCourses'
                        ? 'course'
                        : courseTypeName === 'onlineTestSeries'
                            ? 'testSeries'
                            : courseTypeName === 'videoResources'
                                ? 'videoResource'
                                : 'studyMaterial',
                    (key) => {
                        setCreateCourseObj('image', key);
                    }
                );
            }



            setIsCropModalVisible(false);
        } catch (error) {
            console.error('Failed to crop image:', error);
        }
    };


    const handleUploadChange = (info, key) => {
        const { status, originFileObj } = info.file;
        const isLt5M = info.file.originFileObj?.size / 1024 / 1024 < 5;

        if (status === 'done' || status === 'error') {
            if (!isLt5M && (info.file.originFileObj.type === 'image/jpeg' || info.file.originFileObj.type === 'image/png' || info.file.originFileObj.type === 'image/jpg')) {
                Notification.error('error', 'Image must be smaller than 5MB!');
            } else if (info.file.originFileObj.type !== 'image/jpeg' && info.file.originFileObj.type !== 'image/png' && info.file.originFileObj.type !== 'image/jpg') {
                Notification.error('error', 'File extension not supported');
            } else {

                console.log('Original image:', info.file.originFileObj);

                // Set the image in the crop modal
                const reader = new FileReader();
                reader.onload = () => {
                    setImageSrc(reader.result);
                    setImageFileName(info.file.originFileObj.name);
                    setIsCropModalVisible(true);
                };
                reader.readAsDataURL(originFileObj); // Read file as data URL
            }
        } else if (status === 'error') {
            Notification.error('error', `${info.file.name} file upload failed.`);
        }
    };
    const uploadingProgress = () => {
        const hide = message.loading('Upload in progress..', 0);
        setTimeout(hide, 1500);
    };

    const courseTypeObjects = {
        liveCourses: {
            mode: courseCreateObj?.mode,
            courseName: courseCreateObj?.courseName,
            brief: courseCreateObj?.brief,
            courseDescription: courseCreateObj?.courseDescription,
            image: courseCreateObj?.image,
            langId: courseCreateObj?.langId,
            totalSeats: courseCreateObj?.totalSeats
        },
        videoResources: {
            courseName: courseCreateObj?.courseName,
            brief: courseCreateObj?.brief,
            courseDescription: courseCreateObj?.courseDescription,
            image: courseCreateObj?.image,
            langId: courseCreateObj?.langId,
        },
        onlineTestSeries: {
            courseName: courseCreateObj?.courseName,
            courseDescription: courseCreateObj?.courseDescription,
            image: courseCreateObj?.image,
            langId: courseCreateObj?.langId,

        },
        studyMaterials: {
            courseName: courseCreateObj?.courseName,
            courseDescription: courseCreateObj?.courseDescription,
            image: courseCreateObj?.image,
            langId: courseCreateObj?.langId,

        }
    };

    useEffect(() => {
        let count = 0;

        // if(location?.state?.key?.isEdit == 'isFromEdit'){
        if (!courseTypeObjects[courseTypeName].langId) {
            courseTypeObjects[courseTypeName].langId = 1;
        }
        // }


        const currentCourseObj = courseTypeObjects[courseTypeName];

        for (const item in currentCourseObj) {
            if (currentCourseObj[item] !== null && currentCourseObj[item] !== undefined && currentCourseObj[item] !== '') {
                count++;
            }
        }
        setBasicInfoFilledCount(count);
    }, [courseTypeName, isExpanded, courseCreateObj]);

    useEffect(() => {
        return (() => {
            if (location?.state?.key?.isEdit != 'isFromEdit') {
                clearValues()
            }
        })
    }, [])


    return (
        <div style={newCourseCreationCardStyle}>
            <div className='r-c-sb'>
                <div style={{ color: "#696969", fontSize: 18, fontWeight: 700 }}>Basic information</div>
                <div className='r-ac'>
                    {!isExpanded && basicInfoFilledCount > 0 ? <div className='m-r-10' style={{ color: "#696969" }}>{basicInfoFilledCount} {basicInfoFilledCount == 1 ? "field" : "fields"}</div> : <></>}
                    {isExpanded ?
                        <FiChevronDown style={{ fontSize: 22, color: "#696969", cursor: "pointer" }} onClick={() => { setIsExpanded(!isExpanded) }} /> :
                        <FiChevronRight style={{ fontSize: 22, color: "#696969", cursor: "pointer" }} onClick={() => { setIsExpanded(!isExpanded) }} />
                    }
                </div>
            </div>
            {!expandedSection && courseCreateObj?.courseName ?
                <>
                    <div className='r-ac m-t-10'>
                        {courseCreateObj?.image ? <img src={`${Api.dlS3Url}${courseCreateObj?.image}`} style={{ width: 100, height: 70, borderRadius: 8 }} /> : null}
                        <div style={{ marginLeft: courseCreateObj?.image ? 10 : 0 }}>
                            {courseCreateObj?.courseName ? <Typography.Text ellipsis={{ tooltip: true }} style={{ color: "#191919", fontSize: 16, width: courseCreateObj?.image ? 350 : 450 }}>{courseCreateObj?.courseName}</Typography.Text> : null}
                            {courseTypeName == "onlineTestSeries" || courseTypeName == "studyMaterials" ? <></> : courseCreateObj?.brief ? <div className='m-t-5' style={{ color: "#636363", maxHeight: "3.6em", overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: 2, WebkitBoxOrient: "vertical", minHeight: 40 }}>{IsJsonString(courseCreateObj?.brief) ? HtmlParser(JSON.parse(courseCreateObj?.brief)?.mobile) : stripHtmlTags(courseCreateObj?.brief)}</div> : null}
                        </div>
                    </div>
                </> : <></>}
            {isExpanded ?
                <>
                    {courseTypeName == "liveCourses" ?
                        <>
                            <div className='m-t-10 m-b-5' style={{ color: "#AEAEAE" }}>Mode of class<span style={{ color: "red" }}> *</span></div>
                            <div style={{ display: "flex" }}>
                                <div style={{ backgroundColor: courseCreateObj?.mode == "online" ? "#28DF99" : "#FFFFFF", border: "1px solid #28DF99", color: courseCreateObj?.mode == "online" ? "#FFFFFF" : "#28DF99", ...modeStyle }} onClick={() => { setCreateCourseObj("mode", "online") }}>ONLINE</div>
                                <div style={{ backgroundColor: courseCreateObj?.mode == "offline" ? "#FF414D" : "#FFFFFF", border: "1px solid #FF414D", color: courseCreateObj?.mode == "offline" ? "#FFFFFF" : "#FF414D", ...modeStyle }} onClick={() => { setCreateCourseObj("mode", "offline") }}>OFFLINE</div>
                                <div style={{ backgroundColor: courseCreateObj?.mode == "hybrid" ? "#FFA931" : "#FFFFFF", border: "1px solid #FFA931", color: courseCreateObj?.mode == "hybrid" ? "#FFFFFF" : "#FFA931", ...modeStyle }} onClick={() => { setCreateCourseObj("mode", "hybrid") }}>HYBRID</div>
                            </div>
                        </>
                        : <></>}
                    <AnimatedTextField
                        label={"Course name"}
                        isMandatory={true}
                        inputId={"-1000"}
                        value={courseCreateObj?.courseName ? courseCreateObj?.courseName : ''}
                        handleInputChange={(e) => { setCreateCourseObj("courseName", e.target.value) }}
                        style={{ marginTop: 30 }}
                        onKeyDown={(e) => {
                            if (e.key === " ") {
                                e.stopPropagation(); // Stop event propagation
                            }
                        }}
                    />
                    {courseTypeName == "onlineTestSeries" || courseTypeName == "studyMaterials" ? <></> :
                        <div>
                            <div className='m-t-20 m-b-5' style={{ color: "#AEAEAE" }}>Brief<span style={{ color: "red" }}> *</span></div>
                            <ReactQuill
                                className='annocnementDescription link-div'
                                theme='snow'
                                style={{ height: '100%' }}
                                modules={modules}
                                value={IsJsonString(courseCreateObj?.brief) ? JSON.parse(courseCreateObj?.brief)?.mobile : stripHtmlTags(courseCreateObj?.brief) || ''}
                                onChange={(content, delta, source, editor) => handleBrief(content, delta, source, editor)}
                                ref={el => quillBrief = el}
                                placeholder='Enter course brief'
                                bounds={'.annocnementDescription'}
                                onKeyUp={(e) => handleCourseBrief(e)}
                                onKeyDown={(e) => {
                                    if (e.key === ' ') {
                                        e.stopPropagation(); // Prevent the event from bubbling up to ReactPlayer
                                    }
                                }}
                            />
                        </div>
                    }
                    <div className='m-t-20 m-b-5' style={{ color: "#AEAEAE" }}>Description<span style={{ color: "red" }}> *</span></div>
                    <ReactQuill
                        className="annocnementDescription link-div"
                        theme="snow"
                        style={{ height: '100%' }}
                        modules={modules}
                        value={IsJsonString(courseCreateObj?.courseDescription)
                            ? JSON.parse(courseCreateObj?.courseDescription)?.mobile
                            : stripHtmlTags(courseCreateObj?.courseDescription) || ''}
                        onChange={(content, delta, source, editor) => handleDescription(content, delta, source, editor)}
                        ref={el => quillReff = el}
                        placeholder="Add description"
                        bounds={'.annocnementDescription'}
                        onKeyUp={(e) => handleDescriptionkeyUp(e)}
                        onKeyDown={(e) => {
                            if (e.key === ' ') {
                                e.stopPropagation(); // Prevent the event from bubbling up to ReactPlayer
                            }
                        }}
                    />
                    <div className='m-t-5' style={{ display: 'flex', width: '100%' }}>
                        <div style={{ width: '47%' }}>
                            <div style={{ color: '#8C8C8C', fontSize: 11, marginTop: '15px' }}>Medium of instruction<span style={{ color: "red" }}> *</span></div>

                            <div style={{}}>
                                <Select
                                    className="no-border-select"
                                    style={{ width: '100%', border: 'none' }}
                                    onChange={handleChange}
                                    value={courseCreateObj?.langId ? courseCreateObj?.langId : selectedOption}
                                    // placeholder="Course name"
                                    suffixIcon={<CaretDownOutlined />}

                                >
                                    {courseLanguagesData?.items?.map(item => (
                                        <Option key={item?.id} value={item?.id} style={{ color: `${selectedOption == item?.name ? 'black' : ''}`, backgroundColor: `${selectedOption == item?.name ? '#D6C8F5' : ''}`, borderRadius: `${selectedOption == item?.name ? '8px' : ''}` }}>
                                            {item?.name}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        {courseTypeName == 'liveCourses' ?
                            <div style={{ width: '47%', marginLeft: '6%' }}>
                                <div style={{ color: '#8C8C8C', fontSize: 11, marginTop: '15px', marginBottom: '2.75%' }}>Total number of seats available</div>
                                <div>
                                    <input
                                        type="number"
                                        value={courseCreateObj?.totalSeats ? courseCreateObj?.totalSeats : seatsValue}
                                        onChange={handleInputChange}
                                        style={{ color: "#636363", fontSize: 14, backgroundColor: "#FFFFFF", width: "100%", border: 'none', borderBottom: '1px solid #d9d9d9' }}
                                    // onFocus={handleInputFocus}
                                    // onBlur={handleInputBlur}
                                    // disabled={isDisabled}
                                    // placeholder={placeholder}
                                    onKeyDown={(e) => {
                                        if (e.key === " ") {
                                            e.preventDefault(); // Prevent default space behavior
                                            e.stopPropagation(); // Stop event propagation
                                        }
                                    }}
                                    />
                                </div>
                                {(isBelowSeats && location?.state?.key?.isEdit == 'isFromEdit') ? <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: 12, color: '#ff414d', }}>total number of students enrolled ({courseCreateObj?.enrolledCount})</div> : <></>}
                            </div>
                            : <></>}

                    </div>
                    {courseTypeName == "" ? <></> :
                        <>
                            <div className='m-t-20' style={{ color: "#191919" }}>Add course thumbnail<span style={{ color: "red" }}> *</span></div>
                            <div className='m-t-5' style={{ color: "#636363", fontSize: 12 }}>A captivating thumbnail is crucial for your online course, enticing learners to explore and enroll, impacting engagement and enrollment rates.</div>
                            {!courseCreateObj?.image ?
                                <AddThumbnail
                                    setCreateCourseObj={setCreateCourseObj}
                                    defualThumbnail={defualThumbnail}
                                    setDefaultThumbnail={setDefaultThumbnail} /> : null}
                            {courseCreateObj?.image ?
                                <div className='r-c-c' style={{ width: "100%" }}>
                                    <div className='r-c-c m-t-10' style={{ position: "relative", width: "fit-content" }}>
                                        <div style={{ position: "absolute", top: -5, right: -20, cursor: "pointer" }} onClick={() => { if (defualThumbnail) { setCreateCourseObj("image", null) } else { deleteCourseDocument(courseCreateObj?.image, isType); setCreateCourseObj("image", null) } }}><CloseCircleOutlined style={{ color: "red" }} /></div>
                                        <img src={`${Api.dlS3Url}${courseCreateObj?.image}`} style={{ height: 150, width: 150 }} />
                                    </div>
                                </div>
                                :
                                <>
                                    <div className='m-t-5' style={{ color: "#636363", fontSize: 12 }}>I already have a thumbnail</div>
                                    <Dragger
                                        showUploadList={false}
                                        onChange={(e) => { handleUploadChange(e); setIsType('image') }}
                                        accept={".jpeg,.png,.jpg"}
                                        style={{ borderColor: "#1089FF", cursor: "pointer", width: "100%", marginTop: "10px", borderRadius: 10, backgroundColor: "#F4F0FF" }}
                                    >
                                        <div className='r-ac'>
                                            <img className='m-l-15' style={{ width: "90px" }} src={dragImage} />
                                            <div className='m-l-10'>
                                                <div className='r-c-c' style={{ color: "#636363", fontSize: "14", fontWeight: 600 }}>Drag and drop or <img src={dragFile} style={{ marginLeft: "10px" }} /><span style={{ color: "#1089FF", margin: "0px 5px ", marginLeft: "10px" }}> Choose file </span>to Upload</div>
                                                <div className='r-c-sb' style={{ width: "100%", marginTop: "5px", color: "#AEAEAE", fontSize: "10px" }}>
                                                    <div>Maximum size: 5 MB </div>
                                                    <div>Supported: PNG, JPEG and JPG </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Dragger>
                                </>
                            }
                        </>
                    }
                </>
                :
                <></>}

            <CropModal
                visible={isCropModalVisible}
                imageSrc={imageSrc}
                onCancel={() => setIsCropModalVisible(false)}
                onCropComplete={handleCropComplete}
                onCrop={handleCrop}
                isCrop={isCrop}
                setIsCrop={setIsCrop}
                setCreateCourseObj={setCreateCourseObj}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    const { courseTypeName, specificCourseDataContainer, courseCreateObj, courseLanguagesData } = state.courseManagement
    return {
        courseTypeName, specificCourseDataContainer, courseCreateObj, courseLanguagesData
    };
};

const mapDispatchToProps = (dispatch) => ({
    setCreateCourseObj: (key, val) => dispatch(setCreateCourseObj(key, val)),
    deleteCourseDocument: (key, isType) => dispatch(deleteCourseDocument(key, isType)),
    getCourseLanguages: (callback) => dispatch(getCourseLanguages(callback)),
    UploadCourseDocument: (file, isType, value, successCallback) => dispatch(UploadCourseDocument(file, isType, value, successCallback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(BasicInformationComp);