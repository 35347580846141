import React from 'react';
import { allowed } from "root/root";

import userManagement from "../Assets/layout/newWhiteIcons/umWhite.svg";
import feeManagement from "../Assets/layout/newWhiteIcons/feeMngtWhite.svg";
import classroom from "../Assets/layout/newWhiteIcons/courseWhite.svg";
import MarketPlaceCRM from "../Assets/layout/newWhiteIcons/crmWhite.svg";
import schedule from "../Assets/layout/newWhiteIcons/scheduleWhite.svg";
import attendance from "../Assets/layout/newWhiteIcons/attendanceWhite.svg";
import digitalLibrary from "../Assets/layout/newWhiteIcons/dlWhite.svg";
import test from "../Assets/layout/newWhiteIcons/testWhite.svg";
import DashBoardImg from "../Assets/layout/newWhiteIcons/dashboardWhite.svg";
import announcements from "../Assets/layout/newWhiteIcons/announcementWhite.svg";
import aulasWallet from "../Assets/layout/newWhiteIcons/walletWhite.svg";
import aulasCare from "../Assets/layout/newWhiteIcons/careWhite.svg";
import course from "../Assets/layout/newWhiteIcons/classWhite.svg";
import notification from "../Assets/layout/newWhiteIcons/notificationWhite.svg";
import dashboardWhite from "../Assets/layout/newFilledIcons/dashBoardFilledIcon.svg";
import umWhite from "../Assets/layout/newFilledIcons/umFilledIcon.svg";
import feeWhite from "../Assets/layout/newFilledIcons/fmfilledIcon.svg";
import courseWhite from "../Assets/layout/newFilledIcons/courseFilledIcon.svg";
import classWhite from "../Assets/layout/newFilledIcons/batchFilledIcon.svg";
import scheduleWhite from "../Assets/layout/newFilledIcons/scheduleFilledIcon.svg";
import attendanceWhite from "../Assets/layout/newFilledIcons/attendanceFilledIcon.svg";
import dlWhite from "../Assets/layout/newFilledIcons/dlFilledIcon.svg"
import testWhite from "../Assets/layout/newFilledIcons/testFilledIcon.svg";
import careWhite from "../Assets/layout/newFilledIcons/careFilledIcon2.svg";
import notificationWhite from "../Assets/layout/newFilledIcons/notificationFilledIcon.svg";
import announcementWhite from "../Assets/layout/newFilledIcons/annoucementFilledIcon.svg";
import walletWhite from "../Assets/layout/newFilledIcons/walletFilledIcon.svg";
import CRMWhite from "../Assets/layout/newFilledIcons/crmFilledIcon.svg";
import ExploreIcon from "../Assets/layout/newWhiteIcons/ExploreIcon.svg";
import ExploreIconWhite from "../Assets/layout/newFilledIcons/exploreFilledIcon.svg";
import SyllabusIcon from "../Assets/layout/newWhiteIcons/sylabusWhiteIcon.svg";
import SyllabusIconWhite from "../Assets/layout/newFilledIcons/sylabusFilledIcon.svg";
import MarketPlaceIcFilled from "../Assets/layout/newFilledIcons/marketplace-white.svg";
import MarketPlaceIcOutline from "../Assets/layout/newFilledIcons/market-place-ic-outline.svg";

import { Badge, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
// import Tooltip from 'components/tooltip';


export const menuItems = (props) => {
    const areAllArraysEmpty = props?.myCoursePage && Object.keys(props?.myCoursePage).every(key => props?.myCoursePage[key]?.length === 0);

    return {
        "General": {
            groupAbbr: "Gen",
            allowed: true,
            color: "#594099",
            backgroundColor: "#F6F4FE",
            items: [
                // { title: "Dashboard", link: "/customize", icon: DashBoardImg, whiteIcon: dashboardWhite, key: 33, allowed: allowed(props, 'user', 'users') },
                { title: "Dashboard", link: "/dashboard-student", icon: DashBoardImg, whiteIcon: dashboardWhite, key: 28, allowed: props?.user?.role?.includes("Student") },
                { title: "Explore Courses", link: "/", icon: ExploreIcon, whiteIcon: ExploreIconWhite, key: 32, allowed: !props?.user?.privilage?.includes("SuperAdmin") && !props?.user?.privilage?.includes("Admin") && !areAllArraysEmpty || !props?.myCoursePageForTeacher?.items?.length == 0 },
            ]
        },

        "Management": {
            groupAbbr: "Mgmt",
            allowed: true,
            color: "#594099",
            backgroundColor: "#F6F4FE",
            items: [
                // {title: "User Management", link: "/user-management", icon: userManagement, key: 1, allowed: allowed(props,'user', 'users') },
                { title: "Users", link: "/user-management", icon: userManagement, whiteIcon: umWhite, key: 2, allowed: allowed(props, 'user', 'users') },
                // { title: "CRM", link: "/market-place-crm", icon: MarketPlaceCRM, whiteIcon: CRMWhite, key: 30, allowed: props?.user?.privilage?.includes("SuperAdmin") },
   // original             // { title: "CRM", link: "/overview-crm", icon: MarketPlaceCRM, whiteIcon: CRMWhite, key: 30, allowed: props?.user?.privilage?.includes("SuperAdmin") || props?.user?.privilage?.includes("Marketing") },
                // { title: "CRM", link: "/overview-crm", icon: MarketPlaceCRM, whiteIcon: CRMWhite, key: 30, allowed: allowed(props, "market", "crm")  },
                { title: "Fee", link: "/fee-management", icon: feeManagement, whiteIcon: feeWhite, key: 14, allowed: allowed(props, "fee", "fees") },
                // {
                //     title: "Batches", link: "/classroom", icon: classroom, whiteIcon: classWhite, key: 6,
                //     allowed: (allowed(props, "standard", "classroom-admin") ||
                //         allowed(props, "standard", "classroom-superadmin") ||
                //         allowed(props, "standard", "classroom-leave") ||
                //         allowed(props, "standard", "classroom-fee")
                //     )
                // },
                {
                    title: "Courses", link: "/courses", icon: course, whiteIcon: courseWhite, key: 18,
                    allowed: (allowed(props, "standard", "classroom-admin") ||
                        allowed(props, "standard", "classroom-superadmin") ||
                        allowed(props, "standard", "course-view"))
                },
                {
                    title: "Marketplace", link: "/market-place", icon: MarketPlaceIcOutline, whiteIcon: MarketPlaceIcFilled, key: 34,
                    allowed: props?.user?.privilage?.includes("SuperAdmin") || props?.user?.privilage?.includes("Admin") || props?.user?.privilage?.includes("Marketing") || allowed(props, 'market', 'market-admin-view') || allowed(props, 'market', 'market-admin') 
                },
                {
                    title: "Schedule", link: "/schedule", icon: schedule, whiteIcon: scheduleWhite, key: 4,
                    allowed: allowed(props, "schedule", "schedule-admin") ||
                        allowed(props, "schedule", "schedule-superadmin") ||
                        allowed(props, "schedule", "schedule-teacher") ||
                        allowed(props, "schedule", "schedule-dataoperator") ||
                        allowed(props, "schedule", "schedule-operation")
                },
                { title: "Attendance", link: "/attendance-dashBoard", icon: attendance, whiteIcon: attendanceWhite, key: 21, allowed: !(props?.user?.role?.includes("Student")) },
                { title: "My Courses", link: "/courses-list", icon: course, whiteIcon: courseWhite, key: 31, allowed: (props?.user?.role?.includes("Student") ) },
            ]
        },
        "Classes": {
            groupAbbr: "Cls",
            allowed: props?.user?.role?.includes("Student") || props?.user?.role?.includes("Teacher"),
            color: "#594099",
            backgroundColor: "#F6F4FE",
            items: [
                { title: "Schedule", link: "/schedule", icon: schedule, whiteIcon: scheduleWhite, key: 4, allowed: allowed(props, "schedule", "schedule-student") },
                { title: "Syllabus", link: "/syllabus", icon: SyllabusIcon, whiteIcon: SyllabusIconWhite, key: 7, allowed: allowed(props, "standard", "curriculum") && !props?.user?.privilage?.includes("SuperAdmin") && !props?.user?.privilage?.includes("Admin") },
                { title: "Attendance", link: "/my-attendance-student-dashBoard", icon: attendance, whiteIcon: attendanceWhite, key: 21, allowed: allowed(props, "attendance", "my-attendance") && props?.user?.role?.includes('Student') },
            ]
        },
        "Resources": {
            groupAbbr: "Res",
            allowed: !(props?.user?.role?.includes("Operation") && !props?.user?.privilage?.includes("SuperAdmin") && !props?.user?.privilage?.includes("Admin")),
            color: "#FFA931",
            backgroundColor: "#FFF4D5",
            items: [
                {
                    title: "Library", link: "/digital-library/division-card", icon: digitalLibrary, whiteIcon: dlWhite, key: 13,
                    allowed: allowed(props, "admin-library", "digital-library-teacher") ||
                        allowed(props, "admin-library", "digital-library-superadmin") ||
                        allowed(props, "admin-library", "digital-library-admin") ||
                        allowed(props, "admin-library", "digital-library-student") ||
                        allowed(props, "admin-library", "digital-library-dataoperator")
                },
                {
                    title: "Tests",
                    link: allowed(props, "assignment", "student-test") ? "/student-test" : "/test", icon: test, whiteIcon: testWhite, key: 11,
                    allowed: allowed(props, "assignment", "teacher-test") ||
                        allowed(props, "assignment", "test-admin") ||
                        allowed(props, "assignment", "test-superadmin") ||
                        allowed(props, "assignment", "dataoperator-test") ||
                        allowed(props, "assignment", "student-test")
                },
            ]
        },
        "Communication": {
            groupAbbr: "Com",
            allowed: true,
            color: "#FF414D",
            backgroundColor: "#FFE4D9",
            items: [
                { title: "Announcements", link: "/announcement", icon: announcements, whiteIcon: announcementWhite, key: 8, allowed: allowed(props, "announcement") },
                { title: "aulas Wallet", link: "/aulas-wallet", icon: aulasWallet, whiteIcon: walletWhite, key: 12, allowed: allowed(props, "fee", "wallet") },
                // {
                //     title: "aulas Care", link: "/care", icon: aulasCare, whiteIcon: careWhite, key: 16,
                //     allowed: allowed(props, "care", "care-admin") ||
                //         allowed(props, "care", "care-aulas") ||
                //         allowed(props, "care", "care") ||
                //         allowed(props, "care", "student-care")
                // },
                { title: "Notifications", link: "/notifications-home", icon: notification, whiteIcon: notificationWhite, key: 17, allowed: true },
            ]
        }
    }
}

export const itemsGrp = (group, index, props, selectedKey, collapsed, notifyBadgeListCount) => {

    const groupData = menuItems(props)[group];

    let showSide = false
    groupData.items.forEach(el => showSide |= el.allowed);
    if (!collapsed) {
        if (groupData.allowed && showSide) {
            return (
                <div >
                    <div className='groupName'>{group}</div>
                    <div>
                        {groupData.items.map((item, index) => menuItem(item, index, selectedKey, groupData.color, groupData.backgroundColor, notifyBadgeListCount))}
                        {index < Object.keys(menuItems(props)).length - 1 ? <div style={{ borderBottom: "1px solid #e6e6e6", margin: "15px 20px 5px 20px" }} ></div> : <div style={{ height: 10 }} />}
                    </div>
                </div>
            )
        }
    } else {
        if (groupData.allowed && showSide) {
            return (
                <div>
                    <div className='groupName' style={{ textAlign: 'center' }} >{groupData.groupAbbr} </div>
                    <div >
                        <div >
                            {groupData.items.map((item, index) => menuItemSmall(item, index, selectedKey, collapsed, groupData.backgroundColor, notifyBadgeListCount))}
                            {index < Object.keys(menuItems(props)).length - 1 ? <div style={{ borderBottom: "1px solid #e6e6e6", margin: "15px 20px 5px 20px" }} ></div> : <div style={{ height: 10 }} />}
                        </div>
                    </div>
                </div>
            )
        }
    }

}

const menuItem = (item, index, selectedKey, color, backgroundColor, notifyBadgeListCount) => {
    return (
        (item.allowed ?
            <Link to={item.link} key={index}>
                <div className='itemName'>
                    <div className={'itemNameHover'} style={selectedKey == item.key ? { backgroundColor: "#594099", color: "#FFFFFF", fontWeight: 600, fontSize: `clamp(0.9em, 1.7vmin, 1.5em)` } : null}>
                        <div>
                            <img style={{ height: "3vmin", transform: selectedKey == item.key ? 'scale(1.1)' : null }} className="iconClass" src={selectedKey != item.key ? item.icon : item.whiteIcon} alt="" />
                        </div>
                        <div style={{ marginLeft: "1vw", fontSize: "13px" }}>{item.title}</div>
                        {item.title === "Notification" && <Badge
                            count={notifyBadgeListCount}
                            style={{
                                height: "16px",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginLeft: "8px",
                                border: "1px solid #FF414D"
                            }}
                        />}
                    </div>
                </div>
            </Link> : null)
    )
}



const menuItemSmall = (item, index, selectedKey, collapsed, backgroundColor, notifyBadgeListCount) => {
    return (item.allowed ?
        <Link to={item.link} key={index} >
            <div className='itemIcon'>
                <div className={'itemIconHover'} style={selectedKey == item.key ? { backgroundColor: "#594099", color: "#FFFFFF", fontWeight: 600, fontSize: `clamp(0.7em, 1.5vmin, 1.5em)` } : null} >
                    <Tooltip title={<span style={{ color: "#000" }}>{item.title}</span>} color="#FFF" placement="right">
                        <div className=''>
                            <img className="iconClass" style={{ height: "2.6vmin", marginLeft: item.title === "Notification" ? 20 : null, transform: selectedKey == item.key ? 'scale(1.1)' : null }} src={selectedKey != item.key ? item.icon : item.whiteIcon} alt="" />
                        </div>
                    </Tooltip>
                    {item.title === "Notification" && <Badge
                        count={notifyBadgeListCount > 9 ? "9+" : notifyBadgeListCount}
                        style={{
                            height: "16px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginLeft: -8,
                            marginTop: -15
                        }}
                    />}
                </div>
            </div>
        </Link> : null)

}