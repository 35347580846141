import React from 'react';
// import Styles from './style';
import Button from 'antd/lib/button';
import 'antd/lib/button/style/index';
import { PhoneOutlined, MessageOutlined, EditOutlined, AudioOutlined } from '@ant-design/icons';
import './styles.css';
import { VideoIcon } from '../../icons';

const VidControls = ({changeView,handRaised,time, audioClick, videoClick, onEndClick, chatEvent, canvasEvent}) => {
  return (
    <div className='vidController'>
       <div onClick={changeView} className="changeView"></div>
      <Button type="link" onClick={videoClick} ghost icon={<VideoIcon style={{fontSize: '30px', color: 'white'}}/>} size={"large"} />
      <Button type="link" onClick={audioClick} ghost icon={<AudioOutlined style={{fontSize: 30}}/>} size={"large"} />

    <div className='cutCall' onClick={onEndClick}>
      <div className='semiCirclesSmall'>
      </div>
        <PhoneOutlined style={{
            marginTop: -3,
            fontSize: 30,
            padding: 10,
            color: 'white',
            backgroundColor: '#F64A3F',
            borderRadius: 25,
          }}
          rotate={225}
        />
      <div className='text-sm bold text-center' style={{color: 'white', marginTop: 5}}>
          {time}
        </div>
      </div>
      <Button type="link" onClick={chatEvent} ghost icon={<MessageOutlined style={{fontSize: 30}}/>} size={"large"} />
      <Button type="link" onClick={canvasEvent} ghost icon={<EditOutlined style={{fontSize: 30}}/>} size={"large"} />
      <div onClick={handRaised} className="handRaise"></div>
    </div>
  )
};

export default VidControls;
