import React, { useEffect, useState } from 'react';
import { Pie } from '@ant-design/charts';
import { Tooltip, Typography } from 'antd';


export const CustomTooltip = ({ title, data }) => {
  return (
    <div style={{ width: '100%', height: '100%', marginTop: '2px', padding: '12px' }}>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
        <Typography.Text style={{ color: 'white', marginBottom: "5px", fontFamily: 'roboto' }}>{data?.[0]?.name}</Typography.Text>
        <Typography.Text style={{ color: 'white', fontWeight: 'bold', fontFamily: 'roboto', margin: 0 }}>{data?.[0]?.value} MB  {data?.[0]?.data?.filesCount ? `| ${data?.[0]?.data?.filesCount} files` : ''}</Typography.Text>
      </div>
    </div>
  )
}

export const CustomContent = ({ data }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div>Total Size</div>
      <div>{parseFloat(data).toFixed(2)}</div>
    </div>
  )
}
const StoragePieCard = ({ sizeOfContents, totalStorageSize, totalCountList, totalUnusedStorageSize }) => {

  const data = [
    {
      type: 'Lecture Notes',
      value: parseFloat((parseFloat(sizeOfContents?.sizeOfLn) / 1024 / 1024).toFixed(2)),
      filesCount: totalCountList?.toalNotesCount ? parseInt(totalCountList?.toalNotesCount) : 0,
    },
    {
      type: 'Videos',
      value: parseFloat((parseFloat(sizeOfContents?.sizeOfMp4Video) / 1024 / 1024).toFixed(2)),
      filesCount: totalCountList?.totalVideoCount ? parseInt(totalCountList?.totalVideoCount) : 0,
    },
    {
      type: 'Question Banks',
      value: parseFloat((parseFloat(sizeOfContents?.sizeOfQb) / 1024 / 1024).toFixed(2)),
      filesCount: totalCountList?.totalQuesBankCount ? parseInt(totalCountList?.totalQuesBankCount) : 0,
    },
    {
      type: 'Storage Left',
      value: parseFloat((parseFloat(totalUnusedStorageSize) / 1024 / 1024).toFixed(2)),
      filesCount: null,
    }
  ];

  const config = {
    // appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    innerRadius: 0.7,
    startAngle: 3.14,
    endAngle: 0,
    legend: {
      layout: 'horizontal',
      position: 'bottom',
    },
    color: ({ type }) => {
      if (type === 'Lecture Notes') {
        return '#9876E6';
      }
      else if (type === 'Videos') {
        return '#579EFF';
      }
      else if (type === 'Question Banks') {
        return '#FF83D0'
      }
      else if (type === 'Storage Left') {
        return '#E6E6E6'
      }
    },
    // label: {
    //   type: 'inner',
    //   offset: '-50%',
    //   content: '{value}',
    //   style: {
    //     textAlign: 'center',
    //     fontSize: 14,
    //   },
    // },
    label: false,
    tooltip: {
      // container:<Tooltip title={'1'}></Tooltip>,
      domStyles: {
        'g2-tooltip': { width: '200px', height: '60px', backgroundColor: '#000000', borderRadius: '12px', boxShadow: '4px 4px 18px #302A5F14', opacity: 0.7 }
      },
      customContent: (title, data) => {
        return <CustomTooltip title={title} data={data} />;
      }
    },
    // interactions: [
    //   {
    //     type: 'element-selected',
    //   },
    //   {
    //     type: 'element-active',
    //   },
    // ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: 14,
        },
        content: `Total size ${totalStorageSize} GB`,
      },
    },
  };

  return <Pie className='digitalLibrary-pie' {...config} />;
};

export default StoragePieCard;
