import { Slider } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import MobilePageHeader from '../mobilePageHeader';
import moment from 'moment';
import { checkLiveCourse, getCheckOutItem, getCheckPayment, payNowCart, postCartPayment, postCourseEnrollNow } from 'actions/studentMarketPlace';
import { connect } from 'react-redux';
import { showMenu } from 'actions/layout';
import { generatePaymentRequest } from 'pages/profile/paymentGateway';
import { Button } from 'components/Button';


function MobileFeePlan({ checkLiveCourse, getCheckOutItem, getCheckPayment, payNowCart, postCartPayment, postCourseEnrollNow, user, showMenu }) {
    const location = useLocation();
    const singleCourseDetailsDataContainer = location.state?.singleCourseDetailsDataContainer;
    const history = useHistory();
    const discountedFee = singleCourseDetailsDataContainer?.amount - singleCourseDetailsDataContainer?.discountAmount
    const ActualFee = singleCourseDetailsDataContainer?.amount;
    const [hideProceed, setHideProceed] = useState(false);
    const [enroll, setEnroll] = useState({});
    const [payOfferCode, setPayOfferCode] = useState('');

    const generateOrderId = user?.orgId + user?.id + Math.floor(Math.random() * 100) + moment().format('SS');
    let fromMarket = true;

    const isLiveParam = {
        userId: user?.id,
        userAccessToken: user?.accessToken
    }
    useEffect(() => {
        if (singleCourseDetailsDataContainer?.owned) {
            setHideProceed(true);
        } else {
            setHideProceed(false);
        }
    }, [singleCourseDetailsDataContainer]);


    const balanceAfter = discountedFee - singleCourseDetailsDataContainer?.registrationFee;
    const handleBuyNow = (itemId, itemType) => {
        if (user?.accessToken) {
            history.push({ pathname: '/user-checkout', state: { itemId, itemType } })
        }
        else {
            history.push('/login');
        }
    };
    const handleEnroll = (id, itemType) => {
        if (user?.accessToken) {
            postCourseEnrollNow(id, itemType, (res) => {
                if (res) {

                    setEnroll((prevSavedItems) => ({
                        ...prevSavedItems,
                        [id]: true,
                    }));
                }
                history.push("/")

            });
        } else {
            history.push('/login');
        }
    };

    const handleProceedToPayment = () => {
        if (singleCourseDetailsDataContainer?.amount == 0) {
            if (user?.accessToken) {

                handleEnroll(singleCourseDetailsDataContainer?.id, singleCourseDetailsDataContainer?.resourceType)
            } else {
                history.push("/login")
            }
        } else {
            if (user?.accessToken) {
                handleBuyNow(singleCourseDetailsDataContainer?.id, singleCourseDetailsDataContainer?.resourceType)
            } else {
                history.push("/login")
            }
        }
    }

    const feeCardStyle = {
        width: '100%',
        height: '59px',
        background: 'rgb(244, 240, 255)',
        borderRadius: '8px',
        opacity: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
        padding: "20px",
        color: "#636363",
        fontSize: "10px",
        marginRight: "8px",
        marginBottom: "8px",

    };




    console.log("feeeplan", user)
    return (
        <div>
            <MobilePageHeader title={"FEE PLAN"} onBackPress={() => { history.goBack() }} sider={true} />
            <div style={{ padding: "0px 10px 0px 10px" }}>

                <div className='r-c-sb m-t-15'>
                    <div className='' style={feeCardStyle} >Actual fee<span style={{ fontWeight: "bold", color: "#191919", fontSize: "11px", marginTop: "3px" }}>₹{ActualFee}</span></div>
                    <div className='' style={feeCardStyle} >Discounted fee<span style={{ fontWeight: "bold", color: "#191919", fontSize: "11px", marginTop: "3px" }}>₹{discountedFee}</span></div>
                </div>
                <div className='r-c-sb'>
                    <div className='' style={feeCardStyle} >Registration fee<span style={{ fontWeight: "bold", color: "#191919", fontSize: "11px", marginTop: "3px" }}>₹{singleCourseDetailsDataContainer?.registrationFee}</span></div>
                    <div className='' style={feeCardStyle}>Balance after registration<span style={{ fontWeight: "bold", color: "#191919", fontSize: "11px", marginTop: "3px" }}>₹{balanceAfter}</span></div>                </div>

            </div>
            <div className='r-c-sb m-t-20' style={{ borderTop: "1px solid #AEAEAE" }}>
                <div className='m-t-10'>
                    <div style={{ color: "#636363", fontSize: "12px" }}>Payable now <div style={{ color: "#191919", fontWeight: "bold", fontSize: "12px" }}>₹ {singleCourseDetailsDataContainer?.registrationFee} <span style={{ color: "#636363", fontWeight: "normal", fontSize: "14px" }}>(Registration fee)</span> </div></div>
                </div>
                {hideProceed ? null :
                    <Button type="primary" className=' cursor-pointer' onClick={handleProceedToPayment} style={{ fontSize: "12px", fontWeight: "bold" }}>PROCEED TO PAYMENT</Button>
                }
            </div>

        </div>
    )
}

const mapStateToProps = (state) => {
    const { user } = state.session
    return { user };
};

const mapDispatchToProps = (dispatch) => ({
    getCheckOutItem: (id, itemType, callback) => dispatch(getCheckOutItem(id, itemType, callback)),
    postCourseEnrollNow: (id, itemType, callback) => dispatch(postCourseEnrollNow(id, itemType, callback)),
    getCheckPayment: (param, callback) => dispatch(getCheckPayment(param, callback)),
    postCartPayment: (param, callback) => dispatch(postCartPayment(param, callback)),
    checkLiveCourse: (param) => dispatch(checkLiveCourse(param)),
    showMenu: (bool) => dispatch(showMenu(bool)),
    payNowCart: (params, callback) => dispatch(payNowCart(params, callback)),

});
export default connect(mapStateToProps, mapDispatchToProps)(MobileFeePlan)