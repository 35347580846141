import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { clearPrivateChat } from 'actions/broadcast';
import { logout, notificationBadgeList } from 'actions/login';
import { setScrollPosition, setWidthOfMenu, showMenu, toggleSider } from 'actions/layout';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router';
import { Api } from 'services';
import { locations } from './locations';
import LogoutConfirm from './logoutConfirm';
import { itemsGrp, menuItems } from './menuItems';
import { ProfileMenu } from './profileMenu';
import './styles.css';
import { Tooltip, Typography } from 'antd';
import UserAvatar from 'components/userAvatar';
import { setIsLogoutClicked } from 'actions/studentMarketPlace';
import { urlWithoutMenu } from 'services/urlWithoutMenu';


const SideMenu = React.memo((props) => {

    const { user,
        notifyBadgeListCount,
        orgData,
        logout,
        clearPrivateChat,
        notificationBadgeList,
        collapsed,
        toggleSider,
        authenticated,
        setWidthOfMenu,
        setIsLogoutClicked,
        scrollPosition,
        setScrollPosition,
        showMenu,
        myCoursePage, myCoursePageForTeacher
    } = props;

    const setKeyBylocation = () => {
        locations.forEach((item, i) => {
            if (location.pathname === item.path) {
                setSelectedKey(item.key);
            }
        });
    };

    const setKeyByUrlPath = () => {
        urlWithoutMenu.forEach((item, i) => {
            if (location.pathname === item.urlPath) {
                showMenu(false)
            }
        });

    }


    const location = window.location;
    const divRef = useRef(null);
    const menuRef = useRef(null);


    useEffect(() => {
        setKeyBylocation();
        setKeyByUrlPath()
        // notificationBadgeList();
    }, [location.pathname]);

    useEffect(() => {
        if (divRef.current) {
            const divWidth = divRef.current.clientWidth;
            setWidthOfMenu(divWidth);
        }
    }, [collapsed]);

    const [confirmLogoutVisible, setConfirmLogoutVisible] = useState(false);
    const [selectedKey, setSelectedKey] = useState(1);
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const history = useHistory();

    useEffect(() => {
        return () => {
            if (menuRef.current) {
                setScrollPosition(menuRef.current.scrollTop);
            }
        }
    }, []);

    useEffect(() => {
        if (menuRef.current) {
            menuRef.current.scrollTop = scrollPosition;
        }
    }, [scrollPosition]);

    const handleLogout = () => {
        clearPrivateChat();
        const reason = {
            type: 'Manual'
        }
        logout(history, reason);
        setIsLogoutClicked(true);
    };
    return (
        <div ref={divRef} style={{ position: "relative", height: "100%", width: collapsed ? "5%" : "20%", minWidth: collapsed ? "90px" : "200px", maxWidth: collapsed ? "110px" : "270px", background: "#FAFAFA", padding:"20px 0px" }} className="r-c-c-c">
            <div className='menuWrapper flex-column' id="sideMenuId" >
                <div className='display-flex m-l-20 m-t-20 flex-1 cursor-pointer' style={{ alignItems: "center" }} onClick={() => history.push("/")}>
                    <Tooltip title={<span style={{ color: "#000" }}>{orgData?.title}</span>} placement="right" color='#FFF' >
                        <div className=''>
                            <img style={{ borderRadius: 10, height: "5vmin", width: "5vmin" }} src={`${Api._s3_url}${orgData?.logo || "defaultLogo.png"}`} alt="" />
                        </div>
                    </Tooltip>
                    {
                        collapsed ?
                            null
                            :
                            <div className='orgTitle'>
                                {orgData?.title}
                            </div>
                    }
                </div>
                <div ref={menuRef} className='m-t-10 flex-12 scroll-bar-universal' style={{ position: 'relative' }}>
                    {Object.keys(menuItems(props)).map((grp, index) => itemsGrp(grp, index, props, selectedKey, collapsed, notifyBadgeListCount))}
                </div>
                <div style={{ borderBottom: "1px solid #E6E6E6", margin: "0 20px 0 20px" }} />
                <div className='flex-1 display-flex m-b-10 cursor-pointer' style={{ alignItems: "flex-end", padding: "0 20px ", width: "100%" }} onMouseEnter={() => setShowProfileMenu(true)} onMouseLeave={() => setShowProfileMenu(false)} >
                    <div className='r-jsb' style={{ width: "100%", alignItems: "center" }} >
                        <div className='r-ac'>
                            <div>
                                <UserAvatar size="5vmin" shape="circle" name={user.name} img={user.image == "default.png" ? "" : `${Api._s3_url}${user.image}`} />
                            </div>
                            {collapsed ?
                                null
                                :
                                <div >
                                    <Typography.Text
                                        ellipsis={{ tooltip: true }}
                                        className='userName'
                                        style={{

                                            maxWidth: 120,
                                            width: 'fit-content',
                                        }}
                                    >
                                        {user.name}
                                    </Typography.Text>

                                </div>

                            }

                        </div>
                        <div onClick={e => { e.isPropagationStopped(); toggleSider(!collapsed); }} style={{ zIndex: 100 }} >
                            {collapsed ?
                                null
                                :
                                <>
                                    <LeftOutlined />
                                    <LeftOutlined style={{ marginLeft: -5 }} />
                                </>}
                        </div>
                    </div>
                    <ProfileMenu user={user} visible={showProfileMenu} setConfirmLogoutVisible={setConfirmLogoutVisible} collapsed={collapsed} />
                </div>
                {collapsed ?
                    <div className='r-c-c flex-1 cursor-pointer' style={{ zIndex: 100 }} onClick={e => { e.isPropagationStopped(); toggleSider(!collapsed); }}>
                        <RightOutlined />
                        <RightOutlined style={{ marginLeft: -5 }} />
                    </div>
                    : null
                }

            </div>
            <LogoutConfirm
                visible={confirmLogoutVisible}
                onCancel={() => setConfirmLogoutVisible(false)}
                onConfirm={() => handleLogout(history)}
            />
        </div>
    );
})

const mapStateToProps = (state) => {
    const { collapsed, scrollPosition } = state.layout;
    const { myCoursePage, myCoursePageForTeacher } = state.studentMarketPlace
    const {
        notifyBadgeList,
        notifyBadgeListCount,
        rejectLoader,
        approveLoader,
    } = state.login;
    const { user, authenticated } = state.session;
    const { orgData} = state.loginNew;
    return {
        collapsed,
        user,
        notifyBadgeList,
        notifyBadgeListCount,
        rejectLoader,
        approveLoader,
        orgData,
        authenticated,
        scrollPosition,
        myCoursePage, myCoursePageForTeacher
    };
};

const mapDispatchToProps = (dispatch) => ({
    logout: (history, reason) => dispatch(logout(history, reason)),
    notificationBadgeList: () => dispatch(notificationBadgeList()),
    clearPrivateChat: () => dispatch(clearPrivateChat()),
    toggleSider: (val) => dispatch(toggleSider(val)),
    setWidthOfMenu: (val) => dispatch(setWidthOfMenu(val)),
    setIsLogoutClicked: (val) => dispatch(setIsLogoutClicked(val)),
    setScrollPosition: (val) => dispatch(setScrollPosition(val)),
    showMenu: (bool) => dispatch(showMenu(bool)),

});

const SiderMenu = withRouter(SideMenu);

export default connect(mapStateToProps, mapDispatchToProps)(SiderMenu);