import React, { useEffect, useRef, useState } from 'react';
import "./courseDetailsStyles.css";
import RatingStars from './ratingStars';
import saveOutlined from "../../Assets/aulasMarket/saveOutlineColorIcon.svg"
import saveFilled from "../../Assets/aulasMarket/saveFilledIcon.svg"
import { Button } from 'components/Button';
import ReactPlayer from 'react-player';
import HtmlParser from 'react-html-parser';
import UserAddRatingModal from './userAddRatingModal';
import ShareCourseModal from 'pages/adminMarketPlaceCourse/shareCourseModal';
import { Api } from 'services';
import { getCartCount, postAddCartItem, postAddSaveItem, postCourseEnrollNow, removeSaveItem } from 'actions/studentMarketPlace';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PreviewCourse from './coursePageCompo/previewCourse';
import CourseInstallmentModal from './coursePageCompo/courseInstallment';
import { CheckCircleFilled } from '@ant-design/icons';
const defaultCourseImage = require('Assets/default_course-img.svg').default;
import YTPlayer from 'components/ytPlayer';
import { Popover } from 'antd';
import YtPlayer from 'components/ytPlayer';
import FreeCourseConfirmationModal from './myCourses/freeConfirmationModal';
import DownloadAppModal from './downloadAppModal';
import { setEnrolledItemId, setShowBottomSheet, setShowLoginModal } from 'actions/loginNew';
import ReactPlayerHoc from 'components/ReactPlayerHoc';

function CourseDetailsDescriptionSection({ singleCourseDetailsDataContainer, storeResourceType, user, postAddCartItem, postAddSaveItem, removeSaveItem, fromMyCourcesPage, fromStudyMaterialPage, postCourseEnrollNow,setEnrolledItemId,
    getCartCount, clickFromTestCard, fromTestSeriesPage, testCount, isDownloadBarVisible, showContactBar, isOfferBarVisible, isMinOfferVisible, userMarketPlaceHomeData, setShowLoginModal, setShowBottomSheet, courseEnrolledId }) {
    const [isUserAddRatingModalVisible, setUserAddRatingModalVisible] = useState(false)
    const [isShareModalVisible, setShareModalVisible] = useState(false);
    const [isVideoPreview, setVideoPreview] = useState(false);
    const [previewLink, setPreviewLink] = useState(null);
    const [shareUrl, setShareUrl] = useState();
    const [cart, setCart] = useState({});
    const [saved, setSaved] = useState({});
    const [isInstallmentVisible, setInstallmentVisible] = useState(false);
    const [enroll, setEnroll] = useState({});
    const [enrollLoader, setEnrollLoader] = useState(false);
    const [addCartLoader, setAddCartLoader] = useState(false)
    const [isSavedClicked, setIsSaveClicked] = useState(false)
    const [addCartClicked, setAddToCartClicked] = useState(false)
    const [showDownloadAppModal, setShowDownloadAppModal] = useState(false)
    const [freeConfirmModal,setFreeConfirmModal]= useState(false)
    const [freeCourseName,setFreeCourseName]=useState('')
    const [freeCourseType,setFreeCourseType]=useState('')

    const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 800);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const history = useHistory();
    const playerRef = useRef(null);
    useEffect(() => {
        setSaved((prevSavedItems) => ({
            ...prevSavedItems,
            [singleCourseDetailsDataContainer?.id]: singleCourseDetailsDataContainer?.inSaved == 1 ? true : singleCourseDetailsDataContainer?.inSaved == 0 ? false : null,
        }));
        setEnroll((prevEnrolledItems) => ({
            ...prevEnrolledItems,
            [singleCourseDetailsDataContainer?.id]: singleCourseDetailsDataContainer?.owned == 1 ? true : singleCourseDetailsDataContainer?.owned == 0 ? false : null,
        }));
    }, [singleCourseDetailsDataContainer?.owned, singleCourseDetailsDataContainer?.inSaved])
    function IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    function isHTML(str) {
        var a = document.createElement('div');
        a.innerHTML = str;

        for (var c = a.childNodes, i = c.length; i--;) {
            if (c[i].nodeType === 1) return true;
        }
        return false;
    }
    const handleSaveForLater = (id, itemType) => {
        if (user?.accessToken) {
            setIsSaveClicked(!isSavedClicked)
            if (saved[id]) {
                removeSaveItem(id, itemType, (res) => {
                    if (res) {
                        setSaved((prevSavedItems) => ({
                            ...prevSavedItems,
                            [id]: false,
                        }));
                    }
                });
            } else {
                postAddSaveItem(id, itemType, (res) => {
                    if (res) {
                        setSaved((prevSavedItems) => ({
                            ...prevSavedItems,
                            [id]: true,
                        }));
                    }
                });
            }
        } else {
            history.push('/login')
        }

    };
    const handleAddToCart = (id, itemType) => {
        if (user?.accessToken) {
            postAddCartItem(id, itemType, (res) => {
                setAddToCartClicked(true)
                if (res) {
                    setAddCartLoader(false)
                    setCart((prevSavedItems) => ({
                        ...prevSavedItems,
                        [id]: true,
                    }));
                }
            });
        } else {
            history.push('/login')
        }

    }

    const handleBuyNow = (itemId, itemType) => {
        console.log("itemtypsss", itemId, itemType)
        {
            user?.accessToken ?
                history.push({
                    pathname: '/user-checkout',
                    state: { itemId, itemType }
                }) : history.push('/login')
        }
    }

    const handleEnroll = (id, itemType) => {
        if (user?.accessToken) {
            postCourseEnrollNow(id, itemType, (res) => {
                if (res) {
                    setEnrollLoader(false)
                    setEnroll((prevSavedItems) => ({
                        ...prevSavedItems,
                        [id]: true,
                    }));
                    setFreeConfirmModal(false)
                }
                // history.push("/courses-list")
                setShowDownloadAppModal(true)
            });
        } else {
            history.push('/login');
        }
    };
    const [videoPlaying, setVideoPlaying] = useState(false);
    const handleVideoPause = () => {
        setVideoPreview(true);
        if (playerRef.current && videoPlaying) {
            playerRef.current.seekTo(0); // Optional: Reset the video to the beginning
            setVideoPlaying(false);
        } else {
            setVideoPlaying(true);
        }
        setPreviewLink(singleCourseDetailsDataContainer?.preview);
    }
    const mode = (
        <div>
            {singleCourseDetailsDataContainer?.mode == "online" ?
                <div style={{ width: "150px" }}>Classes to be conducted over the internet.</div>
                :
                singleCourseDetailsDataContainer?.mode == "hybrid" ?
                    <div style={{ width: "150px" }}>Classes to be held in physical classroom and also over the internet.</div>
                    :
                    <div style={{ width: "140px" }}>Classes to be held in physical classroom.</div>
            }
        </div>
    );

    const CalculateMarginTop = () => {
        const margins = {
            isDownloadBarVisible: 55,
            showContactBar: 55,
            isOfferBarVisible: 60,
            isMinOfferVisible: 55,
        }
        return 60 + (isDownloadBarVisible ? margins.isDownloadBarVisible : 0) +
        (showContactBar ? margins.showContactBar : 0) +
        (isOfferBarVisible ? margins.isOfferBarVisible : 0) +
        (isMinOfferVisible ? margins.isMinOfferVisible : 0);
        // if (isDownloadBarVisible && showContactBar && isOfferBarVisible && isMinOfferVisible) {
        //     return 160
        // } else if ((isDownloadBarVisible && !showContactBar) || (!isDownloadBarVisible && showContactBar)) {
        //     return 110
        // } else if (!isDownloadBarVisible && !showContactBar) {
        //     return 60
        // }
    }

    const handleFree=()=>{
        handleEnroll(singleCourseDetailsDataContainer?.id, singleCourseDetailsDataContainer?.resourceType)
    }


    return (
        <div className={`${fromMyCourcesPage || fromStudyMaterialPage || fromTestSeriesPage ? 'bodyStyle' : 'course-details-description-parent'}`} style={{ marginTop: window?.location?.pathname == "/study-material" ? "" : window.innerWidth > 800 ? CalculateMarginTop(): "" }}>
            {clickFromTestCard || fromTestSeriesPage
                ? (<></>) :
                singleCourseDetailsDataContainer?.resourceType == "videoResource" || singleCourseDetailsDataContainer?.resourceType == "studyMaterial" || fromStudyMaterialPage ? (<></>) :
                    <Popover content={mode}>
                        <div className='mobile-not-visible' style={{ fontSize: 12, color: "#FFFFFF", cursor: "pointer", backgroundColor: singleCourseDetailsDataContainer?.mode == "online" ? "#28DF99" : singleCourseDetailsDataContainer?.mode == "hybrid" ? "#FFA931" : "#FF414D", padding: "3px 6px", fontWeight: "bold", width: "fit-content" }}>{singleCourseDetailsDataContainer?.mode?.charAt(0)?.toUpperCase() + singleCourseDetailsDataContainer?.mode?.slice(1)}</div>
                    </Popover>
            }
            <div className='r-jsb'>
                <div className='course-details-description-child1'>
                    <div className='course-name-font-size'>
                        {singleCourseDetailsDataContainer?.resourceType == "course" ? singleCourseDetailsDataContainer?.courseName : singleCourseDetailsDataContainer?.name}{singleCourseDetailsDataContainer?.isNew == 1 ? <span style={{ color: "#FFA931", fontSize: 15, fontWeight: "bold", marginLeft: 15 }}>NEW</span> : <></>}</div>
                    <div className='course-brief-font-size'>{IsJsonString(singleCourseDetailsDataContainer?.brief) ? !IsJsonString(JSON.parse(singleCourseDetailsDataContainer?.brief)?.mobile) && isHTML(JSON.parse(singleCourseDetailsDataContainer?.brief)?.mobile) ? HtmlParser(JSON.parse(singleCourseDetailsDataContainer?.brief)?.mobile) : HtmlParser(JSON.parse(singleCourseDetailsDataContainer?.brief)?.mobile) : singleCourseDetailsDataContainer?.brief}</div>
                    <div className='course-brief-font-size' style={{ fontWeight: 'bold', marginBottom: 10 }}>
                        {
                            (clickFromTestCard || fromTestSeriesPage) ?
                                <>{testCount} {testCount <= 1 ? "Test" : "Tests"}</> :
                                <></>
                        }
                    </div>
                    {fromMyCourcesPage || fromStudyMaterialPage || fromTestSeriesPage ?
                        (<>
                            <div style={{ display: 'flex' }}>
                                {fromTestSeriesPage ?
                                    singleCourseDetailsDataContainer?.averageRating > 0 ?
                                        <div className='r-c-fs m-r-10'>
                                            <RatingStars star={singleCourseDetailsDataContainer?.averageRating} style={{}} /> <span className='m-l-5'>({singleCourseDetailsDataContainer?.totalReviews})</span>
                                        </div>
                                        : <></>
                                    :
                                    singleCourseDetailsDataContainer?.ratingValue > 0 ?
                                        <div className='r-c-fs m-r-10'>
                                            <RatingStars star={singleCourseDetailsDataContainer?.ratingValue} style={{}} /> <span className='m-l-5'>({singleCourseDetailsDataContainer?.ratingCount})</span>
                                        </div>
                                        : <></>
                                }

                                <div className='' style={{ color: "#1089FF", fontSize: 16, fontWeight: "bold", cursor: "pointer" }} onClick={() => { 
                                    if (user.accessToken) { 
                                        setUserAddRatingModalVisible(true) 
                                    } else { 
                                        if(isMobile){
                                            history.push("/login")
                                        }else {
                                            setShowLoginModal(true)
                                        }
                                    } }}>Add a rating</div>
                            </div>
                            {fromMyCourcesPage || fromStudyMaterialPage || fromTestSeriesPage ? (<></>) :
                                <div className='mobile-not-visible' style={{ color: "#191919", fontWeight: "bold", fontSize: 24, marginTop: 10 }}>
                                    ₹{(singleCourseDetailsDataContainer?.amount - singleCourseDetailsDataContainer?.discountAmount).toLocaleString()}
                                    {singleCourseDetailsDataContainer?.discountAmount > 0 ? <span style={{ color: "#636363", textDecoration: 'line-through', fontSize: 16, fontWeight: 400, marginLeft: 10 }}>₹ {singleCourseDetailsDataContainer?.amount.toLocaleString()}</span> : <></>}
                                    {singleCourseDetailsDataContainer?.discountPercent > 0 ? <span style={{ color: "#28DF99", fontSize: 18, fontWeight: 650, marginLeft: 10 }}>{singleCourseDetailsDataContainer?.discountPercent}% off</span> : <></>}
                                    {IsJsonString(singleCourseDetailsDataContainer?.installmentDetails) ? <span style={{ color: "#1089FF", fontSize: 14, cursor: "pointer" }} onClick={() => { setInstallmentVisible(true) }} >VIEW INSTALLMENTS</span> : <></>}</div>
                            }
                            <div className='course-details-mobile-price'>
                                <div style={{ color: "#191919", fontWeight: "bold", fontSize: 24, marginTop: 10 }}>₹{(singleCourseDetailsDataContainer?.amount - singleCourseDetailsDataContainer?.discountAmount).toLocaleString()}{singleCourseDetailsDataContainer?.discountAmount > 0 ? <span style={{ color: "#636363", textDecoration: 'line-through', fontSize: 16, fontWeight: 400, marginLeft: 10 }}>₹ {singleCourseDetailsDataContainer?.amount.toLocaleString()}</span> : <></>}{singleCourseDetailsDataContainer?.discountPercent > 0 ? <span style={{ color: "#28DF99", fontSize: 18, fontWeight: 650, marginLeft: 10 }}>{singleCourseDetailsDataContainer?.discountPercent}% off</span> : <></>}</div>
                                <div className='m-t-5'>
                                    <img onClick={() => {
                                        setShareModalVisible(true);
                                        setShareUrl(`${Api?._base_url}/resource-details?id=${singleCourseDetailsDataContainer?.id}&type=${singleCourseDetailsDataContainer?.resourceType}`)
                                    }} src={require("../../Assets/aulasMarket/shareIcon.svg").default} style={{ height: 20, width: 20, marginRight: 20 }} />
                                    {singleCourseDetailsDataContainer?.owned == 1 ?
                                        <></>
                                        :
                                        <img onClick={(e) => {
                                            e.stopPropagation();
                                            if (user.accessToken) {
                                                handleSaveForLater(singleCourseDetailsDataContainer?.id, singleCourseDetailsDataContainer?.resourceType)
                                            } else {
                                                if(isMobile){
                                                    history.push("/login")
                                                }else {
                                                    setShowLoginModal(true)
                                                }
                                            }
                                        }} src={saved[singleCourseDetailsDataContainer?.id] || (singleCourseDetailsDataContainer?.inSaved == 0 && isSavedClicked) ? saveFilled : saveOutlined} style={{ height: 20, width: 20 }} />
                                    }
                                </div>
                            </div>
                            {IsJsonString(singleCourseDetailsDataContainer?.installmentDetails) ? <span className='mobile-instalment-visible' style={{ color: "#1089FF", fontSize: 12, cursor: "pointer" }} onClick={() => { setInstallmentVisible(true) }} >VIEW INSTALLMENTS</span> : <></>}
                        </>) : clickFromTestCard ?
                            <div className='r-c-fs  m-t-20' style={{}}>
                                <div className='display-flex'>
                                    {singleCourseDetailsDataContainer?.bestseller == 1 ?
                                        <div className='m-r-20' style={{ color: "#191919", backgroundColor: "#FFA931", border: "1px solid #707070", padding: "2px 8px", height: "fit-content", fontWeight: "bold", borderRadius: 4, fontSize: 12, }}>Bestseller</div> : <></>}

                                    {singleCourseDetailsDataContainer?.averageRating && singleCourseDetailsDataContainer?.averageRating > 0 ? <div className=''> <RatingStars star={singleCourseDetailsDataContainer?.averageRating} /></div> : <></>}
                                    {singleCourseDetailsDataContainer?.totalReviews && singleCourseDetailsDataContainer?.totalReviews > 0 ? <div style={{ color: "#636363", fontSize: 12, margin: "2px 0px 0px 10px", fontWeight: 600 }}>{`(${singleCourseDetailsDataContainer?.totalReviews})`}</div> : <></>}
                                </div>
                                {singleCourseDetailsDataContainer?.owned == 1 ? <div className='m-r-20' style={{ color: "#1089FF", fontSize: 16, fontWeight: "bold", cursor: "pointer" }} onClick={() => { 
                                    if (user.accessToken) {
                                        setUserAddRatingModalVisible(true)
                                    } else {
                                        if (isMobile) {
                                            history.push("/login")
                                        } else {
                                            setShowLoginModal(true)
                                        }
                                    }
                                }}>Add a rating</div> : null}
                                <div  onClick={() => {
                                    setShareModalVisible(true);
                                    setShareUrl(`${Api?._base_url}/resource-details?id=${singleCourseDetailsDataContainer?.id}&type=${"testSeries"}`)
                                }} className='mobile-not-visible m-r-20 m-l-20' style={{ color: "#594099", fontSize: 16, fontWeight: "bold", cursor: "pointer" }}><img src={require("../../Assets/aulasMarket/shareIcon.svg").default} style={{ height: 20, width: 20, marginRight: 10, marginTop: -2 }} />Share</div>
                                {singleCourseDetailsDataContainer?.owned == 1 || singleCourseDetailsDataContainer?.disableAdmission ?
                                    <></>
                                    :
                                    <div className='mobile-not-visible' style={{ color: "#594099", fontSize: 16, fontWeight: "bold", cursor: "pointer" }} onClick={(e) => {
                                        e.stopPropagation();
                                        if (user.accessToken) {
                                            handleSaveForLater(singleCourseDetailsDataContainer?.id, "testSeries")
                                        } else {
                                            if(isMobile){
                                                history.push("/login")
                                            }else {
                                                setShowLoginModal(true)
                                            }
                                        }
                                    }} ><img src={saved[singleCourseDetailsDataContainer?.id] || singleCourseDetailsDataContainer?.inSaved == 1 ? saveFilled : saveOutlined} style={{ height: 20, width: 20, marginRight: 10 }} />Save for later
                                    </div>
                                }
                            </div>
                            :
                            <div className='r-c-fs  m-t-20'>
                                <div className='display-flex'>
                                    {console.log('here4')}
                                    {singleCourseDetailsDataContainer?.bestseller == 1 ?
                                        <div className='m-r-20' style={{ color: "#191919", backgroundColor: "#FFA931", border: "1px solid #707070", padding: "2px 8px", height: "fit-content", fontWeight: "bold", borderRadius: 4, fontSize: 12, }}>Bestseller</div>
                                        : <></>}
                                    {singleCourseDetailsDataContainer?.ratingValue && singleCourseDetailsDataContainer?.ratingValue > 0 ? <div className='m-r-20'><RatingStars star={singleCourseDetailsDataContainer?.ratingValue} /> </div> : <></>}
                                    {singleCourseDetailsDataContainer?.ratingCount && singleCourseDetailsDataContainer?.ratingCount > 0 ? <div style={{ color: "#636363", fontSize: 12, margin: "3px 20px 0px 10px", fontWeight: 600 }}>{`(${singleCourseDetailsDataContainer?.ratingCount})`}</div> : <></>}
                                </div>
                                {singleCourseDetailsDataContainer?.owned == 1 ? <div className='m-r-20' style={{ color: "#1089FF", fontSize: 16, fontWeight: "bold", cursor: "pointer" }} onClick={() => {
                                    if (user.accessToken) {
                                        setUserAddRatingModalVisible(true)
                                    } else {
                                        if (isMobile) {
                                            history.push("/login")
                                        } else {
                                            setShowLoginModal(true)
                                        }
                                    }
                                }}>Add a rating</div> : null}
                                <div onClick={() => {
                                    setShareModalVisible(true);
                                    setShareUrl(`${Api?._base_url}/resource-details?id=${singleCourseDetailsDataContainer?.id}&type=${singleCourseDetailsDataContainer?.resourceType}`)
                                }} className='mobile-not-visible m-r-20' style={{ color: "#594099", fontSize: 16, fontWeight: "bold", cursor: "pointer" }}><img src={require("../../Assets/aulasMarket/shareIcon.svg").default} style={{ height: 20, width: 20, marginRight: 10, marginTop: -2 }} />Share</div>
                                {user?.role?.includes("Student") || user?.accessToken == undefined ? <>
                                    {singleCourseDetailsDataContainer?.owned == 1 || singleCourseDetailsDataContainer?.disableAdmission ?
                                        <></>
                                        :
                                        <div className='mobile-not-visible' style={{ color: "#594099", fontSize: 16, fontWeight: "bold", cursor: "pointer" }} onClick={(e) => {
                                            e.stopPropagation();
                                            if (user.accessToken) {
                                                handleSaveForLater(singleCourseDetailsDataContainer?.id, singleCourseDetailsDataContainer?.resourceType)
                                            } else {
                                                if(isMobile){
                                                    history.push("/login")
                                                }else {
                                                    setShowLoginModal(true)
                                                }
                                            }
                                        }} ><img src={addCartClicked ? saveOutlined : saved[singleCourseDetailsDataContainer?.id] || (singleCourseDetailsDataContainer?.inSaved == 0 && isSavedClicked) ? saveFilled : saveOutlined} style={{ height: 20, width: 20, marginRight: 10 }} />Save for later
                                        </div>
                                    }
                                </> : <></>}
                            </div>}
                    {fromMyCourcesPage || fromStudyMaterialPage || fromTestSeriesPage ? (<></>) :

                        clickFromTestCard ?
                            (<>
                                {console.log('clickFromTestCard1', clickFromTestCard)}
                                <div className='mobile-not-visible' style={{ color: "#191919", fontWeight: "bold", fontSize: 24, marginTop: 10 }}>₹{(singleCourseDetailsDataContainer?.amount - singleCourseDetailsDataContainer?.discount).toLocaleString()}{singleCourseDetailsDataContainer?.discount > 0 ? <span style={{ color: "#636363", textDecoration: 'line-through', fontSize: 16, fontWeight: 400, marginLeft: 10 }}>₹ {singleCourseDetailsDataContainer?.amount.toLocaleString()}</span> : <></>}{singleCourseDetailsDataContainer?.discount > 0 ? <span style={{ color: "#28DF99", fontSize: 18, fontWeight: 650, marginLeft: 10 }}>{singleCourseDetailsDataContainer?.discount}% off</span> : <></>}
                                    {IsJsonString(singleCourseDetailsDataContainer?.installmentDetails) ? <span style={{ color: "#1089FF", fontSize: 14, cursor: "pointer", marginLeft: 15 }} onClick={() => { setInstallmentVisible(true) }} >VIEW INSTALLMENTS</span> : <></>}</div>
                                <div className='course-details-mobile-price'>
                                    <div style={{ color: "#191919", fontWeight: "bold", fontSize: 24, marginTop: 10 }}>₹{(singleCourseDetailsDataContainer?.amount - singleCourseDetailsDataContainer?.discount).toLocaleString()}{singleCourseDetailsDataContainer?.discount > 0 ? <span style={{ color: "#636363", textDecoration: 'line-through', fontSize: 16, fontWeight: 400, marginLeft: 10 }}>₹ {singleCourseDetailsDataContainer?.amount.toLocaleString()}</span> : <></>}{singleCourseDetailsDataContainer?.discount > 0 ? <span style={{ color: "#28DF99", fontSize: 18, fontWeight: 650, marginLeft: 10 }}>{singleCourseDetailsDataContainer?.discount}% off</span> : <></>}</div>
                                    <div className='m-t-5'>
                                        <img onClick={() => {
                                            setShareModalVisible(true);
                                            setShareUrl(`${Api?._base_url}/resource-details?id=${singleCourseDetailsDataContainer?.id}&type=${"testSeries"}`)
                                        }} src={require("../../Assets/aulasMarket/shareIcon.svg").default} style={{ height: 20, width: 20, marginRight: 20 }} />
                                        {singleCourseDetailsDataContainer?.owned == 1 ?
                                            <></>
                                            :
                                            <img onClick={(e) => {
                                                e.stopPropagation();
                                                if (user.accessToken) {
                                                    handleSaveForLater(singleCourseDetailsDataContainer?.id, "testSeries")
                                                } else {
                                                    if(isMobile){
                                                        history.push("/login")
                                                    }else {
                                                        setShowLoginModal(true)
                                                    }
                                                }
                                            }} src={saved[singleCourseDetailsDataContainer?.id] || singleCourseDetailsDataContainer?.inSaved == 1 ? saveFilled : saveOutlined} style={{ height: 20, width: 20 }} />
                                        }
                                    </div>
                                </div>
                                {singleCourseDetailsDataContainer?.installmentDetails ? <span className='mobile-instalment-visible' style={{ color: "#1089FF", fontSize: 14, cursor: "pointer" }} onClick={() => { setInstallmentVisible(true) }} >VIEW INSTALLMENTS</span> : <></>}
                            </>)
                            :
                            (<>
                                <div className='mobile-not-visible' style={{ color: "#191919", fontWeight: "bold", fontSize: 24, marginTop: 10 }}>₹{(singleCourseDetailsDataContainer?.amount - singleCourseDetailsDataContainer?.discountAmount).toLocaleString()}{singleCourseDetailsDataContainer?.discountAmount > 0 ? <span style={{ color: "#636363", textDecoration: 'line-through', fontSize: 16, fontWeight: 400, marginLeft: 10 }}>₹ {singleCourseDetailsDataContainer?.amount}</span> : <></>}{singleCourseDetailsDataContainer?.discountPercent > 0 ? <span style={{ color: "#28DF99", fontSize: 18, fontWeight: 650, marginLeft: 10 }}>{singleCourseDetailsDataContainer?.discountPercent}% off</span> : <></>}
                                    {singleCourseDetailsDataContainer?.disableAdmission ? null : <>
                                        {(((singleCourseDetailsDataContainer?.resourceType === "course" || storeResourceType == 'live') && user?.accessToken && user?.role?.includes("Student") && singleCourseDetailsDataContainer?.owned != 1) || singleCourseDetailsDataContainer?.resourceType === "course" && !user?.accessToken) && (
                                            <span style={{ color: "#1089FF", fontSize: 14, cursor: "pointer", marginLeft: 15 }} onClick={() => { setInstallmentVisible(true) }} >FEE PLAN</span>
                                        )}
                                    </>}
                                </div>
                                <div className='course-details-mobile-price'>
                                    <div style={{ color: "#191919", fontWeight: "bold", fontSize: 24, marginTop: 10 }}>₹{(singleCourseDetailsDataContainer?.amount - singleCourseDetailsDataContainer?.discountAmount).toLocaleString()}{singleCourseDetailsDataContainer?.discountAmount > 0 ? <span style={{ color: "#636363", textDecoration: 'line-through', fontSize: 16, fontWeight: 400, marginLeft: 10 }}>₹ {singleCourseDetailsDataContainer?.amount.toLocaleString()}</span> : <></>}{singleCourseDetailsDataContainer?.discountPercent > 0 ? <span style={{ color: "#28DF99", fontSize: 18, fontWeight: 650, marginLeft: 10 }}>{singleCourseDetailsDataContainer?.discountPercent}% off</span> : <></>}</div>
                                    <div className='m-t-5'>
                                        <img onClick={() => {
                                            setShareModalVisible(true);
                                            setShareUrl(`${Api?._base_url}/resource-details?id=${singleCourseDetailsDataContainer?.id}&type=${singleCourseDetailsDataContainer?.resourceType}`)
                                        }} src={require("../../Assets/aulasMarket/shareIcon.svg").default} style={{ height: 20, width: 20, marginRight: 20 }} />
                                        {singleCourseDetailsDataContainer?.owned == 1 ?
                                            <></>
                                            :
                                            <img onClick={(e) => {
                                                e.stopPropagation();
                                                if (user.accessToken) {
                                                    handleSaveForLater(singleCourseDetailsDataContainer?.id, singleCourseDetailsDataContainer?.resourceType)
                                                } else {
                                                    if(isMobile){
                                                        history.push("/login")
                                                    }else {
                                                        setShowLoginModal(true)
                                                    }
                                                }
                                            }} src={saved[singleCourseDetailsDataContainer?.id] || singleCourseDetailsDataContainer?.inSaved == 1 ? saveFilled : saveOutlined} style={{ height: 20, width: 20 }} />
                                        }
                                    </div>
                                </div>
                                {singleCourseDetailsDataContainer?.disableAdmission ? null : <>
                                    {(((singleCourseDetailsDataContainer?.resourceType === "course" || storeResourceType == 'live') && user?.role?.includes("Student") && singleCourseDetailsDataContainer?.owned != 1) || !user?.accessToken) && (
                                        <span className='mobile-instalment-visible' style={{ color: "#1089FF", fontSize: 14, cursor: "pointer" }} onClick={() => { history.push("/fee-plan", { singleCourseDetailsDataContainer: singleCourseDetailsDataContainer }) }} >FEE PLAN</span>
                                    )}
                                </>}
                            </>)
                    }
                    {singleCourseDetailsDataContainer?.disableAdmission ?
                        <>
                            <div className='r-c-c' style={{ fontSize: "14px", color: "#FF414D", backgroundColor: "#FFE4D9", width: "150px", height: "35px", borderRadius: "50px", marginTop: 10, fontWeight: 600, marginBottom: 10 }}>Admission closed</div>
                        </> : <>
                            {fromMyCourcesPage || fromStudyMaterialPage || fromTestSeriesPage ? (<></>) :
                                user?.role?.includes("Student") || user?.accessToken == undefined ?
                                    clickFromTestCard ?
                                        (<>
                                            {enroll[singleCourseDetailsDataContainer?.id] || singleCourseDetailsDataContainer?.owned == 1 ?
                                                <div style={{ display: "flex", alignItems: "center", margin: "30px 0px" }}>
                                                    <CheckCircleFilled style={{ fontSize: 24, marginRight: 10, color: "#61E3A7" }} />
                                                    <div style={{ color: "#594099", fontSize: 18, fontWeight: "bold" }}>ENROLLED</div>
                                                </div>
                                                :
                                                singleCourseDetailsDataContainer?.amount == 0 ?
                                                    <Button loading={enrollLoader} type="primary" onClick={() => {
                                                        if (singleCourseDetailsDataContainer?.amount == 0) {
                                                            if (user.accessToken) {

                                                                setFreeConfirmModal(true)
                                                                setFreeCourseType(singleCourseDetailsDataContainer?.resourceType)
                                                                setFreeCourseName(singleCourseDetailsDataContainer?.resourceType == "studyMaterial" || singleCourseDetailsDataContainer?.resourceType == "testSeries" ? singleCourseDetailsDataContainer?.name : singleCourseDetailsDataContainer?.courseName)

                                                            } else {
                                                                if(isMobile){
                                                                    if(singleCourseDetailsDataContainer?.amount == 0){
                                                                        setEnrolledItemId({id:singleCourseDetailsDataContainer?.id,type:singleCourseDetailsDataContainer?.resourceType})
                                                                    } 
                                                                    setShowBottomSheet(true)
                                                                }else {
                                                                    if(singleCourseDetailsDataContainer?.amount == 0){
                                                                        setEnrolledItemId({id:singleCourseDetailsDataContainer?.id,type:singleCourseDetailsDataContainer?.resourceType})
                                                                    } 
                                                                    setShowLoginModal(true)
                                                                }
                                                            }
                                                        }
                                                    }} style={{ width: 140, height: 35, marginTop: 20 }}>{"ENROLL NOW"}</Button>
                                                    :
                                                    <div className='display-flex m-t-20 course-details-btn'>
                                                        <Button loading={addCartLoader} style={{ border: "1px solid #594099", color: "#594099", backgroundColor: "#FFFFFF", marginRight: 20, width: 140, height: 35 }} onClick={(e) => {
                                                            e.stopPropagation()
                                                            if ((singleCourseDetailsDataContainer?.inCart == 1 || cart[singleCourseDetailsDataContainer?.id])) {
                                                                if (user.accessToken) {
                                                                    history.push("/cart")
                                                                } else {
                                                                    if(isMobile){
                                                                        history.push("/login")
                                                                    }else {
                                                                        setShowLoginModal(true)
                                                                    }
                                                                }
                                                            } else {
                                                                if (user.accessToken) {
                                                                    setAddCartLoader(true)
                                                                    handleAddToCart(singleCourseDetailsDataContainer?.id, "testSeries")
                                                                    getCartCount()
                                                                } else {
                                                                    if(isMobile){
                                                                        history.push("/login")
                                                                    }else {
                                                                        setShowLoginModal(true)
                                                                    }
                                                                }
                                                            }
                                                        }}>{singleCourseDetailsDataContainer?.inCart == 1 || cart[singleCourseDetailsDataContainer?.id] ? "GO TO CART" : "ADD TO CART"}</Button>
                                                        <Button type="primary" onClick={() => {
                                                            if (singleCourseDetailsDataContainer?.amount == 0) {
                                                                if (user.accessToken) {
                                                                    setFreeConfirmModal(true)
                                                                    setFreeCourseType(singleCourseDetailsDataContainer?.resourceType)
                                                                    setFreeCourseName(singleCourseDetailsDataContainer?.resourceType == "studyMaterial" || singleCourseDetailsDataContainer?.resourceType == "testSeries" ? singleCourseDetailsDataContainer?.name : singleCourseDetailsDataContainer?.courseName)

                                                                } else {
                                                                    if(isMobile){
                                                                        history.push("/login")
                                                                    }else {
                                                                        setShowLoginModal(true)
                                                                    }
                                                                }
                                                            } else {
                                                                if (user.accessToken) {
                                                                    handleBuyNow(singleCourseDetailsDataContainer?.id, "testSeries")
                                                                } else {
                                                                    if(isMobile){
                                                                        if(singleCourseDetailsDataContainer?.amount == 0){
                                                                            setEnrolledItemId({id:singleCourseDetailsDataContainer?.id,type:singleCourseDetailsDataContainer?.resourceType})
                                                                        } 
                                                                        setShowBottomSheet(true)
                                                                    }else {
                                                                        if(singleCourseDetailsDataContainer?.amount == 0){
                                                                            setEnrolledItemId({id:singleCourseDetailsDataContainer?.id,type:singleCourseDetailsDataContainer?.resourceType})
                                                                        } 
                                                                        setShowLoginModal(true)
                                                                    }
                                                                }
                                                            }
                                                        }} style={{ width: 140, height: 35 }}>{singleCourseDetailsDataContainer?.amount == 0 || singleCourseDetailsDataContainer?.resourceType == "course" ? "ENROLL2 NOW" : "BUY NOW"}</Button>
                                                    </div>
                                            }
                                        </>)
                                        :
                                        (<>
                                            {enroll[singleCourseDetailsDataContainer?.id] || singleCourseDetailsDataContainer?.owned == 1 ?
                                                <div style={{ display: "flex", alignItems: "center", margin: "30px 0px" }}>
                                                    <CheckCircleFilled style={{ fontSize: 24, marginRight: 10, color: "#61E3A7" }} />
                                                    <div style={{ color: "#594099", fontSize: 18, fontWeight: "bold" }}>ENROLLED</div>
                                                </div>
                                                :
                                                singleCourseDetailsDataContainer?.amount == 0 ?
                                                    <Button loading={enrollLoader} type="primary" onClick={() => {
                                                        if (singleCourseDetailsDataContainer?.amount == 0) {
                                                            if (user.accessToken) {
                                                                setFreeConfirmModal(true)
                                                                setFreeCourseType(singleCourseDetailsDataContainer?.resourceType)
                                                                setFreeCourseName(singleCourseDetailsDataContainer?.resourceType == "studyMaterial" || singleCourseDetailsDataContainer?.resourceType == "testSeries" ? singleCourseDetailsDataContainer?.name : singleCourseDetailsDataContainer?.courseName)
                                                                // handleEnroll(singleCourseDetailsDataContainer?.id, singleCourseDetailsDataContainer?.resourceType)
                                                            } else {
                                                                if(isMobile){
                                                                    if(singleCourseDetailsDataContainer?.amount == 0){
                                                                        setEnrolledItemId({id:singleCourseDetailsDataContainer?.id,type:singleCourseDetailsDataContainer?.resourceType})
                                                                    } 
                                                                    setShowBottomSheet(true)
                                                                }else {
                                                                    if(singleCourseDetailsDataContainer?.amount == 0){
                                                                        setEnrolledItemId({id:singleCourseDetailsDataContainer?.id,type:singleCourseDetailsDataContainer?.resourceType})
                                                                    } 
                                                                    setShowLoginModal(true)
                                                                }
                                                            }
                                                        }
                                                    }} style={{ width: 140, height: 35, marginTop: 20 }}>{"ENROLL NOW"}</Button>
                                                    :
                                                    <div className='display-flex m-t-20 course-details-btn'>
                                                        {singleCourseDetailsDataContainer?.resourceType == "course" ? null :
                                                            <Button loading={addCartLoader} style={{ border: "1px solid #594099", color: "#594099", backgroundColor: "#FFFFFF", marginRight: 20, width: 140, height: 35 }} onClick={(e) => {
                                                                e.stopPropagation()
                                                                if ((singleCourseDetailsDataContainer?.inCart == 1 || cart[singleCourseDetailsDataContainer?.id])) {
                                                                    if (user.accessToken) {
                                                                        history.push("/cart")
                                                                    } else {
                                                                        if(isMobile){
                                                                            history.push("/login")
                                                                        }else {
                                                                            setShowLoginModal(true)
                                                                        }
                                                                    }
                                                                } else {
                                                                    if (user.accessToken) {
                                                                        setAddCartLoader(true)
                                                                        handleAddToCart(singleCourseDetailsDataContainer?.id, singleCourseDetailsDataContainer?.resourceType)
                                                                        getCartCount()
                                                                    } else {
                                                                        if(isMobile){
                                                                            history.push("/login")
                                                                        }else {
                                                                            setShowLoginModal(true)
                                                                        }
                                                                    }
                                                                }
                                                            }}>{singleCourseDetailsDataContainer?.inCart == 1 || cart[singleCourseDetailsDataContainer?.id] ? "GO TO CART" : "ADD TO CART"}</Button>
                                                        }
                                                        <Button type="primary" onClick={() => {
                                                            if (singleCourseDetailsDataContainer?.amount == 0) {
                                                                if (user.accessToken) {
                                                                    setFreeConfirmModal(true)
                                                                    setFreeCourseType(singleCourseDetailsDataContainer?.resourceType)
                                                                setFreeCourseName(singleCourseDetailsDataContainer?.resourceType == "studyMaterial" || singleCourseDetailsDataContainer?.resourceType == "testSeries" ? singleCourseDetailsDataContainer?.name : singleCourseDetailsDataContainer?.courseName)
                                                                } else {
                                                                    if(isMobile){
                                                                        history.push("/login")
                                                                    }else {
                                                                        setShowLoginModal(true)
                                                                    }
                                                                }
                                                            } else {
                                                                if (user.accessToken) {
                                                                    handleBuyNow(singleCourseDetailsDataContainer?.id, singleCourseDetailsDataContainer?.resourceType)
                                                                } else {
                                                                    if(isMobile){
                                                                        if(singleCourseDetailsDataContainer?.amount == 0){
                                                                            setEnrolledItemId({id:singleCourseDetailsDataContainer?.id,type:singleCourseDetailsDataContainer?.resourceType})
                                                                        } 
                                                                        setShowBottomSheet(true)
                                                                    }else {
                                                                        if(singleCourseDetailsDataContainer ?.amount == 0){
                                                                            setEnrolledItemId({id:singleCourseDetailsDataContainer?.id,type:singleCourseDetailsDataContainer?.resourceType})
                                                                        } 
                                                                        setShowLoginModal(true)
                                                                    }
                                                                }
                                                            }
                                                        }} style={{ width: 140, height: 35 }}>{singleCourseDetailsDataContainer?.amount == 0 || singleCourseDetailsDataContainer?.resourceType == "course" ? "ENROLL NOW" : "BUY NOW"}</Button>
                                                    </div>
                                            }
                                        </>)
                                    :
                                    <></>
                            }
                        </>}
                </div>
                {fromStudyMaterialPage || singleCourseDetailsDataContainer?.resourceType == 'studyMaterial' || clickFromTestCard || fromTestSeriesPage ? (<></>) :
                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                        <img className='course-details-description-child2' src={singleCourseDetailsDataContainer?.image ? `${Api.dlS3Url}${singleCourseDetailsDataContainer?.image}` : defaultCourseImage} />
                    </div>
                }
            </div>
            <div className='course-details-desc-div'>
                {fromStudyMaterialPage || fromTestSeriesPage ? (<></>) :
                    (<>
                        {singleCourseDetailsDataContainer?.preview != null ?
                            <div className='course-details-preview-video'>
                                {isVideoPreview ? null :
                                    (<>
                                        {singleCourseDetailsDataContainer?.previewType == 'link' ?
                                            <div style={{ height: "25dvh"}}>
                                                <YtPlayer keyboardControls={true}  videoUrl={`${Api.proDlS3Url}${singleCourseDetailsDataContainer?.preview}`} />
                                            </div> :
                                            <ReactPlayerHoc
                                                height='25dvh'
                                                width='100%'
                                                keyboardControls={true}
                                                url={`${Api.proDlS3Url}${singleCourseDetailsDataContainer?.preview}`}
                                                controls={true}
                                            />}
                                    </>)}
                                <center onClick={() => {
                                    handleVideoPause()
                                }} style={{ color: "#594099", fontSize: 16, fontWeight: "bold", marginTop: 5, cursor: "pointer" }}>Preview this course</center>
                            </div>
                            : (<></>)
                        }
                    </>)}
                {singleCourseDetailsDataContainer?.resourceType == undefined || fromTestSeriesPage ? (<>
                    <div style={{ color: "#191919", fontSize: 16, fontWeight: 600, marginTop: -30, textAlign: "justify" }}>
                        {IsJsonString(singleCourseDetailsDataContainer?.description) ? !IsJsonString(JSON.parse(singleCourseDetailsDataContainer?.description)?.mobile) && isHTML(JSON.parse(singleCourseDetailsDataContainer?.description)?.mobile) ? HtmlParser(JSON.parse(singleCourseDetailsDataContainer?.description)?.mobile) : HtmlParser(JSON.parse(singleCourseDetailsDataContainer?.description)?.mobile) : singleCourseDetailsDataContainer?.description}
                    </div></>) : (<>
                        {fromStudyMaterialPage ? <></> :
                            <>
                                <div className='course-details-desc-child'>
                                    <div className='description-margin' style={{ color: "#191919", fontSize: 20, fontWeight: "bold" }}>Description</div>
                                    {singleCourseDetailsDataContainer?.resourceType == 'course' ?
                                        <div style={{ color: "#191919", fontSize: 16, fontWeight: 600, marginTop: 10, textAlign: "justify" }}>
                                            {IsJsonString(singleCourseDetailsDataContainer?.courseDescription) ? !IsJsonString(JSON.parse(singleCourseDetailsDataContainer?.courseDescription)?.mobile) && isHTML(JSON.parse(singleCourseDetailsDataContainer?.courseDescription)?.mobile) ? HtmlParser(JSON.parse(singleCourseDetailsDataContainer?.courseDescription)?.mobile) : HtmlParser(JSON.parse(singleCourseDetailsDataContainer?.courseDescription)?.mobile) : singleCourseDetailsDataContainer?.courseDescription}
                                        </div> :
                                        <div style={{ color: "#191919", fontSize: 16, fontWeight: 600, marginTop: 10, textAlign: "justify" }}>
                                            {IsJsonString(singleCourseDetailsDataContainer?.description) ? !IsJsonString(JSON.parse(singleCourseDetailsDataContainer?.description)?.mobile) && isHTML(JSON.parse(singleCourseDetailsDataContainer?.description)?.mobile) ? HtmlParser(JSON.parse(singleCourseDetailsDataContainer?.description)?.mobile) : HtmlParser(JSON.parse(singleCourseDetailsDataContainer?.description)?.mobile) : singleCourseDetailsDataContainer?.description}
                                        </div>
                                    }
                                </div>
                            </>
                        }</>)}
            </div>
            {isUserAddRatingModalVisible &&
                <UserAddRatingModal
                    isUserAddRatingModalVisible={isUserAddRatingModalVisible}
                    setUserAddRatingModalVisible={setUserAddRatingModalVisible}
                    courseId={singleCourseDetailsDataContainer?.id}
                    resourceType={clickFromTestCard || fromTestSeriesPage ? "testSeries" : singleCourseDetailsDataContainer?.resourceType}
                    fromMyCourcesPage={fromMyCourcesPage}

                />}
            {isShareModalVisible &&
                <ShareCourseModal
                    isShareModalVisible={isShareModalVisible}
                    setShareModalVisible={setShareModalVisible}
                    shareUrl={shareUrl}
                />}
            {isVideoPreview &&
                <PreviewCourse
                    isVideoPreview={isVideoPreview}
                    setVideoPreview={setVideoPreview}
                    previewLink={previewLink}
                    courseName={singleCourseDetailsDataContainer}
                    videoType={singleCourseDetailsDataContainer?.previewType}

                />
            }
            {isInstallmentVisible &&
                <CourseInstallmentModal
                    isInstallmentVisible={isInstallmentVisible}
                    setInstallmentVisible={setInstallmentVisible}
                    singleCourseDetailsDataContainer={singleCourseDetailsDataContainer}
                    billingPlans={singleCourseDetailsDataContainer?.billingPlans}
                    courseBilling={singleCourseDetailsDataContainer?.courseBilling}
                />
            }
            {showDownloadAppModal &&
                <DownloadAppModal
                    showDownloadAppModal={showDownloadAppModal}
                    setShowDownloadAppModal={setShowDownloadAppModal}
                    userMarketPlaceHomeData={userMarketPlaceHomeData}
                />}

            {freeConfirmModal && <FreeCourseConfirmationModal
                freeConfirmModal={freeConfirmModal}
                setFreeConfirmModal={setFreeConfirmModal}
                freeCourseName={freeCourseName}
                freeCourseType={freeCourseType}
                handleFree={handleFree} />}
        </div>
    )
}



const mapStateToProps = (state) => {
    const { isDownloadBarVisible, showContactBar, storeResourceType, userMarketPlaceHomeData } = state.studentMarketPlace;
    const { user } = state.session
    const { courseEnrolledId } = state.loginNew
    return { user, isDownloadBarVisible, showContactBar, storeResourceType , userMarketPlaceHomeData, courseEnrolledId};
};

const mapDispatchToProps = (dispatch) => ({
    postAddSaveItem: (id, itemType, callback) => dispatch(postAddSaveItem(id, itemType, callback)),
    removeSaveItem: (id, itemType, callback) => dispatch(removeSaveItem(id, itemType, callback)),
    postAddCartItem: (id, itemType, callback) => dispatch(postAddCartItem(id, itemType, callback)),
    postCourseEnrollNow: (id, itemType, callback) => dispatch(postCourseEnrollNow(id, itemType, callback)),
    getCartCount: () => dispatch(getCartCount()),
    setShowLoginModal: (val) => dispatch(setShowLoginModal(val)),
    setShowBottomSheet: (val) => dispatch(setShowBottomSheet(val)),
    setEnrolledItemId: (val) => dispatch(setEnrolledItemId(val)),

});
export default connect(mapStateToProps, mapDispatchToProps)(CourseDetailsDescriptionSection)