export const TOGGLE_SIDER = "TOGGLE_SIDER";
export const SHOW_MENU = "SHOW_MENU";
export const SAVE_PAGE_COORDINATES = "SAVE_PAGE_COORDINATES";
export const SET_NOTIFICATIONS_MODAL = "SET_NOTIFICATIONS_MODAL";
export const SET_MENU_WIDTH = "SET_MENU_WIDTH";
export const SET_SCROLL_POS = "SET_SCROLL_POS";


export const setScrollPosition = val => ({
  type: SET_SCROLL_POS,
  val
})
export const setWidthOfMenu = (value) => ({
  type: SET_MENU_WIDTH,
  value
})

export const toggleSider = (value) => ({
  type: TOGGLE_SIDER,
  value,
});

export const showMenu = (bool) => ({
  type: SHOW_MENU,
  bool,
});

export const savePageCoordinates = (x,y) => ({
  type: SAVE_PAGE_COORDINATES,
  x,
  y
})

export const setNotificationVisible = (visible, responseType,  title = "", message = "" ) => ({
  type: SET_NOTIFICATIONS_MODAL,
  visible, title, message, responseType
})