import React, { useEffect, useState } from 'react'
import { Row, Col, Alert, Input } from 'antd'
import Divisioncomponent from '../components/Divisioncomponent'
import LibraryCard from '../components/LibraryCard'
import logo from '../components/assets/first.png'
import logo2 from '../components/assets/videodash.svg'
import logo3 from '../components/assets/thirddash.svg'
import { connect } from 'react-redux'
import {
    fetchDashBoardData, storeArray, updatepdf, updateCurrentSub, updateCurrentStd, updateCurrentIcon, fetchTeacherHomeData,
    fetchStudentHome,
    updateSelectedTopic,
    updateSelectedChapter,
    updatePageNumber,
    subjectFilter,
    // fetchClassLibraryData
} from 'actions/digitalLibrary'
import PageDataLoader from 'components/pageDataLoader';
import AdminDashBoardCard from './dashBoard'
import TeacherHome from './teacherHome'
import UploadModal from '../modals/uploadModel'
import Uploadfiles from '../modals/uploadfiles'
import Videouploadmodel from '../modals/Videouploadmodel'
import Succesfulmodel from '../modals/Succesfulmodel'
import StudentHome from './studentHome'
import VideoTypeModal from '../modals/videoTypeModal'
import moment from 'moment'
import NoDataCard from '../components/noDataCard'
import PageHeader from 'components/pageHeader'
import Search from 'antd/lib/transfer/search'
import BackToTop from 'components/backToTop'
import { Heading } from 'components/Typography'
import { savePageCoordinates } from 'actions/layout'
import { PlusCircleOutlined } from '@ant-design/icons'
import { Button } from 'components/Button'
import { InputSearch } from 'components/customInputs'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';


function AdminEmptystate({
    fetchDashBoardData, standardsInfo,
    storeArray, arrStoreData, updatepdf, totalCountList, updateCurrentIcon, updateCurrentSub, updateCurrentStd, fetchTeacherHomeData,
    user, fetchStudentHome, updateSelectedTopic, updateSelectedChapter, videoPremium, updatePageNumber, currentSubscription, savePageCoordinates, /* fetchClassLibraryData */ subjectFilter

}) {
    const location = useLocation()
    const [divisionComponentData, setdivisionComponentData] = useState({})
    const [arr, setarr] = useState([])
    const [pageLoader, togglePageLoader] = useState(false)
    const [modalUpload, setModalUpload] = useState(false)
    const [uploadFileModal, toggleUploadFileModel] = useState(false)
    const [modal, setmodel] = useState(false)
    const [isVideoTypeModal, setIsVideoTypeModal] = useState(false)
    const [videoType, setVideoType] = useState('')
    const [type, setType] = useState('')
    const [search, setSearch] = useState('')

    console.log("AA-redirectingPageDL",location);
const history =useHistory()
    useEffect(() => {
        togglePageLoader(true)
        updateCurrentStd({})
        updateCurrentSub({})
        updateSelectedTopic({})
        updateSelectedChapter({})
        updateCurrentIcon('')
        updatePageNumber(1)
        if (user.access.includes("digital-library-admin") || user.access.includes("digital-library-superadmin") || user.access.includes("digital-library-dataoperator")) {
            fetchDashBoardData(() => togglePageLoader(false))
        }
        else if (user.access.includes("digital-library-teacher")) {
            fetchTeacherHomeData(() => togglePageLoader(false))
        }
        else {
            fetchStudentHome(null, () => togglePageLoader(false))
        }
        updatepdf(null);

        return () => {
            if (!window.location.pathname.includes("/digital-library")) {
                savePageCoordinates(0, 0)
            }
        }

    }, [])


    useEffect(() => {
        var componentObject = {}
        if (standardsInfo) {
            standardsInfo.map(({ std_id, std_std, std_section,
                notesCount, videoCount, courseName,
                quesBankCount, std_stream: std_stream, totalSize:totalSize }) => {
                if (componentObject[std_std]) {
                    componentObject[std_std] =
                        [
                            ...componentObject[std_std],
                            {
                                std_id: std_id,
                                std_section: std_section,
                                notesCount: notesCount,
                                videoCount: videoCount,
                                quesBankCount: quesBankCount,
                                std_stream: std_stream,
                                courseName: courseName,
                                totalSize: totalSize || 0
                            }
                        ]
                }
                else {
                    componentObject[std_std] = [{
                        std_id: std_id,
                        std_section: std_section,
                        notesCount: notesCount,
                        videoCount: videoCount,
                        quesBankCount: quesBankCount,
                        std_stream: std_stream,
                        courseName: courseName,
                        totalSize:totalSize || 0
                    }]
                }
            })
        }
        var keysOfComponet = Object.keys(componentObject);
        setdivisionComponentData(componentObject)
        var keysOfComponentwithId = [];
        keysOfComponet.map((item) => {
            keysOfComponentwithId.push([item, componentObject[item][0].std_id])
        })
        storeArray(keysOfComponentwithId)
        setarr(keysOfComponentwithId)
    }, [standardsInfo])

    const handleSearch = (e) => {
        setSearch(e)
    }

    const searchResults = arr.filter(el => {
        const std = el[0];
        return std.toLowerCase().includes(search.toLowerCase());
    })

    const actions = [
        <Button type="primary" onClick={() => { setModalUpload(true) }} style={{}} icon={<PlusCircleOutlined />}>UPLOAD CONTENT</Button>
    ]

    return (
        <div>
            {location?.state?.fromBrodcastVR?.redirectingPageDL
            ?
        (<>
         <div style={{ marginBottom: 50 }}>
                                    <PageHeader title='Library'  
                                     onBackPress={() => {
                                        history.push({
                                            pathname: "/course-details",
                                            state: {
                                              fromBrodcastVR:
                                              {
                                                fromAddBroadcastPage:true,
                                                sections: location?.state?.fromBrodcastVR?.sections,
                                              }
                                            }
                                          })
                
                                    }}/>
                                </div>
        </>):(<>
            <div >
                {!user.access.includes("digital-library-superadmin") && !user.access.includes("digital-library-admin") && user.access.includes("digital-library-teacher") ? <TeacherHome /> : <div>{user.access.includes('digital-library-student') ? <StudentHome /> :
                    <div>
                        {totalCountList?.toalNotesCount > 0 || totalCountList?.totalVideoCount > 0 || totalCountList?.totalQuesBankCount > 0 ?
                            (<div>
                                <div style={{ marginBottom: 70 }}>
                                    <PageHeader title='Library' actions={actions} />
                                </div>

                                {currentSubscription && Object.keys(currentSubscription)?.length != 0 ?
                                    <div style={{ marginTop: '5px', marginLeft: '8px' }}>
                                        {moment().format('YYYY-MM-DD') > moment(currentSubscription?.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD') ?
                                            <Alert message={`Your storage subscription has expired on ${moment(currentSubscription?.endDate, 'YYYY-MM-DD').format('MMMM DD YYYY')}.Please RENEW your subscription or make other changes.`} type="info" closeText="Close" style={{ backgroundColor: '#F6F4FE', border: '1px solid #E6E6E6' }} />
                                            :
                                            <Alert message={`Your storage subscription shall expire on ${moment(currentSubscription?.endDate, 'YYYY-MM-DD').format('MMMM DD YYYY')}.Please RENEW your subscription or make other changes.`} type="info" closeText="Close" style={{ backgroundColor: '#F6F4FE', border: '1px solid #E6E6E6' }} />
                                        }
                                    </div> : null}
                                {/* <div style={{ fontSize: "15px", fontWeight: "bold", marginLeft: "10px", marginTop: "10px" }}>Overall stats</div> */}

                                <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>

                                    <AdminDashBoardCard totalCountList={totalCountList} togglePageLoader={togglePageLoader} pageLoader={pageLoader} />
                                </div>
                            </div>
                            ) :
                            (<div>
                                <div className='lecturenotestitle' style={{ fontSize: '28px', marginTop: '10px', marginBottom: '10px' }}>Library</div>
                                <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%', height: 'max-content' }}>
                                    <LibraryCard classess={arr} title='Notes'
                                        text='Upload study material (pdf) of all kinds in an organized manner (subject-wise). The content shall be displayed to only those students who belong to the classroom(s) for which it is being uploaded. Files cannot be downloaded or screenshotted by the students.'
                                        logo={logo}
                                        togglePageLoader={togglePageLoader}

                                    />
                                    <LibraryCard classess={arr} title='Videos'
                                        text='Upload links of video lectures/instructional videos from YouTube in an organized manner (subject-wise). Just copy the link and paste it here. Access shall be provided to only those students who belong to the classroom(s) for which it is being uploaded. Upload videos cannot be downloaded by the students.'
                                        logo={logo2}
                                        togglePageLoader={togglePageLoader}
                                    />
                                    <LibraryCard classess={arr} title='Test papers'
                                        text='Create your own customized set of question papers (pdf) for every subject of every classroom. The papers shall be accessible to only those students who belong to the classroom(s) for which they are being uploaded. Papers cannot be downloaded or screenshotted by the students.'
                                        logo={logo3}
                                        togglePageLoader={togglePageLoader}
                                    />
                                </div>

                            </div>
                            )
                        }
                    </div>
                }
                </div>

                }

            </div>
        </>)}
           
            
            {user.access.includes("digital-library-admin") || user.access.includes("digital-library-superadmin") || user.access.includes("digital-library-dataoperator") || user.access.includes("digital-library-teacher") ?

                <div>
                    <div style={{ width: "30%", marginTop: "20px", float: "right" }}>
                        {/* <Search placeholder="Search Classes" value={search} onChange={(e)=>{handleSearch(e)}}allowClear  style={{ width: '80%' }} /> */}
                        <InputSearch allowClear placeholder="Search classes" onInputChange={(e) => { handleSearch(e) }} onClear={() => { handleSearch('') }} />
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between",marginLeft:"10px" }}>
                        <Heading className='m-t-20'>All batches</Heading>
                    </div>
                    {searchResults?.length != 0 ?
                        searchResults.map((items, index) => {
                            return (
                                <div style={{marginTop:"10px"}}>
                                    <p className='lecturenotestitle' style={{ fontSize: '18px', }}>{items[0]}</p>
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        {
                                            divisionComponentData[items[0]].map((item, index) => {
                                                return (<Divisioncomponent std_section={item.std_section} notesCount={item.notesCount} videoCount={item.videoCount} quesBankCount={item.quesBankCount} index={index} stds={items} section_arr={divisionComponentData} std_stream={item?.courseName || 'NA'} location={location} item={item} totalsize={item?.totalSize}/>)
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                        :
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
                            <NoDataCard imgwidth={'200px'} imgheight={'200px'} />
                        </div>
                    }
                </div>
                : null}

            {videoPremium ? (isVideoTypeModal && <VideoTypeModal visible={isVideoTypeModal} setVisible={setIsVideoTypeModal} setVideoType={setVideoType} toggleUploadFileModel={toggleUploadFileModel} />) : null}
            {videoType == 'videoLink' ? (uploadFileModal && <Videouploadmodel type={type} classess={arrStoreData[0]} ismodelvisible={uploadFileModal} setmodevisible={toggleUploadFileModel} setmodel={setmodel} />) : (uploadFileModal && <Uploadfiles classess={arrStoreData[0]} type={type} ismodelvisible={uploadFileModal} setmodevisible={toggleUploadFileModel} setmodel={setmodel} contentPage={false} location={"home"} />)}
            {(modalUpload && <UploadModal visible={modalUpload} setVisible={setModalUpload} toggleUploadFileModel={toggleUploadFileModel} setType={setType} setIsVideoTypeModal={setIsVideoTypeModal} setVideoType={setVideoType} location={"home"} />)}
            <Succesfulmodel togglePageLoader={togglePageLoader} fetchApi={false} videoType={videoType} />
            <PageDataLoader visible={pageLoader} />
            <BackToTop />
        </div>
    )
}

const mapStateToProps = (state) => {
    const {
        standardsInfo,
        arrStoreData, totalCountList,
        videoPremium,
        currentSubscription

    } = state.digitalLibrary;
    const { user } = state.session;
    return {
        standardsInfo,
        arrStoreData,
        totalCountList,
        user,
        videoPremium,
        currentSubscription
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchDashBoardData: (callback) => dispatch(fetchDashBoardData(callback)),
    storeArray: (keysOfComponentwithId) => dispatch(storeArray(keysOfComponentwithId)),
    updatepdf: (data) => dispatch(updatepdf(data)),
    updateCurrentStd: (data) => dispatch(updateCurrentStd(data)),
    updateCurrentSub: (data) => dispatch(updateCurrentSub(data)),
    updateCurrentIcon: (data) => dispatch(updateCurrentIcon(data)),
    fetchTeacherHomeData: (callback) => dispatch(fetchTeacherHomeData(callback)),
    fetchStudentHome: (params, callback) => dispatch(fetchStudentHome(params, callback)),
    updateSelectedTopic: (object) => dispatch(updateSelectedTopic(object)),
    updateSelectedChapter: (object) => dispatch(updateSelectedChapter(object)),
    updatePageNumber: (data) => dispatch(updatePageNumber(data)),
    // fetchClassLibraryData : () => dispatch(fetchClassLibraryData())
    subjectFilter: (subject, callback) => dispatch(subjectFilter(subject, callback)),
    savePageCoordinates: (x, y) => dispatch(savePageCoordinates(x, y))

});

export default connect(mapStateToProps, mapDispatchToProps)(AdminEmptystate);
