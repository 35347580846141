import { ArrowLeftOutlined, EditOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { getSubjStudentsData, getCourseData, getSelectedSectionData, storeSelectedClassDetails, setAllStudentsPageParam, updateMarkAsCompleted } from 'actions/classRoom';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import EditClassModal from './modals/editClass';
import PageDataLoader from "components/pageDataLoader";
import { Avatar, Col, Divider, Row, Typography } from 'antd';
import moment from 'moment';
import { Button } from 'components/Button';
import CreateSubjectsModal from 'pages/courseModule/createSubjectsModal';
import AddStudentModal from './modals/addStudent';
import { SquareSubIcon } from 'components/subIcon';
import { Api } from 'services';
import { addSubjects } from 'actions/course';
import HtmlParser from 'react-html-parser';
import ic_file_add from "../../Assets/images/ic_file_add.svg"
import CreateCurriculumModal from 'pages/adminMarketPlaceCourse/createCurriculumModal';
import { postCreateCurriculum } from 'actions/courseManagement';
import { stripHtmlTags } from 'pages/adminMarketPlaceCourse/courseDetailsComp';
import { setWhichViewAllClicked } from 'actions/feeManagement';


export function NodataCard({
  containerheight = "80%",
  imgwidth = "30%",
  imgheight = "30%",
}) {
  return (
    <div className="r-c-c-c" style={{ height: containerheight }}>
      <img
        style={{ width: imgwidth, height: imgheight, marginBottom: "20px" }}
        src={require("../../Assets/test/no-subjects.svg").default}
        alt=""
      />
      <div className="r-c-c-c">
        <h1 style={{ fontFamily: "roboto", fontWeight: "800" }}>No data!</h1>
        <p style={{ color: "#636363", fontFamily: "roboto" }}>
          There is no course assigned yet.
        </p>
      </div>
    </div>
  );
}

const bodyStyle = {
  background: "#FAFAFA",
  width: "calc(100% + 40px)",
  height: "calc(100% + 40px)",
  margin: -20,
  padding: 20,
  overflow: "auto"
}

const NoDataCardComponent = ({ image, title, description, isBtn, isOneBtn, isTwoBtns, setIsCreateCurriculumModalVisible, setAddStudentModalVisible, user }) => {
  const history = useHistory();
  return (
    <>
      <div className='r-c-c-c' style={{ height: isBtn ? 300 : "" }}>
        <img style={{ width: 150, height: 150 }} src={image} alt="" />
        <div style={{ fontSize: 17, fontWeight: "bold", color: "#191919" }}>{title}</div>
        <center style={{ color: "#636363", fontSize: 15 }}>{description}</center>
      </div>
      {isTwoBtns && (user?.privilage?.includes('SuperAdmin') || user?.privilage?.includes('Admin')) ?
        <div className='r-c-se m-t-20' style={{ width: "100%" }}>
          <Button onClick={() => { setIsCreateCurriculumModalVisible(true) }}>CREATE</Button>
          <Button type="primary" onClick={() => { history.push("/classroom/resources") }}><SearchOutlined style={{ marginRight: 5 }} />BROWSE</Button>
        </div>
        :
        isOneBtn && (user?.privilage?.includes('SuperAdmin') || user?.privilage?.includes('Admin')) ?
          <div className='r-c-c m-t-20' style={{ width: "100%" }}>
            <Button type="primary" onClick={() => { setAddStudentModalVisible(true) }}><PlusCircleOutlined style={{ marginRight: 5 }} />ADD</Button>
          </div>
          :
          <></>
      }
    </>
  )
}

function SingleSectionDetails({ getSelectedSectionData, getCourseData, selectedSectionData, selectedCourseDataContainer, selectedSection, storeSelectedClassDetails, getSubjStudentsData, addSubjects, updateCurriculumClass, setAllStudentsPageParam, selectedCourseId, user, isFromCourseDetailsReducer, postCreateCurriculum, updateMarkAsCompleted,setWhichViewAllClicked }) {
  const history = useHistory();
  const [pageLoader, togglePageLoader] = useState(false);
  const [isAddStudentModalVisible, setAddStudentModalVisible] = useState(false)
  const [showEditClassModal, setShowEditClassModal] = useState(false);
  const [isCreateCurriculumModalVisible, setIsCreateCurriculumModalVisible] = useState(false);
  const [check, setCheck] = useState(false)

  useEffect(()=>{
      setCheck(selectedSection?.markAs)
    },[selectedSection])

  const tempArr = [
    {
      image: require("../../Assets/classroom/schedule.svg").default,
      title: "Schedules",
      renderFee: true
    },
    {
      image: require("../../Assets/classroom/attendance.svg").default,
      title: "Attendance",
      renderFee: true
    },
    {
      image: require("../../Assets/classroom/fee.svg").default,
      title: "Fee",
      renderFee: selectedSectionData?.redirect?.includes('fee')
    },
    {
      image: require("../../Assets/classroom/liveTest.svg").default,
      title: "Live Test",
      renderFee: true
    },
    // {
    //   image: require("../../Assets/classroom/practiceTest.svg").default,
    //   title: "Practice Test",
    // },
    {
      image: require("../../Assets/classroom/DL.svg").default,
      title: "Digital Library",
      renderFee: true
    }
  ]
  const ModuleCards = tempArr.filter(item => item.renderFee)
  useEffect(() => {
    setAllStudentsPageParam("search", null);
    setAllStudentsPageParam("page", 1);
    setAllStudentsPageParam("limit", 10);
    setAllStudentsPageParam("sort", null);
    if (selectedCourseId) {
      getCourseData();
    } else { }
    getSubjStudentsData(() => { })
    togglePageLoader(true)
    getSelectedSectionData(() => togglePageLoader(false))
  }, [])
  function IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  function isHTML(str) {
    var a = document.createElement('div');
    a.innerHTML = str;

    for (var c = a.childNodes, i = c.length; i--;) {
      if (c[i].nodeType === 1) return true;
    }

    return false;
  }

  const handelOnEditCurriculum = () => {
    history.push("/classroom/curriculum")
  }
  return (
    <div style={bodyStyle}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ fontWeight: "700", display: "flex", flexDirection: "row", marginTop: "5px" }}>
          <ArrowLeftOutlined
            style={{ marginTop: "8px", fontSize: "20px" }}
            onClick={() => {
              if(isFromCourseDetailsReducer){
                history.push({ pathname: "/course-details", state: { key: "openBatches" } });
              }else{
                history.push("/classroom");
              }
            }} />
          <div style={{ marginLeft: "15px", fontSize: "20px" }}>{selectedSection?.std} : {selectedSection?.section}</div>
        </div>
        {(user?.privilage?.includes('SuperAdmin')) || (user?.privilage?.includes('Admin')) ? <div className='cursor-pointer m-t-10' onClick={() => { storeSelectedClassDetails([selectedSection]); setShowEditClassModal(true) }} style={{ color: "#1089FF", fontSize: 16, fontWeight: "bold" }}><EditOutlined /> EDIT BATCH</div> : <></>}
      </div>
      <div style={{ width: "100%", display: "flex" }} className='m-t-20'>
        <div style={{ width: "35%", margin: "0px 20px 0px 0px" }}>
          <div className='container border-16 boxShadow118' style={{ height: 400, width: "100%", padding: 20, fontFamily: "roboto" }}>
            {selectedCourseId ?
              <>
              <div style={{display:'flex', justifyContent:'space-between',}}>
              <div className='r-jsb' style={{ width:'55%'}}>
                  <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: 18, fontWeight: "bold", color: "#191919", }}>{selectedCourseDataContainer?.courseName}</Typography.Text>
                </div>
                <div style={{width:'45%',fontWeight:'bold',color:'#1089FF', display:'flex', justifyContent:'flex-end', paddingTop:4, fontSize:15, cursor:'pointer'}} onClick={()=>{updateMarkAsCompleted(!check,()=>{}); getSubjStudentsData(() => { })}}>{check ? 'MARK AS INCOMPLETE' : 'MARK AS COMPLETED'}</div>
              </div>
                
                {selectedCourseDataContainer?.courseDescription ?
                  <div className='scroll-bar-universal m-t-10' style={{ height: 320, color: "#636363", fontSize: 16 }}>
                    {IsJsonString(selectedCourseDataContainer?.courseDescription) ? HtmlParser(JSON.parse(selectedCourseDataContainer?.courseDescription)?.mobile) : stripHtmlTags(selectedCourseDataContainer?.courseDescription)}
                    {/* {!IsJsonString(JSON.parse(selectedCourseDataContainer?.courseDescription)?.mobile) && isHTML(JSON.parse(selectedCourseDataContainer?.courseDescription)?.mobile) ? HtmlParser(JSON.parse(selectedCourseDataContainer?.courseDescription)?.mobile) : HtmlParser(JSON.parse(selectedCourseDataContainer?.courseDescription)?.mobile)} */}
                  </div>
                  :
                  <></>}
              </>
              :
              <NodataCard />
            }
          </div>
        </div>
        <div style={{ width: "30%", margin: "0px 20px 0px 0px" }}>
          <div className='container border-16 boxShadow118' style={{ height: 400, width: "100%", padding: 20, fontFamily: "roboto" }}>
            <div className='r-jsb m-b-20'>
              <div style={{ fontSize: 18, fontWeight: "bold", color: "#191919" }}>Curriculum</div>
              {selectedSection?.subjects?.length && (user?.privilage?.includes('SuperAdmin')) || (user?.privilage?.includes('Admin')) ? <div className='cursor-pointer' onClick={handelOnEditCurriculum} style={{ color: "#1089FF", fontSize: 16, fontWeight: "bold" }}><EditOutlined /> EDIT</div> : <></>}
            </div>
            {selectedSection?.subjects?.length ?
              <>
                <div style={{ height: 270 }}>
                  {selectedSection?.subjects?.map((item, index) => {
                    return (
                      <div style={{ display: index <= 3 ? 'view' : "none" }}>
                        <div style={{ display: "flex", fontFamily: "roboto" }}>
                          <SquareSubIcon name={item.name} size={24} />
                          <div className='m-l-20'>
                            <Typography.Text ellipsis={{ tooltip: true }} style={{ color: "#191919", fontSize: 16, fontWeight: "bold", width: 200 }}>{item?.name}</Typography.Text>
                            <div style={{ color: "#636363", }}>{item?.chapters ? item?.chapters?.length : 0} Chapters</div>
                          </div>
                        </div>
                        <Divider style={{ margin: "10px 0px" }} />
                      </div>
                    )
                  })
                  }
                </div>
                {<div className='r-c-c m-t-20' style={{ color: "#1089FF", fontSize: 15, fontWeight: "bold", cursor: "pointer" }} onClick={handelOnEditCurriculum}>VIEW ALL</div>}
              </>
              :
              <NoDataCardComponent
                image={require("../../Assets/test/no-subjects.svg").default}
                title={"No subjects added yet!"}
                description={"You can browse from our resources or create manual"}
                isTwoBtns={true}
                setIsCreateCurriculumModalVisible={setIsCreateCurriculumModalVisible}
                user={user} />
            }
          </div>
        </div>
        <div style={{ width: "30%", margin: "0px 0px 0px 0px" }}>
          <div className='container border-16 boxShadow118' style={{ height: 400, width: "100%", padding: 20, fontFamily: "roboto" }}>
            <div className='r-jsb m-b-20'>
              <div style={{ fontSize: 18, fontWeight: "bold", color: "#191919" }}>Student(s) <span style={{ color: "#AEAEAE" }}>({selectedSection?.students?.meta?.totalItems})</span></div>
              {selectedSection?.students?.items?.length && (user?.privilage?.includes('SuperAdmin')) || (user?.privilage?.includes('Admin')) ? <div className='cursor-pointer' onClick={() => { setAddStudentModalVisible(true) }} style={{ color: "#1089FF", fontSize: 16, fontWeight: "bold" }}><PlusCircleOutlined /> ADD</div> : <></>}
            </div>
            {selectedSection?.students?.items?.length ?
              <>
                <div style={{ height: 270 }}>
                  {selectedSection?.students?.items?.map((item, index) => {
                    return (
                      <div style={{ display: index <= 3 ? 'view' : "none" }}>
                        <div style={{ display: "flex", fontFamily: "roboto", }}>
                          <img
                            src={`${Api._s3_url}${item.image}`}
                            className="radius-100 batch-detial__student-img"
                            style={{ height: 50, width: 50, borderRadius: '50%' }}
                          />
                          <div className="m-l-20">
                            <Typography.Text ellipsis={{ tooltip: true }} style={{ color: "#191919", fontSize: 16, fontWeight: "bold", width: 200 }}>{item.name}</Typography.Text>
                            <Typography.Text ellipsis={{ tooltip: true }} style={{ color: "#636363", width: 200 }}>Roll no. {item.rollNo}</Typography.Text>
                          </div>
                        </div>
                        <Divider style={{ margin: "10px 0px" }} />
                      </div>
                    )
                  })
                  }
                </div>
                <div className='r-c-c m-t-20' style={{ color: "#1089FF", fontSize: 15, fontWeight: "bold", cursor: "pointer" }} onClick={() => history.push("/classroom/allstudents")}>VIEW ALL</div>
              </>
              :
              <NoDataCardComponent
                image={require("../../Assets/classroom/no-students.svg").default}
                title={"No students here"}
                description={"Start adding students to this class"}
                isOneBtn={true}
                setAddStudentModalVisible={setAddStudentModalVisible}
                user={user}
              />
            }
          </div>
        </div>
      </div>
      <div style={{ width: "100%", display: "flex" }} className='m-t-20'>
        <div style={{ display: "flex", flexWrap: "wrap", width: "70%" }}>
          {ModuleCards?.map((item, index) => {
            return (
              <>
                <div className='container border-16 boxShadow118 m-b-20 m-r-20' style={{ height: 190, width: "30%", padding: 20, fontFamily: "roboto" }}>
                  <img style={{ width: 50, height: 50 }} src={item?.image} alt='' />
                  <div style={{ fontSize: 17, fontWeight: "bold", color: "#191919", marginTop: 10 }}>{item?.title}</div>
                  <div style={{ color: "#636363", fontSize: 13, marginBottom: 10 }}>
                    {item.title == 'Schedules' ? (selectedSectionData?.cards?.schedules || 0) + `${user?.role?.includes('Teacher') && !(user?.privilage?.includes('SuperAdmin') || user?.privilage?.includes('Admin')) ? ' classes completed by you' : ' classes completed'}`
                      : item.title == 'Attendance' ? "Average percentage is " + (selectedSectionData?.cards?.attendance || 0) + "%"
                        : item.title == 'Fee' ? (selectedSectionData?.cards?.fee || 0) + " payments requested"
                          : item.title == 'Live Test' ? (selectedSectionData?.cards?.liveTest || 0) + `${user?.role?.includes('Teacher') && !(user?.privilage?.includes('SuperAdmin') || user?.privilage?.includes('Admin')) ? ' live tests held by you' : ' live tests held'}`
                            : item.title == 'Digital Library' ? (!(user?.privilage?.includes('SuperAdmin') || user?.privilage?.includes('Admin')) && user?.role?.includes('Teacher')) ? `${selectedSectionData?.cards?.digitalLibrary ?? 0} resources uploaded by you` : `${selectedSectionData?.cards?.digitalLibrary ?? 0} resources uploaded`
                              : null
                    }
                  </div>
                  <div
                    style={{
                      color: "#1089FF",
                      fontSize: 15,
                      fontWeight: "bold",
                      cursor:
                        (item.title == 'Schedules' && !selectedSectionData?.redirect?.includes("schedules")) ? "not-allowed" :
                          (item.title == 'Attendance' /* && !selectedSectionData?.redirect?.includes("attendance")*/) ? "not-allowed" :
                            (item.title == 'Fee' && !selectedSectionData?.redirect?.includes("fee")) ? "not-allowed" :
                              (item.title == 'Live Test' && !selectedSectionData?.redirect?.includes("liveTest")) ? "not-allowed" :
                                (item.title == 'Digital Library' && !selectedSectionData?.redirect?.includes("digitalLibrary")) ? "not-allowed" : "pointer",
                      opacity:
                        (item.title == 'Schedules' && !selectedSectionData?.redirect?.includes("schedules")) ? 0.5 :
                          (item.title == 'Attendance' /* && !selectedSectionData?.redirect?.includes("attendance")*/) ? 0.5 :
                            (item.title == 'Fee' && !selectedSectionData?.redirect?.includes("fee")) ? 0.5 :
                              (item.title == 'Live Test' && !selectedSectionData?.redirect?.includes("liveTest")) ? 0.5 :
                                (item.title == 'Digital Library' && !selectedSectionData?.redirect?.includes("digitalLibrary")) ? 0.5 : 1,
                    }}
                    onClick={() => {
                      if (item.title == 'Schedules' && selectedSectionData?.redirect?.includes("schedules")) {
                        history.push({
                          pathname: "/schedule",
                          state: {
                            classroom: {
                              classId: selectedSection?.id
                            },
                            key: 'fromClassroom',
                          }
                        })
                      } else if (item.title == 'Attendance' /* && selectedSectionData?.redirect?.includes("attendance")*/) {
                        // history.push({ pathname: "/student-detail-page", state: { key: "fromClassroom" } })
                      } else if (item.title == 'Fee' && selectedSectionData?.redirect?.includes("fee")) {
                        setWhichViewAllClicked('course')
                        history.push({
                          pathname: "/fee-management/course-details",
                          state: {
                            classroom: selectedSection?.id
                          }
                        })
                      } else if (item.title == 'Live Test' && selectedSectionData?.redirect?.includes("liveTest")) {
                        history.push({ pathname: "/test-std-to-subjects-screen", state: { key: "fromClassroom" } })
                      } else if (item.title == 'Digital Library' && selectedSectionData?.redirect?.includes("digitalLibrary")) {
                        history.push({
                          pathname: "/digital-library/dashbord-subject",
                          state: {
                            classroom: {
                              std_id: selectedSection?.id,
                              courseName: selectedSection?.courseName,
                              standard: selectedSection?.std,
                              std_section: selectedSection?.section
                            }
                          }
                        })
                      }
                    }}
                  >VIEW</div>
                </div>
              </>
            )
          })}
        </div>
        {((user?.privilage?.includes('SuperAdmin')) || (user?.privilage?.includes('Admin'))) ?
          <div style={{ width: "30%", marginLeft: -20 }}>
            <div className='container border-16 boxShadow118' style={{ height: 400, width: "100%", padding: 20, fontFamily: "roboto" }}>
              <div style={{ fontSize: 18, fontWeight: "bold", color: "#191919", marginBottom: 10 }}>Announcements</div>
              {selectedSectionData?.announcements?.length ?
                <>
                  <div style={{ height: 280 }}>
                    {selectedSectionData?.announcements?.map((item, index) => {
                      return (
                        <div style={{ display: index <= 2 ? 'view' : "none" }}>
                          <Typography.Text ellipsis={{ tooltip: true }} style={{ width: "200px", fontFamily: 'roboto', color: '#191919', fontSize: '14px', fontWeight: 'bold' }}>{item?.title}</Typography.Text>
                          {item?.fileName ?
                            <div className='' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                              {item?.fileName?.map((i) => (
                                <div style={{ fontSize: "12px" }}><img src={ic_file_add} />{i.key}</div>
                              ))}</div>
                            :
                            <Typography.Text style={{ fontFamily: 'roboto', color: '#636363', fontSize: '14px', width: 250, height: 20 }} ellipsis={{ tooltip: true }}>
                              {!IsJsonString(JSON.parse(item?.description)?.mobile) && isHTML(JSON.parse(item?.description)?.mobile) ? HtmlParser(JSON.parse(item?.description)?.mobile) : HtmlParser(JSON.parse(item?.description)?.mobile)}
                            </Typography.Text>
                          }
                          <div style={{ display: "flex" }}>
                            <div style={{ color: "#AEAEAE", marginRight: "5px" }}>{moment(item?.date, 'YYYY-MM-DD').format('DD/MM/YYYY')}, </div>
                            <div style={{ color: "#AEAEAE", marginRight: "5px" }}> {moment(item?.time, 'HH:mm:ss').format('hh:mm A')}</div>
                          </div>
                          <Divider style={{ margin: "10px 0px" }} />
                        </div>
                      )
                    })
                    }
                  </div>
                  {(user?.privilage?.includes('SuperAdmin')) || (user?.privilage?.includes('Admin')) ? <div className='r-c-c m-t-20' style={{ color: "#1089FF", fontSize: 15, fontWeight: "bold", cursor: "pointer" }} onClick={() => history.push({ pathname: "/announcement", state: { key: "fromClassroom", key1: selectedSection?.id } })}>VIEW ALL</div> : <> </>}
                </>
                :
                <NoDataCardComponent
                  image={require("../../Assets/classroom/ic-no-announcements.svg").default}
                  title={"No announcements yet!"}
                  description={"There are no announcements yet"}
                  isBtn={true}
                  user={user} />
              }
            </div>
          </div> : <></>}
      </div>
      {showEditClassModal &&
        <EditClassModal
          showEditClassModal={showEditClassModal}
          setShowEditClassModal={setShowEditClassModal}
          isInsideSingleClass={true}
          togglePageLoader={togglePageLoader}
          getSubjStudentsData={getSubjStudentsData}
          selectedCourseId={selectedCourseId}
          getCourseData={getCourseData} />
      }
      {isCreateCurriculumModalVisible &&
        <CreateCurriculumModal
          isCreateCurriculumModalVisible={isCreateCurriculumModalVisible}
          setIsCreateCurriculumModalVisible={setIsCreateCurriculumModalVisible}
          togglePageLoader={togglePageLoader}
          getSubjStudentsData={getSubjStudentsData}
          postCreateCurriculum={postCreateCurriculum}
          fromClassroom={true}
        />
      }
      {isAddStudentModalVisible &&
        <AddStudentModal
          isAddStudentModalVisible={isAddStudentModalVisible}
          setAddStudentModalVisible={setAddStudentModalVisible}
          standardId={selectedSection?.id}
          togglePageLoader={togglePageLoader}
          getSubjStudentsData={getSubjStudentsData}
        />}
      <PageDataLoader visible={pageLoader} />
    </div>
  )
}
const mapStateToProps = state => {
  const { selectedSectionData, selectedSection } = state.classRoom;
  const { selectedCourseDataContainer, selectedCourseId } = state.course;
  const { user } = state.session
  const { isFromCourseDetailsReducer } = state.courseManagement;
  return {
    selectedSectionData, selectedCourseDataContainer, selectedSection, selectedCourseId, user, isFromCourseDetailsReducer
  };
};

const mapDispatchToProps = dispatch => ({
  getSelectedSectionData: (callBack) => dispatch(getSelectedSectionData(callBack)),
  getCourseData: () => dispatch(getCourseData()),
  getSubjStudentsData: (callBack) => dispatch(getSubjStudentsData(callBack)),
  updateMarkAsCompleted: (markAs,callBack) => dispatch(updateMarkAsCompleted(markAs,callBack)),
  storeSelectedClassDetails: (val) => dispatch(storeSelectedClassDetails(val)),
  addSubjects: (subjectName, addChapter, isAdding, fromClassroom, callBack, successCallBack) => dispatch(addSubjects(subjectName, addChapter, isAdding, fromClassroom, callBack, successCallBack)),
  setAllStudentsPageParam: (key, val) => dispatch(setAllStudentsPageParam(key, val)),
  setWhichViewAllClicked: res => dispatch(setWhichViewAllClicked(res)),
  postCreateCurriculum: (params, fromClassroom, callback, succesCallback) => dispatch(postCreateCurriculum(params, fromClassroom, callback, succesCallback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleSectionDetails)