import {
  ArrowRightOutlined,
  LockOutlined,
  MinusOutlined,
  SettingFilled,
  SettingOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { Avatar, Col, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import DashBoardGraph from "./dashBoardGraph";
import OverViewGraph from "./overViewGraph";
import style from "./style.css";
import image from "../../Assets/feeManagement/Mask Group 2.png";
import image1 from "../../Assets/feeManagement/ic-generate.svg";
import image2 from "../../Assets/feeManagement/ic-offline.svg";
import image3 from "../../Assets/feeManagement/ic-transactions.svg";
import image4 from "Assets/user-management/ic-students.svg";
import empty from "Assets/feeManagement/ill-kyc-verification.svg";
import SettingsModal from "./settingsModal";
import GeneratePayRequest from "./generatePayRequest";
import AddDetailsModal from "./addDetailsModal";
import OfflinePayReq from "./addOfflinePayReqModal";
import { useHistory } from "react-router";
import {
  getFeeOverView,
  recentActivitiesVAFromWhere,
  setAllActivitiesFilter,
  setFeeDetails,
  setOverviewFilter,
  storeAllStudentByCourseDetails,
} from "actions/feeManagement";
import { connect } from "react-redux";
import moment from "moment";
import { Api } from "services";
import ViewTransactionDetailsModal from "./viewTransactionDetailsModal";
import PageDataLoader from "components/pageDataLoader";
import imageSet from "../../Assets/care/no-subjects.svg";
import { setAulasCareChat } from "actions/adminCare";
import SettlementDetailsModal from "./settlementDetailsModal";
import KycSuccess from "pages/kyc/kycSuccess";
import KycReject from "pages/kyc/kycReject";
import { updateRejectKycMessage } from "actions/fees";
import ShowReasonModal from "./showReasonModal";
import { NodataCard } from "pages/Test";
import PageHeaderGlobal from "components/pageHeader";
import PageHeader from "components/pageHeader";
import { Button } from "components/Button";



function FeeManagement({
  getFeeOverView,
  feeMangementData,
  setAllActivitiesFilter,
  setFeeDetails,
  setOverviewFilter,
  user,
  setAulasCareChat,
  rejectKycMessage,
  updateRejectKycMessage,
  overviewFilter,
  recentActivitiesVAFromWhere,
  storeAllStudentByCourseDetails
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showGeneratePayReq, setShowGeneratePayReq] = useState(false);
  const [showAddDetailsModal, setShowAddDetailsModal] = useState(false);
  const [showOfflinePayReqModal, setShowOfflinePayReqModal] = useState(false);
  const [isAddOrGenModal, setIsAddOrGenModal] = useState(false);
  const [isAdditionModal, setIsAdditionModal] = useState(false);
  const [selectedBtn, setSelectedBtn] = useState(1);
  const [showSettlementModal, setShowSettlementModal] = useState(false);
  const [showReasonModal, setShowReasonModal] = useState(false)

  const [pageLoader, tooglePageLoder] = useState(false);
  const history = useHistory();
  const [formattedDate, setFormattedDate] = useState({ datePart: '', timePart: '' });
  function numberWithCommas(x) {
    return x.toString().split('.')[0].length > 3 ? x.toString().substring(0, x.toString().split('.')[0].length - 3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length - 3) : x.toString();
  }

  function updateCursor(status, index) {
    if (index < 2 && status !== 'Approved') {
      return "not-allowed";
    }

    return "pointer";
  }
  useEffect(() => {
    setOverviewFilter("standardId", null);
    setOverviewFilter("userId", null);
    setOverviewFilter("year", null);
    setAllActivitiesFilter("userId", null);
    tooglePageLoder(true);
    getFeeOverView(() => tooglePageLoder(false));
    setFeeDetails("generatePayDate", null);
    setFeeDetails("generatePayDiscount", null);
    setFeeDetails("generatePayAdditions", null);
    updateRejectKycMessage(feeMangementData?.dashBoardDetails?.rejectKycMessage ? feeMangementData?.dashBoardDetails?.rejectKycMessage : "")
  }, []);


  const actions = [
    <Button type="secondary" onClick={() => { setIsModalVisible(true) }} style={{}} icon={<SettingFilled />}>SETTINGS</Button>
  ]

  return (
    <div style={{ width: "100%" }}>

      <div style={{ marginTop: -10, height: 50 }}>
        <PageHeader title='Fee Management' actions={actions} />
      </div>

      {feeMangementData?.dashBoardDetails?.kycStatus !== 'Approved' && (
        <div style={{ backgroundColor: '#F6F4FE', border: '1px solid #E6E6E6', padding: 10, cursor: 'text', marginBottom: 20 }}>
          <span style={{ fontSize: '1rem' }}>
            {feeMangementData?.dashBoardDetails?.kycStatus == 'Pending' ?
              'Activate your KYC to start accepting payments from customers.' :
              feeMangementData?.dashBoardDetails?.kycStatus == 'Submitted' ?
                'The details and documents you had submitted are currently being verified. For any further assistance please drop us a mail at care@aulas.in' :
                feeMangementData?.dashBoardDetails?.kycStatus == 'Rejected' ?
                  "Some inconsistency has been noted in the submitted details or documents. Please read the comments and submit it again." :
                  ''
            }
            {feeMangementData?.dashBoardDetails?.kycStatus == 'Rejected' && (
              <span style={{ color: '#1089ff', cursor: 'pointer', paddingLeft: 5 }} onClick={() => { setShowReasonModal(true) }}>
                View Details
              </span>
            )}

            <span style={{ color: '#1089ff', cursor: 'pointer', paddingLeft: 5 }} onClick={() => { history.push("/kyc-form") }}>
              {feeMangementData?.dashBoardDetails?.kycStatus == 'Submitted' ? 'View Kyc' :
                feeMangementData?.dashBoardDetails?.kycStatus == 'Pending' ? 'Update KYC' : ''}
            </span>
          </span>
        </div>
      )}
      <Row style={{ marginTop: 20 }}>
        <Col
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 16 }}
        >
          <div
            className=" div_style box-shadow"
            style={{
              height: "450px",
              padding: "20px",
              borderRadius: "15px",
              overflowY: "hidden",
            }}
          >
            <div className="r-jc">
              <div style={{ width: "25%" }}>
                <div>
                  <div className="font_roboto bold-600">Overview</div>
                  <div className="font_roboto" style={{ fontSize: "12px", color: "#636363", fontWeight: 500 }}>
                    {overviewFilter?.type == 'week' ? `${moment(overviewFilter?.startDate).format('DD-MM-YYYY')} - ${moment(overviewFilter?.endDate).format('DD-MM-YYYY')}` : overviewFilter?.year}
                  </div>
                </div>
                <div style={{ marginTop: "60px" }}>
                  <div className="r-ac">
                    <div className="dot" style={{ marginRight: "3px", color: '#28DF99' }}></div>
                    <div style={{ color: "#28DF99" }}>
                      <span className="bold-700">
                        {" "}
                        {
                          feeMangementData?.dashBoardDetails
                            ?.totalPaymentPaid?.count
                        }
                      </span>{" "}
                      {feeMangementData?.dashBoardDetails
                        ?.totalPaymentPaid?.count == 1
                        ? "Payment"
                        : "Payments"}{" "}
                      Collected
                    </div>
                  </div>
                  <div className="bold-700" style={{ fontSize: "1rem" }}>
                    ₹{" "}

                    {
                      feeMangementData?.dashBoardDetails
                        ?.totalPaymentPaid?.amount &&
                      numberWithCommas(feeMangementData?.dashBoardDetails
                        ?.totalPaymentPaid?.amount)

                    }
                  </div>
                  <div style={{ color: "#AEAEAE", fontSize: "12px" }}>
                    {
                      feeMangementData?.dashBoardDetails
                        ?.totalPaymentPaid?.todayCount
                    }{" "}
                    {feeMangementData?.dashBoardDetails
                      ?.totalPaymentPaid?.todayCount == 1
                      ? "payment"
                      : "payments"}{" "}
                    Collected today
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "80%",
                  marginRight: "-15px",
                  marginTop: "-10px",
                }}
              >
                <OverViewGraph />
              </div>
            </div>
            <div
              className="r-c-sb scroll-bar-universal"
              style={{
                borderTop: "1px solid #E6E6E6",
                backgroundColor: "white",
                width: '100%',
              }}
            >
              <div
                style={{
                  borderRight: "1px solid #E6E6E6",
                  padding: "10px",
                  marginTop: "10px",
                  width: '100%',
                }}
              >
                <div className="r-ac">
                  <div
                    className="dot"
                    style={{ marginRight: "3px", backgroundColor: "#FFA931" }}
                  ></div>
                  <div
                    style={{
                      color: "#FFA931",
                      fontSize: "10px",
                      fontWeight: "550",
                      minWidth: 120,

                    }}
                  >
                    <span className="bold-700" style={{ fontSize: "12.5px" }}>
                      {" "}
                      {
                        feeMangementData?.dashBoardDetails
                          ?.totalPaymentPending?.count
                      }
                    </span>{" "}
                    {feeMangementData?.dashBoardDetails?.totalPaymentPending
                      ?.count == 1
                      ? "payment"
                      : "payments"}{" "}
                    pending
                  </div>
                </div>
                <div className="bold-700" style={{ fontSize: "15px" }}>
                  ₹{" "}
                  {
                    feeMangementData?.dashBoardDetails?.totalPaymentPending
                      ?.amount &&
                    numberWithCommas(feeMangementData?.dashBoardDetails?.totalPaymentPending
                      ?.amount)

                  }
                </div>
                <div style={{ color: "#AEAEAE", fontSize: "10px" }}>
                  {
                    feeMangementData?.dashBoardDetails?.totalPaymentPending
                      ?.todayCount
                  }{" "}
                  {feeMangementData?.dashBoardDetails?.totalPaymentPending
                    ?.todayCount == 1
                    ? "payment"
                    : "payments"}{" "}
                  pending today
                </div>
              </div>
              <div
                style={{
                  borderRight: "1px solid #E6E6E6",
                  padding: "10px",
                  marginTop: "10px",
                }}
              >
                <div className="r-ac">
                  <div
                    className="dot"
                    style={{ marginRight: "3px", backgroundColor: "#594099" }}
                  ></div>
                  <div
                    style={{
                      color: "#594099",
                      fontSize: "10px",
                      fontWeight: "550",
                      minWidth: 140,

                    }}
                  >
                    <span className="bold-700" style={{ fontSize: "12.5px" }}>
                      {" "}
                      {
                        feeMangementData?.dashBoardDetails?.totalTransactionRequested
                          ?.count
                      }
                    </span>{" "}
                    {feeMangementData?.dashBoardDetails?.totalTransactionRequested
                      ?.count == 1
                      ? "payment"
                      : "payments"}{" "}
                    requested
                  </div>
                </div>
                <div className="bold-700" style={{ fontSize: "15px" }}>
                  ₹{" "}
                  {
                    feeMangementData?.dashBoardDetails?.totalTransactionRequested
                      ?.amount &&
                    numberWithCommas(feeMangementData?.dashBoardDetails?.totalTransactionRequested
                      ?.amount)
                  }
                </div>
                <div style={{ color: "#AEAEAE", fontSize: "10px" }}>
                  {
                    feeMangementData?.dashBoardDetails?.totalTransactionRequested
                      ?.todayCount
                  }{" "}
                  {feeMangementData?.dashBoardDetails?.totalTransactionRequested
                    ?.todayCount == 1
                    ? "payment"
                    : "payments"}{" "}
                  made today
                </div>
              </div>
              <div
                style={{
                  borderRight: "1px solid #E6E6E6",
                  padding: "10px",
                  marginTop: "10px",

                }}
              >
                <div className="r-ac">
                  <div
                    className="dot"
                    style={{ marginRight: "3px", backgroundColor: "#FF414D" }}
                  ></div>
                  <div
                    style={{
                      color: "#FF414D",
                      fontSize: "10px",
                      fontWeight: "550",
                      minWidth: 120,

                    }}
                  >
                    <span className="bold-700" style={{ fontSize: "12.5px" }}>
                      {" "}
                      {
                        feeMangementData?.dashBoardDetails
                          ?.totalPaymentOverDue?.count
                      }
                    </span>{" "}
                    {feeMangementData?.dashBoardDetails?.totalPaymentOverDue
                      ?.count == 1
                      ? "payment"
                      : "payments"}{" "}
                    overdue
                  </div>
                </div>
                <div className="bold-700" style={{ fontSize: "15px" }}>
                  ₹{" "}
                  {
                    feeMangementData?.dashBoardDetails?.totalPaymentOverDue
                      ?.amount &&
                    numberWithCommas(feeMangementData?.dashBoardDetails?.totalPaymentOverDue
                      ?.amount)

                  }
                </div>
                <div style={{ color: "#AEAEAE", fontSize: "10px" }}>
                  {
                    feeMangementData?.dashBoardDetails?.totalPaymentOverDue
                      ?.todayCount
                  }{" "}
                  {feeMangementData?.dashBoardDetails?.totalPaymentOverDue
                    ?.todayCount == 1
                    ? "payment"
                    : "payments"}{" "}
                  overdue today
                </div>
              </div>
              <div
                style={{
                  borderRight: "1px solid #E6E6E6",
                  padding: "10px",
                  marginTop: "10px",

                }}
              >
                <div className="r-ac">
                  <div
                    className="dot"
                    style={{ marginRight: "3px", backgroundColor: "#636363" }}
                  ></div>
                  <div
                    style={{
                      color: "#636363",
                      fontSize: "10px",
                      fontWeight: "550",
                      overflow: "hidden",
                      width: "100%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      minWidth: 120,

                    }}
                  >
                    <span
                      className="bold-700"
                      style={{
                        fontSize: "12.5px",
                      }}
                    >
                      {" "}
                      {
                        feeMangementData?.dashBoardDetails
                          ?.totalPaymentCancelled?.count
                      }
                    </span>{" "}
                    {feeMangementData?.dashBoardDetails?.totalPaymentCancelled
                      ?.count == 1
                      ? "payment"
                      : "payments"}{" "}
                    cancelled
                  </div>
                </div>
                <div className="bold-700" style={{ fontSize: "15px" }}>
                  ₹{" "}
                  {
                    feeMangementData?.dashBoardDetails?.totalPaymentCancelled
                      ?.amount &&
                    numberWithCommas(feeMangementData?.dashBoardDetails?.totalPaymentCancelled
                      ?.amount)
                  }
                </div>
                <div style={{ color: "#AEAEAE", fontSize: "10px" }}>
                  {
                    feeMangementData?.dashBoardDetails?.totalPaymentCancelled
                      ?.todayCount
                  }{" "}
                  {feeMangementData?.dashBoardDetails?.totalPaymentCancelled
                    ?.todayCount == 1
                    ? "payment"
                    : "payments"}{" "}
                  cancelled today
                </div>
              </div>
              <div
                style={{
                  // borderRight: "1px solid #E6E6E6",
                  padding: "10px",
                  marginTop: "10px",
                }}
              >
                <div className="r-ac">
                  <div
                    className="dot"
                    style={{ marginRight: "3px", backgroundColor: "#1089FF" }}
                  ></div>
                  <div
                    style={{
                      color: "#1089FF",
                      fontSize: "10px",
                      fontWeight: "550",
                      overflow: "hidden",
                      width: "100%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      minWidth: 120,

                    }}
                  >
                    <span className="bold-700" style={{ fontSize: "12px" }}>
                      {" "}
                      {
                        feeMangementData?.dashBoardDetails
                          ?.totalPaymentRefunded?.count
                      }
                    </span>{" "}
                    {feeMangementData?.dashBoardDetails?.totalPaymentRefunded
                      ?.count == 1
                      ? "payment"
                      : "payments"}{" "}
                    refunded
                  </div>
                </div>
                <div className="bold-700" style={{ fontSize: "15px" }}>
                  ₹{" "}
                  {
                    feeMangementData?.dashBoardDetails?.totalPaymentRefunded
                      ?.amount &&
                    numberWithCommas(feeMangementData?.dashBoardDetails?.totalPaymentRefunded
                      ?.amount)
                  }
                </div>
                <div style={{ color: "#AEAEAE", fontSize: "10px" }}>
                  {
                    feeMangementData?.dashBoardDetails?.totalPaymentRefunded
                      ?.todayCount
                  }{" "}
                  {feeMangementData?.dashBoardDetails?.totalPaymentRefunded
                    ?.todayCount == 1
                    ? "payment"
                    : "payments"}{" "}
                  refunded today
                </div>
              </div>
              {/* <div style={{ padding: "10px", marginTop: "10px" }}>
                <div className="r-ac">
                  <div className="dot" style={{ marginRight: "3px", backgroundColor: "#FEA5DD" }}></div>
                  <div style={{ color: "#FEA5DD", fontSize: "10px", fontWeight: "550", overflow: "hidden", width: "100%", textOverflow: "ellipsis", whiteSpace: "nowrap", minWidth: 120 }}>
                    <span className="bold-700" style={{ fontSize: "12px" }}>
                      {" "}
                      {feeMangementData?.dashBoardDetails?.totalPaymentRefunded?.count}
                    </span>{" "}
                    {feeMangementData?.dashBoardDetails?.totalPaymentRefunded?.count == 1 ? "payment" : "payments"}{" "} collected
                  </div>
                </div>
                <div className="bold-700" style={{ fontSize: "15px" }}>
                  ₹{" "} 1000
                  {feeMangementData?.dashBoardDetails?.totalPaymentRefunded?.amount && numberWithCommas(feeMangementData?.dashBoardDetails?.totalPaymentRefunded?.amount)}
                </div>
                <div style={{ color: "#AEAEAE", fontSize: "10px" }}>
                  {feeMangementData?.dashBoardDetails?.totalPaymentRefunded?.todayCount}{" "}
                  {feeMangementData?.dashBoardDetails?.totalPaymentRefunded?.todayCount == 1 ? "payment" : "payments"}{" "}
                  from online classes
                </div>
              </div> */}
            </div>
          </div>
        </Col>
        <Col
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 8 }}
        >
          <div
            className="box-shadow"
            style={{
              height: "450px",
              borderRadius: "15px",
              marginLeft: "10px",
              padding: "10px",
            }}
          >
            <div
              className="r-ac"
              style={{
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  fontFamily: "roboto",
                  fontSize: "18px",
                  fontWeight: "550",
                }}
              >
                Settlements{" "}
                <span style={{ color: "#AEAEAE" }}>
                  (
                  {
                    feeMangementData?.dashBoardDetails?.settlementLists?.items
                      ?.length
                    // 0
                  }
                  )
                </span>
              </div>
              {feeMangementData?.dashBoardDetails?.settlementLists &&
                feeMangementData?.dashBoardDetails?.settlementLists?.items
                  ?.length != 0 ? (
                <div
                  onClick={() => {
                    history.push("/all-settlements");
                  }}
                  style={{
                    fontFamily: "roboto",
                    fontSize: "12px",
                    fontWeight: "550",
                    color: "#1089FF",
                    cursor: "pointer",
                  }}
                >
                  VIEW ALL
                </div>
              ) : null}
            </div>
            {feeMangementData?.dashBoardDetails?.settlementLists?.items
              ?.length != 0 ? (
              feeMangementData?.dashBoardDetails?.settlementLists?.items?.map(
                (item) => (
                  <div
                    onClick={() => {
                      setFeeDetails("selectedSettlement", item);
                      if (item?.status != 'Initiated') {
                        setShowSettlementModal(true);
                      }
                    }}
                    style={{
                      fontFamily: "roboto",
                      border: "1px solid #E6E6E6",
                      padding: "10px",
                      borderRadius: "5px",
                      margin: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <div style={{ fontSize: "12px" }}>Amount received</div>
                    <div style={{ fontWeight: "600", fontSize: "14px" }}>
                      ₹ {item?.total}
                    </div>
                    <div
                      className="r-ac"
                      style={{
                        color: "#AEAEAE",
                        fontSize: "11px",
                        fontFamily: "roboto",
                        marginTop: "10px",
                      }}
                    >
                      <div>
                        <div>SETTLEMENT DATE</div>
                        <div style={{ color: "#636363", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                          {item?.closedDate ? moment(
                            item?.closedDate,
                            "YYYY/MM/DD HH:mm A"
                          ).format("DD/MM/YYYY ,HH:mm A") : <MinusOutlined />}
                        </div>
                      </div>
                      <div style={{ marginLeft: "25px" }}>
                        <div>STATUS</div>
                        <div style={{ color: "#636363", textTransform: "uppercase" }}>
                          {/* REF_0438151296 */}
                          {item?.status}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )
            ) : (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "-20px",
                }}
              >
                <img
                  style={{
                    width: "30%",
                    height: "30%",
                    // marginBottom: "10px",
                  }}
                  src={imageSet}
                  alt=""
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h1 style={{ fontWeight: "500" }}>No data!</h1>
                  <p
                    style={{
                      color: "#636363",
                      width: "70%",
                      textAlign: "center",
                    }}
                  >
                    There are no settlements to show yet.
                  </p>
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <div
        className="r-ac"
        style={{
          width: "65%",
          justifyContent: "space-between",
          marginTop: "50px",
          fontFamily: "roboto",
          fontSize: "18px",
          fontWeight: "550",
        }}
      >
        <div style={{}}>Recent Activities</div>

        <div
          onClick={() => {
            recentActivitiesVAFromWhere('fromIndexPage')
            setAllActivitiesFilter('resourceId', undefined);
            setAllActivitiesFilter('resourceType', undefined);
            setAllActivitiesFilter('standardId', undefined);
            history.push("/fee-management/all-activities");
          }}
          style={{ color: "#1089FF", fontSize: "15px", cursor: "pointer" }}
        >
          VIEW ALL
        </div>
      </div>
      <Row>
        <Col sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 16 }}>
          <table style={{ marginTop: "18px", width: "100%" }}>
            <thead className="tablehead">
              <tr
                style={{
                  backgroundColor: "#F6F4FE",
                  padding: "20px",
                  height: "50px",
                }}
              >
                <th style={{ color: "#AEAEAE" }}>STUDENT’S DETAILS</th>
                <th style={{ color: "#AEAEAE" }}>DESCRIPTIONS</th>
                <th style={{ color: "#AEAEAE" }}>PAYMENT DATE/ISSUE DATE</th>
                <th style={{ color: "#AEAEAE" }}>STATUS</th>
              </tr>
            </thead>
            {feeMangementData?.dashBoardDetails?.recentTransactions
              ?.allTransactionList?.length != 0
              ? feeMangementData?.dashBoardDetails?.recentTransactions?.allTransactionList?.map(
                (item, index) =>
                  index < 4 ? (
                    <tr
                      style={
                        index % 2
                          ? {
                            backgroundColor: "#FAFAFA",
                            // padding: "100px",
                            height: "100px",
                            // marginLeft: "30px",
                          }
                          : {
                            // padding: "100px"
                            height: "100px",
                          }
                      }
                    >
                      <td
                        style={{
                          // backgroundColor: "red",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100px",
                          marginRight: "-10px",
                          // width: "200px",
                        }}
                      >
                        <div
                          className="r-ac"
                          style={{ fontFamily: "roboto", width: "160px" }}
                        >
                          <div>
                            <Avatar
                              style={{ width: "40px", height: "40px" }}
                              src={Api._s3_url + item?.user?.image}
                            />
                          </div>
                          <div style={{ marginLeft: "10px" }}>
                            {/* <div style={{ fontWeight: "550" }}>
                                  {item?.user?.name}
                                </div> */}
                            <Typography.Text
                              ellipsis={{ tooltip: true }}
                              style={{
                                // color: "#636363",
                                width: "100px",
                                fontWeight: "550",
                              }}
                            >
                              {item?.user?.name}
                            </Typography.Text>
                            <Typography.Text ellipsis={{ tooltip: true }} style={{ color: "#636363", width: 130 }}>
                              {item?.resourceType != null ? item?.resourceLabel : item?.standardNames}
                            </Typography.Text>
                          </div>
                        </div>
                      </td>
                      <td style={{ height: "100px" }}>
                        <div
                          // className="c-ac"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginLeft: "10px",
                            // backgroundColor: "red",
                            justifyContent: "center",
                            // width: "200px",
                            textAlign: "center",
                          }}
                        >
                          <div style={{ fontWeight: "550" }}>
                            ₹ {
                              item?.amountPayable &&
                              numberWithCommas(item?.amountPayable)
                            }
                          </div>
                          <Typography.Text
                            ellipsis={{ tooltip: true }}
                            style={{ color: "#636363", width: "200px" }}
                          >
                            {item?.resourceType != null ? item?.resourceName : item?.note}
                          </Typography.Text>
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            color: "#636363",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginLeft: "10px",
                            // backgroundColor: "red",
                            justifyContent: "center",
                          }}
                        >
                          {item?.status === 'Paid' && item?.paymentDate ? (
                            <>
                              <div>{moment(item?.paymentDate?.split(',')[0], 'DD-MM-YYYY').format('DD/MM/YYYY')}</div>
                              <div>{item.paymentDate.split(',')[1]}</div>
                            </>
                          ) : (
                            item?.date && (
                              <>
                                <div>{moment(item?.date.split(',')[0], 'DD-MM-YYYY').format('DD/MM/YYYY')}</div>
                                <div>{item?.date.split(',')[1]}</div>
                              </>
                            )
                          )}
                        </div>
                      </td>
                      <td
                        style={{
                          width: "150px",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div
                          style={{
                            width: "120px",
                            border: `1px solid ${item?.status == "Overdue"
                              ? "#FF414D"
                              : item?.status == "Paid"
                                ? "#28DF99"
                                : item?.status == "Refunded"
                                  ? "#1089FF"
                                  : item?.status == "Cancelled"
                                    ? "#1089FF"
                                    : "#FFA931"
                              }`,
                            borderRadius: "7px",
                            textAlign: "center",
                            fontSize: "10px",
                            padding: "5px",
                            fontWeight: "500",
                            color: `${item?.status == "Overdue"
                              ? "#FF414D"
                              : item?.status == "Paid"
                                ? "#28DF99"
                                : item?.status == "Refunded"
                                  ? "#1089FF"
                                  : item?.status == "Cancelled"
                                    ? "#1089FF"
                                    : "#FFA931"
                              }`,
                            backgroundColor: `${item?.status == "Overdue"
                              ? "#FFECED"
                              : item?.status == "Paid"
                                ? "#EAFCF5"
                                : item?.status == "Refunded"
                                  ? "#E7F3FF"
                                  : item?.status == "Cancelled"
                                    ? "#E7F3FF"
                                    : "#FFF6EA"
                              }`,
                          }}
                        >
                          {item?.status}
                        </div>
                      </td>
                    </tr>
                  ) : null
              )
              :
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", position: "absolute" }}>
                <NodataCard />
              </div>}
          </table>
        </Col>
        <Col sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
          <div
            style={{
              border: "1px solid #302A5F14",
              borderRadius: "15px",
              marginLeft: "10px",
              // height: "400px",
              padding: "20px",
              marginTop: "15px",
            }}
          >
            {[true, true, true, true].map((item, index) => (
              <div
                onClick={() => {
                  if (index == 0) {
                    setShowGeneratePayReq(true);
                  } else if (index == 1) {
                    setFeeDetails("paidDate", null);
                    setShowOfflinePayReqModal(true)
                  } else if (index == 2) {
                    history.push("/fee-management/all-transactions");
                  } else if (index == 3) {
                    storeAllStudentByCourseDetails('subcategoryId', null)
                    history.push("/fee-management/all-students/courses");
                  }
                }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: index != 3 ? "1px solid #E6E6E6" : "none",
                  padding: "10px",
                  paddingTop: "25px",
                  paddingBottom: "25px",
                  cursor: /* updateCursor(feeMangementData?.dashBoardDetails?.kycStatus,index) */"pointer"
                }}
              >
                {index == "0" ? (
                  <div style={{ marginLeft: "-15px" }}>
                    <img width="50px" src={image1} alt="" />
                  </div>
                ) : null}
                {index == "1" ? (
                  <div style={{ marginLeft: "-15px" }}>
                    <img width="50px" src={image2} alt="" />
                  </div>
                ) : null}
                {index == "2" ? (
                  <div style={{ marginLeft: "-15px" }}>
                    <img width="50px" src={image3} alt="" />
                  </div>
                ) : null}
                {index == "3" ? (
                  <div style={{ marginLeft: "-15px" }}>
                    <img width="50px" src={image4} alt="" />
                  </div>
                ) : null}

                <div
                  style={{
                    fontSize: "15px",
                    fontWeight: "600",
                    fontFamily: "roboto",
                    // backgroundColor: "red",
                  }}
                >
                  {index == "0" ? " Generate Payment Request" : null}
                  {index == "1" ? " Add Offline Payment" : null}
                  {index == "2" ? " Transactions" : null}
                  {index == "3" ? " All Students" : null}
                </div>
                <ArrowRightOutlined
                  style={{ color: "#1089FF", fontSize: "25px" }}
                />
              </div>
            ))}
          </div>
        </Col>
      </Row>
      <PageDataLoader visible={pageLoader} />
      {isModalVisible && (
        <SettingsModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      )}
      {showGeneratePayReq && (
        <GeneratePayRequest
          isModalVisible={showGeneratePayReq}
          setIsModalVisible={setShowGeneratePayReq}
          setShowAddDetailsModal={setShowAddDetailsModal}
          setIsAdditionModal={setIsAdditionModal}
          setSelectedBtn={setSelectedBtn}
          selectedBtn={selectedBtn}
          showReasonModal={showReasonModal}
          setShowReasonModal={setShowReasonModal}
        />
      )}
      {showAddDetailsModal && (
        <AddDetailsModal
          isModalVisible={showAddDetailsModal}
          setIsAddOrGenModal={setIsAddOrGenModal}
          isAddOrGenModal={isAddOrGenModal}
          isAdditionModal={isAdditionModal}
          handleOk={() => {
            setShowAddDetailsModal(false);
            // if (isAddOrGenModal == true) {
            //   setShowOfflinePayReqModal(true);
            //   setIsAddOrGenModal(false);
            // } else {
            //   setShowGeneratePayReq(true);
            //   setIsAddOrGenModal(false);
            // }
          }}
          handleCancel={() => {
            setShowAddDetailsModal(false);
            // if (isAddOrGenModal == true) {
            //   setShowOfflinePayReqModal(true);
            //   setIsAddOrGenModal(false);
            // } else {
            //   setShowGeneratePayReq(true);
            //   setIsAddOrGenModal(false);
            // }
          }}
          setShowGeneratePayReq={setShowGeneratePayReq}
        />
      )}
      {showOfflinePayReqModal && (
        <OfflinePayReq
          isModalVisible={showOfflinePayReqModal}
          setIsModalVisible={setShowOfflinePayReqModal}
          setShowAddDetailsModal={setShowAddDetailsModal}
          setIsAddOrGenModal={setIsAddOrGenModal}
          setIsAdditionModal={setIsAdditionModal}
          showReasonModal={showReasonModal}
          setShowReasonModal={setShowReasonModal}
        />
      )}
      <SettlementDetailsModal
        isModalVisible={showSettlementModal}
        setShowSettlementModal={setShowSettlementModal}
      />
      {showReasonModal && (
        <ShowReasonModal
          showReasonModal={showReasonModal}
          setShowReasonModal={setShowReasonModal}
          kycStatus={feeMangementData?.dashBoardDetails?.kycStatus}
          rejectKycMessage={feeMangementData?.dashBoardDetails?.rejectKycMessage ? feeMangementData?.dashBoardDetails?.rejectKycMessage : ''}
        />
      )
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  const { feeMangementData, overviewFilter } = state.feeManagement;
  const { rejectKycMessage } = state.fees;
  const { user } = state.session;
  return {
    feeMangementData,
    user,
    rejectKycMessage,
    overviewFilter
  };
};

const mapDispatchToProps = (dispatch) => ({
  getFeeOverView: (callback) => dispatch(getFeeOverView(callback)),
  setAllActivitiesFilter: (key, val) =>
    dispatch(setAllActivitiesFilter(key, val)),
  setFeeDetails: (key, val) => dispatch(setFeeDetails(key, val)),
  setOverviewFilter: (key, val) => dispatch(setOverviewFilter(key, val)),
  setAulasCareChat: (val) => dispatch(setAulasCareChat(val)),
  updateRejectKycMessage: (message) => dispatch(updateRejectKycMessage(message)),
  recentActivitiesVAFromWhere: val => dispatch(recentActivitiesVAFromWhere(val)),
  storeAllStudentByCourseDetails: (key, val) => dispatch(storeAllStudentByCourseDetails(key, val)),

});

export default connect(mapStateToProps, mapDispatchToProps)(FeeManagement);