import { store } from "App";
import {updateUserRole} from 'actions/liveClass';
import {
  startClass,
  updateActiveClass,
  setConferenceJoinLoader
} from 'actions/schedule';
import { setRightView, setRightViewType } from "actions/broadcast";

export const handleJoinClass = async (item, history) => {
  store.dispatch(setConferenceJoinLoader(true));
  if (item.mode == 'Broadcast') {
    return alert('Broadcast mode not yet supported');
  }
  const {user} = store.getState().session;
  store.dispatch(updateActiveClass(item));
  if (item.mode == 'Zoom 40') {

  } else {
    store.dispatch(setRightView(false));
    store.dispatch(setRightViewType(''));
    await store.dispatch(startClass(item.id, false, history));
  }

  if (item.teacherId === user.id) {
    store.dispatch(updateUserRole({isTeacher: true}));
  }
};
