import React, { useEffect, useState } from 'react';
import "./userHomeStyles.css"
import { connect } from 'react-redux';
import footerBackground from '../../Assets/Footer/NewFooter.svg'
import appStore from '../../Assets/Footer/Appstore.svg'
import playStore from '../../Assets/Footer/googleplay.svg'
import locations from '../../Assets/Footer/locations.svg'
import mobLoc from '../../Assets/Footer/chevrons-down.svg'
import footerBackgroundMob from '../../Assets/Footer/NewFooterMob.svg'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import FooterSocialMediaModal from './footerSocialMediaModal';

function NewFooter({ userMarketPlaceHomeData, fromLocationDetails }) {
    const history = useHistory()
    const [isSocialMedia, setIsSocialMedia] = useState(false);
    const [socialMediaData, setSocialMediaData] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 800);


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 800);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    function handleClick(number) {
        window.location.href = `tel:${number}`;
    }
    const handleSocialMediaClick = (item) => {
        if (item?.urls?.length > 1) {
            setIsSocialMedia(true)
            setSocialMediaData(item)
        } else {
            window.open(item?.url, '_blank')
        }

    }

    console.log('ghjk', userMarketPlaceHomeData);
    const websiteUrl = userMarketPlaceHomeData?.footerData?.website;
    console.log('websiteUrl:', websiteUrl, 'Type:', typeof websiteUrl);

    const validUrl = typeof websiteUrl === 'string' && (websiteUrl.startsWith('http://') || websiteUrl.startsWith('https://'))
        ? websiteUrl
        : `https://${websiteUrl}`;

        console.log({validUrl});
        


    return (

        <div id='footer' style={{
            // backgroundImage: `url(${isMobile ? footerBackgroundMob : footerBackground})`,
            // backgroundSize: 'cover',
            // backgroundRepeat: 'no-repeat',

            // backgroundPosition:'center',
            // overflowY: 'auto',
            // overflowX: 'hidden',
            //  height: "400px", 
            width: "100%",
            zIndex:100
            //  height:'fit',
        }}>

            <div className='web-footer'>
                <div style={{ position: "relative", overflow: "hidden", display: "flex" }}>
                    <div style={{
                        backgroundImage: `url(${isMobile ? footerBackgroundMob : footerBackground})`
                    }}>
                        <img src={footerBackground} style={{ width: '100%', }} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', position: "absolute", paddingTop: '3%', paddingLeft: '9%', paddingRight: '9%', width: '100%' }}>
                        <div style={{}}>
                            <div style={{ fontSize: 17, color: '#FFEF00' }}>{userMarketPlaceHomeData?.orgName}</div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ fontSize: 13, color: '#FAFAFA', marginTop: 12 }}>{userMarketPlaceHomeData?.footerData?.secendaryLocation?.locality},{" "}{userMarketPlaceHomeData?.footerData?.secendaryLocation?.city}</div>
                                <div style={{ fontSize: 13, color: '#FAFAFA', }}>{" "}{userMarketPlaceHomeData?.footerData?.secendaryLocation?.state}</div>
                                <div style={{ fontSize: 13, color: '#FAFAFA', }}>{" "}{userMarketPlaceHomeData?.footerData?.secendaryLocation?.country}
                                    {" "}{userMarketPlaceHomeData?.footerData?.secendaryLocation?.pincode}</div>
                            </div>

                            {userMarketPlaceHomeData?.footerData?.locations?.length > 0 && !fromLocationDetails ? <div style={{ display: 'flex', marginTop: 20, }}>

                                <div style={{ color: '#FAFAFA', fontSize: 17, cursor: 'pointer' }} onClick={() => { history?.push('/location-details') }}>View other locations</div>
                                <img src={locations} style={{ marginTop: 6, marginLeft: 10, cursor: 'pointer' }} onClick={() => { history?.push('/location-details') }} />
                            </div>
                                : <></>}


                        </div>

                        <div style={{}}>
                            <div style={{ fontSize: 17, color: '#FFEF00' }}>Contact us</div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <a href={`tel:${userMarketPlaceHomeData?.footerData?.mobileNumber1}`}
                                    style={{ fontSize: 13, color: '#FAFAFA', marginTop: 12, textDecoration: 'none' }}>
                                    {userMarketPlaceHomeData?.footerData?.mobileNumber1}
                                </a>


                                {userMarketPlaceHomeData?.footerData?.mobileNumber2 && (
                                    <a href={`tel:${userMarketPlaceHomeData?.footerData?.mobileNumber2}`}
                                        style={{ fontSize: 13, color: '#FAFAFA', marginTop: 6, textDecoration: 'none' }}>
                                        {userMarketPlaceHomeData?.footerData?.mobileNumber2}
                                    </a>
                                )}


                                <a href={`mailto:${userMarketPlaceHomeData?.footerData?.emailId}`}
                                    className="cursor-pointer"
                                    style={{ fontSize: 13, color: '#FAFAFA', textDecoration: 'none', marginTop: 4 }}>
                                    {userMarketPlaceHomeData?.footerData?.emailId}
                                </a>
                            </div>

                        </div>

                        <div style={{}}>
                            <div style={{ fontSize: 17, color: '#FFEF00' }}>Download our app now </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <img className='m-t-20 play-store-img-size-new' src={playStore} onClick={() => window.open(userMarketPlaceHomeData?.pSLink, "_blank")} />
                                <img className='play-store-img-size-new responsive-margin' src={appStore} onClick={() => window.open(userMarketPlaceHomeData?.aSLink, "_blank")} />

                            </div>

                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: -20 }}>
                            {userMarketPlaceHomeData?.footerData?.socialMedia?.length > 0  || userMarketPlaceHomeData?.footerData?.website ? <div style={{ fontSize: 17, color: '#FFEF00' }}>Visit us at</div> : <></>}
                            <div style={{ display: 'flex', flexDirection: 'column' }}>

                                <div className='cursor-pointer' style={{ fontSize: 13, color: '#FAFAFA', marginTop: 12, display: 'flex', }} onClick={() => { window.open(validUrl, "_blank") }}>{userMarketPlaceHomeData?.footerData?.website}</div>
                                {userMarketPlaceHomeData?.footerData?.socialMedia?.length > 0 ?
                                    <>

                                        <div style={{ width: "150px", display: "flex", flexWrap: "wrap", marginTop: -5, }}>
                                            {userMarketPlaceHomeData?.footerData?.socialMedia?.map((item) => (
                                                <div style={{ marginTop: 20 }}>
                                                    {item?.name == "Instagram" ? <img className="footer-logo-size" src={require("../../Assets/Footer/insta.svg").default} onClick={() => handleSocialMediaClick(item)} /> : <></>}
                                                    {item?.name == "YouTube" ? <img className="footer-logo-size" src={require("../../Assets/Footer/yt.svg").default} onClick={() => handleSocialMediaClick(item)} /> : <></>}
                                                    {item?.name == "WhatsApp" ? <img className="footer-logo-size" src={require("../../Assets/Footer/whatsapp.svg").default} onClick={() => handleSocialMediaClick(item)} /> : <></>}
                                                    {item?.name == "Facebook" ? <img className="footer-logo-size" src={require("../../Assets/Footer/fb1.svg").default} onClick={() => handleSocialMediaClick(item)} /> : <></>}
                                                    {item?.name == "Twitter" ? <img className="footer-logo-size" src={require("../../Assets/Footer/twitter1.svg").default} onClick={() => handleSocialMediaClick(item)} /> : <></>}
                                                    {item?.name == "LinkedIn" ? <img className="footer-logo-size" src={require("../../Assets/Footer/LinkedIn.svg").default} onClick={() => handleSocialMediaClick(item)} /> : <></>}
                                                    {/* {item?.name == "LinkedIn" ? <img className="footer-logo-size" src={require("../../Assets/aulasMarket/youtube.svg").default} onClick={() => handleSocialMediaClick(item)} /> : <></>} */}
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                    :
                                    <></>}
                            </div>

                        </div>
                    </div>
                    <div style={{ position: 'absolute', display: 'flex', bottom: '5%', paddingLeft: '9%', paddingRight: '9%', width: '100%', flexDirection: 'column' }}>
                        <div style={{ border: '1px solid #A987FF', width: '100%', height: '1px' }}></div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
                            <div style={{ fontSize: 13, color: '#FAFAFA' }}>Copyright @ Vectors Education LLP. All rights reserved.</div>
                            <div style={{ fontSize: 13, color: '#FAFAFA' }}> <a href="https://aulas.in/privacy-policy" target="_blank" rel="noreferrer"><span style={{ fontSize: 13, color: '#FAFAFA' }}>Privacy policy </span>&nbsp;</a>   |  &nbsp; <a href="https://aulas.in/terms-conditions" target="_blank" rel="noreferrer"><span style={{ fontSize: 13, color: '#FAFAFA' }}>T & C</span></a></div>

                        </div>
                    </div>

                </div>
            </div>

            <div className='mobile-footer'>
                <div style={{ position: "relative", overflow: "hidden", display: "flex" }}>
                    <div style={{
                        width:'100%'
                    }}>
                        <img src={footerBackgroundMob} style={{ width: '100%', }} />
                    </div>
                    <div style={{ display: 'flex', position: 'absolute', width: '100%' }}>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                            <center style={{ fontSize: 13, color: '#FFEF00', width: '100%', marginTop: 20 }}>Download our app now </center>
                            <div style={{ display: 'flex', justifyContent: 'space-around', width: '65%' }}>
                                <img className='m-t-15 play-store-img-size-new' src={playStore} onClick={() => window.open(userMarketPlaceHomeData?.pSLink, "_blank")} />
                                <img className='m-t-15 play-store-img-size-new' src={appStore} onClick={() => window.open("https://apps.apple.com/in/app/aulas/id1564724183", "_blank")} />
                            </div>

                            <center style={{ fontSize: 13, color: '#FFEF00', width: '100%', marginTop: 20 }}>Visit us at </center>
                            <center className='cursor-pointer' style={{ fontSize: 11, color: '#FAFAFA', marginTop: 3 }} onClick={() => { window.open(validUrl, "_blank") }}>{userMarketPlaceHomeData?.footerData?.website}</center>

                            <div style={{ display: "flex", gap: 20, justifyContent: "center" }}>
                                {userMarketPlaceHomeData?.footerData?.socialMedia?.map((item) => (
                                    <div style={{ marginTop: 10 }}>
                                        {item?.name == "Instagram" ? <img className="footer-logo-size" src={require("../../Assets/Footer/insta.svg").default} onClick={() => handleSocialMediaClick(item)} /> : <></>}
                                        {item?.name == "YouTube" ? <img className="footer-logo-size" src={require("../../Assets/Footer/yt.svg").default} onClick={() => handleSocialMediaClick(item)} /> : <></>}
                                        {item?.name == "WhatsApp" ? <img className="footer-logo-size" src={require("../../Assets/Footer/whatsapp.svg").default} onClick={() => handleSocialMediaClick(item)} /> : <></>}
                                        {item?.name == "Facebook" ? <img className="footer-logo-size" src={require("../../Assets/Footer/fb1.svg").default} onClick={() => handleSocialMediaClick(item)} /> : <></>}
                                        {item?.name == "Twitter" ? <img className="footer-logo-size" src={require("../../Assets/Footer/twitter1.svg").default} onClick={() => handleSocialMediaClick(item)} /> : <></>}
                                        {item?.name == "LinkedIn" ? <img className="footer-logo-size" src={require("../../Assets/Footer/LinkedIn.svg").default} onClick={() => handleSocialMediaClick(item)} /> : <></>}
                                    </div>
                                ))}
                            </div>



                            <center style={{ fontSize: 13, color: '#FFEF00', width: '100%', marginTop: 20 }}>Contact us</center>

                            <center style={{ fontSize: 11, color: '#FAFAFA', marginTop: 5 }}>
                                <a href={`tel:${userMarketPlaceHomeData?.footerData?.mobileNumber1}`} style={{ color: '#FAFAFA', textDecoration: 'none' }}>
                                    {userMarketPlaceHomeData?.footerData?.mobileNumber1}
                                </a>
                            </center>
                            {userMarketPlaceHomeData?.footerData?.mobileNumber2 ? (
                                <center style={{ fontSize: 11, color: '#FAFAFA', marginTop: 3 }}>
                                    <a href={`tel:${userMarketPlaceHomeData?.footerData?.mobileNumber2}`} style={{ color: '#FAFAFA', textDecoration: 'none' }}>
                                        {userMarketPlaceHomeData?.footerData?.mobileNumber2}
                                    </a>
                                </center>
                            ) : (
                                <></>
                            )}

                            <center className='cursor-pointer'>
                                <a href={`mailto:${userMarketPlaceHomeData?.footerData?.emailId}`} style={{ fontSize: 11, color: '#FAFAFA', textDecoration: 'none', marginTop: 3 }}>
                                    {userMarketPlaceHomeData?.footerData?.emailId}
                                </a>
                            </center>

                            <center style={{ fontSize: 13, color: '#FFEF00', width: '100%', marginTop: 20 }}>{userMarketPlaceHomeData?.orgName}</center>




                            <center style={{ fontSize: 11, color: '#FAFAFA', marginTop: 5 }}>{userMarketPlaceHomeData?.footerData?.secendaryLocation?.locality},{" "}{userMarketPlaceHomeData?.footerData?.secendaryLocation?.city}</center>
                            <center style={{ fontSize: 11, color: '#FAFAFA', marginTop: 3 }}>{" "}{userMarketPlaceHomeData?.footerData?.secendaryLocation?.state} </center>
                            <center style={{ fontSize: 11, color: '#FAFAFA', marginTop: 3 }}>{" "}{userMarketPlaceHomeData?.footerData?.secendaryLocation?.country}
                                {" "}{userMarketPlaceHomeData?.footerData?.secendaryLocation?.pincode} </center>


                            {userMarketPlaceHomeData?.footerData?.locations?.length > 0 && !fromLocationDetails ?
                                <div style={{ display: 'flex', marginTop: 15, }}>
                                    <div style={{ color: '#FAFAFA', fontSize: 12, cursor: 'pointer' }} onClick={() => { history?.push('/location-details') }}>View other locations</div>
                                    <img src={mobLoc} style={{ marginLeft: 5, cursor: 'pointer' }} onClick={() => { history?.push('/location-details') }} />
                                </div>
                                : <></>}



                            <div style={{ position: 'absolute', display: 'flex', bottom: !fromLocationDetails ? -40 : -75, paddingLeft: '5%', paddingRight: '5%', width: '100%', flexDirection: 'column' }}>
                                <div style={{ border: '1px solid #A987FF', width: '100%', height: '1px' }}></div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5 }}>
                                    <div style={{ fontSize: 8, color: '#FAFAFA' }}>Copyright @ Vectors Education LLP. All rights reserved.</div>
                                    <div style={{ fontSize: 13, color: '#FAFAFA' }}> <a href="https://aulas.in/privacy-policy" target="_blank" rel="noreferrer"><span style={{ fontSize: 13, color: '#FAFAFA' }}>Privacy policy </span>&nbsp;</a>   |  &nbsp; <a href="https://aulas.in/terms-conditions" target="_blank" rel="noreferrer"><span style={{ fontSize: 13, color: '#FAFAFA' }}>T & C</span></a></div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>


            <FooterSocialMediaModal
                setIsSocialMedia={setIsSocialMedia}
                isSocialMedia={isSocialMedia}
                footerData={socialMediaData}
            />
            </div>




    )
}
const mapStateToProps = (state) => {
    const { userMarketPlaceHomeData } = state.studentMarketPlace;
    return { userMarketPlaceHomeData };
};

const mapDispatchToProps = (dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(NewFooter);