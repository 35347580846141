import { Heading } from 'components/Typography';
import { Button } from 'components/Button';
import React, { useEffect, useState } from 'react';
import { CheckCircleOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Col, Progress, Row, Table } from 'antd';
import ConfirmLeaveDelete from './confirmLeaveDelete';
import MarkLeaveModal from './markLeaveModal';
import { connect } from 'react-redux';
import { deleteHistory, getLeavesData } from 'actions/profileNew';
import moment from 'moment';
import EditLeaveModal from './editLeaveModal';
import MultipleLeaveDeleteModal from './multipleLeaveDeleteModal'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import LeaveRequestsModal from './leaveRequestsModal';

const columns = (setDeleteLeaveModal, handleEditLeave, selectedRowKeys, setIdToDelete, user, setMultiLeaveDeleteModal, fromWhere) => [

    {
        title: 'Date(s)',
        dataIndex: 'dates',
        key: 'dates',
        render: (value, record) => <div style={{display:'flex',justifyContent:'flex-start'}}>{moment(record.startDate, "YYYY-MM-DD").format("DD/MM/YYYY")} - {moment(record.endDate, "YYYY-MM-DD").format("DD/MM/YYYY")} </div>
    },
    {
        title: 'Leave type',
        dataIndex: 'leaveType',
        key: 'leaveType',
        render: value => <div style={{display:'flex',justifyContent:'flex-start'}}>{value} leave</div>
    },
    {
        title: 'Duration',
        dataIndex: 'duration',
        key: 'duration',
        render: value => <div style={{display:'flex',justifyContent:'flex-start'}}>{value} day(s)</div>
    },
    {
        title: "Status",
        dataIndex: 'status',
        key: 'status',
        render: (value) => <div style={{display:'flex',justifyContent:'flex-start', color: value === "Approved" ? "#28DF99" : "red", textTransform: "uppercase" }}>{value}</div>
    },
    {
        title: 'Action',
        render: (value, record) => (
            <div className='r-ac' style={{display:'flex',justifyContent:'flex-start'}}>
                {moment(record?.endDate, 'YYYY-MM-DD') >= moment() && fromWhere !== 'userManagementProfile' ?
                    <>
                        <Button icon={<EditOutlined style={{ color: "#1089FF" }} />} style={{ padding: 0 }} type="transparent" className="" onClick={(e) => { e.stopPropagation(), handleEditLeave(record) }} disabled={selectedRowKeys.includes(record.id)} />
                        <Button icon={<DeleteOutlined style={{ color: "red" }} />} style={{ padding: 0 }} type="transparent" className="" onClick={(e) => { e.stopPropagation(), record?.duration > 1 ? setMultiLeaveDeleteModal(true) : setDeleteLeaveModal(true); setIdToDelete(record?.id) }} disabled={selectedRowKeys.includes(record.id)} />
                    </>
                    : null
                }
            </div>
        )
    },
]

const leaveTypeProps = {
    Sick: {
        color: "#FFA931"
    },
    Casual: {
        color: "#FEA5DD"
    },
    Privilege: {
        color: "#9463FA"
    },
    Maternity: {
        color: "#B2DFFF"
    },
    Paternity: {
        color: "#B2DFFF"
    },
    Earned: {
        color: "#99DED9"
    },
    Wedding: {
        color: "#28DF99"
    }
}

const LeaveSummary = ({ getLeavesData, leaveDetails, setPageLoader, user, othersSelectedTab, deleteHistory, specificUserId, fromWhere }) => {

    const [markLeaveModal, setMarkLeaveModal] = useState(false);
    const [deleteLeaveModal, setDeleteLeaveModal] = useState(false);
    const [editLeaveModal, setEditLeaveModal] = useState(false);
    const [leaveSummaryData, setLeaveSummaryData] = useState([[], []]);
    const [multiLeaveDeleteModal, setMultiLeaveDeleteModal] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [selectedLeaveRow, setSelectedLeaveRow] = useState([]);
    const [openLeaveRequestModal, setLeaveRequestModal] = useState(false);
    const [leaveFormDetails, setLeaveDetails] = useState({
        leaveType: null,
        subType: null,
        startDate: null,
        endDate: null,
        duration: null,
        reason: null
    });

    const location = useLocation();

    useEffect(() => {
        setPageLoader(true)
        getLeavesData({ userId: location?.state?.key ? specificUserId : user.id }, () => setPageLoader(false))
    }, []);

    useEffect(() => {
        if (leaveDetails?.leaveSummary) {
            for (let i = 0; i < 3; i++) {
                leaveSummaryData[0][i] = leaveDetails?.leaveSummary[i]
            }
            for (let i = 3; i < 6; i++) {
                leaveSummaryData[1][i - 3] = leaveDetails?.leaveSummary[i]
            }
            setLeaveSummaryData([...leaveSummaryData])
        }
    }, [leaveDetails]);

    const disablePrevDateRows = (record) => {
        const startDate = moment(record?.startDate, 'YYYY-MM-DD');
        const currentDate = moment().startOf('day');
        return startDate.isBefore(currentDate);
    };

    const rowSelection = {
        type: "checkbox",
        selectedRowKeys: selectedRowKeys,
        getCheckboxProps: (record) => ({ disabled: disablePrevDateRows(record) }),
        onChange: (selectedRowKeys, selections) => {
            setSelectedRows([...selections?.map(item => item?.fileKey)]);
            setSelectedRowKeys([...selectedRowKeys])
        },
    };

    const handleEditLeave = (record) => {
        setMarkLeaveModal(true);
        setLeaveDetails({ ...record, startDate: moment(record?.startDate), endDate: moment(record?.endDate), subType: record.duration === 0.5 ? "half-day" : "full-day" })
    }

    const handleCloseMarkLeaveModal = () => {
        setMarkLeaveModal(false)
        setLeaveDetails({
            leaveType: null,
            subType: null,
            startDate: null,
            endDate: null,
            duration: null,
            reason: null
        })
    }

    const setIdToDelete = (id) => {
        selectedRowKeys.push(id);
        setSelectedRowKeys([...selectedRowKeys]);
    };

    const successCallback = () => {
        setDeleteLeaveModal(false);
        setSelectedRowKeys([]);
    }

    const handleDelete = () => {
        let params = {
            commonId: selectedRowKeys
        }
        setDeleteLoader(true)
        deleteHistory(params, () => setDeleteLoader(false), () => successCallback(), 'LeaveSummary');
    }

    const onRow = (record) => {
        return {
            onClick: () => {
                setSelectedLeaveRow(record);
                setLeaveRequestModal(true);
            }
        }
    };


    const onHeaderDeleteClick = () => {
        let temp = [];
        if (selectedRowKeys?.length > 1) {
            setSelectedLeaveRow([]);
            setSelectedRowKeys([]);
            setMultiLeaveDeleteModal(true);
            leaveDetails?.leaveHistory?.forEach(item => {
                if (selectedRowKeys.includes(item.id)) {
                    temp = [...temp, ...item.requests]
                }
            })
            setSelectedLeaveRow({ requests: [...temp] })
        } else {
            setDeleteLeaveModal(true);
        }
    }

    return (
        <div>
            <div className='r-jsb'>
                <Heading>Summary</Heading>
                <div className='r-ac'>
                    {((user?.privilage?.includes('SuperAdmin') || user?.privilage?.includes('LeaveManager')) || !user?.role?.includes("Student")) ?
                        <>
                            {(user?.privilage?.includes('SuperAdmin') || user?.privilage?.includes('LeaveManager')) && <Button type="link" style={{ marginRight: 20, fontSize: 14 }} onClick={() => { setEditLeaveModal(true); }} icon={<EditOutlined />}>EDIT</Button>}
                            <Button type="primary" icon={<CheckCircleOutlined />} onClick={() => setMarkLeaveModal(true)}>{(user?.privilage?.includes('SuperAdmin') || user?.privilage?.includes('LeaveManager')) ? 'MARK LEAVES' : 'APPLY LEAVES'}</Button>
                        </>
                        : null
                    }
                </div>
            </div>
            {leaveSummaryData.map((row, i) =>
                <Row>
                    <>
                        {row.map((summary, index) => (
                            summary ? <Col md={{ span: 8 }} sm={{ span: 12 }}>
                                <div style={{ width: "90%", height: "100%" }} className="m-t-10 r-jsb">
                                    {index > 0 ? <div style={{ height: "80%", borderRight: "1px solid #E6E6E6", marginRight: 10 }} /> : null}
                                    <div style={{ width: "100%" }}>
                                        <div style={{ fontSize: 16, fontWeight: 500 }}>{summary?.leaveType} Leave</div>
                                        <div className='r-jsb m-t-15' style={{ width: "70%" }} >
                                            <div style={{ color: "#636363" }}>Balance</div>
                                            <div>{summary.remaining}</div>
                                        </div>
                                        <div className='r-jsb' style={{ width: "70%" }}>
                                            <div style={{ color: "#636363" }}>Availed</div>
                                            <div>{summary.availed}</div>
                                        </div>
                                    </div>
                                    <div style={{ width: "45%" }} className="m-t-20">
                                        <Progress type="circle" percent={summary?.remaining / summary?.total * 100} width={60} strokeWidth={12} strokeColor={leaveTypeProps[summary?.leaveType]?.color}
                                            format={percent => <div>
                                                <div style={{ color: "#191919" }}>{summary?.total}</div>
                                                <div style={{ fontSize: 12, color: "#636363" }} className="m-t-5">Total</div>
                                            </div>
                                            } />
                                    </div>
                                </div>
                            </Col> : null

                        ))}
                        {row.length > 0 ? <div style={{ width: "100%", borderBottom: "1px solid #E6E6E6" }} className="m-t-20" /> : null}
                    </>
                </Row>)}
            <div className='r-jsb m-t-30'>
                <Heading>History</Heading>
                {/* {fromWhere == 'userManagementProfile' ? null :
                    <Button type="delete" icon={<DeleteOutlined />} onClick={() => { setDeleteLeaveModal(true) }} disabled={selectedRowKeys?.length < 2}>DELETE</Button>
                } */}
            </div>
            <div className='m-t-20'>
                <Table
                    columns={columns(setDeleteLeaveModal, handleEditLeave, selectedRowKeys, setIdToDelete, user, setMultiLeaveDeleteModal, fromWhere)}
                    locale={{
                        emptyText: <>
                            <div>
                                <img
                                    width={200}
                                    style={{ marginBottom: "20px" }}
                                    src={require("Assets/test/no-subjects.svg").default}
                                    alt=""
                                />
                            </div>
                            <div style={{ color: "#191919", fontSize: 16, fontWeight: 500 }}>No attendance request!</div>
                            <div style={{ color: "#636363" }}>No data available to show</div>
                        </>
                    }}
                    dataSource={leaveDetails?.leaveHistory || []}
                    // rowSelection={rowSelection}
                    rowKey={record => record?.id}
                    onRow={onRow}
                />
            </div>
            {markLeaveModal &&
                <MarkLeaveModal visible={markLeaveModal} onClose={handleCloseMarkLeaveModal} leaveFormDetails={leaveFormDetails} setLeaveDetails={setLeaveDetails} pagecallback={setPageLoader} setPageLoader={setPageLoader} fromWhere={fromWhere} />
            }
            {deleteLeaveModal &&
                <ConfirmLeaveDelete visible={deleteLeaveModal} onClose={() => setDeleteLeaveModal(false)} onDelete={handleDelete} deleteLoader={deleteLoader} />
            }
            {editLeaveModal &&
                <EditLeaveModal visible={editLeaveModal} onClose={() => setEditLeaveModal(false)} leaveDetails={leaveDetails} getLeavesData={getLeavesData} fromWhere={fromWhere} />
            }
            {multiLeaveDeleteModal &&
                <MultipleLeaveDeleteModal visible={multiLeaveDeleteModal} data={selectedLeaveRow} loading={deleteLoader} deleteHistory={deleteHistory} setMultiLeaveDeleteModal={setMultiLeaveDeleteModal} setDeleteLoader={setDeleteLoader} setSelectedRowKeys={setSelectedRowKeys} />
            }
            {openLeaveRequestModal &&
                <LeaveRequestsModal open={openLeaveRequestModal} onClose={() => setLeaveRequestModal(false)} data={selectedLeaveRow} />
            }
        </div>
    );
}


const mapStateToProps = (state) => {
    const { user } = state.session;
    const { leaveDetails, othersSelectedTab } = state.profileNew;
    const { specificUserId } = state.userManagementV2;
    return {
        leaveDetails, user, othersSelectedTab, specificUserId
    }
}

const mapDispatchToProps = (dispatch) => ({
    getLeavesData: (params, callback) => dispatch(getLeavesData(params, callback)),
    deleteHistory: (params, callback, successCallback, fromWhere) => dispatch(deleteHistory(params, callback, successCallback, fromWhere))
})

export default connect(mapStateToProps, mapDispatchToProps)(LeaveSummary);
