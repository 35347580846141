import React, { useState } from 'react';
import { Button, Modal, Radio } from 'antd';
import Mask_Group_48 from "../../../Assets/images/Mask_Group_48.svg"
import Mask_Group_50 from "../../../Assets/images/Mask_Group_50.svg"
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";

import CreateAnnouncementModal from './createAnnouncmentModal';
import { LockOutlined, RightOutlined } from '@ant-design/icons';

const ChooseAnnouncement = ({ chooseModal, setChooseModal, close, setIsAnnouncement, isModalVisible, setIsModalVisible, announcementType ,setKey,key}) => {
    console.log("AAA-key",key);
    const [isValue, setIsValue] = useState(0)
    const showModal = () => {
        setChooseModal(true);
    };

    const handleCancel = () => {
        setChooseModal(false);
    };
    const handleNext= ()=>{
        if(isValue == 1){
            setIsValue(1);
          }else{
            setIsValue(2);
          };
    }
    
    return (
        <>

            <Modal
                className="modal-round-corner"
                open={chooseModal}
                footer={null}
                //centered={true}
                // width={"50%"}
                closable={true}
                bodyStyle={{ borderRadius:"10px" }}
                onCancel={() => handleCancel()}
            >
                <div style={{ textAlign: "center", fontSize: "21px", color: "#191919", fontWeight: "bold", }}>Create annoucement</div>
              
                
            <div  style={{marginTop:"20px",}}>
                <div className='shadow-box p-20 position-relative cursor-pointer' style={{width: '100%', border: '1px solid #E6E6E6', borderRadius: 8, minWidth: 250}} onClick={()=>{setIsModalVisible(true);close(false)}}>
                {/* <div style={{float: 'right'}}> */}
                {/* {isValue == 1 ? <MdRadioButtonChecked className="color-purple"/> : <MdRadioButtonUnchecked/>} */}
            {/* </div> */}
            <div style={{ width: "100%",display:"flex" }}>
                <img src={Mask_Group_48}  />
                <div style={{marginLeft:"20px",width:"70%"}} >
                    <div style={{ color: "#191919", fontSize: "17px", fontWeight: "bold" }}>Create text announcement</div>
                    <div style={{ color: "#636363", fontSize: "13px", }}>Create a formal written notice of certain events which has happened or going to happen</div>
                </div>
                
                <div style={{marginTop:"20px",fontSize:"20px"}}><RightOutlined /></div>
            </div>
        </div>

        <div className='shadow-box p-20 position-relative cursor-pointer' style={{ width: '100%', border: '1px solid #E6E6E6', borderRadius: 8, minWidth: 250,marginTop:"10px"}} onClick={()=>{setIsAnnouncement(true);close(false)}}>
                            <div style={{ width: "100%",display:"flex" }}>
                                <img src={Mask_Group_50} />
                                <div style={{marginLeft:"20px",width:"70%"}}>
                                    <div style={{ color: "#191919", fontSize: "15px", fontWeight: "bold" }}>Create attachment announcement</div>
                                    <div style={{ color: "#636363", fontSize: "13px" }}>Create and easily send out announcement with attachments (doc, xlsx, pdf, png, jpg, etc.)</div>
                                </div>
                                <div style={{marginTop:"20px",fontSize:"20px"}}><RightOutlined /></div>

                            </div>
        </div>
        </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginTop: "40px",
                    }}
                >
                    {/* <div>
                        <Button
                            style={{
                                borderRadius: "19px",
                                width: "150px",
                                height: "40px",
                                fontWeight: "500",
                                backgroundColor: "#FFFFFF",
                                border: "1px solid #636363 ",
                                color: "#636363",
                            }}
                            onClick={handleCancel}


                        >
                            CANCEL
                        </Button>
                    </div> */}
                    {/* <div>
                        <Button
        

                            disabled={isValue  !== 1  && isValue !==2 }
                            style={{
                                borderRadius: "19px",
                                width: "150px",
                                height: "40px",
                                fontWeight: "500",
                                backgroundColor: "#594099",
                                color: "#FFFFFF",

                            }}
        // onClick={()=>handleNext()}

                            onClick={() => {
                                
                                console.log("AA-isValue", isValue);
                                if (isValue == 1) {
                                    setIsModalVisible(true)
                                    close(false)


                                } else if (isValue == 2) {
                                  
                                    setIsAnnouncement(true)
                                    close(false)
                                }
                            }}
                        >
                            NEXT
                        </Button>
                    </div> */}

                </div>

            </Modal>

        </>
    );
};
export default ChooseAnnouncement;