import { CaretDownOutlined, CaretUpOutlined, LeftOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import { showMenu } from 'actions/layout';
import { getAllCoursesData, getCartCount, getMarketSettingsData, getOffersCarouselData, getUserCourseAutocompleteSearch, getUserMarketPlaceCoursesData, getUserMarketPlaceHomeData, setIsLogoutClicked, setOffersBarVisible, setParentLink, setSelectedSuggestion, setStorePromoPrice, storeCartCountData, setCourseData, impressionEngagementIncrement, postCourseEnrollNow } from 'actions/studentMarketPlace';
import { AutoComplete, Dropdown, Menu, Typography } from 'antd';
import PageDataLoader from 'components/pageDataLoader';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Slider from "react-slick";
import { Api } from 'services';
import CourseCard from './courseCard';
import DefaultBanner from './defaultBanner';
import Footer from './footer';
import MobileCourseCard from './mobileCourseCard';
import MobileDiscountOffersCarousel from './mobileDiscountOffersCarousel';
import OffersCarousel from './offersCarousel';
import TopBar from './topBar';
import './userHomeStyles.css';
import NewCoursesCards from './myCourses/newCourrsesCards';
import NewListViewCards from './myCourses/newListViewCards';
import MobileViewGridCards from './myCourses/mobileViewGridCards';
import MobileViewListCards from './myCourses/mobileViewListCards';
import AdmissionEnquiryForm from './admissionEnquiryForm';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import NewFooter from './newFooter';
import DownloadAppModal from './downloadAppModal';
import { setEnrolledItemId, setShowBottomSheet, setShowLoginModal } from 'actions/loginNew';
const illNoData = require("../../Assets/feeManagement/ill-no-data.svg").default;


function UserMarketPlaceHome({ getUserMarketPlaceHomeData, setStorePromoPrice, userMarketPlaceHomeData, getOffersCarouselData, offersCarouselData, getAllCoursesData, user, setParentLink, getMarketSettingsData, marketSettingData, showMenu, isLogoutClicked, getCartCount, storeCartCountData, setIsLogoutClicked, isDownloadBarVisible, showContactBar, isOffersBarVisible, setOffersBarVisible, getUserMarketPlaceCoursesData, isFromAdminPreview, getUserCourseAutocompleteSearch, selectedSuggestion, setSelectedSuggestion, storeCourseData, setCourseData, setShowLoginModal, setShowBottomSheet, setEnrolledItemId, courseEnrolledId, impressionEngagementIncrement, postCourseEnrollNow }) {
  const [pageLoader, togglePageLoader] = useState(false);
  const [showMobDiscount, setShowMobDiscount] = useState(true);
  const [isLeftArrowDisabled, setLeftArrowDisabled] = useState(true);
  const [isRightArrowDisabled, setRightArrowDisabled] = useState(false);
  const [isPopularLeftArrowDisabled, setPopularLeftArrowDisabled] = useState(true);
  const [isPopularRightArrowDisabled, setPopularRightArrowDisabled] = useState(false);
  const [isTopCatLeftArrowDisabled, setTopCatLeftArrowDisabled] = useState(true);
  const [isTopCatRightArrowDisabled, setTopCatRightArrowDisabled] = useState(false);
  const [isOutstandingLeftArrowDisabled, setOutstandingLeftArrowDisabled] = useState(true);
  const [isOutstandingRightArrowDisabled, setOutstandingRightArrowDisabled] = useState(false);
  const [isBannerLeftArrowDisabled, setBannerLeftArrowDisabled] = useState(true);
  const [isBannerRightArrowDisabled, setBannerRightArrowDisabled] = useState(false);
  const [isLearnersLeftArrowDisabled, setLearnersLeftArrowDisabled] = useState(true);
  const [isLearnersRightArrowDisabled, setLearnersRightArrowDisabled] = useState(false);
  const [userSearchDataContainer, setUserSearchData] = useState(null);
  const [showLoader, setShowLoader] = useState(true)
  const [isAdmissionModalVisible, setIsAdmissionModalVisible] = useState(false);

  const [hideNoData, setHideNoData] = useState(false)


  const sliderRef = useRef(null);
  const popularSliderRef = useRef(null);
  const topCatSliderRef = useRef(null);
  const outStandingSliderRef = useRef(null);
  const bannerSliderRef = useRef(null);
  const learnersSliderRef = useRef(null);
  const [reload, setReload] = useState(false)
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();
  const history = useHistory();
  const [renderHome, setRenderHome] = useState(false)
  const [cursorValid, setCursorValid] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isCategoryClicked, setIsCategoryClicked] = useState(false);
  const [isProfileClicked, setIsProfileClicked] = useState(false);

  const [courses, setCourses] = useState(storeCartCountData.items || []);
  const [page, setPage] = useState(1);
  const [searchLoader, setSearchLoader] = useState(null);
  const [autocompleteSearchedValue, setAutocompleteSearchedValue] = useState(null)
  const [selectedOption, setSelectedOption] = useState(
      userMarketPlaceHomeData?.liveCourseEnabled ? 'Live Courses' :
    userMarketPlaceHomeData?.videoResourceEnabled ? 'Video Courses' : 
    userMarketPlaceHomeData?.studyMaterialEnabled ? 'Study materials' :
     'Test series'
);
  const [isCategoryId, setCategoryId] = useState(null)
  const [menuVisible, setMenuVisible] = useState(true);
  const [activeCategoryIndex, setActiveCategoryIndex] = useState(null);
  const [isCategories, setCategories] = useState([{
    categoriesName: null,
    subCategoriesName: null
  }])
  const [hideBanner, SetHidBanner] = useState(false)
  const [isSearch, setIsSearch] = useState(true)
  const [showDownloadAppModal, setShowDownloadAppModal] = useState(false) 
  const [isFooterVisible, setIsFooterVisible] = useState(false);


  // useEffect(()=>{ 
  //   if(userMarketPlaceHomeData?.liveCourseEnabled){
  //     setSelectedOption('Live Courses')
  //   }
  //   else if(userMarketPlaceHomeData?.videoResourceEnabled){
  //     setSelectedOption('Video Courses')
  //   }
  //   else if(userMarketPlaceHomeData?.studyMaterialEnabled){
  //     setSelectedOption('Study materials')
  //   }
  //   else if(userMarketPlaceHomeData?.onlineTestEnabled){
  //     setSelectedOption('Test series')
  //   }
  //   // setSelectedOption(userMarketPlaceHomeData?.liveCourseEnabled ? 'Live Courses' :
  //   //   userMarketPlaceHomeData?.videoResourceEnabled ? 'Video Courses' : 
  //   //   userMarketPlaceHomeData?.studyMaterialEnabled ? 'Study materials' : 'Test series')
  // },[userMarketPlaceHomeData])
       
  useEffect(() => {

    const userAgent = navigator.userAgent.toLowerCase();

    if (/android/.test(userAgent)) {
      setSpecificDeviceName('android')
    } else if (/iphone|ipod|ipad/.test(userAgent)) {
      setSpecificDeviceName('ios')
    } else {
      // setSpecificDeviceName('unknown')
    }
  
  }, [user]);

  useEffect(() => {
    const footerElement = document.getElementById('footer'); 
    const observer = new IntersectionObserver(
      ([entry]) => setIsFooterVisible(entry.isIntersecting),
      { threshold: 0.1 } 
    );
    if (footerElement) observer.observe(footerElement);

    return () => observer.disconnect(); 
  }, [document.getElementById('footer')]);

  useEffect(() => {

    if(courseEnrolledId?.id){
      let params = {
        id: courseEnrolledId?.id,
        type: 'engagement',
        courseType: courseEnrolledId?.type
    }
    impressionEngagementIncrement(params, () => {  togglePageLoader(false) })

    if (user?.accessToken) {
        postCourseEnrollNow(courseEnrolledId?.id, courseEnrolledId?.type, (res) => {
            if (res) {
              togglePageLoader(false)
                // setEnroll((prevSavedItems) => ({
                //     ...prevSavedItems,
                //     [id]: true,
                // }));
                // setFreeConfirmModal(false)
                // history.push('/courses-list')
            }
            setShowDownloadAppModal(true)
        });
        setEnrolledItemId({id:null, type:''})
    } else {
        history.push('/login');
    }
    }
  }, [user]);



  useEffect(() => {
    setStorePromoPrice(null)
    showMenu(false);
    setShowLoginModal(false)
    togglePageLoader(true);
    getMarketSettingsData(false, (res) => {
      setShowLoader(false)
      if (res?.active) {
        setRenderHome(true)
        if (isLogoutClicked) {
          storeCartCountData(0)
        } else {
          getCartCount()
        }
        getUserMarketPlaceHomeData(undefined, () => { togglePageLoader(false); setIsLogoutClicked(false) })
        setCourses([])
        const requestsParams = {
          page: 1,
          courseType: userMarketPlaceHomeData?.liveCourseEnabled ? 'course' :userMarketPlaceHomeData?.videoResourceEnabled ? 'videoResource' : userMarketPlaceHomeData?.studyMaterialEnabled ? 'studyMaterial' : userMarketPlaceHomeData?.onlineTestEnabled ? 'testSeries' : userMarketPlaceHomeData?.orgName == 'ashreya' ? 'videoResource': 'course',
          limit: 3,
        };
        setisLoading(true);
        setIsSearch(false)
        setHideNoData(false)
        getUserMarketPlaceCoursesData(requestsParams, (data) => {
          setIsSearch(true)
          if (isCategories[0]?.subCategoriesName) {
            setHideNoData(true)
          }
          setisLoading(false);
          if (data?.items && data?.items.length > 0) {
            setCourses((prevCourses) => [...prevCourses, ...data?.items]);
          }
          if (data?.items?.length < requestsParams?.limit) {
            setHasMore(false);
          }
        });
        getOffersCarouselData()
      }
      else {
        history.push("/login")
      }
    })
  }, [])

  const CustomArrowForOutstandingCard = ({ onClick, direction, disabled }) => (
    <div
      className={`carousel-outstanding-arrow carousel-custom-arrow-${direction} ${disabled ? "arrow-disable" : "carousel-course-card-arrow"}`}
      onClick={onClick}
    >
      {direction === 'left' ? <LeftOutlined style={{ color: "#AEAEAE" }} /> : <RightOutlined style={{ color: "#AEAEAE" }} />}
    </div>
  );

  const CustomArrowForLearnersCard = ({ onClick, direction, disabled }) => (
    <div
      className={`carousel-outstanding-arrow carousel-custom-arrow-${direction} ${disabled ? "arrow-disable" : "carousel-course-card-arrow"}`}
      onClick={onClick}
    >
      {direction === 'left' ? <LeftOutlined style={{ color: "#AEAEAE" }} /> : <RightOutlined style={{ color: "#AEAEAE" }} />}
    </div>
  )

  const CustomBannerArrow = ({ onClick, direction, disabled }) => (
    <div
      className={`carousel-custom-arrow-${direction} ${disabled ? "arrow-disable" : "carousel-custom-arrow"}`}
      onClick={onClick}
    >
      {direction === 'left' ? <LeftOutlined style={{ color: "#AEAEAE" }} /> : <RightOutlined style={{ color: "#AEAEAE" }} />}
    </div>
  );

  const CustomArrowForCourseCard = ({ onClick, direction, disabled }) => (
    <div
      className={`carousel-custom-arrow-${direction} ${disabled ? "arrow-disable" : "carousel-course-card-arrow"}`}
      onClick={onClick}
    >
      {direction === 'left' ? <LeftOutlined style={{ color: "#AEAEAE" }} /> : <RightOutlined style={{ color: "#AEAEAE" }} />}
    </div>
  );
  const CustomArrowForPopularCourseCard = ({ onClick, direction, disabled }) => (
    <div
      className={`carousel-custom-arrow-${direction} ${disabled ? "arrow-disable" : "carousel-course-card-arrow"}`}
      onClick={onClick}
    >
      {direction === 'left' ? <LeftOutlined style={{ color: "#AEAEAE" }} /> : <RightOutlined style={{ color: "#AEAEAE" }} />}
    </div>
  );

  const CustomArrowForTopCatCard = ({ onClick, direction, disabled }) => (
    <div
      className={`carousel-custom-arrow-${direction} ${disabled ? "arrow-disable" : "carousel-course-card-arrow"}`}
      onClick={onClick}
    >
      {direction === 'left' ? <LeftOutlined style={{ color: "#AEAEAE" }} /> : <RightOutlined style={{ color: "#AEAEAE" }} />}
    </div>
  );

  const CarouselPreviousArrow = ({ onClick, fromCourseCard, fromPopularCourseCard, fromTopCatCard, fromOutstandingCards, fromLearnersCards, disabled }) => (
    <>
      {fromCourseCard ? (
        <CustomArrowForCourseCard onClick={onClick} direction="left" disabled={disabled} />
      ) :
        fromPopularCourseCard ?
          <CustomArrowForPopularCourseCard onClick={onClick} direction="left" disabled={disabled} />
          :
          fromTopCatCard ?
            <CustomArrowForTopCatCard onClick={onClick} direction="left" disabled={disabled} />
            :
            fromOutstandingCards ? (
              <CustomArrowForOutstandingCard onClick={onClick} direction="left" disabled={disabled} />
            )
              :
              fromLearnersCards ?
                <CustomArrowForLearnersCard onClick={onClick} direction="left" disabled={disabled} />
                :
                (
                  <CustomBannerArrow onClick={onClick} direction="left" disabled={disabled} />
                )}
    </>
  );


  const CarouselNextArrow = ({ onClick, fromCourseCard, fromPopularCourseCard, fromTopCatCard, fromOutstandingCards, fromLearnersCards, disabled }) => (
    <>
      {fromCourseCard ? (
        <CustomArrowForCourseCard onClick={onClick} direction="right" disabled={disabled} />
      ) :
        fromPopularCourseCard ?
          <CustomArrowForPopularCourseCard onClick={onClick} direction="right" disabled={disabled} />
          :
          fromTopCatCard ?
            <CustomArrowForTopCatCard onClick={onClick} direction="right" disabled={disabled} />
            :
            fromOutstandingCards ? (
              <CustomArrowForOutstandingCard onClick={onClick} direction="right" disabled={disabled} />
            )
              :
              fromLearnersCards ?
                <CustomArrowForLearnersCard onClick={onClick} direction="right" disabled={disabled} />
                :
                (
                  <CustomBannerArrow onClick={onClick} direction="right" disabled={disabled} />
                )}
    </>
  );

  useEffect(() => {
    const handleResize = () => {
      updateArrowStates();
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // increase total visitors count using google analytics
    console.log('user event check');
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', 'G-DN80PX2DW4');
  }, [])

  useEffect(() => {
    if (window.performance.navigation.type === 0) {
      setOffersBarVisible(true)
    }
  }, [])

  const handleBeforeChange = (current, next) => {
    setLeftArrowDisabled(next <= 0);
    setTimeout(() => {
      updateArrowStates();
    }, 0);
  };

  const updateArrowStates = () => {
    const slider = sliderRef.current;
    if (slider) {
      const totalSlides = userMarketPlaceHomeData?.newlyLaunchedCourses?.length;
      const windowWidth = window.innerWidth;
      const responsiveSettings = courseCardSettings?.responsive;
      let slidesToShow = courseCardSettings?.slidesToShow;
      for (let i = 0; i < responsiveSettings.length; i++) {
        if (windowWidth <= responsiveSettings[i].breakpoint) {
          slidesToShow = responsiveSettings[i].settings.slidesToShow;
          break;
        }
      }
      const currentSlide = slider.innerSlider.state.currentSlide;
      setRightArrowDisabled(currentSlide + slidesToShow >= totalSlides);
    }
  };

  useEffect(() => {
    const handlePopularResize = () => {
      updatePopularArrowStates();
    };
    window.addEventListener('resize', handlePopularResize);
    return () => {
      window.removeEventListener('resize', handlePopularResize);
    };
  }, []);

  const handlePopularBeforeChange = (current, next) => {
    setPopularLeftArrowDisabled(next <= 0);
    setTimeout(() => {
      updatePopularArrowStates();
    }, 0);
  };

  const updatePopularArrowStates = () => {
    const slider = popularSliderRef.current;
    if (slider) {
      const totalSlides = userMarketPlaceHomeData?.popularCourses?.length;
      const windowWidth = window.innerWidth;
      const responsiveSettings = popularCardSettings?.responsive;
      let slidesToShow = popularCardSettings?.slidesToShow;
      for (let i = 0; i < responsiveSettings?.length; i++) {
        if (windowWidth <= responsiveSettings[i].breakpoint) {
          slidesToShow = responsiveSettings[i].settings.slidesToShow;
          break;
        }
      }
      const currentSlide = slider.innerSlider.state.currentSlide;
      setPopularRightArrowDisabled(currentSlide + slidesToShow >= totalSlides);
    }
  };

  useEffect(() => {
    const handleTopCatResize = () => {
      updateTopCatArrowStates();
    };
    window.addEventListener('resize', handleTopCatResize);
    return () => {
      window.removeEventListener('resize', handleTopCatResize);
    };
  }, []);

  const handleTopCatBeforeChange = (current, next) => {
    setTopCatLeftArrowDisabled(next <= 0);
    setTimeout(() => {
      updateTopCatArrowStates();
    }, 0);
  };

  const updateTopCatArrowStates = () => {
    const slider = topCatSliderRef.current;
    if (slider) {
      const totalSlides = userMarketPlaceHomeData?.topCategories?.length;
      const windowWidth = window.innerWidth;
      const responsiveSettings = topCategoriesSettings?.responsive;
      let slidesToShow = topCategoriesSettings?.slidesToShow;
      for (let i = 0; i < responsiveSettings?.length; i++) {
        if (windowWidth <= responsiveSettings[i].breakpoint) {
          slidesToShow = responsiveSettings[i].settings.slidesToShow;
          break;
        }
      }
      const currentSlide = slider.innerSlider.state.currentSlide;
      setTopCatRightArrowDisabled(currentSlide + slidesToShow >= totalSlides);
    }
  };

  useEffect(() => {
    const handleOutStandingResize = () => {
      updateOutStandingArrowStates();
    };
    window.addEventListener('resize', handleOutStandingResize);
    return () => {
      window.removeEventListener('resize', handleOutStandingResize);
    };
  }, []);

  const handleOutstandingBeforeChange = (current, next) => {
    setOutstandingLeftArrowDisabled(next <= 0);
    setTimeout(() => {
      updateOutStandingArrowStates();
    }, 0);
  };

  const updateOutStandingArrowStates = () => {
    const slider = outStandingSliderRef.current;
    if (slider) {
      const totalSlides = userMarketPlaceHomeData?.outStandingResultDataArray?.length;
      const windowWidth = window.innerWidth;
      const responsiveSettings = outstandingCardsSettings?.responsive;
      let slidesToShow = outstandingCardsSettings?.slidesToShow;
      for (let i = 0; i < responsiveSettings.length; i++) {
        if (windowWidth <= responsiveSettings[i].breakpoint) {
          slidesToShow = responsiveSettings[i].settings.slidesToShow;
          break;
        }
      }
      const currentSlide = slider.innerSlider.state.currentSlide;
      setOutstandingRightArrowDisabled(currentSlide + slidesToShow >= totalSlides);
    }
  };

  const handleBannerBeforeChange = (current, next) => {
    setBannerLeftArrowDisabled(next <= 0);
    setTimeout(() => {
      updateBannerArrowStates();
    }, 0);
  };

  const updateBannerArrowStates = () => {
    const slider = bannerSliderRef.current;
    if (slider) {
      const totalSlides = userMarketPlaceHomeData?.bannerData?.length;;
      let slidesToShow = bannerSettings?.slidesToShow;
      const currentSlide = slider.innerSlider.state.currentSlide;
      setBannerRightArrowDisabled(currentSlide + slidesToShow >= totalSlides);
    }
  };
  useEffect(() => {
    const handleLearnersResize = () => {
      updateLearnersArrowStates();
    };
    window.addEventListener('resize', handleLearnersResize);
    return () => {
      window.removeEventListener('resize', handleLearnersResize);
    };
  }, []);

  const handleLearnersBeforeChange = (current, next) => {
    setLearnersLeftArrowDisabled(next <= 0);
    setTimeout(() => {
      updateLearnersArrowStates();
    }, 0);
  };

  const updateLearnersArrowStates = () => {
    const slider = learnersSliderRef.current;
    if (slider) {
      const totalSlides = userMarketPlaceHomeData?.testimonialsData?.length;
      const windowWidth = window.innerWidth;
      const responsiveSettings = learnersCardsSettings?.responsive;
      let slidesToShow = learnersCardsSettings?.slidesToShow;
      for (let i = 0; i < responsiveSettings.length; i++) {
        if (windowWidth <= responsiveSettings[i].breakpoint) {
          slidesToShow = responsiveSettings[i].settings.slidesToShow;
          break;
        }
      }
      const currentSlide = slider.innerSlider.state.currentSlide;
      setLearnersRightArrowDisabled(currentSlide + slidesToShow >= totalSlides);
    }
  };

  const bannerSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 5000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // prevArrow: <CarouselPreviousArrow disabled={isBannerLeftArrowDisabled} />,
    // nextArrow: <CarouselNextArrow disabled={isBannerRightArrowDisabled} />,
    beforeChange: (current, next) => handleBannerBeforeChange(current, next),
  };

  const courseCardSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <CarouselPreviousArrow fromCourseCard={true} disabled={isLeftArrowDisabled} />,
    nextArrow: <CarouselNextArrow fromCourseCard={true} disabled={isRightArrowDisabled} />,
    beforeChange: (current, next) => handleBeforeChange(current, next),
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          dots: false,
        },
      },
    ],
  };

  const popularCardSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <CarouselPreviousArrow fromPopularCourseCard={true} disabled={isPopularLeftArrowDisabled} />,
    nextArrow: <CarouselNextArrow fromPopularCourseCard={true} disabled={isPopularRightArrowDisabled} />,
    beforeChange: (current, next) => handlePopularBeforeChange(current, next),
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          dots: false,
        },
      },
    ],
  };
  const topCategoriesSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <CarouselPreviousArrow fromTopCatCard={true} disabled={isTopCatLeftArrowDisabled} />,
    nextArrow: <CarouselNextArrow fromTopCatCard={true} disabled={isTopCatRightArrowDisabled} />,
    beforeChange: (current, next) => handleTopCatBeforeChange(current, next),
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          dots: false,
        },
      },
    ],
  };

  const outstandingCardsSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <CarouselPreviousArrow fromOutstandingCards={true} disabled={isOutstandingLeftArrowDisabled} />,
    nextArrow: <CarouselNextArrow fromOutstandingCards={true} disabled={isOutstandingRightArrowDisabled} />,
    beforeChange: (current, next) => handleOutstandingBeforeChange(current, next),
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          dots: false,
        },
      },
    ],
  };
  const learnersCardsSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <CarouselPreviousArrow fromLearnersCards={true} disabled={isLearnersLeftArrowDisabled} />,
    nextArrow: <CarouselNextArrow fromLearnersCards={true} disabled={isLearnersRightArrowDisabled} />,
    beforeChange: (current, next) => handleLearnersBeforeChange(current, next),
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          dots: false,
        },
      },
    ],
  };

  const handleChangeCategory = (cId, category, subCategory) => {
    setParentLink({ parentName: category, });
    history.push({
      pathname: '/all-popular-courses',
      state: {
        type: 'pid',
        categoryId: cId,
      }
    })
  }


  const CategoryCard = ({ category, fromMobile, lessData, index }) => {
    return (
      <div className={`r-c-c ${fromMobile ? 'm-r-30' : lessData && index === 3 ? ' m-b-20 m-t-20' : lessData && index !== 3 ? 'm-r-20 m-b-20 m-t-20' : ''}`} style={{ border: "1px solid #E6E6E6", backgroundColor: "#FFFFFF", height: 100, width: fromMobile ? "100%" : lessData ? 293 : "92%", padding: fromMobile ? "20px 50px" : "20px", borderRadius: 8, cursor: "pointer" }}
        onClick={() => {
          handleChangeCategory(category?.id, category?.name,)
        }}>
        <center className='category-font' style={{ color: "#191919", fontWeight: "bold" }}>{category?.name}</center>
      </div>
    )
  }

  const OutstandingResultsCard = ({ item, isOne, isTwo, isThree }) => {
    return (
      <div style={{ width: "fit-content" }}
      //  className={`${isOne ? 'outstanding-one-card-parent' : isTwo ? 'outstanding-two-card-parent' : isThree ? 'outstanding-three-card-parent' : 'mobile-outstanding-card-parent'}`}
      >
        <div className='r-c-c' style={{ borderRadius: "8px 8px 0px 0px", backgroundColor: "#594099", border: "1px solid #707070", height: 60, width: isOne || isTwo || isThree ? "100%" : "94%", padding: "20px", marginTop: isOne || isTwo || isThree ? "20px" : "" }}>
          <center><Typography.Text className='home-examName-font' ellipsis={{ tooltip: true }} style={{ color: "#ffffff", width: 300, fontWeight: "bold" }}>{item?.examName}</Typography.Text></center>
        </div>
        <div className='scroll-container' style={{ backgroundColor: "#F6F4FE", border: "1px solid #E6E6E6", height: 200, width: isOne || isTwo || isThree ? "100%" : "94%", padding: "10px 20px", borderRadius: "0px 0px 8px 8px", display: item?.students?.length < 6 ? "flex" : "", justifyContent: item?.students?.length < 6 ? "space-evenly" : "" }}>
          {item?.students?.map((student) => (
            <div style={{ margin: "5px 10px" }}>
              <img src={`${Api._s3_url}${student?.key}`} style={{ height: 90, width: 90, borderRadius: "8px 8px 0px 0px" }} />
              <center><Typography.Text ellipsis={{ tooltip: true }} style={{ backgroundColor: "#594099", height: "fit-content", width: 90, color: "#ffffff", fontWeight: 650, borderRadius: "0px 0px 8px 8px" }}>{student?.rank} </Typography.Text></center>
              <div style={{ marginTop: 5, color: "#636363", fontSize: 12, width: 90, textAlign: "center", overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitBoxOrient: "vertical", WebkitLineClamp: 2 }}>
                <Typography.Text title={student?.name}>{student?.name}</Typography.Text>
              </div>
              <center style={{ marginTop: -3, marginBottom: 5 }}><Typography.Text ellipsis={{ tooltip: true }} style={{ color: "#191919", fontSize: 11, fontWeight: 650, width: 90 }}>{student?.additionalInfo}</Typography.Text></center>
            </div>
          ))}
        </div>
      </div>
    )
  }

  const LearnersCard = ({ item, isOne, isTwo, isThree, isMobile }) => {
    return (
      <div className={`${isOne ? 'outstanding-one-card-parent' : isTwo ? 'outstanding-two-card-parent' : isThree ? 'outstanding-three-card-parent' : 'mobile-learners-card-parent'}`}>
        <div className='r-c-c-c' style={{ border: "1px solid #E6E6E6", borderRadius: 8, height: 260, width: isOne || isTwo || isThree ? "100%" : isMobile ? "320PX" : "94%", backgroundColor: "#F6F4FE", padding: 30, marginTop: isOne || isTwo || isThree ? "20px" : "", marginRight: isMobile ? 20 : '' }}>
          <img src={`${Api._s3_url}${item?.key}`} style={{ height: 80, width: 80, borderRadius: "50%" }} />
          <center><Typography.Text ellipsis={{ tooltip: true }} style={{ margin: "10px 0px", color: "#191919", fontSize: 16, fontWeight: "bold" }}>{item?.name}</Typography.Text></center>
          <center style={{ color: "#191919", fontWeight: 600, }}>"{item?.review}"</center>
        </div>
      </div>
    )
  }

  
  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (e) {
      return false;
    }
  };
 
  const CalculateMarginTop = () => {
    if (isDownloadBarVisible) {
      return '112px';
    } else if (!isDownloadBarVisible) {
      return '55px';
    }
  }

  
  const handleOptionClick = (item) => {
 
    setIsSearch(false)
    setHideNoData(false)
    setSelectedOption(item);
    setCourseData([])
    setCourses([])
    setPage(1)
    setHasMore(true)

  };

  useEffect(() => {
    setCourses([]);
    setPage(1);
    setHasMore(true);
  }, [selectedOption, isCategoryId]);

  useEffect(() => {
    if (autocompleteSearchedValue == '') {
      setCourses([]);
      setPage(1);
      setHasMore(true);
    }

  }, [autocompleteSearchedValue])

  useEffect(() => {
    if (autocompleteSearchedValue == '') {
      setIsSearch(false)
      setAutocompleteSearchedValue(null)
      setCourses([])

      const requestsParams = {
        page: page,
        courseType: selectedOption === 'Live Courses' ? 'course'
          : selectedOption === 'Video Courses' ? 'videoResource'
            : selectedOption === 'Study materials' ? 'studyMaterial'
              : 'testSeries',
        limit: 3,
        cId: isCategoryId,
        search: ''
      };
      setisLoading(true);
      setIsSearch(false)
      setHideNoData(false)
      if(renderHome){
      getUserMarketPlaceCoursesData(requestsParams, (data) => {
        setIsSearch(true)
        if (isCategories[0]?.subCategoriesName) {
          setHideNoData(true)
        }
        setisLoading(false);
        if (data?.items && data?.items.length > 0) {
          setCourses((prevCourses) => [...prevCourses, ...data?.items]);
        }
        if (data?.items?.length < requestsParams?.limit) {
          setHasMore(false);
        }
      });
    }
    }
  }, [autocompleteSearchedValue])

  // useEffect(() => {
  //   if (!showDownloadAppModal) {
  //     setPage(1)
  //     setIsSearch(false)
  //     setAutocompleteSearchedValue(null)
  //     setCourses([])
  //     const requestsParams = {
  //       page: 1,
  //       courseType: selectedOption === 'Live Courses' ? 'course'
  //         : selectedOption === 'Video Courses' ? 'videoResource'
  //           : selectedOption === 'Study materials' ? 'studyMaterial'
  //             : 'testSeries',
  //       limit: 3,
  //       cId: isCategoryId,
  //       search: ''
  //     };
  //     setisLoading(true);
  //     setIsSearch(false)
  //     setHideNoData(false)
  //     getUserMarketPlaceCoursesData(requestsParams, (data) => {
  //       setIsSearch(true)
  //       if (isCategories[0]?.subCategoriesName) {
  //         setHideNoData(true)
  //       }
  //       setisLoading(false);
  //       if (data?.items && data?.items.length > 0) {
  //         setCourses((prevCourses) => [...prevCourses, ...data?.items]);
  //       }
  //       if (data?.items?.length < requestsParams?.limit) {
  //         setHasMore(false);
  //       }
  //     });
  //   }
  // }, [showDownloadAppModal])


  

  const loadMoreCourses = () => {
    if (!isLoading && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    const requestsParams = {
      page: page,
      courseType: selectedOption === 'Live Courses' ? 'course'
        : selectedOption === 'Video Courses' ? 'videoResource'
          : selectedOption === 'Study materials' ? 'studyMaterial'
            : 'testSeries',
      limit: 3,
      cId: isCategoryId,
      search: selectedSuggestion
    };
    setisLoading(true);
    setIsSearch(false)
    setHideNoData(false)
    getUserMarketPlaceHomeData(undefined, () => { togglePageLoader(false); setIsLogoutClicked(false) })
    if(renderHome){
    getUserMarketPlaceCoursesData(requestsParams, (data) => {
      setisLoading(false);
      if (autocompleteSearchedValue) {
        setIsSearch(true)
      }
      setHideNoData(true)
      if (data?.items && data?.items?.length > 0) {
        setCourses((prevCourses) => [...prevCourses, ...data?.items]);
      }
      if (data?.items?.length < requestsParams?.limit) {
        setHasMore(false);
      }
    });
  }
  }, [selectedOption, page, isCategoryId, isCategories]);

  useEffect(() => {
    const lastCourseElement = document.querySelector('.loading-indicator');

    const observerCallback = (entries) => {
      if (entries[0].isIntersecting) {
        loadMoreCourses();
      }
    };

    if (lastCourseElement) {
      observer.current = new IntersectionObserver(observerCallback);
      observer.current.observe(lastCourseElement);
    }

    return () => {
      if (observer.current && lastCourseElement) {
        observer.current.unobserve(lastCourseElement);
      }
    };
  }, [isLoading]);

  useEffect(() => {
    setAutocompleteSearchedValue([])
    setPage(1)
    setSelectedSuggestion(null)
  }, [])


  console.log("subcategory?.name760", autocompleteSearchedValue, selectedSuggestion);



  const handleSubCategorySelection = (category, subcategory) => {
    setAutocompleteSearchedValue(null);
    setSelectedSuggestion(null);
    setMenuVisible(false);
    setCategoryId(subcategory?.id);
    SetHidBanner(true);
    setIsSearch(false)
    setUserSearchData(null)

    setCourses([]);

    // Update both category and subcategory upon subcategory selection
    setCategories(prev => {
      const newCategories = prev && prev.length > 0
        ? { ...prev[0], categoriesName: category?.name, subCategoriesName: subcategory?.name }
        : { categoriesName: category?.name, subCategoriesName: subcategory?.name };
      return [newCategories];
    });
  };

  const filterSubCategoryMenu = (category) => (
    <Menu
      className='scroll-bar-universal'
      style={{
        height: "fit-content",
        backgroundColor: "#FFFFFF",
        boxShadow: "4px 4px 16px #00000014",
        width: 230,
        borderRadius: 8,
        margin: "40px 0px 0px -2px"
      }}
    >
      {category?.children && category.children.map((subcategory) => (
        <div
          className='dropmenuBp'
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: 45
          }}
          onClick={() => handleSubCategorySelection(category, subcategory)}
        >
          <Typography.Text
            ellipsis={{ tooltip: true }}
            style={{ color: "#191919", width: 210, fontWeight: 550 }}
          >
            {subcategory?.name}
          </Typography.Text>
        </div>
      ))}
    </Menu>
  );


  const userCategoryMenu = (
    <Menu
      className='scroll-bar-universal'
      style={{
        height: userMarketPlaceHomeData?.categories?.length > 5 ? 280 : "fit-content",
        backgroundColor: "#FFFFFF",
        boxShadow: "4px 4px 16px #00000014",
        width: 230,
        borderRadius: 8,
        marginTop: 5,
        marginLeft:-22
      }}
    >
      {userMarketPlaceHomeData?.categories && userMarketPlaceHomeData.categories.map((category, index) => (
        <Dropdown
          key={index}
          open={menuVisible && activeCategoryIndex === index}
          overlay={filterSubCategoryMenu(category)}
          placement='right'
          trigger={['click']}
        >
          <div
            className='dropmenuBp'
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: 45,
              paddingLeft:20
            }}
            onMouseEnter={(e) => {
              e.stopPropagation();
              setActiveCategoryIndex(index);
              setMenuVisible(true);

            }}
          >
            <Typography.Text
              ellipsis={{ tooltip: true }}
              style={{ color: "#191919", width: 210, fontWeight: 550 }}
            >
              {category?.name}
            </Typography.Text>
            <RightOutlined style={{ color: "#AEAEAE" }} />
          </div>
        </Dropdown>
      ))}
    </Menu>
  );

  const handleAutocompleteSearch = (value) => {
    setIsCategoryClicked(false); setIsProfileClicked(false)
    setAutocompleteSearchedValue(value);
    setSelectedSuggestion(null)
    setSearchLoader(true);
    getUserCourseAutocompleteSearch(value, (response) => { setUserSearchData(response); setSearchLoader(false) })
  }
  const renderTitle = (title) => (
    <div style={{ color: "#636363", fontSize: 16, marginLeft: 10 }}>{title}</div>
  )
  console.log("userSearchDataContainer?.suggestions", userSearchDataContainer?.suggestions);
  const searchOptions = [
    {
      label: userSearchDataContainer?.suggestions?.length != 0 ? renderTitle("Suggestions") : null,
      options: userSearchDataContainer?.suggestions?.length ? userSearchDataContainer?.suggestions?.map((item) => ({
        label: (
          <div style={{ display: 'flex', alignItems: 'center', height: 'fit-content', padding: "3px 0px" }} onClick={() => {
            setSelectedSuggestion(item);
            setCourses([])
            const requestsParams = {
              page: 1,
              courseType: selectedOption === 'Live Courses' ? 'course'
                : selectedOption === 'Video Courses' ? 'videoResource'
                  : selectedOption === 'Study materials' ? 'studyMaterial'
                    : 'testSeries',
              limit: 3,
              cId: isCategoryId,
              search: item
            };
            setisLoading(true);
            setIsSearch(false)
            setHideNoData(false)
            getUserMarketPlaceCoursesData(requestsParams, (data) => {
              setisLoading(false);
              setIsSearch(true)
              if (isCategories[0]?.subCategoriesName) {
                setHideNoData(true)
              }
              if (data?.items && data?.items?.length > 0) {
                setCourses((prevCourses) => [...prevCourses, ...data?.items]);
              }
              if (data?.items?.length < requestsParams?.limit) {
                setHasMore(false);
              }
            });

          }}>
            <SearchOutlined /><Typography.Text ellipsis={{ tooltip: true }} style={{ color: '#191919', fontWeight: 600, marginLeft: 10, width: 230 }}>{item}</Typography.Text>
          </div>
        ),
      }))
        :
        []
    },
    {
      label: userSearchDataContainer?.resources?.length != 0 ? renderTitle("Courses") : null,
      options: userSearchDataContainer?.resources?.length ? userSearchDataContainer?.resources?.map((item) => ({
        label: (
          <div style={{ display: 'flex', alignItems: 'center', height: 'fit-content', padding: "5px 0px" }} onClick={() => {
            setSelectedSuggestion(item?.name)
            history.push({
              pathname: '/resource-details',
              state: {
                id: item?.id,
                resourceType: item?.type,
              },
            })
          }}>
            {<img src={`${Api.dlS3Url}${item?.image}`} style={{ width: 70, height: 40 }} />}

            <Typography.Text ellipsis={{ tooltip: true }} style={{ color: '#191919', fontWeight: 600, marginLeft: 10, width: 230 }}>{item?.name}</Typography.Text>
          </div>
        ),
      }))
        :
        []
    },
  ];

  const filterOptions = ['Live Courses', 'Video Courses', 'Study materials', 'Test series']


  const filteredOptions = 
  filterOptions.filter(option => {
    if (option === 'Study materials' && !userMarketPlaceHomeData?.studyMaterialEnabled) {
      return false;
    }
    if (option === 'Video Courses' && !userMarketPlaceHomeData?.videoResourceEnabled) {
      return false;
    }
    if (option === 'Live Courses' && !userMarketPlaceHomeData?.liveCourseEnabled) {
      return false;
    }
    if (option === 'Test series' && !userMarketPlaceHomeData?.onlineTestEnabled) {
      return false;
    }
    return true;
  });

  useEffect(() => {
    if (!filteredOptions.includes(selectedOption)) {
      // setSelectedOption('Live Courses');
      if(userMarketPlaceHomeData?.liveCourseEnabled){
        setSelectedOption('Live Courses')
      }
      else if(userMarketPlaceHomeData?.videoResourceEnabled){
        setSelectedOption('Video Courses')
      }
      else if(userMarketPlaceHomeData?.studyMaterialEnabled){
        setSelectedOption('Study materials')
      }
      else if(userMarketPlaceHomeData?.onlineTestEnabled){
        setSelectedOption('Test series')
      }
      else{
        setSelectedOption('Live Courses')
      }
    }
  }, [userMarketPlaceHomeData, selectedOption, filteredOptions]);

  let url = new URL(window.location.href);
  console.log("url", url);
  let courseId = url.searchParams.get("id");
  console.log("url1", courseId);
  let currentURL = window.location.href;
  let id = currentURL?.split('=')[1]?.split('&')[0];
  console.log("tutorislsId", id)

  useEffect(() => {
      if (id == 'pyq') {
        setSelectedOption('Video Courses');
      }  
  }, [id]) 

  
  useEffect(() => {
    setCourses([])
    const requestsParams = {
      page: 1,
      courseType: selectedOption === 'Live Courses' ? 'course'
        : selectedOption === 'Video Courses' ? 'videoResource'
          : selectedOption === 'Study materials' ? 'studyMaterial'
            : 'testSeries',
      limit: 3,
      cId: isCategoryId,
      search: ''
    };
    setisLoading(true);
    if(renderHome){
    getUserMarketPlaceCoursesData(requestsParams, (data) => {
      setisLoading(false);
      if (data.items && data.items.length > 0) {
        setCourses((prevCourses) => [...prevCourses, ...data.items]);
      }
      if (data.items.length < requestsParams.limit) {
        setHasMore(false);
      }
    });
  }
  }, [])
  const [viewType, setViewType] = useState(false)
  const [searchResult, setSearchResult] = useState(false)

  const { ref: bannerInViewRef, inView: isInView } = useInView({
    triggerOnce: false,
    threshold: 0.2,
  });

  const controls = useAnimation();
  const controls2 = useAnimation();

  const [ref1, inView1] = useInView({ threshold: 0.1 });
  const [ref2, inView2] = useInView({ threshold: 0.1 });

  useEffect(() => {
    if (inView1) {
      controls.start({ opacity: 1, y: 0, transition: { duration: 0.8 } });
    } else {
      controls.start({ opacity: 0, y: 50 });
    }
  }, [controls, inView1]);

  useEffect(() => {
    if (inView2) {
      controls2.start({ opacity: 1, y: 0, transition: { duration: 0.8 } });
    } else {
      controls2.start({ opacity: 0, y: 50 });
    }
  }, [controls2, inView2]);

  window.parent.postMessage(userMarketPlaceHomeData, "https://coaching.ashreya.com"); // http://localhost:3001 insted of this give aulas url
  window.parent.postMessage(userMarketPlaceHomeData, "https://tutorials.ashreya.com");

  const pageVariants = {
    initial: {
      opacity: 0,
      x: '100vw',
    },
    animate: {
      opacity: 1,
      x: 0,
      transition: {
        ease: 'easeInOut',
        duration: 0.5,
      },
    },
  };

  return (
    <>
      <PageDataLoader visible={pageLoader} />
      {renderHome ?
        <>
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={pageVariants}
            style={{ overflow: 'hidden' }}
            onClick={(e) => {
              e.stopPropagation(); setIsCategoryClicked(false); setIsProfileClicked(false),
                setMenuVisible(false);
            }}>
             
            <TopBar setCategoryId={setCategoryId} setPage={setPage} setCourses={setCourses} isCategoryClicked={isCategoryClicked} setIsCategoryClicked={setIsCategoryClicked} isProfileClicked={isProfileClicked} setIsProfileClicked={setIsProfileClicked}
              setCategories={setCategories} userSearchData={setUserSearchData} autocompleteSearched={setAutocompleteSearchedValue} SetHidBanner={SetHidBanner} setHasMore={setHasMore} page={page} setShowDownloadAppModal={setShowDownloadAppModal} />
            <div style={{ backgroundColor: "#FAFAFA", paddingBottom: 50, }}>
              {!hideBanner ?
                <div style={{ marginTop: window.innerWidth > 800 ? CalculateMarginTop() : 20  }}>
                  {isOffersBarVisible !== false ? <OffersCarousel /> : null}
                </div>
                : null}
              {!hideBanner ?
                <div className="banner-parent" style={{ marginTop: -15,}}>
                  {userMarketPlaceHomeData?.bannerData?.length === 0 || userMarketPlaceHomeData?.defaultBanner === true ? (
                    <DefaultBanner
                      orgName={userMarketPlaceHomeData?.orgName}
                      defaultBannerState={userMarketPlaceHomeData?.defaultBanner}
                      bannerUrl={userMarketPlaceHomeData?.defaultBannerUrl}
                    />
                  ) : (
                    <motion.div className="banner-container" ref={bannerInViewRef}>
                      <Slider ref={bannerSliderRef} {...bannerSettings} className="home-banner-slider">
                        {userMarketPlaceHomeData?.bannerData?.map((item, index) => (
                          <motion.div
                            key={index}
                            className="static-banner-wrapper"
                            initial={{ scale: 0.8, opacity: 0 }}
                            animate={isInView ? { scale: 1, opacity: 1 } : { scale: 0.8, opacity: 0 }}
                            transition={{ duration: 0.3, ease: "easeInOut" }}
                          >
                            <motion.img
                              key={index}
                              src={`${Api._s3_url}${item?.key}`}
                              alt={item.key}
                              onClick={() => {
                                if (isValidUrl(item?.urlPath)) {
                                  window.open(item?.urlPath, '_blank');
                                }
                              }}
                              style={{
                                cursor: isValidUrl(item?.urlPath) ? 'pointer' : '',
                                width: "100%"
                              }}
                            />
                          </motion.div>
                        ))}
                      </Slider>
                    </motion.div>
                  )}
                </div>
                : null}
              {hideBanner ? <div className='categories-parent' style={{ marginTop: 120, }}>
                <div style={{ display: "flex", width: "100%", gap: 5 }}>
                  <div style={{
                    height: 30,
                    backgroundColor: '#DFDEF5',
                    borderRadius: "80px",
                    textAlign: "center",
                    border: "1px solid #CDC6E0",
                    fontSize: 14,
                    color: "#191919",
                    padding: "2px 10px",
                    whiteSpace: "nowrap",
                    display: "inline-block",
                    cursor: "pointer"
                  }}
                    onClick={() => {
                      setCategories([])
                      setCourses([]);
                      setPage(1);
                      setHasMore(true);
                      setCategoryId([])
                      SetHidBanner(false)
                      setAutocompleteSearchedValue(null)
                      setSelectedSuggestion(null)
                      setUserSearchData([])
                    }}>
                    Home
                  </div>
                  {isCategories?.map((item, index) => (
                    <div key={index} style={{ display: "flex", gap: 5 }}>
                      {item?.categoriesName && (
                        <>
                          <img src={require('../../Assets/aulasMarket/chevron-right1.svg').default} />

                          <div style={{
                            height: 30,
                            backgroundColor: '#DFDEF5',
                            borderRadius: "80px",
                            textAlign: "center",
                            border: "1px solid #CDC6E0",
                            fontSize: 14,
                            color: "#191919",
                            padding: "2px 10px",
                            whiteSpace: "nowrap",
                            flex: "none"
                          }}>
                            {item?.categoriesName}
                          </div>
                          <img src={require('../../Assets/aulasMarket/chevron-right1.svg').default} />
                        </>
                      )}
                      {item.subCategoriesName && (
                        <div style={{
                          height: 30,
                          backgroundColor: '#594099',
                          borderRadius: "80px",
                          textAlign: "center",
                          border: "1px solid #594099",
                          fontSize: 14,
                          color: "#fff",
                          padding: "2px 10px",
                          whiteSpace: "nowrap",
                          flex: "none"
                        }}>
                          {item?.subCategoriesName}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div> : <div ></div>}
              <div className='categories-parent' style={{ width: "100%", height: 40, }}>
                <div style={{ width: '100%', display: "flex", gap: 10 }}>
                  <div style={{ width: '100%', border: '1px solid #CDC6E0', height: 40, borderRadius: 10, position: 'relative' }}>
                    <div style={{ display: "flex", width: '100%' }}>
                      <div style={{ width: '15%', marginTop: -2, marginLeft: -2, backgroundColor: '#EFE8FF', height: 42, borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }}>
                        <div style={{ display: "flex", justifyContent: "center", marginTop: 7, width: '100%' }}>
                          {userMarketPlaceHomeData?.categories?.length > 0 ?
                            <Dropdown overlay={userCategoryMenu} trigger={['hover']} open={isCategoryClicked}>
                              <div style={{ color: "#191919", fontWeight: 400, fontSize: 17, cursor: "pointer" }} onClick={(e) => {
                                if (isFromAdminPreview) { }
                                else {
                                  e.stopPropagation(); setIsCategoryClicked(!isCategoryClicked); setIsProfileClicked(false)
                                }
                              }}>Categories <img src={require('../../Assets/aulasMarket/chevronDown.svg').default} /></div>
                            </Dropdown> : <div></div>}
                        </div>
                      </div>
                      <div className='search-margin-top' style={{ marginLeft: 10, width: "100%", }} onClick={() => { SetHidBanner(true) }}>

                        <AutoComplete
                          bordered={false}
                          allowClear
                          popupClassName="certain-category-search-dropdown "
                          style={{ marginRight: 20, width: "100%" }}
                          options={(userSearchDataContainer?.suggestions?.length > 0 || userSearchDataContainer?.resources?.length) ? searchOptions : []}
                          onSearch={handleAutocompleteSearch}
                          onClear={() => {
                            setSelectedSuggestion(null);
                            setAutocompleteSearchedValue(null);
                            setSelectedSuggestion(null)
                            setIsSearch(false)


                            // history.push("/home")
                          }}
                          value={selectedSuggestion ? selectedSuggestion : autocompleteSearchedValue ? autocompleteSearchedValue : null}
                          placeholder={<span><SearchOutlined /> Search</span>}
                          loading={searchLoader}
                          className=' search-width '
                          disabled={isFromAdminPreview ? true : false}
                        // open={true}
                        >
                        </AutoComplete>
                        {console.log("data", isSearch)}

                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: 3, cursor: "pointer" }} onClick={() => {
                    setViewType(!viewType)
                  }}>
                    {viewType ?
                      <img src={require('../../Assets/aulasMarket/grid.svg').default} style={{ cursor: "pointer" }} /> :
                      <img src={require('../../Assets/aulasMarket/list.svg').default} />}
                  </div>
                </div>
              </div>
              {hideBanner ? <div className='mobile-filter-container'>
                <div style={{ display: "flex", width: "100%", gap: 5 }}>
                  <div style={{
                    height: 22,
                    backgroundColor: '#DFDEF5',
                    borderRadius: "80px",
                    textAlign: "center",
                    border: "1px solid #CDC6E0",
                    fontSize: 12,
                    color: "#191919",
                    padding: "0 10px",
                    whiteSpace: "nowrap",
                    display: "inline-block",
                    cursor: "pointer"
                  }}
                    onClick={() => {
                      setCategories([])
                      setCourses([]);
                      setPage(1);
                      setHasMore(true);
                      setCategoryId([])
                      SetHidBanner(false)
                      setUserSearchData([])
                      setAutocompleteSearchedValue(null)
                      setSelectedSuggestion(null)
                    }}>
                    Home
                  </div>
                  {isCategories[0]?.subCategoriesName ? (<>
                    {isCategories?.map((item, index) => (
                      <div key={index} style={{ display: "flex", gap: 5 }}>
                        {item?.categoriesName ?
                          <>
                            <img src={require('../../Assets/aulasMarket/chevron-right1.svg').default} />
                            <div style={{
                              height: 22,
                              backgroundColor: '#DFDEF5',
                              borderRadius: "80px",
                              textAlign: "center",
                              border: "1px solid #CDC6E0",
                              fontSize: 12,
                              color: "#191919",
                              padding: "0 10px",
                              whiteSpace: "nowrap",
                              flex: "none"
                            }}>
                              {item?.categoriesName}
                            </div>
                            <img src={require('../../Assets/aulasMarket/chevron-right1.svg').default} />
                          </>
                          : null}
                        {item?.subCategoriesName ?
                          <div style={{
                            height: 22,
                            backgroundColor: '#594099',
                            borderRadius: "80px",
                            textAlign: "center",
                            border: "1px solid #594099",
                            fontSize: 12,
                            color: "#fff",
                            padding: "0 10px", // Add padding for better spacing inside
                            whiteSpace: "nowrap", // Prevent text wrapping
                            flex: "none" // Let the div take the width of the content
                          }}>
                            {item?.subCategoriesName}
                          </div> : null}
                      </div>
                    ))}
                  </>) : (<></>)}

                </div>
              </div> : <div style={{ marginTop: 20 }}></div>}
              <div className='mobile-categories'>
                <div style={{ width: '100%', display: "flex", gap: 10 }}>
                  <div style={{ width: '100%', border: '1px solid #CDC6E0', height: 40, borderRadius: 10, position: 'relative' }}>
                    <div style={{ display: "flex", width: '100%' }}>
                      <div className='categories-container-list'>
                        <div style={{ display: "flex", justifyContent: "center", marginTop: 7, width: '100%' }}>
                          {userMarketPlaceHomeData?.categories?.length > 0 ?
                            <Dropdown overlay={userCategoryMenu} trigger={['hover']} open={isCategoryClicked}>
                              <div className='categories-title' onClick={(e) => {
                                if (isFromAdminPreview) { }
                                else {
                                  e.stopPropagation(); setIsCategoryClicked(!isCategoryClicked); setIsProfileClicked(false)
                                }
                              }}>Categories <img src={require('../../Assets/aulasMarket/chevronDown.svg').default} /></div>
                            </Dropdown> : <div></div>}
                        </div>
                      </div>
                      <div style={{ marginLeft: 10, width: "100%", marginTop: -2 }} onClick={() => { SetHidBanner(true) }}>
                        <AutoComplete
                          bordered={false}
                          allowClear
                          popupClassName="certain-category-search-dropdown "
                          style={{ marginRight: 20, width: "100%" }}
                          options={(userSearchDataContainer?.suggestions?.length > 0 || userSearchDataContainer?.resources?.length) ? searchOptions : []}
                          onSearch={handleAutocompleteSearch}
                          onClear={() => {
                            setSelectedSuggestion(null);
                            setAutocompleteSearchedValue(null);
                            setSelectedSuggestion(null)
                            setIsSearch(false)
                            // history.push("/home")
                          }}
                          value={selectedSuggestion ? selectedSuggestion : autocompleteSearchedValue ? autocompleteSearchedValue : null}
                          placeholder={<span><SearchOutlined /> Search</span>}
                          loading={searchLoader}
                          className=' search-width '
                          disabled={isFromAdminPreview ? true : false}
                        >
                        </AutoComplete>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: 3, cursor: "pointer" }} onClick={() => {
                    setViewType(!viewType)
                  }}>
                    {!viewType ?
                      <img src={require('../../Assets/aulasMarket/gridViewMobile.svg').default} style={{ height: 30, width: 30 }} /> :
                      <img src={require('../../Assets/aulasMarket/listViewMobile.svg').default} style={{ height: 30, width: 30 }} />}
                  </div>
                </div>
              </div>
              <div className='categories-parent' style={{ width: "100%", }}>
                {/* <div>{searchResult ? <>hi</>:<></>}</div> */}
                {console.log("isSearch12", isSearch)}
                {/* <div>{isSearch ? (courses?.length != 0 ? <div>{storeCourseData?.meta?.totalItems}results for "{selectedSuggestion}"{console.log("courses?.meta?.totalItems",storeCourseData?.meta?.totalItems)}</div>:null):(<></>)} </div> */}
                <div style={{ width: '100%', display: "flex", justifyContent: "flex-end", gap: 20 }}>
                  {filteredOptions?.map((item) => {
                    return (
                      <>
                        <div key={item}
                          onClick={() => handleOptionClick(item)}
                          style={{
                            width: 150, height: 40, borderRadius: 80, border: selectedOption === item ? '' : '1px solid #CDC6E0', backgroundColor: selectedOption === item ? '#594099' : '',
                            cursor: 'pointer'
                          }}>
                          <div style={{ textAlign: "center", color: selectedOption === item ? '#fff' : '#191919', marginTop: 6, fontSize: "16px", fontWeight: 400 }}>{item}</div>
                        </div>
                      </>
                    )
                  })}
                </div>
              </div>
              <div className='mobile-filter-container'>
                <div style={{
                  width: '100%',
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: 20,
                  overflowX: 'auto',
                  whiteSpace: 'nowrap',
                  scrollbarWidth: 'none',
                }}>
                  {filteredOptions?.map((item) => {
                    return (
                      <div key={item}
                        onClick={() => handleOptionClick(item)}
                        style={{
                          display: 'inline-block', // Keeps the items inline for sliding
                          marginTop: 5,
                          height: 35,
                          borderRadius: 80,
                          border: '1px solid #CDC6E0',
                          backgroundColor: selectedOption === item ? '#594099' : '',
                          cursor: 'pointer',
                          textAlign: 'center',

                        }}>
                        <div style={{ width: 140 }}>
                          <div style={{
                            color: selectedOption === item ? '#fff' : '#191919',
                            marginTop: 6,
                            fontSize: "15x",
                            fontWeight: 400
                          }}>
                            {item}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {/* </AnimatedSection> */}
              {!viewType ?
                <>
                  <div className='courses-card-container course-cards-blocks' >
                    {courses.map((course, index) => (
                      <div key={index} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <NewCoursesCards item={course} setShowDownloadAppModal={setShowDownloadAppModal} />
                      </div>
                    ))}
                    <iframe id="receiver-iframe" src="http://localhost:3001/course-cards" style={{ display: 'none' }} />

                  </div>
                  {
                    hideNoData ? (
                      // If categories exist
                      <>
                        {isSearch ? (
                          // If search is true
                          <>
                            {courses?.length === 0 && selectedSuggestion !== null ? (
                              <div className='categories-parent'>
                                <div style={{ fontSize: '2rem', color: '#191919', fontWeight: 500 }}>
                                  Sorry, we couldn't find any results for "{selectedSuggestion}"
                                </div>
                                <div style={{ fontSize: '1.2rem', color: '#191919', fontWeight: 500 }}>
                                  Try adjusting your search. Here are some ideas:
                                </div>
                                <ul>
                                  <li style={{ color: '#191919', fontSize: '15px' }}>Make sure all words are spelled correctly</li>
                                  <li style={{ color: '#191919', fontSize: '15px' }}>Try different search terms</li>
                                  <li style={{ color: '#191919', fontSize: '15px' }}>Try more general search terms</li>
                                </ul>
                              </div>
                            )
                              :
                              (
                                // If search is not true, but categories exist
                                courses?.length === 0 && (
                                  <div
                                    className="r-c-c-c"
                                    style={{ width: "100%", height: "30vh", marginTop: "-50px" }}
                                  >
                                    <div /* style={{top:"265px", left: "1407px", width:"180px", height:"156px" }} */
                                    >
                                      <img src={illNoData} />
                                    </div>
                                    <div>
                                      <h4>
                                        <b>No courses</b>
                                      </h4>
                                    </div>
                                    <div>
                                      <h5>There are no data to show yet. Please check back later</h5>
                                    </div>
                                  </div>
                                )
                              )
                            }
                          </>
                        ) : (
                          // If search is not true, but categories exist
                          courses?.length === 0 && (
                            <div
                              className="r-c-c-c categories-parent"
                              style={{ width: "100%", height: "30vh", marginTop: "-50px" }}
                            >
                              <div /* style={{top:"265px", left: "1407px", width:"180px", height:"156px" }} */
                              >
                                <img src={illNoData} />
                              </div>
                              <div>
                                <h4>
                                  <b>No courses</b>
                                </h4>
                              </div>
                              <div>
                                <h5>There are no data to show yet. Please check back later</h5>
                              </div>
                            </div>
                          )
                        )}
                      </>
                    ) : (
                      // If no categories, check for search
                      isSearch && courses?.length === 0 && (
                        <div className='categories-parent'>
                          <div style={{ fontSize: '2rem', color: '#191919', fontWeight: 500 }}>
                            Sorry, we couldn't find any results for "{selectedSuggestion}"
                          </div>
                          <div style={{ fontSize: '1.2rem', color: '#191919', fontWeight: 500 }}>
                            Try adjusting your search. Here are some ideas:
                          </div>
                          <ul>
                            <li style={{ color: '#191919', fontSize: '15px' }}>Make sure all words are spelled correctly</li>
                            <li style={{ color: '#191919', fontSize: '15px' }}>Try different search terms</li>
                            <li style={{ color: '#191919', fontSize: '15px' }}>Try more general search terms</li>
                          </ul>
                        </div>
                      )
                    )
                  }

                  {/* {isSearch ? (<>{
                  courses?.length == 0 ? (<>
                    <div className='categories-parent'>
                      <div style={{ fontSize: '2rem', color: '#191919', fontWeight: 500 }}>Sorry, we couldn't find any results for "{selectedSuggestion}"</div>
                      <div style={{ fontSize: '1.2rem', color: '#191919', fontWeight: 500 }}>Try adjusting your search. Here are some ideas:</div>
                      <ul>
                        <li style={{ color: '#191919', fontSize: '15px' }}>Make sure all words are spelled correctly</li>
                        <li style={{ color: '#191919', fontSize: '15px' }}>Try different search terms</li>
                        <li style={{ color: '#191919', fontSize: '15px' }}>Try more general search terms</li>

                      </ul>
                    </div>
                  </>) : <></>}
                </>) : (<></>)} */}
                  <div className='mobile-newly-launched-parent' >
                    {courses.map((course, index) => (
                      <div key={index} style={{ width: '100%', justifyContent: 'center', marginTop: 10 }}>
                        <MobileViewGridCards item={course} setShowDownloadAppModal={setShowDownloadAppModal} />
                      </div>
                    ))}
                  </div>
                  <div className='mobile-newly-launched-parent'>
                    {
                      hideNoData ? (
                        // If categories exist
                        <>
                          {isSearch ? (
                            // If search is true
                            <>
                              {courses?.length === 0 && selectedSuggestion != null ? (
                                <div className='mobile-newly-launched-parent'>
                                  <div style={{ fontSize: '15px', color: '#191919', fontWeight: 500 }}>
                                    Sorry, we couldn't find any results for "{selectedSuggestion}"
                                  </div>
                                  <div style={{ fontSize: '13px', color: '#191919', fontWeight: 500 }}>
                                    Try adjusting your search. Here are some ideas:
                                  </div>
                                  <ul>
                                    <li style={{ color: '#191919', fontSize: '12px' }}>Make sure all words are spelled correctly</li>
                                    <li style={{ color: '#191919', fontSize: '12px' }}>Try different search terms</li>
                                    <li style={{ color: '#191919', fontSize: '12px' }}>Try more general search terms</li>
                                  </ul>
                                </div>
                              )
                                :
                                (
                                  <div
                                    className="r-c-c-c mobile-newly-launched-parent"
                                    style={{ width: "100%", height: "30vh" }}
                                  >
                                    <div style={{ textAlign: "center" }}
                                    >
                                      <img src={illNoData} />
                                    </div>
                                    <div style={{ textAlign: "center" }}>
                                      <h4>
                                        <b>No courses</b>
                                      </h4>
                                    </div>
                                    <div style={{ textAlign: "center" }}>
                                      <h5>There are no data to show yet. Please check back later</h5>
                                    </div>
                                  </div>
                                )
                              }
                            </>
                          ) : (
                            // If search is not true, but categories exist
                            courses?.length === 0 && (
                              <div
                                className="r-c-c-c mobile-newly-launched-parent"
                                style={{ width: "100%", height: "30vh" }}
                              >
                                <div style={{ textAlign: "center" }}
                                >
                                  <img src={illNoData} />
                                </div>
                                <div style={{ textAlign: "center" }}>
                                  <h4>
                                    <b>No courses</b>
                                  </h4>
                                </div>
                                <div style={{ textAlign: "center" }}>
                                  <h5>There are no data to show yet. Please check back later</h5>
                                </div>
                              </div>
                            )
                          )}
                        </>
                      ) : (
                        // If no categories, check for search
                        isSearch && courses?.length === 0 && (
                          <div className='mobile-newly-launched-parent'>
                            <div style={{ fontSize: '16px', color: '#191919', fontWeight: 500 }}>
                              Sorry, we couldn't find any results for "{selectedSuggestion}"
                            </div>
                            <div style={{ fontSize: '13px', color: '#191919', fontWeight: 500 }}>
                              Try adjusting your search. Here are some ideas:
                            </div>
                            <ul>
                              <li style={{ color: '#191919', fontSize: '12px' }}>Make sure all words are spelled correctly</li>
                              <li style={{ color: '#191919', fontSize: '12px' }}>Try different search terms</li>
                              <li style={{ color: '#191919', fontSize: '12px' }}>Try more general search terms</li>
                            </ul>
                          </div>
                        )
                      )
                    }
                  </div>
                  {/* {isSearch ? (<>{
                  courses?.length == 0 ? (<>
                    <div className='mobile-newly-launched-parent'>
                      <div style={{ fontSize: '15px', color: '#191919', fontWeight: 500 }}>Sorry, we couldn't find any results for "{selectedSuggestion}"</div>
                      <div style={{ fontSize: '13px', color: '#191919', fontWeight: 500 }}>Try adjusting your search. Here are some ideas:</div>
                      <ul>
                        <li style={{ color: '#191919', fontSize: '12px' }}>Make sure all words are spelled correctly</li>
                        <li style={{ color: '#191919', fontSize: '12px' }}>Try different search terms</li>
                        <li style={{ color: '#191919', fontSize: '12px' }}>Try more general search terms</li>
                      </ul>
                    </div>
                  </>) : <></>}
                </>) : (<></>)} */}
                </>
                : (
                  <>
                    <div className='courses-card-container-list ' >
                      {courses.map((course, index) => (
                        <div className='course-cards-blocks' key={index} style={{ width: '100%', justifyContent: 'center', marginBottom: 20 }}>
                          {/* <NewCoursesCards item={course} /> */}
                          <NewListViewCards item={course} setShowDownloadAppModal={setShowDownloadAppModal} />
                        </div>
                      ))}
                    </div>
                    {
                      hideNoData ? (
                        // If categories exist
                        <>
                          {isSearch ? (
                            // If search is true
                            <>
                              {courses?.length === 0 && (
                                <div className='categories-parent'>
                                  <div style={{ fontSize: '2rem', color: '#191919', fontWeight: 500 }}>
                                    Sorry, we couldn't find any results for "{selectedSuggestion}"
                                  </div>
                                  <div style={{ fontSize: '1.2rem', color: '#191919', fontWeight: 500 }}>
                                    Try adjusting your search. Here are some ideas:
                                  </div>
                                  <ul>
                                    <li style={{ color: '#191919', fontSize: '15px' }}>Make sure all words are spelled correctly</li>
                                    <li style={{ color: '#191919', fontSize: '15px' }}>Try different search terms</li>
                                    <li style={{ color: '#191919', fontSize: '15px' }}>Try more general search terms</li>
                                  </ul>
                                </div>
                              )}
                            </>
                          ) : (
                            // If search is not true, but categories exist
                            courses?.length === 0 && (
                              <div
                                className="r-c-c-c"
                                style={{ width: "100%", height: "30vh", marginTop: "-50px" }}
                              >
                                <div /* style={{top:"265px", left: "1407px", width:"180px", height:"156px" }} */
                                >
                                  <img src={illNoData} />
                                </div>
                                <div>
                                  <h4>
                                    <b>No courses</b>
                                  </h4>
                                </div>
                                <div>
                                  <h5>There are no data to show yet. Please check back later</h5>
                                </div>
                              </div>
                            )
                          )}
                        </>
                      ) : (
                        // If no categories, check for search
                        isSearch && courses?.length === 0 && (
                          <div className='categories-parent'>
                            <div style={{ fontSize: '2rem', color: '#191919', fontWeight: 500 }}>
                              Sorry, we couldn't find any results for "{selectedSuggestion}"
                            </div>
                            <div style={{ fontSize: '1.2rem', color: '#191919', fontWeight: 500 }}>
                              Try adjusting your search. Here are some ideas:
                            </div>
                            <ul>
                              <li style={{ color: '#191919', fontSize: '15px' }}>Make sure all words are spelled correctly</li>
                              <li style={{ color: '#191919', fontSize: '15px' }}>Try different search terms</li>
                              <li style={{ color: '#191919', fontSize: '15px' }}>Try more general search terms</li>
                            </ul>
                          </div>
                        )
                      )
                    }
                    <div className='mobile-newly-launched-parent'>
                      {courses.map((course, index) => (
                        <div className='course-cards-blocks' key={index} style={{ width: '100%', justifyContent: 'center', marginBottom: 20 }}>
                          <MobileViewListCards item={course} setShowDownloadAppModal={setShowDownloadAppModal} />
                        </div>
                      ))}
                    </div>
                    <div className='mobile-newly-launched-parent'>
                      {
                        hideNoData ? (
                          // If categories exist
                          <>
                            {isSearch ? (
                              // If search is true
                              <>
                                {courses?.length === 0 && (
                                  <div className='mobile-newly-launched-parent'>
                                    <div style={{ fontSize: '15px', color: '#191919', fontWeight: 500 }}>
                                      Sorry, we couldn't find any results for "{selectedSuggestion}"
                                    </div>
                                    <div style={{ fontSize: '13px', color: '#191919', fontWeight: 500 }}>
                                      Try adjusting your search. Here are some ideas:
                                    </div>
                                    <ul>
                                      <li style={{ color: '#191919', fontSize: '12px' }}>Make sure all words are spelled correctly</li>
                                      <li style={{ color: '#191919', fontSize: '12px' }}>Try different search terms</li>
                                      <li style={{ color: '#191919', fontSize: '12px' }}>Try more general search terms</li>
                                    </ul>
                                  </div>
                                )}
                              </>
                            ) : (
                              // If search is not true, but categories exist
                              courses?.length === 0 && (
                                <div
                                  className="r-c-c-c mobile-newly-launched-parent"
                                  style={{ width: "100%", height: "30vh" }}
                                >
                                  <div style={{ textAlign: "center" }}
                                  >
                                    <img src={illNoData} />
                                  </div>
                                  <div style={{ textAlign: "center" }}>
                                    <h4>
                                      <b>No courses</b>
                                    </h4>
                                  </div>
                                  <div style={{ textAlign: "center" }}>
                                    <h5>There are no data to show yet. Please check back later</h5>
                                  </div>
                                </div>
                              )
                            )}
                          </>
                        ) : (
                          // If no categories, check for search
                          isSearch && courses?.length === 0 && (
                            <div className='mobile-newly-launched-parent'>
                              <div style={{ fontSize: '16px', color: '#191919', fontWeight: 500 }}>
                                Sorry, we couldn't find any results for "{selectedSuggestion}"
                              </div>
                              <div style={{ fontSize: '13px', color: '#191919', fontWeight: 500 }}>
                                Try adjusting your search. Here are some ideas:
                              </div>
                              <ul>
                                <li style={{ color: '#191919', fontSize: '12px' }}>Make sure all words are spelled correctly</li>
                                <li style={{ color: '#191919', fontSize: '12px' }}>Try different search terms</li>
                                <li style={{ color: '#191919', fontSize: '12px' }}>Try more general search terms</li>
                              </ul>
                            </div>
                          )
                        )
                      }
                    </div>
                  </>
                )}
              {isLoading && (
                <div className='r-c-c-c' style={{ height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <img src={require('../../Assets/gifs/section-loader.gif').default} style={{ width: 200 }} />
                </div>
              )}
              {hasMore && (
                <div className='loading-indicator' style={{ height: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {/* Add loading text or any content if needed */}
                </div>
              )}
              <motion.div
                ref={ref1}
                initial={{ opacity: 0, y: 50 }}
                animate={controls}
              >
                {!hideBanner ? (
                  <>
                    {userMarketPlaceHomeData?.outStandingResultDataArray?.length > 0 ? (
                      <>
                        <div className={userMarketPlaceHomeData?.topCategories?.length > 4 ? 'home-outstanding-parent1' : 'home-outstanding-parent'}>
                          <div className='mobile-title' style={{ color: '#191919', fontWeight: 'bold', fontSize: 20 }}>Outstanding Results</div>
                          {userMarketPlaceHomeData?.outStandingResultDataArray?.length > 3 ? (
                            <Slider ref={outStandingSliderRef} {...outstandingCardsSettings} className='home-outstanding-slider'>
                              {userMarketPlaceHomeData?.outStandingResultDataArray?.map((item) => (
                                <OutstandingResultsCard item={item} />
                              ))}
                            </Slider>
                          ) : userMarketPlaceHomeData?.outStandingResultDataArray?.length == 1 ? (
                            <div style={{ display: 'flex', width: '100%' }}>
                              {userMarketPlaceHomeData?.outStandingResultDataArray?.map((item) => (
                                <OutstandingResultsCard item={item} isOne={true} />
                              ))}
                            </div>
                          ) : userMarketPlaceHomeData?.outStandingResultDataArray?.length == 2 ? (
                            <div style={{ display: 'flex', gap: 40, width: '100%' }}>
                              {userMarketPlaceHomeData?.outStandingResultDataArray?.map((item) => (
                                <OutstandingResultsCard item={item} isTwo={true} />
                              ))}
                            </div>
                          ) : (
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                              {userMarketPlaceHomeData?.outStandingResultDataArray?.map((item) => (
                                <OutstandingResultsCard item={item} isThree={true} />
                              ))}
                            </div>
                          )}
                        </div>
                        <div className='mobile-outstanding-parent'>
                          <div className='mobile-title' style={{ color: '#191919', fontWeight: 'bold', fontSize: 20 }}>Outstanding Results</div>
                          <div className='scroll-container m-t-20'>
                            {userMarketPlaceHomeData?.outStandingResultDataArray?.map((item) => (
                              <OutstandingResultsCard item={item} />
                            ))}
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : null}
              </motion.div>
              <motion.div
                ref={ref2}
                initial={{ opacity: 0, y: 50 }}
                animate={controls2}
              >
                {!hideBanner ? (
                  <>
                    {userMarketPlaceHomeData?.testimonialsData?.length > 0 ? (
                      <>
                        <div className='home-learners-parent'>
                          <div className='mobile-title' style={{ color: '#191919', fontWeight: 'bold', fontSize: 20 }}>Listen from our Learners</div>
                          {userMarketPlaceHomeData?.testimonialsData?.length > 3 ? (
                            <Slider ref={learnersSliderRef} {...learnersCardsSettings} className='home-outstanding-slider'>
                              {userMarketPlaceHomeData?.testimonialsData?.map((item) => (
                                <LearnersCard item={item} />
                              ))}
                            </Slider>
                          ) : userMarketPlaceHomeData?.testimonialsData?.length == 1 ? (
                            <div style={{ display: 'flex', width: '100%' }}>
                              {userMarketPlaceHomeData?.testimonialsData?.map((item) => (
                                <LearnersCard item={item} isOne={true} />
                              ))}
                            </div>
                          ) : userMarketPlaceHomeData?.testimonialsData?.length == 2 ? (
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                              {userMarketPlaceHomeData?.testimonialsData?.map((item) => (
                                <LearnersCard item={item} isTwo={true} />
                              ))}
                            </div>
                          ) : (
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                              {userMarketPlaceHomeData?.testimonialsData?.map((item) => (
                                <LearnersCard item={item} isThree={true} />
                              ))}
                            </div>
                          )}
                        </div>
                        <div className='mobile-learners-parent'>
                          <div className='mobile-title' style={{ color: '#191919', fontWeight: 'bold', fontSize: 20 }}>Listen from our Learners</div>
                          <div className='scroll-container m-t-20 m-r-20'>
                            {userMarketPlaceHomeData?.testimonialsData?.map((item) => (
                              <LearnersCard item={item} isMobile={true} />
                            ))}
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : null}
              </motion.div>
            </div>
            <div style={{ position: "relative",zIndex:100}}>
              <NewFooter />
            </div>
            <PageDataLoader visible={pageLoader} />
            {isAdmissionModalVisible &&
              <AdmissionEnquiryForm
                isAdmissionModalVisible={isAdmissionModalVisible}
                setIsAdmissionModalVisible={setIsAdmissionModalVisible} />}
            {showDownloadAppModal &&
              <DownloadAppModal
                showDownloadAppModal={showDownloadAppModal}
                setShowDownloadAppModal={setShowDownloadAppModal}
                userMarketPlaceHomeData={userMarketPlaceHomeData}
              />}
          </motion.div>


          {/* {user?.accessToken ? null : (
            <div
              onClick={() => {
                setIsAdmissionModalVisible(true);
              }}
              style={{
                position: 'fixed',
                right: '3%',
                bottom: '10%',
                zIndex: isFooterVisible ? -1 : 1,
                cursor: 'pointer',
              }}
            >
              <img
                src={require('../../Assets/aulasMarket/contactUs.svg').default}
                height={70}
                width={70}
              />
            </div>
          )} */}


      
        </>
        :
        <></>
      }
    </>
  )
}
const mapStateToProps = (state) => {
  const { userMarketPlaceHomeData, offersCarouselData, marketSettingData, isLogoutClicked, isDownloadBarVisible, showContactBar, isOffersBarVisible, selectedSuggestion, storeCourseData } = state.studentMarketPlace;
  const { user } = state.session
  const { courseEnrolledId } = state.loginNew
  const { isFromAdminPreview } = state.courseManagement;

  return { userMarketPlaceHomeData, offersCarouselData, user, marketSettingData, isLogoutClicked, isDownloadBarVisible, showContactBar, isOffersBarVisible, isFromAdminPreview, selectedSuggestion, storeCourseData, courseEnrolledId };
};

const mapDispatchToProps = (dispatch) => ({
  getUserMarketPlaceHomeData: (noCourse, callBack) => dispatch(getUserMarketPlaceHomeData(noCourse, callBack)),
  getUserMarketPlaceCoursesData: (requestsParams, callBack) => dispatch(getUserMarketPlaceCoursesData(requestsParams, callBack)),
  getOffersCarouselData: () => dispatch(getOffersCarouselData()),
  postHomeReachUsForm: (reachUsParams, callback, successCallback) => dispatch(postHomeReachUsForm(reachUsParams, callback, successCallback)),
  getAllCoursesData: (token, fromHomePage, cId, callback) => dispatch(getAllCoursesData(token, fromHomePage, cId, callback)),
  setParentLink: (item) => dispatch(setParentLink(item)),
  getMarketSettingsData: (all, callBack) => dispatch(getMarketSettingsData(all, callBack)),
  showMenu: (bool) => dispatch(showMenu(bool)),
  getCartCount: () => dispatch(getCartCount()),
  storeCartCountData: (val) => dispatch(storeCartCountData(val)),
  setIsLogoutClicked: (val) => dispatch(setIsLogoutClicked(val)),
  setStorePromoPrice: (val) => dispatch(setStorePromoPrice(val)),
  setOffersBarVisible: (val) => dispatch(setOffersBarVisible(!!val)),
  getUserCourseAutocompleteSearch: (search, callBack) => dispatch(getUserCourseAutocompleteSearch(search, callBack)),
  setSelectedSuggestion: (val) => dispatch(setSelectedSuggestion(val)),
  setCourseData: (val) => dispatch(setCourseData(val)),
  setShowContactBar: (val) => dispatch(setShowContactBar(val)),
  setShowLoginModal: (val) => dispatch(setShowLoginModal(val)),
  setShowBottomSheet: (val) => dispatch(setShowBottomSheet(val)),
  setEnrolledItemId: (val) => dispatch(setEnrolledItemId(val)),
  impressionEngagementIncrement: (params, callback) => dispatch(impressionEngagementIncrement(params, callback)),
  postCourseEnrollNow: (id, itemType, callback) => dispatch(postCourseEnrollNow(id, itemType, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(UserMarketPlaceHome);