import Axios from "axios";
import moment from "moment";
import fileDownload from "js-file-download";
import { Api, Notification } from "../services";


export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
export const LIST_OF_USERS = 'LIST_OF_USERS';
export const SET_ROLE = 'SET_ROLE';
export const ADD_USERS_FIELDS_DATA = 'ADD_USERS_FIELDS_DATA';
export const FETCH_CLASS_LIST = 'FETCH_CLASS_LIST';
export const STORE_EXCEL_DETAILS = 'STORE_EXCEL_DETAILS';
export const STORE_EXCEL_FILEID = 'STORE_EXCEL_FILEID';
export const STORE_PENDING_USERS = 'STORE_PENDING_USERS';
export const STORE_EXCEL_DATA = 'STORE_EXCEL_DATA'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const SET_SPECIFIC_USER_ID = 'SET_SPECIFIC_USER_ID'
export const DELETED_USERS_LIST = 'DELETED_USERS_LIST'
export const UPDATE_USER_CONTENT = 'UPDATE_USER_CONTENT'
export const UPDATE_USER_ALL_DATA = 'UPDATE_USER_ALL_DATA'
export const UPDATE_SEARCHED_TEACHERS = 'UPDATE_SEARCHED_TEACHERS'
export const SET_TRANSFERDATA_VALUE = 'SET_TRANSFERDATA_VALUE'
export const UPDATE_USER_TO_TRANSFER_DATA = 'UPDATE_USER_TO_TRANSFER_DATA'
export const STORE_STANDARDS = 'STORE_STANDARDS'
export const SET_USERRECORD = 'SET_USERRECORD'
export const SET_COURSES = 'SET_COURSES'
export const STUDENT_ROLL_NO = 'STUDENT_ROLL_NO'
export const STORE_ALL_COURSES = 'STORE_ALL_COURSES'
export const STORE_COURSE_STANDARDS = 'STORE_COURSE_STANDARDS'
export const SET_SELECTED_COURSE = 'SET_SELECTED_COURSE'
export const SET_SELECTED_STANDARD = 'SET_SELECTED_STANDARD'
export const STORE_STUDENTS_WRT_COURSETYPE = 'STORE_STUDENTS_WRT_COURSETYPE'
export const STORE_USER_RESOURCES = 'STORE_USER_RESOURCES'
export const SET_STUDENT_WITHOUT_DATA = 'SET_STUDENT_WITHOUT_DATA'
export const SET_USERIDS = 'SET_USERIDS'
export const STORE_AUTOCOMPLETE_COURSES_LIST = 'STORE_AUTOCOMPLETE_COURSES_LIST'

export const dashboard = res => ({
        type: GET_DASHBOARD_DATA,
        res
});

export const listOfUsers = res => ({
        type: LIST_OF_USERS,
        res
});

export const setRole = (key, val) => ({
        type: SET_ROLE,
        key, val
});

export const addUserFieldsData = (key, val) => ({
        type: ADD_USERS_FIELDS_DATA,
        key, val
});

export const fetchClassList = res => ({
        type: FETCH_CLASS_LIST,
        res
});

export const storeAutocompleteCoursesList = res => ({
        type: STORE_AUTOCOMPLETE_COURSES_LIST,
        res
});

export const storeExcelUploadDetails = (res) => ({
        type: STORE_EXCEL_DETAILS,
        res
});

export const storeDownloadTemplateId = (id) => ({
        type: STORE_EXCEL_FILEID,
        id
});

export const storePendingStatus = (res) => ({
        type: STORE_PENDING_USERS,
        res
});

export const storeExcelData = (res) => ({
        type: STORE_EXCEL_DATA,
        res
});

export const updateProfile = (res) => ({
        type: UPDATE_PROFILE,
        res,
});

export const setSpecificUserId = (id) => ({
        type: SET_SPECIFIC_USER_ID,
        id
});

export const fetchDeletedUsersList = res => ({
        type: DELETED_USERS_LIST,
        res
});

const updateUserContent = (data, dataType) => ({
        type: UPDATE_USER_CONTENT,
        data, dataType
});

const updateUserAllData = (data) => ({
        type: UPDATE_USER_ALL_DATA,
        data
});

const updateSearchedTeachers = (data) => ({
        type: UPDATE_SEARCHED_TEACHERS,
        data
});

export const setTranseferDataValue = (res) => ({
        type: SET_TRANSFERDATA_VALUE,
        res
});

export const updateUserToTranferData = (data) => ({
        type: UPDATE_USER_TO_TRANSFER_DATA,
        data
});

export const storeStandards = (res) => ({
        type: STORE_STANDARDS,
        res
});

export const setUserDetails = res => ({
        type: SET_USERRECORD,
        res
});

export const storeCourses = res => ({
        type: SET_COURSES,
        res
});

export const storeStudentRollNo = rollNo => ({
        type: STUDENT_ROLL_NO,
        rollNo
});

export const storeAllCourses = res => ({
        type: STORE_ALL_COURSES,
        res
});

export const storeCourseStandards = res => ({
        type: STORE_COURSE_STANDARDS,
        res
})

export const setSelectedCourse = res => ({
        type: SET_SELECTED_COURSE,
        res
});

export const setSelectedStandard = res => ({
        type: SET_SELECTED_STANDARD,
        res
})

export const storeStudentsWRTCoutseType = res => ({
        type: STORE_STUDENTS_WRT_COURSETYPE,
        res
})

export const storeUserResources = res => ({
        type: STORE_USER_RESOURCES,
        res
})

export const setStudentWithoutData = res => ({
        type: SET_STUDENT_WITHOUT_DATA,
        res
})

export const setUserids = userIds => ({
        type: SET_USERIDS,
        userIds
})


export const postUrlBoilerPlate = (params, callback, successCallback) => {
        return (dispatch, getState) => {
                const url = ``;
                const { user } = getState().session;
                return Api.post(url).params(params).send((response) => {
                        if (response && response.show.type === "success") {
                                successCallback();
                        }
                        callback();
                });
        };
};

export const getDashboard = (params, callBack) => {
        return (dispatch, getState) => {
                const url = `/user/userStats`;
                return (
                        Api.get(url).params(params).send((response) => {
                                if (response !== undefined) {
                                        dispatch(dashboard(response))
                                }
                                callBack();
                        })
                );
        };
};

export const getUsers = (params, callBack) => {
        return (dispatch, getState) => {
                const url = `/user/users`;
                return (
                        Api.get(url).params(params).send((response) => {
                                if (response !== undefined) {
                                        dispatch(listOfUsers(response))
                                }
                                callBack();
                        })
                );
        };
};

export const updateRegistrationSettings = (params, callback = null) => {
        return (dispatch, getState) => {
                const url = '/user/addUserSettings'
                return Api.post(url).params(params).send((response) => {
                        if (response !== undefined) {
                                dispatch(dashboard(response))
                        }
                        callback();
                });
        };
};

export const addUserByRole = (callback = null, successCallback) => {
        return (dispatch, getState) => {
                const { addUserFields } = getState().userManagementV2;

                const formData = new FormData();

                formData.append('name', addUserFields?.name || '');
                formData.append('email', addUserFields?.email || '');
                formData.append('phone', addUserFields?.phone || '');
                formData.append('role', addUserFields?.role || '');
                formData.append('image', addUserFields?.file || null);

                if (addUserFields?.role === "Student" && addUserFields?.course) {
                        formData.append('courseId', addUserFields?.course);
                        formData.append('courseType',addUserFields?.courseType || 'course');
                } else if (addUserFields?.privilage?.length) {
                        addUserFields.privilage.forEach(value => {
                                formData.append('privilage[]', value);
                        });
                }

                const url = '/user/addUserV2'

                return Api.post(url).params(formData).send(response => {
                        callback(response);
                        if (response !== undefined ) {
                                dispatch(setStudentsListOfStd(response));
                                successCallback();
                        }
                     
                });
        };
};


export const fetchStudentsClassList = (params, callBack = null) => {
        return (dispatch, getState) => {
                const url = `/user/courses`;
                return (
                        Api.get(url).params(params).send((response) => {
                                if (response !== undefined) {
                                        dispatch(fetchClassList(response));
                                }
                                callBack();
                        })
                );
        };
};

export const fetchAutocompleteCoursesList = (params, callBack = null) => {
        return (dispatch, getState) => {
                return Api.get(`/course/autocomplete-resources`).params({ ...(params || {}), search:'', names: true, emptySearch: params.emptySearch || false }).send((response) => {
                        if (response !== undefined && response?.show?.type != 'error') {
                            if (callBack) {
                                callBack()
                            }
                            if (response !== undefined && response?.show?.type != 'error') {
                                dispatch(storeAutocompleteCoursesList(response))
                            }
                        }
                })
        };
};

export const downloadTemplate = (callback) => {
        return (dispatch, getState) => {
                return Api.get(`/user/getExcel`).params().send((response) => {
                        if (response !== undefined) {
                                if (callback) {
                                        callback();
                                }
                                const fileName = 'excel-template.xlsx';
                                Axios.get(response.fileName, {
                                        responseType: "blob",
                                }).then((res) => {
                                        fileDownload(res.data, fileName);
                                });
                        };
                });
        };
};

export const uploadBulkUsers = (callback = null, successCallBack) => {
        return (dispatch, getState) => {
                const { excelFileId, excelFile } = getState().userManagementV2;
                const params = {
                        excel: excelFile
                };

                return Api.post("/user/addUsers").upload(params, (percent, response) => {
                        if (response !== undefined) {
                                dispatch(storeDownloadTemplateId(response?.data?.fileId));
                                dispatch(storePendingStatus(response?.data?.data))
                                if (percent == 100) {
                                        callback();
                                        if (successCallBack) {
                                                successCallBack();
                                        };
                                };
                        };
                })
        };
};

export const fetchExcelData = (callback) => {

        return (dispatch, getState) => {
                const { excelFileId } = getState().userManagementV2;
                return Api.get(`/user/get-excel-status?fileId=${excelFileId}`).send((response) => {
                        if (response !== undefined) {
                                if (callback) {
                                        callback();
                                };
                                dispatch(storeExcelData(response));
                        };
                });
        };
};

export const deleteExcelOnComplete = () => {
        return (dispatch, getState) => {
                const { excelFileId } = getState().userManagementV2;
                return Api.delete(`/user/delete-excel-users?fileId=${excelFileId}`).send((response) => {
                        if (response !== undefined) { };
                });
        };
};

export const fetchSpecificUserProfile = (id, callback) => {
        return (dispatch) => {
                const url = `/user/userId/${id}`;
                return Api.get(url).send((response, error) => {
                        if (response != undefined) {
                                dispatch(updateProfile(response));
                        };
                        callback();
                });
        };
};

export const updateUserRegistrations = (params, callback, successCallback) => {
        return (dispatch, getState) => {
                const url = `/user/updateRegistrationStatuses`;
                const { user } = getState().session;
                return Api.post(url).params(params).send((response) => {
                        if (response && response.show.type === "success") {
                                successCallback();
                        };
                        callback();
                });
        };
};

export const deleteUsersFromTable = (params, callback, successCallback) => {
        return (dispatch, getState) => {
                const { } = getState().userManagementV2;
                return Api.delete(`/user/deleteUsers`).params(params).send((response) => {
                        if (response && response.show.type === "success") {
                                successCallback();
                        }
                        callback();
                });
        };
};

export const deleteUsersPermanent = (params, callback, successCallback) => {
        return (dispatch, getState) => {
                const { } = getState().userManagementV2;
                return Api.delete(`/user/initiate-users-data-delete`).params(params).send((response) => {
                        if (response && response.show.type === "success") {
                                successCallback();
                        }
                        callback();
                });
        };
};

export const fetchDeletedUsers = (params, callback) => {
        return (dispatch, getState) => {

                const url = `/user/deleted-users`;
                return Api.get(url).params(params).send((response, error) => {
                        if (response != undefined) {
                                dispatch(fetchDeletedUsersList(response));
                        };
                        callback();
                });
        };
};

export const restoreDeletedUsers = (params, callback = null) => {

        return (dispatch, getState) => {
                const url = `/user/restore-deleted-users`
                return Api.patch(url).params(params).send((response) => {
                        if (response !== undefined) {
                        };
                        callback();
                });
        };
};

export const getMoreContent = (params, callback) => {
        const url = `/user/user-transfer-data-by-type`;
        return (dispatch, getState) => {
                return Api.get(url).params(params).send((response, error) => {
                        if (response) {
                                dispatch(updateUserContent(response, params.type));
                        }
                        callback();
                });
        }
};

export const getUserAllData = (params, callback) => {
        return (dispatch) => {
                const url = `/user/user-transfer-data/${params.id}`;

                return Api.get(url).send((response, error) => {
                        if (response) {
                                dispatch(updateUserAllData(response));
                        };
                        callback();
                });
        };
};

export const searchUsers = (params, callback) => {
        return (dispatch, getState) => {
                const url = `/user/autocomplete`;

                return Api.get(url).params(params).send((response, error) => {
                        if (response) {
                                dispatch(updateSearchedTeachers(response))
                        };
                        callback();
                });
        };
};

export const transferData = (params, callback, pageCallBack) => {
        return (dispatch, getState) => {
                const url = `/user/transfer-user-data`;

                return Api.patch(url).params(params).send((response, error) => {
                        if (response?.show?.type === "success") {
                                pageCallBack(true)
                                dispatch(getUserAllData({ id: params.sourceUserId }, () => pageCallBack(false)))
                        };
                        callback();
                });
        };
};

export const permanentlyDelete = (params, callback, successCallback) => {
        return (dispatch) => {
                const url = `/user/user-transfer-resource/`;
                return Api.delete(url).params(params).send((response, error) => {
                        if (response) {
                                successCallback()
                        };
                        callback();
                });
        };
};

export const getDeletingUserData = (params, callback) => {
        return (dispatch) => {
                const url = `/user/permanent-delete-operation-status`;
                return new Promise((resolve, reject) => {
                        Api.get(url).params({ userIds: params }).send((response, error) => {
                                if (response) {
                                        dispatch(seDeletingStatus(response))
                                        callback();
                                        resolve(response)
                                };
                                if (error) {
                                        callback();
                                        reject(error)
                                };
                        });
                });
        };
};

export const fetchStandards = (params) => {
        return (dispatch, getState) => {
                const url = `/user/standards`;
                return (
                        Api.get(url).params(params).send((response) => {
                                if (response !== undefined) {
                                        const stdSectionArray = response.map((item) => ({
                                                text: `${item.std} - ${item.section}`,
                                                value: item?.id,
                                        }));

                                        stdSectionArray.unshift({
                                                text: 'unassigned',
                                                value: -1,
                                        });

                                        dispatch(storeStandards(stdSectionArray));
                                };
                        })
                );
        };
};

export const fetchCourses = (params) => {
        return (dispatch, getState) => {
                const url = `/user/courses`;
                return (
                        Api.get(url).params(params).send((response) => {
                                if (response !== undefined) {
                                        ('response courses', response)
                                        const stdSectionArray = response?.response?.items?.map((item) => ({
                                                text: item?.courseName,
                                                value: item?.id,
                                        }));
                                        dispatch(storeCourses(stdSectionArray));
                                };
                        })
                );
        };
};


export const fetchStudentRollNo = (id) => {
        return (dispatch, getState) => {
                const url = `/user/newStudentRoll`;
                return (
                        Api.get(url).params({ standardId: id }).send((response) => {
                                console.log(response, 'rollNo response');
                                dispatch(storeStudentRollNo(response));
                        })
                )
        }
}

export const assignStudentToStd = (callback = null, successCallback) => {
        return (dispatch, getState) => {

                const { userDetailsById, studentCourseDetails } = getState().userManagement;
                const { studentRollNo } = getState().userManagementV2;

                let params = {
                        userId: userDetailsById?.id,
                        standardId: studentCourseDetails?.sectionId,
                        rollNo: studentRollNo.rollNo == null ? undefined : studentRollNo?.rollNo.toString()
                }

                const url = '/user/assignStudentStandard'
                return Api.post(url).params(params).send((response) => {
                        if (response !== undefined) {
                                successCallback();
                        }
                        callback();
                });
        };
};

export const unAssignStudentfromStd = (params, callback = null) => {

        return (dispatch, getState) => {

                const url = `/user/unassignStudent`;
                return Api.patch(url).params(params).send((response) => {
                        if (response !== undefined) {
                                callback();
                        };
                });
        };
};


export const fetchStudentCourses = (params, callback) => {
        return (dispatch, getState) => {
                const url = `/user/course-standard-list`;
                return (
                        Api.get(url).params(params).send((response) => {
                                if (response !== undefined) {
                                        dispatch(storeAllCourses(response));
                                        callback();
                                };
                        })
                );
        };
};

export const fetchcourseStandards = (params, callback) => {
        return (dispatch, getState) => {
                const url = `/user/standard-student-list`;
                return (
                        Api.get(url).params(params).send((response) => {
                                if (response !== undefined) {
                                        dispatch(storeCourseStandards(response));
                                        callback();
                                };
                        })
                );
        };
};

export const fetchStudentsWRTCourseType = (params, callback) => {
        return (dispatch, getState) => {
                const url = `/user/student-info-course-type`;
                return (
                        Api.get(url).params(params).send((response) => {
                                if (response !== undefined) {
                                        dispatch(storeStudentsWRTCoutseType(response))
                                        callback();
                                };
                        })
                );
        };
};

export const fetchUserResources = (params, callback) => {
        return (dispatch, getState) => {
                const url = `/user/resources`;
                return (
                        Api.get(url).params(params).send((response) => {
                                if (response !== undefined) {
                                        dispatch(storeUserResources(response))
                                        callback();
                                };
                        })
                );
        };
};
