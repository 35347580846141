import React, { useState } from 'react';
import courseImage from '../../Assets/aulasMarket/teamgrp.png';

const courses = [
    {
        title: "course 1",
        description: "20 hours videos | 6 quizzes | 14 notes",
        rating: 4,
        totalRatings: 215,
        classType: "Online",
        mode: "Classes to be conducted over the internet.",
        free: true,
        uid: 1,
        costRs: "₹3,199",
        content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    {
        title: "Ultimate AWS Certified Cloud Practitioner - 2023",
        description: "Full Practice Exam included + explanations | Learn Cloud Computing | Pass the AWS Cloud Practitioner CLF-C01 exam!",
        rating: 4,
        totalRatings: "(158,352 ratings)",
        classType: "Hybrid",
        mode: "Classes to be held in physical classroom and also over the internet.",
        free: false,
        uid: 2,
        costRs: "₹3,199",
        content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    {
        title: "course 3",
        description: "20 hours videos | 6 quizzes | 14 notes",
        rating: 4,
        totalRatings: 215,
        classType: "Offline",
        mode: "Classes to be held in physical classroom.",
        free: false,
        uid: 3,
        costRs: "₹3,199",
        content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    {
        title: "course 4",
        description: "20 hours videos | 6 quizzes | 14 notes",
        rating: 4,
        totalRatings: 215,
        classType: "Online",
        mode: "Classes to be conducted over the internet.",
        free: true,
        uid: 4,
        costRs: "₹3,199",
        content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    {
        title: "course 5",
        description: "20 hours videos | 6 quizzes | 14 notes",
        rating: 4,
        totalRatings: 215,
        classType: "Hybrid",
        mode: "Classes to be held in physical classroom and also over the internet.",
        free: false,
        uid: 5,
        costRs: "₹3,199",
        content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    {
        title: "course 6",
        description: "20 hours videos | 6 quizzes | 14 notes",
        rating: 4,
        totalRatings: 215,
        classType: "Online",
        mode: "Classes to be conducted over the internet.",
        free: true,
        uid: 6,
        costRs: "₹3,199",
        content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    {
        title: "course 7",
        description: "20 hours videos | 6 quizzes | 14 notes",
        rating: 4,
        totalRatings: 215,
        classType: "Offilne",
        free: false,
        uid: 7,
        costRs: "₹3,199",
        content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    {
        title: "course 8",
        description: "20 hours videos | 6 quizzes | 14 notes",
        rating: 4,
        totalRatings: 215,
        classType: "online",
        mode: "Classes to be conducted over the internet.",
        free: true,
        uid: 8,
        costRs: "₹3,199",
        content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
];
const carouselItemStyle = {
    width: '30%',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '10px',
    position: 'relative',
    overflow: 'hidden',
    border: "1px solid black"
};

const cardStyle = {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '5px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    position: 'absolute',
    top: '300px',
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'block',
    transition: 'transform 0.3s ease, opacity 0.3s ease',
};

const cardHoveredStyle = {
    transform: 'translate(-50%, -100%)',
    opacity: 1,
};

const CarouselCompo = () => {
    const [currentItemIndex, setCurrentItemIndex] = useState(0);
    const [isHovered, setIsHovered] = useState(false);


    const NewCourses = ({ data,index }) => {
        return (
            <div className='r-c-fs'>
                <img className='' style={{width:"100px",height:"50px"}} src={courseImage} />
                {isHovered && (
                    <div style={{ ...cardStyle, ...(cardHoveredStyle) }}>
                        <p>Card for {data?.title}</p>
                    </div>
                )}
            </div>
        )
    }


    const goToPreviousItem = () => {
        setCurrentItemIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : courses.length - 1
        );
    };

    const goToNextItem = () => {
        setCurrentItemIndex((prevIndex) =>
            prevIndex < courses.length - 1 ? prevIndex + 1 : 0
        );
    };

    const handleHover = () => {
        setIsHovered(true);
    };

    const handleUnhover = () => {
        setIsHovered(false);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', border: "1px solid black", width: "100%", height: "200px" }}>
            <div
                style={carouselItemStyle}
                onMouseEnter={handleHover}
                onMouseLeave={handleUnhover}
            >
                {courses?.map((i, index) => (
                    <>

                        <NewCourses data={i} index={index} />

                    </>
                ))}
            </div>
            <div>
                <button onClick={goToPreviousItem}>Previous</button>
                <button onClick={goToNextItem}>Next</button>
            </div>
        </div>
    );
};

export default CarouselCompo;