let fake_teacherId = 1131;
export const fake_ongoingConferenceData = {
    "id": 33172,
    "startTime": "00:00:00",
    "endTime": "23:00:00",
    "status": "Ongoing",
    "teacherId": 5132,
    "subjectId": 3,
    "mode": "Conference",
    "scheduleId": 1769,
    "date": "2023-08-03",
    "roomName": "fb2b7658-d8c2-4fea-8c85-139762216f78xxx2",
    "firstJoined": false,
    "attendanceMarked": false,
    "isTransfered": false,
    "updatedAt": "2023-08-02T18:22:03.496Z",
    "createdAt": "2023-08-02T18:22:03.496Z",
    "teacher": {
        "id": 5132,
        "name": "Sahana_teacher2",
        "image": "default.png"
    },
    "standards": [
        {
            "id": 3289,
            "std": "0 P r a b h u",
            "section": "X",
            "stream": "NA"
        }
    ],
    "subject": {
        "id": 3,
        "name": "English",
        "icon": "icons/sgj-english.svg"
    },
    "schedule": {
        "id": 1769,
        "weekdays": [
            "thursday"
        ],
        "startTime": "00:00:00",
        "endTime": "23:00:00",
        "mode": "Conference",
        "startDate": "2023-08-03",
        "endDate": "2023-08-03"
    }
}


export const fake_OrgData = {
    "orgCreatedOn": "2023-06-20 13:40:06",
    "transactionPaidBy": "student",
    "approvedKycDocs": [],
    "rejectedKycDocs": [],
    "status": "Active",
    "plan": "Basic",
    "liveClass": [
        {
            "mode": "conference",
            "server": "https://antmedia.classroom.digital/WebRtcRecordingProd"
        },
        {
            "mode": "broadcast",
            "server": ""
        },
        {
            "mode": "offline",
            "server": ""
        }
    ],
    "services": [
        {
            "name": "user",
            "host": "dev-e-learning-user-service-e-learning-user-srv",
            "port": 8001
        },
        {
            "name": "schedule",
            "host": "dev-e-learning-schedule-service",
            "port": 8002
        },
        {
            "name": "assignment",
            "host": "dev-e-learning-assignment-service",
            "port": 8003
        },
        {
            "name": "notification",
            "host": "dev-e-learning-notification-service",
            "port": 8006
        },
        {
            "name": "announcement",
            "host": "dev-e-learning-announcement-service",
            "port": 8007
        },
        {
            "name": "standard",
            "host": "dev-e-learning-standard-service",
            "port": 8009
        },
        {
            "name": "attendance",
            "host": "dev-e-learning-attendance-service",
            "port": 8005
        },
        {
            "name": "fee",
            "host": "dev-e-learning-fee-service",
            "port": 8020
        },
        {
            "name": "admin-library",
            "host": "dev-e-learning-digitallibrary-service",
            "port": 8008
        },
        {
            "name": "care",
            "host": "dev-e-learning-care-service",
            "port": 8021
        },
        {
            "name": "dashboard",
            "host": "dev-e-learning-dashboard-service-e-learning-user-srv",
            "port": 8024
        }
    ],
    "users": [],
    "geofence": [],
    "country": "India",
    "logo": "4cb2509d-70f5-435e-8792-d24937743b53/org/7742cdba-ae6c-4836-b2dc-da62d8b4f1bf1687341927929.jpg",
    "supportPhone": [
        "+9187654567",
        "+845134548",
        "+84643454548",
        "+8454454545454",
        "+9198779",
        "+913"
    ],
    "_id": "625f9fae34522312ff9fb09f",
    "url": "eks2.aulas.one",
    "kycStatus": "Approved",
    "id": "4cb2509d-70f5-435e-8792-d24937743b53",
    "undefined": null,
    "name": "TEST",
    "bankDetails": "{\"accountNumber\":\"1234567890\",\"ifsc\":\"HDFC0002377\",\"txn_type\":\"I\",\"bene_code\":\"TEST389\",\"bank_name\":\"HDFC Bank\",\"branch_name\":\"WHITE FIELD\"}",
    "address": "{\"locality\":\"HSR\",\"city\":\"Bangalore\",\"state\":\"Karnataka\",\"pincode\":\"560102\"}",
    "billingAddress": "{\"locality\":\"HSR\",\"city\":\"Bangalore\",\"state\":\"Karnataka\",\"pincode\":\"123456\",\"country\":\"India\"}",
    "contactDesignation": "Marketing",
    "contactEmail": "rashmi@gmail.com",
    "contactName": "rashmi",
    "contactPhone": "+918541258",
    "gstin": "22AAAAA0000A1Z5",
    "orgType": "Private Limited",
    "panNo": "7349839024",
    "settings": {
        "studentAutoApprove": false,
        "invoiceType": "manual"
    },
    "superAdminDesignation": "Principal",
    "website": "brigosha12.com",
    "instituteId": "1234567",
    "dlStorage": 25,
    "hsn_sac": "",
    "kycSubmittedOn": "2023-05-02, 12:33:02",
    "kycApprovedOn": "2023-06-21, 17:52:36",
    "email": "bikash.singha@brigosha.com",
    "superAdminName": "Super admin 1",
    "kycRejectedComments": "1",
    "kycRejectedOn": "2023-05-09, 12:35:47",
    "accountHolder": {
        "name": "Bikash",
        "role": "Fee Manager"
    },
    "fcmKey": "AAAA-TKj0t8:APA91bE2LvlWtoSVP9A7qHt-He_A0anZ8hfYa38a7OR0MA_R1eHDR-oWnlxh84ajYbWqv-5tfVSlYuSA7YnUbdlnw56mHyCIbKvCSpG67O43VX5xRHVAZVBTGR4O8xPCUU0kEFxzSti8",
    "superAdminMobileNo": "+919812168599",
    "superAdminEmail": null,
    "noOfStudents": "101-500",
    "__v": 1
}