import React, { useState, useEffect } from 'react'
import './styles.css'
import { Heading } from 'components/Typography'
import { connect } from 'react-redux';
import { SubIcon } from 'components/subIcon';
import { Col, Row, Table, Typography } from 'antd';
import { fetchStudentsWRTCourseType, setSelectedCourse, setSelectedStandard, getUsers, setSpecificUserId, setUserDetails, setRole, setStudentWithoutData, setUserids } from 'actions/userManagementV2';
import PageDataLoader from 'components/pageDataLoader';
import PageHeader from 'components/pageHeader';
import { PlusCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'components/Button';
import AddUser from './modals/addUser';
import { InputSearch } from 'components/customInputs';
import { IMG_URL } from "../../env.json";
import { setOthersSelectedTab } from 'actions/profileNew';
import UserDeleteModal from './modals/userDelete'
import PermanentDeleteModal from './modals/parmanentDeleteModal'
import { storeResourceData } from 'actions/feeManagement';
import { setSearchResultPageParamsObj } from 'actions/courseManagement';
import { getCrmDashboardData } from 'actions/marketPlaceCRM';
import moment from 'moment'

const courseIcon = require('Assets/ic-course.svg').default;
const defaultUser = require('Assets/userManagementV2/ic-students.svg').default;
const liveCoursesIcon = require('Assets/liveCoursesIcon.svg').default;
const videoResourceIcon = require('Assets/videoResourceIcon.svg').default;
const studyMaterialIcon = require('Assets/studyMaterialIcon.svg').default;
const testSeriesIcon = require('Assets/testSeriesIcon.svg').default;

 const visitors = require('Assets/crmOverview/Group 38872.png').default;
 const download = require('Assets/crmOverview/Group 39589.png').default;


const cardData = (item, setSelectedCourse, history, setRole, setSearchResultPageParamsObj, setStudentWithoutData) => [
    {
        count: item?.courseStudentsCount,
        title: 'Live Courses',
        lastWeekCount: item?.sinceLastWeekCourse,
        image: liveCoursesIcon,
        onClick: () => { setStudentWithoutData(undefined); setSearchResultPageParamsObj('mode', null); setSearchResultPageParamsObj('status', true); setSearchResultPageParamsObj('subcategoryId', null); setRole('userRole', 'Students'); setSelectedStandard(null); setSelectedCourse('Live Courses'); history.push('/user-management/students-by-courses'); }
    },
    {
        count: item?.vrStudentsCount,
        title: 'Video Courses',
        lastWeekCount: item?.sinceLastWeekVr,
        image: videoResourceIcon,
        onClick: () => { setStudentWithoutData(undefined); setSearchResultPageParamsObj('mode', null); setSearchResultPageParamsObj('status', true); setSearchResultPageParamsObj('subcategoryId', null); setRole('userRole', 'Students'); setSelectedStandard(null); setSelectedCourse('Video Courses'); history.push('/user-management/students-by-courses') }
    },
    {
        count: item?.testStudentsCount,
        title: 'Online Test Series',
        lastWeekCount: item?.sinceLastWeekTest,
        color: '#E7F3FF',
        image: testSeriesIcon,
        onClick: () => { setStudentWithoutData(undefined); setSearchResultPageParamsObj('mode', null); setSearchResultPageParamsObj('status', true); setSearchResultPageParamsObj('subcategoryId', null); setRole('userRole', 'Students'); setSelectedStandard(null); setSelectedCourse('Online Test Series'); history.push('/user-management/students-by-courses') }
    },
    {
        count: item?.smStudentsCount,
        title: 'Study Materials',
        lastWeekCount: item?.sinceLastWeekSm,
        image: studyMaterialIcon,
        onClick: () => { setStudentWithoutData(undefined); setSearchResultPageParamsObj('mode', null); setSearchResultPageParamsObj('status', true); setSearchResultPageParamsObj('subcategoryId', null); setRole('userRole', 'Students'); setSelectedCourse('Study Materials'); history.push('/user-management/students-by-courses') }
    },
];

const CourseCard = ({ item, history, setSelectedCourse, onClick }) => {

    return (
        <div onClick={onClick} className='CardShadow cursor-pointer' style={{ border: '1px solid #E6E6E6', height: '14vh', width: '17vw', borderRadius: 8, marginTop: 5, minWidth: '250px' }}>
            <div className='p-20'>
                <div className='r-jsb' style={{ width: '100%' }}>
                    <div style={{}} className='simGrey text-md bold-600'>{item?.count}</div>
                    <div> <img src={item.image} style={{ width: 40 }} /></div>
                </div>
                <div style={{ fontSize: 12, marginTop: 3, color: '#AEAEAE' }}>Students in <span style={{ color: '#636363', fontWeight: 700 }}>{item?.title}</span></div>
                <div style={{ fontSize: 10, color: "#28DF99", fontWeight: 600 }}>
                    {item?.lastWeekCount != 0 ?
                        <>
                            {item?.lastWeekCount > 0 ?
                                <div style={{ fontSize: 10, color: "#28DF99", fontWeight: 600 }}>
                                    +{item?.lastWeekCount} from last week
                                </div>
                                :
                                <div style={{ fontSize: 10, color: '#FF414D' }}>
                                    {item?.lastWeekCount} from last week
                                </div>
                            }
                        </> : null
                    }
                </div>
            </div>
        </div>
    );
};

const UnassignedCard = ({ studentsDataWRTCourseType, history, setSelectedStandard, setSelectedCourse }) => {

    const handleUnassignedClick = () => {
        let obj = { courseName: '', section: '', std: 'Un-Assigned', standard_id: -1 }
        setSelectedStandard(obj)
        history.push('/user/student')
        setSelectedCourse('')
    }

    return (
        <div className='cursor-pointer CardShadow' onClick={() => handleUnassignedClick()} style={{ border: '1px solid #E6E6E6', height: 100, width: "22%", borderRadius: 8, marginTop: 10, backgroundColor: 'white', padding: 15, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div className='display-flex'>
                <img src={defaultUser} style={{ width: 50 }} />
                <div className='flex-column p-10 m-l-15'>
                    <div style={{ color: '#191919', fontWeight: 600, fontSize: '1.5vw' }}>{studentsDataWRTCourseType?.unassignedStudentCount}</div>
                    <div style={{ color: '#636363', fontWeight: 600, fontSize: '0.8vw' }}>{'students'}</div>
                </div>
            </div>
            <div><RightOutlined /></div>
        </div>
    );
}

const CoursesWNoClasses = ({ studentsDataWRTCourseType, setSelectedCourse, history, setSelectedStandard, storeResourceData, setStudentWithoutData, setUserids }) => {
    console.log("studentsDataWRTCourseType", studentsDataWRTCourseType)
    const handleCoursesWNoClassesClick = () => {
        setSelectedStandard(null)
        setSelectedCourse('Students without courses')
        storeResourceData(-1)
        setStudentWithoutData(true)
        setUserids([])
        history.push('/user/student')
    }

    return (
        <div onClick={() => handleCoursesWNoClassesClick()} className='CardShadow cursor-pointer' style={{ border: '1px solid #E6E6E6', height: '14vh', width: '17vw', borderRadius: 8, marginTop: 5, minWidth: '250px' }}>
            <div className='p-20'>
                <div className='r-jsb' style={{ width: '100%' }}>
                    <div style={{}} className='simGrey text-md bold-600'>{studentsDataWRTCourseType?.studentWithoutCoursesCount ?? 'NA'}</div>
                    <div> <img src={courseIcon} style={{ width: 40 }} /></div>
                </div>
                <div style={{ fontSize: 12, marginTop: 3, color: '#AEAEAE' }}>Students  <span style={{ color: '#636363', fontWeight: 700 }}> without course</span></div>
            </div>
        </div>
    )
}

const StudentCoursesWClasses = ({ history, fetchStudentsWRTCourseType, studentsDataWRTCourseType, setSelectedCourse, setSelectedStandard, getUsers, usersList, setSpecificUserId, setUserDetails, setOthersSelectedTab, user, setRole, storeResourceData, setStudentWithoutData, setUserids, setSearchResultPageParamsObj, getCrmDashboardData, crmDashboardData }) => {

    let fromUserManagement = true
    const [pageLoader, togglePageLoader] = useState(false);
    const [addUserModal, setAdduserModal] = useState(false);
    const [search, setSearch] = useState('');
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [parmanentDeleteModal, setPermanentDeletModal] = useState(false)
    const [userDeleteModalBool, setUserDeleteModalOpen] = useState(false);
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))

   
    useEffect(() => {
        setSelectedStandard()
        togglePageLoader(true)
        fetchStudentsWRTCourseType(null, () => togglePageLoader(false));
        getCrmDashboardData(startDate, endDate, () => { togglePageLoader(false) })
    }, []);

    let params = {
        search: search,
        userType: 'Student'
    }

    const callbackForAddUsers = () => {
        togglePageLoader(true)
        getUsers(params, () => togglePageLoader(false))
    }

    useEffect(() => {
        togglePageLoader(true)
        getUsers(params, () => togglePageLoader(false))
    }, [search]);

    const setIdToDelete = (id) => {
        selectedRowKeys.push(id);
        setSelectedRowKeys([...selectedRowKeys]);
    };

    const openDeleteModal = (e, record) => {
        setUserDeleteModalOpen(true);
        e.stopPropagation();
        setIdToDelete(record?.id);
    }

    const openPermanentDeleteModal = (e, record) => {
        setPermanentDeletModal(true);
        e.stopPropagation();
        setIdToDelete(record?.id);
    }

    const onDeleteModalCancel = () => {
        setUserDeleteModalOpen(false)
    };
    const onPermanentDeleteModalCancel = () => {
        setPermanentDeletModal(false)
    };

    const columns = (user) => {
        const renderName = (value, record) => (
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: 0 }}>
                <img src={`${IMG_URL}/${record?.image ? record?.image : 'default.png'}`} alt="" style={{ width: '20px', marginRight: '10px' }} />
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', fontWeight: 600 }}>
                    <Typography.Text ellipsis={{ tooltip: true }}>{value}</Typography.Text>
                </div>
            </div>
        );

        const baseColumns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                width: '20%',
                render: renderName
            },
            {
                title: 'Class Details',
                dataIndex: 'name',
                key: 'name',
                width: '20%',
                render: (value, record) => (
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <div style={{ width: 180 }}>
                            <Typography.Text ellipsis={{ tooltip: true }} style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
                                {record?.classDetails ? record?.classDetails : 'NA'}
                            </Typography.Text>
                        </div>
                    </div>
                )
            },
            {
                title: 'Courses',
                dataIndex: 'name',
                key: 'name',
                width: '20%',
                render: (value, record) => (
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <div style={{ width: 220 }}>
                            <Typography.Text ellipsis={{ tooltip: true }} style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
                                {record?.courses ? record?.courses : 'NA'}
                            </Typography.Text>
                        </div>
                    </div>
                )
            },
            {
                title: 'Action',
                dataIndex: 'status',
                key: 'status',
                render: (value, record) => (
                    <div className='' style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        {(user?.privilage?.includes('SuperAdmin') || user?.privilage?.includes('Admin')) && (
                            <div style={{ width: 380, display: 'flex', justifyContent: 'flex-start' }}>
                                <Button onClick={(e) => openDeleteModal(e, record)} type='transparent' style={{ color: '#636363', padding: 0 }}>ARCHIVE</Button>
                                <Button onClick={(e) => openPermanentDeleteModal(e, record)} type='transparent' style={{ color: '#FF414D' }}>PERMANENT DELETE</Button>
                            </div>
                        )}
                    </div>
                )
            }
        ];

        return baseColumns;
    };

    const onRow = (record, rowIndex) => {
        return {
            style: { cursor: 'pointer' },
            onClick: () => {
                history.push({
                    pathname: '/user-management/profile',
                    state: {
                        key: fromUserManagement
                    }
                })
                setSpecificUserId(record?.id);
                setUserDetails(record);
                setOthersSelectedTab('Basic Profile');
            }
        }
    }

    const rowSelection = {
        type: "checkbox",
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys, selections) => {
            setSelectedRowKeys([...selectedRowKeys]);
        },
    };

    console.log({ studentsDataWRTCourseType });
    console.log({ usersList });

    const actions = [
        <>
            <InputSearch style={{ borderRadius: 32 }} val={search} placeholder='Search by name/mobile' allowClear onEnter={(e) => setSearch(e)} onClear={() => setSearch('')} />
            <Button style={{ marginLeft: 10 }} onClick={() => setAdduserModal(true)} type='primary' icon={<PlusCircleOutlined />}>ADD STUDENT(S)</Button>
        </>
    ]

    const searchViewActions = [
        <InputSearch val={search} style={{ borderRadius: 32 }} placeholder='Search by name/mobile' allowClear onEnter={(e) => setSearch(e)} onClear={() => setSearch('')} />
    ]

    return (
        <div>
            {search != '' && usersList?.items?.length !== 0 ? (
                <>
                    <PageHeader title='Search Result' actions={searchViewActions} onBackPress={() => history.goBack()} />
                    {pageLoader ? null :
                        <div className='m-t-70'>
                            <Table
                                style={{ width: "100vw" }}
                                rowKey={record => record?.id}
                                onRow={onRow}
                                rowSelection={rowSelection}
                                dataSource={usersList?.items || []}
                                columns={columns(user)}
                            />
                        </div>
                    }
                </>
            ) : (
                <>
                    <PageHeader title='All courses' actions={actions} onBackPress={() => history.goBack()} />
                    {pageLoader ? null : (
                        <>
                        <div style={{display:'flex'}}>
                        <div className='display-flex m-t-100' style={{marginLeft:20}} >
                                <div style={{marginRight:10}}>
                                    <img src={visitors} style={{ width: 50, height: 50 }} />
                                </div>
                                <div className='m-l-10' style={{ width: "12.5vw" ,marginTop:-5}}>
                                <div className='text-md color-black bold-600 '>{711}</div>
                                    <div  style={{ height: 20, fontSize: 14, color:'#787b83' }}>
                                        total visitors 
                                    </div>
                                    
                                </div>
                                <div style={{ borderRight:  "1px solid #E6E6E6", marginRight: 20 }}>
                                </div>
                            </div>
                            <div className='display-flex m-t-100' style={{marginLeft:20}} >
                                <div style={{marginRight:10}}>
                                    <img src={download} style={{ width: 50, height: 50 }} />
                                </div>
                                <div className='m-l-10' style={{ width: "12.5vw" ,marginTop:-5}}>
                                <div className='text-md color-black bold-600 '>{crmDashboardData?.download ? crmDashboardData?.download : 0}</div>
                                    <div  style={{ height: 20, fontSize: 14, color:'#787b83' }}>
                                        app downloads 
                                    </div>
                                    
                                </div>
                               
                            </div>
                        </div>
                        <div style={{ borderBottom:  "1px solid #E6E6E6", padding:'20px 40px 20px 40px', marginLeft:'20px'}}></div>
                            
                            <div className='m-t-10 p-20'>
                                {/* <Heading bold className='m-t-30'>All courses</Heading> */}
                                <div className='display-flex flex-wrap' style={{ gap: 20, width: '100%' }}>
                                    {cardData(studentsDataWRTCourseType, setSelectedCourse, history, setRole, setSearchResultPageParamsObj, setStudentWithoutData)?.map((item, index) => (
                                        <div key={index}>
                                            <CourseCard item={item} history={history} setSelectedCourse={setSelectedCourse} onClick={item?.onClick} />
                                        </div>
                                    ))}
                                </div>
                                <div className='m-t-30'>
                                    <Heading>Students without courses</Heading>
                                    <CoursesWNoClasses studentsDataWRTCourseType={studentsDataWRTCourseType} setSelectedCourse={setSelectedCourse} history={history} setSelectedStandard={setSelectedStandard} storeResourceData={storeResourceData} setStudentWithoutData={setStudentWithoutData} setUserids={setUserids} />
                                </div>
                            </div>

                        </>
                    )}

                </>
            )}

            <PageDataLoader visible={pageLoader} />
            {addUserModal && <AddUser open={addUserModal} onCancel={() => setAdduserModal(false)} callbackForAddUsers={callbackForAddUsers} />}
            {userDeleteModalBool && <UserDeleteModal params={params} togglePageLoader={togglePageLoader} getUsers={getUsers} setSelectedRowKeys={setSelectedRowKeys} selectedRowKeys={selectedRowKeys} open={userDeleteModalBool} onCancel={() => onDeleteModalCancel()} />}
            {parmanentDeleteModal && <PermanentDeleteModal params={params} togglePageLoader={togglePageLoader} getUsers={getUsers} setSelectedRowKeys={setSelectedRowKeys} selectedRowKeys={selectedRowKeys} open={parmanentDeleteModal} onCancel={() => onPermanentDeleteModalCancel()} />}
        </div>
    )
}

const mapStateToProps = (state) => {
    const { user } = state.session;
    const { studentsDataWRTCourseType, usersList } = state.userManagementV2;
    const { crmDashboardData } = state.marketPlaceCRM;
    return { studentsDataWRTCourseType, usersList, user ,crmDashboardData}
};

const mapDispatchToProps = (dispatch) => ({
    fetchStudentsWRTCourseType: (params, callback) => dispatch(fetchStudentsWRTCourseType(params, callback)),
    setSelectedCourse: res => dispatch(setSelectedCourse(res)),
    setSelectedStandard: res => dispatch(setSelectedStandard(res)),
    getUsers: (params, callback) => dispatch(getUsers(params, callback)),
    setSpecificUserId: id => dispatch(setSpecificUserId(id)),
    setUserDetails: res => dispatch(setUserDetails(res)),
    setOthersSelectedTab: (tab) => dispatch(setOthersSelectedTab(tab)),
    setRole: (key, val) => dispatch(setRole(key, val)),
    storeResourceData: res => dispatch(storeResourceData(res)),
    setStudentWithoutData: res => dispatch(setStudentWithoutData(res)),
    setUserids: userIds => dispatch(setUserids(userIds)),
    setSearchResultPageParamsObj: (key, val) => dispatch(setSearchResultPageParamsObj(key, val)),
    getCrmDashboardData: (startDate, endDate, callBack) => dispatch(getCrmDashboardData(startDate, endDate, callBack)),
    

});

export default connect(mapStateToProps, mapDispatchToProps)(StudentCoursesWClasses);