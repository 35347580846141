import React from "react";
import "antd/lib/button/style/index";
import "antd/lib/menu/style/index";
import "antd/lib/dropdown/style/index";
import "antd/lib/avatar/style/index";
import "antd/lib/tabs/style/index";
import "antd/lib/page-header/style/index";
import "antd/lib/modal/style/index";
import "antd/lib/pagination/style/index";
import "antd/lib/empty/style/index";
import DoughnutCard from "./localComponent/doughnutCard";
import "./styles.css";
import { Row, Col, Menu, Dropdown, DatePicker } from "antd";
import { connect } from "react-redux";
import TestCard from "./localComponent/testCards";
import { useState, useEffect } from "react";
import CreateTestModal from "./modals/createTestModal";
import BackToTop from "components/backToTop";

import CreateTestType from "./modals/createTestType";


import {
  fetchDashBoardData,
  fetchTests,
  fetchBarChartData,
  updateSelectedTest,
  createTestModal,
  unsetCreateTestModalBool,
  fetchPublishedResult,
  postQuestionCreate,
  updateListViewType,
  updateRole,
  getTestClassList,
  setOpenTab,
  updateFilter,
  fetchStandards,
  fromCreateNewButton,
  setFromSectionMarkingScheme,
  setImportMeta,
  storeSchemeId,
} from "actions/testAdmin";

import { BsFilterLeft } from "react-icons/bs";
import PageDataLoader from "components/pageDataLoader";
import ConfirmationModal from "./modals/confirmationModal";
import OverallStatusCard from "./localComponent/overallStatusCard";
import TestPublishModal from "./localComponent/testPublishModal";
import { PlusCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import BarGraph from "./localComponent/barGraph";
import SearchBox from './localComponent/searchBox';
import Loader from "./localComponent/loader";
import moment from "moment";
import PageHeaderGlobal from "components/pageHeader";
import PageHeader from "components/pageHeader";
import { Button } from 'components/Button'
import { Heading, SubHeading } from "components/Typography";
import { InputSearch } from "components/customInputs";


export function NodataCard({
  containerheight = "80%",
  imgwidth = "30%",
  imgheight = "30%",
}) {
  return (
    <div className="r-c-c-c" style={{ height: containerheight }}>
      <img
        style={{ width: imgwidth, height: imgheight, marginBottom: "20px" }}
        src={require("../../Assets/test/no-subjects.svg").default}
        alt=""
      />
      <div className="r-c-c-c">
        <h1 style={{ fontFamily: "roboto", fontWeight: "800" }}>No data!</h1>
        <p style={{ color: "#636363", fontFamily: "roboto", textAlign: 'center' }}>
          There is no data to show yet. Please check back later.
        </p>
      </div>
    </div>
  );
}
let timer = false;
function Test({
  postQuesCreate,
  fetchDashBoardData,
  pieData,
  classTestInfo,
  resultsToPublish,
  publishedResults,
  testsToPublish,
  fetchBarChartData,
  universalLoaderBool,
  barchartData,
  isBarChartEmpty,
  fetchTests,
  updateSelectedTest,
  isCModalVisible,
  cModalType,
  reloadToggler,
  history,
  createTestModal,
  unsetCreateTestModalBool,
  updateListViewType,
  userType,
  selectedSubject,
  feedbackFromTeacher,
  sentForFeedback,
  updateRole,
  user,
  ongoingTests,
  getTestClassList,
  createTestModalBool,
  dashBoardMeta,
  setOpenTab,
  updateFilter,
  loaders,
  filter,
  fetchStandards,
  fromCreateNewButton,
  setFromSectionMarkingScheme,
  setImportMeta,
  storeSchemeId
}) {
  const [selectedFilter, setSelectedFilter] = useState("Week");
  const [pageLoader, togglePageLoader] = useState(false);
  const [isDropdownVisible, setDropdownVisibility] = useState(false);
  const [testModalOpen, setTestModalOpen] = useState(false);

  const showModal = () => {
    setTestModalOpen(true);
  };

  const closeModal = () => {
    setTestModalOpen(false);
  }

  useEffect(() => {
    updateFilter('indexSearch', null);
    storeSchemeId(-1)
  }, []);
  useEffect(() => {
    updateFilter('selectedGraphFilter', 'week');
    updateFilter('graphStartDate', moment().startOf("week").format("YYYY-MM-DD"));
    updateFilter('graphEndDate', moment().startOf("week").add(6, "days").format("YYYY-MM-DD")),
      updateFilter('graphYear', moment().format('YYYY'));
    updateRole();
    fetchDashBoardData(() => { });
    fetchBarChartData('overall', (bool) => { bool });
  }, [reloadToggler]);


  useEffect(() => {
    fetchBarChartData('overall', (bool) => { bool });
  }, [filter?.graphStartDate, filter?.graphEndDate, filter?.graphYear]);

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fetchStandards();
    }, 1000);
  }, [filter?.indexSearch]);


  const handleFilterSelect = (key, filterText) => {
    updateFilter('selectedGraphFilter', key);
    setSelectedFilter(filterText);
    fetchBarChartData('overall', () => { });
  };
  const handleResultCardClick = (test, type) => {
    updateSelectedTest(test);
    if (type == "dash_scheduled") {
      // history.push('admin-test')
    } else {
      history.push("test-publish-result-screen");
    }
  };
  const handlePickerChange = (value, dateString) => {
    togglePageLoader(true);
    fetchBarChartData("weekcustom", dateString[0], dateString[1], (bool) =>
      togglePageLoader(bool)
    );
  };
  const handleCreateClick = () => {
    // getTestClassList([]);
    // history.push("/create-test")
    // history.push("/create/update-test")
    showModal();
    updateListViewType("create");
    createTestModal("test-create", user, "home");
  };

  const onDateBackward = () => {
    if (selectedFilter === "Week") {
      updateFilter('graphStartDate', moment(filter?.graphStartDate, "YYYY-MM-DD").subtract(7, 'days').format('YYYY-MM-DD'));
      updateFilter('graphEndDate', moment(filter?.graphEndDate, "YYYY-MM-DD").subtract(7, 'days').format('YYYY-MM-DD'))
    } else if (selectedFilter === 'Months') {
      updateFilter('graphYear', "" + (parseInt(filter?.graphYear) - 1))
    }
  }

  const onDateForward = () => {
    if (selectedFilter === "Week") {
      updateFilter('graphStartDate', moment(filter?.graphStartDate, "YYYY-MM-DD").add(7, 'days').format('YYYY-MM-DD'));
      updateFilter('graphEndDate', moment(filter?.graphEndDate, "YYYY-MM-DD").add(7, 'days').format('YYYY-MM-DD'))
    } else if (selectedFilter === 'Months') {
      updateFilter('graphYear', "" + (parseInt(filter?.graphYear) + 1))
    }
  }
  const handleVAclearUp = () => {
    updateFilter('isTabPage', false);
    updateFilter('isVA', true);
    updateFilter('VAsearch', null);
    updateFilter('VAclasses', []);
    updateFilter('VAsyllabus', []);
    updateFilter('isVAdatefiltered', false);
    updateFilter('vaDate', null);
  }
  const onSearch = (e) => {
    updateFilter('indexSearch', e);
  }

  // const buttons = [
  //   {label: 'CREATE TEST', onClick: ()=>{handleCreateClick()}}
  // ]

  const actions = [
    <Button type="" onClick={() => { history.push("/marking-scheme-dashboard"); fromCreateNewButton(false); setFromSectionMarkingScheme(false) }} style={{ marginRight: 20 }} icon={<CheckCircleOutlined />}>MARKING SCHEMES</Button>,
    <Button type="primary" className='m-r-20' onClick={() => { handleCreateClick() }} icon={<PlusCircleOutlined />}>CREATE TEST</Button>

  ]

  return (
    <>
      <div
        onClick={() => {
          setDropdownVisibility(false);
        }}
        style={{ backgroundColor: "#FAFAFA", padding: 20 }}
      >
        <div style={{ marginBottom: 50 }}>
          <PageHeader title='Tests' actions={actions} />
        </div>
        {/* <div className="r-c-sb m-b-10">
        <div>
          <h1
            className="color-black bold-700"
            style={{ fontFamily: "roboto", fontSize: "19px" }}
          >
            Test
          </h1>
        </div>
        <div style={{display:"flex"}}>
        <div style={{marginRight:"30px"}}>
          <Button
            onClick={()=>{history.push("/marking-scheme-dashboard")}}
            className="whitebtnWrap radius-100"
          >
            <CheckCircleOutlined /> MARKING SCHEMES
          </Button>
        </div>
        <div>
          <Button
            onClick={() => handleCreateClick()}
            className="purpleBtnWrap radius-100 purpleButtonHoverEffect"
          >
            <PlusCircleOutlined /> CREATE TEST
          </Button>
        </div>
        </div> */}
        <Row>
          <Col sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 11 }} xl={{ span: 7 }} className="m-r-10">
            <div className="overallStatusCard1 shadow-box m-b-20">
              {userType == "dataOperator" ? (
                <OverallStatusCard />
              ) : (
                <>
                  <div className="r-jsb">
                    <h3 className="overallStatusCard-heading">
                      Today&#39;s Activities
                    </h3>
                    <div className="blue bold-700 cursor-pointer" onClick={() => {
                      updateFilter('TAclasses', []);
                      updateFilter('TAsyllabus', []);
                      updateFilter('TAsearch', null);
                      setOpenTab('Upcoming');
                      history.push("testlist")
                    }}
                      style={{ zIndex: 5 }}>
                      VIEW
                    </div>
                  </div>
                  <div className="r-c-c-c full-width full-height relative">
                    <DoughnutCard pieData={pieData} />
                  </div>
                </>
              )}
            </div>
          </Col>
          <Col sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 16 }} style={{ height: '375px' }}>
            <BarGraph barchartData={barchartData} userType={userType} isDropdownVisible={isDropdownVisible} setDropdownVisibility={setDropdownVisibility}
              selectedFilter={selectedFilter} handleFilterSelect={handleFilterSelect} handlePickerChange={handlePickerChange} isBarChartEmpty={isBarChartEmpty}
              loading={loaders?.graphLoader} starDate={filter?.graphStartDate} endDate={filter?.graphEndDate} selectedYear={filter?.graphYear}
              onDateBackward={onDateBackward} onDateForward={onDateForward}/>
          </Col>
        </Row>
        {userType === 'admin' || userType === 'teacher' ? (
          <>
            <div>
              {ongoingTests && ongoingTests.length > 0 ? (
                <>
                  <Heading className="m-l-10">
                    Live Test(s)
                  </Heading>
                  <div className="r-c-fs flex-wrap">
                    {ongoingTests?.map((test, index) => (
                      <TestCard
                        type={"adminlive"}
                        test={test}
                        key={index}
                        index={index}
                        fromTodayActivities={true}
                      />
                    ))}
                  </div>
                </>
              ) : null}
            </div>
            {userType === 'admin' ?
              <div>
                {resultsToPublish?.length + publishedResults?.length > 0 ? (
                  <>
                    <div className="r-c-sb" >
                      <Heading className="m-l-10">Result(s)</Heading>
                      {
                        (dashBoardMeta?.resultsPublishedCount + dashBoardMeta?.resultCount) > 3 ?
                          <Button type="transparent" size="small" className="viewsolutionsbtn fs-12 m-t-30" style={{ padding: 0, width: "fit-content", color: '#1089FF', fontWeight: 700 }}
                            onClick={() => {
                              handleVAclearUp();
                              updateFilter('VAtype', 'results');
                              history.push('result-list')
                            }}>
                            VIEW ALL
                          </Button>
                          :
                          null
                      }
                    </div>
                    <div className="r-c-fs flex-wrap">
                      {resultsToPublish?.map((test, index) => <TestCard key={index} type='published' test={test} index={index} ispublished={false} handleResultCardClick={handleResultCardClick} />)}
                      {resultsToPublish?.length < 3 ? publishedResults?.slice(0, 3 - resultsToPublish?.length)?.map((test, index) => <TestCard key={index} type='published' test={test} index={index} ispublished={true} handleResultCardClick={handleResultCardClick} />) : null}
                    </div>
                  </>
                ) : null}
              </div>
              : null}
            {
              userType === 'admin' ?
                <div>
                  {testsToPublish?.length > 0 ? (
                    <>
                      <div className="r-c-sb">
                        <Heading className="m-t-20 m-l-10">Test(s) ready to be published</Heading>
                        {dashBoardMeta?.readyToPublishCount > 3 ?
                          <Button type="transparent" size="small" className="viewsolutionsbtn fs-12 m-t-30" style={{ padding: 0, width: "fit-content", color: '#1089FF', fontWeight: 700 }}
                            onClick={() => {
                              updateFilter('VAtype', 'ready');
                              handleVAclearUp();
                              history.push('/publish-list')
                            }}>
                            VIEW ALL
                          </Button>
                          : null
                        }
                      </div>
                      <div className="r-c-fs flex-wrap">
                        {testsToPublish?.slice(0, 3)?.map((test, index) => <TestCard type='dash_scheduled' test={test} key={index} index={index} handleResultCardClick={handleResultCardClick} />)}
                      </div>
                    </>
                  ) : null}
                </div>
                : null
            }
          </>
        ) : null}
        {userType == "teacher" || userType == "admin" ? (
          <>
            {sentForFeedback?.length > 0 ? (
              <>
                <div className="r-c-sb">
                  <Heading className="m-t-20 m-l-10">
                    Test(s) to be Reviewed
                  </Heading>
                  {
                    dashBoardMeta?.reviewCount > 3 ?
                      <Button type="transparent" size="small" className="viewsolutionsbtn fs-12 m-t-30" style={{ padding: 0, width: "fit-content", color: '#1089FF', fontWeight: 700 }}
                        onClick={() => {
                          updateFilter('VAtype', 'review');
                          handleVAclearUp();
                          history.push('/review-list')
                        }}>
                        VIEW ALL
                      </Button>
                      : null
                  }

                </div>
                <div className="r-c-fs flex-wrap">
                  {sentForFeedback?.map((test, index) => (
                    <TestCard
                      type="Feedback"
                      test={test}
                      key={index}
                      index={index}
                      handleResultCardClick={handleResultCardClick}
                      sendForFeedback={true}
                      dash={true}
                    />
                  ))}
                </div>
              </>
            ) : null}
            {/* {userType === 'admin' ?
              <div>
                {resultsToPublish?.length + publishedResults?.length > 0 ? (
                  <>
                    <div className="r-c-sb" >
                      <Heading className="m-l-10">Result(s)</Heading>
                      {
                        (dashBoardMeta?.resultsPublishedCount + dashBoardMeta?.resultCount) > 3 ?
                          <Button type="transparent" size="small" className="viewsolutionsbtn fs-12 m-t-30" style={{ padding: 0, width: "fit-content", color: '#1089FF', fontWeight: 700 }}
                            onClick={() => {
                              handleVAclearUp();
                              updateFilter('VAtype', 'results');
                              history.push('/result-list')
                            }}>
                            VIEW ALL
                          </Button>
                          :
                          null
                      }
                    </div>
                    <div className="r-c-fs flex-wrap">
                      {resultsToPublish?.map((test, index) => <TestCard key={index} type='published' test={test} index={index} ispublished={false} handleResultCardClick={handleResultCardClick} />)}
                      {resultsToPublish?.length < 3 ? publishedResults?.slice(0, 3 - resultsToPublish?.length)?.map((test, index) => <TestCard key={index} type='published' test={test} index={index} ispublished={true} handleResultCardClick={handleResultCardClick} />) : null}
                    </div>
                  </>
                ) : null}
              </div>
              : null} */}
            {/* {
              userType === 'admin' ?
                <div>
                  {testsToPublish?.length > 0 ? (
                    <>
                      <div className="r-c-sb">
                        <Heading className="m-t-20 m-l-10">Test(s) ready to be published</Heading>
                        {dashBoardMeta?.readyToPublishCount > 3 ?
                          <Button type="transparent" size="small" className="viewsolutionsbtn fs-12 m-t-30" style={{ padding: 0, width: "fit-content", color: '#1089FF', fontWeight: 700 }}
                            onClick={() => {
                              updateFilter('VAtype', 'ready');
                              handleVAclearUp();
                              history.push('/publish-list')
                            }}>
                            VIEW ALL
                          </Button>
                          : null
                        }
                      </div>
                      <div className="r-c-fs flex-wrap">
                        {testsToPublish?.slice(0, 3)?.map((test, index) => <TestCard type='dash_scheduled' test={test} key={index} index={index} handleResultCardClick={handleResultCardClick} />)}
                      </div>
                    </>
                  ) : null}
                </div>
                : null
            } */}
          </>
        ) : null}
        {/* {userType == "teacher" || userType == "admin" ? (
          <>
            {sentForFeedback?.length > 0 ? (
              <>
                <div className="r-c-sb" >
                  <Heading className="m-t-20 m-l-10">
                    Test(s) to be Reviewed
                  </Heading>
                  {
                    dashBoardMeta?.reviewCount > 3 ?
                      <Button type="transparent" size="small" className="viewsolutionsbtn fs-12 m-t-30" style={{ padding: 0, width: "fit-content", color: '#1089FF', fontWeight: 700 }}
                        onClick={() => {
                          updateFilter('VAtype', 'review');
                          handleVAclearUp();
                          history.push('/review-list')
                        }}>
                        VIEW ALL
                      </Button>
                      : null
                  }

                </div>
                <div className="r-c-fs flex-wrap">
                  {sentForFeedback?.map((test, index) => (
                    <TestCard
                      type="Feedback"
                      test={test}
                      key={index}
                      index={index}
                      handleResultCardClick={handleResultCardClick}
                      sendForFeedback={true}
                      dash={true}
                    />
                  ))}
                </div>
              </>
            ) : null}
          </>
        ) : null} */}
        {userType == "dataOperator" || userType == "admin" ? (
          <>
            {feedbackFromTeacher?.length > 0 ? (
              <>
                <div className="r-c-sb">
                  <Heading className="m-t-20 m-l-10">
                    Feedback from Teacher(s)
                  </Heading>
                  {
                    dashBoardMeta?.feedbackCount > 3 ?
                      <Button type="transparent" size="small" className="viewsolutionsbtn fs-12 m-t-30" style={{ padding: 0, width: "fit-content", color: '#1089FF', fontWeight: 700 }}
                        onClick={() => {
                          updateFilter('VAtype', 'feedback');
                          handleVAclearUp();
                          history.push('/feedback-list')
                        }}>
                        VIEW ALL
                      </Button>
                      : null
                  }
                </div>
                <div className="r-c-fs flex-wrap">
                  {feedbackFromTeacher?.map((test, index) => (
                    <TestCard
                      type="Feedback"
                      test={test}
                      key={index}
                      index={index}
                      handleResultCardClick={handleResultCardClick}
                      sendForFeedback={false}
                      fromHome={true}
                      dash={true}
                    />
                  ))}
                </div>
              </>
            ) : null}
          </>
        ) : null}
        {/* <div className="r-c-sb m-t-30 m-b-20" style={{ height: 30 }}>
          <Heading className="m-l-10"> All Classes </Heading>
          <InputSearch loading={false} onInputChange={onSearch} placeholder="Search by class name" allowClear onClear={() => { onSearch('') }} style={{ width: 200 }} />
        </div> */}
        {/* <div className="m-l-10"> */}
        {/* {loaders?.indexSearchLoader ?
            <Loader style={{ fontSize: 24 }} />
            :
            <>
              {feedbackFromTeacher?.length > 0 ? (
                <>
                  <div className="r-c-sb">
                    <Heading className="m-t-20 m-l-10">
                      Feedback from Teacher(s)
                    </Heading>
                    {
                      dashBoardMeta?.feedbackCount > 3 ?
                        <Button type="link" size="small" className="viewsolutionsbtn fs-12 m-t-30" style={{ padding: 0, width: "20%" }}
                          onClick={() => {
                            updateFilter('VAtype', 'feedback');
                            handleVAclearUp();
                            history.push('result-list')
                          }}>
                          VIEW ALL
                        </Button>
                        : null
                    }
                  </div>
                  <div className="r-c-fs flex-wrap">
                    {feedbackFromTeacher?.map((test, index) => (
                      <TestCard
                        type="Feedback"
                        test={test}
                        key={index}
                        index={index}
                        handleResultCardClick={handleResultCardClick}
                        sendForFeedback={false}
                        fromHome={true}
                        dash={true}
                      />
                    ))}
                  </div>
                </>
              ) : null}
            </>} */}
        <div className="r-c-sb m-t-30 m-b-20" style={{ height: 30 }}>
          <Heading className="m-l-10"> All Classes </Heading>
          <InputSearch loading={false} onInputChange={onSearch} placeholder="Search by class name" allowClear onClear={() => { onSearch('') }} style={{ width: 200 }} />
        </div>
        <div className="m-l-10">
          {
            loaders?.indexSearchLoader ?
              <Loader style={{ fontSize: 24 }} />
              :
              <>
                {
                  classTestInfo && Object.keys(classTestInfo)?.length > 0 ?
                    Object.keys(classTestInfo)?.map((key) => (
                      <>
                        <div key={key}>
                          <h2
                            className="userCard-heading m-t-10"
                            style={{ fontSize: "14px" }}
                          >
                            {key}
                          </h2>
                        </div>
                        <div className="r-c-fs flex-wrap">
                          {Array.isArray(classTestInfo[key])
                            ? classTestInfo[key]?.map((classcardData, index) => (
                              <TestCard
                                type="class_card"
                                key={index}
                                class_std_name={key}
                                sectionid={classcardData.section}
                                classdata={classcardData}
                                index={index}
                              />
                            ))
                            : null}
                        </div>
                      </>
                    ))
                    : <NodataCard />
                }
              </>
          }
        </div>
        {/* {createTestModalBool && <CreateTestModal history={history} />} */}
        {testModalOpen && <CreateTestType isModalOpen={testModalOpen} closeModal={closeModal} />}
        <TestPublishModal history={history} />
        <ConfirmationModal
          isCModalVisible={isCModalVisible}
          cModalType={cModalType}
        />
        <PageDataLoader visible={loaders?.indexLoader} />
        <BackToTop />
        {/* </div> */}
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  const {
    universalLoaderBool,
    pieData,
    classTestInfo,
    resultsToPublish,
    publishedResults,
    testsToPublish,
    barchartData,
    isBarChartEmpty,
    isCModalVisible,
    cModalType,
    reloadToggler,
    userType,
    selectedSubject,
    feedbackFromTeacher,
    sentForFeedback,
    ongoingTests,
    createTestModalBool,
    dashBoardMeta,
    loaders,
    filter
  } = state.testAdmin;
  const { user } = state.session;
  return {
    universalLoaderBool,
    pieData,
    classTestInfo,
    resultsToPublish,
    publishedResults,
    testsToPublish,
    barchartData,
    isBarChartEmpty,
    isCModalVisible,
    cModalType,
    reloadToggler,
    userType,
    selectedSubject,
    feedbackFromTeacher,
    sentForFeedback,
    user,
    ongoingTests,
    createTestModalBool,
    dashBoardMeta,
    loaders,
    filter
  };
};

const mapDispatchToProps = (dispatch) => ({
  postQuestionCreate: () => dispatch(postQuestionCreate()),
  fetchPublishedResult: () => dispatch(fetchPublishedResult()),
  fetchDashBoardData: (loaderCallback) =>
    dispatch(fetchDashBoardData(loaderCallback)),
  fetchTests: (type, callback, startDate, endDate, searchText) =>
    dispatch(fetchTests(type, callback, startDate, endDate, searchText)),
  fetchBarChartData: (type, loaderCallback) => {
    dispatch(fetchBarChartData(type, loaderCallback));
  },
  updateSelectedTest: (test) => dispatch(updateSelectedTest(test)),
  createTestModal: (name, user, source) =>
    dispatch(createTestModal(name, user, source)),
  unsetCreateTestModalBool: () => dispatch(unsetCreateTestModalBool()),
  updateListViewType: (data) => dispatch(updateListViewType(data)),
  updateRole: () => dispatch(updateRole()),
  getTestClassList: (subid) => dispatch(getTestClassList(subid)),
  setOpenTab: (type) => dispatch(setOpenTab(type)),
  updateFilter: (key, val) => dispatch(updateFilter(key, val)),
  fetchStandards: (callback) => dispatch(fetchStandards(callback)),
  fromCreateNewButton: (bool) => dispatch(fromCreateNewButton(bool)),
  setFromSectionMarkingScheme: (val) => dispatch(setFromSectionMarkingScheme(val)),
  setImportMeta: (key, val) => dispatch(setImportMeta(key, val)),
  storeSchemeId : (val) => dispatch(storeSchemeId(val))
});

export default connect(mapStateToProps, mapDispatchToProps)(Test);