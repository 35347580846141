import { Heading } from 'components/Typography';
import { Button } from 'components/Button';
import React, { useEffect, useState } from 'react';
import { CheckCircleOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import MarkAttendanceModal from './markAttendanceModal';
import { connect } from 'react-redux';
import { deleteHistory, getAttendanceRequests } from 'actions/profileNew';
import moment from 'moment';
import ConfirmLeaveDelete from './confirmLeaveDelete';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import AttendanceRequestsModal from './attendanceRequestsModal';

const columns = (setDeleteLeaveModal, handleEditLeave, selectedRowKeys, storeSingleId, fromWhere, user, setIsEdit) => [
    {
        title: 'Date',
        dataIndex: 'startDate',
        key: 'startDate',
        render: (value) => <div style={{ display:'flex', justifyContent:'flex-start'}}>{moment(value).format("DD/MM/YYYY")}</div> 
    },
    {
        title: 'Timings',
        dataIndex: 'clockIn',
        key: 'clockIn',
        render: (value, record) => <div style={{ display:'flex', justifyContent:'flex-start'}}>{moment(value, 'HH:mm:ss').format("hh:mm A")} - {moment(record.clockOut, 'HH:mm:ss').format("hh:mm A")}</div>
    },
    {
        title: 'Total hours',
        dataIndex: 'duration',
        key: 'duration',
        render: (value) => <div style={{ display:'flex', justifyContent:'flex-start'}}>{value} hrs</div>
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (value) => <div style={{ color: value === "Approved" ? "#28DF99" : "red", textTransform: "uppercase", display:'flex', justifyContent:'flex-start' }}>{value}</div>
    },
    {
        title: 'Action',
        dataIndex: 'action',
        render: (value, record) => (
            <div className='r-ac' style={{ display:'flex', justifyContent:'flex-start'}}>
                {fromWhere !== 'userManagementProfile' && !user?.privilage?.includes('Admin') ?
                    <>
                        <Button type="transparent" style={{ padding: 0 }} disabled={selectedRowKeys.includes(record.id)} icon={<EditOutlined style={{ color: "#1089FF" }} />} onClick={(e) => { e.stopPropagation(), handleEditLeave(record) ; setIsEdit('edit')}}></Button>
                        <Button type="transparent" style={{ padding: 0 }} disabled={selectedRowKeys.includes(record.id)} icon={<DeleteOutlined style={{ color: "red" }} />} onClick={(e) => { e.stopPropagation(), setDeleteLeaveModal(true); storeSingleId(record?.id); }}></Button>
                    </>
                    : null
                }
            </div>
        )
    }
]

const AttendanceRequests = ({ getAttendanceRequests, setPageLoader, user, attendanceRequests, deleteHistory, specificUserId, fromWhere }) => {
    const [markAttendaceVisible, setMarkAttendanceVisible] = useState(false);
    const [filter, setFilter] = useState({
        page: 1,
        limit: 10
    })
    const [attendanceFormDetails, setAttendanceFormDetails] = useState({
        date: null,
        clockIn: null,
        clockOut: null,
        totalHours: null,
        reason: null

    })
    const [deleteLeaveModal, setDeleteLeaveModal] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [singleId, setSingleId] = useState([]);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [attRequestModal, setAttRequestModal] = useState(false);
    const [storeRowData, setStoreRowData] = useState(null)
    const [isEdit, setIsEdit] = useState('')

    const location = useLocation();

    useEffect(() => {
        setPageLoader(true)
        getAttendanceRequests({
            userId: location?.state?.key ? specificUserId : user.id,
            ...filter
        }, () => setPageLoader(false))
    }, []);

    useEffect(() => {
        setPageLoader(true)
        getAttendanceRequests({
            userId: location?.state?.key ? specificUserId : user.id,
            ...filter
        }, () => setPageLoader(false))
    }, [filter]);

    const rowSelection = {
        type: "checkbox",
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys, selections) => {
            setSelectedRows([...selections.map(item => item.fileKey)]);
            setSelectedRowKeys([...selectedRowKeys])
        },
    };

    const handleEditLeave = (record) => {
        setMarkAttendanceVisible(true);
        setAttendanceFormDetails({
            ...record, date: moment(record.startDate),
            clockIn: moment(record.clockIn, "HH:mm:ss"),
            clockOut: moment(record.clockOut, "HH:mm:ss"),
            totalHours: record.duration
        })
    }

    const handleCloseEditLeave = () => {
        setMarkAttendanceVisible(false);
        setAttendanceFormDetails({
            date: null,
            clockIn: null,
            clockOut: null,
            totalHours: null,
            reason: null
        })
    }

    const successCallback = () => {
        setDeleteLeaveModal(false);
        setSelectedRowKeys([]);
    }

    const storeSingleId = (id) => {
        selectedRowKeys.push(id);
        setSelectedRowKeys([...selectedRowKeys]);
    }

    const handleDelete = () => {
        let params = {
            id: selectedRowKeys
        };

        setDeleteLoader(true);
        deleteHistory(params, () => setDeleteLoader(false), () => successCallback(), 'AttendanceRequests');
    };

    const onRow = (record, rowIndex) => {
        return {
            onClick: () => {
                setAttRequestModal(true);
                setStoreRowData(record);
            }
        }
    }

    return (
        <div>
            <div className='r-jsb'>
                <Heading>History</Heading>
                <div className='r-ac'>
                    {selectedRowKeys.length >= 1 ? <Button type="delete" style={{ marginRight: 10 }} onClick={() => setDeleteLeaveModal(true)}>DELETE</Button> : null}
                    {(fromWhere == 'userManagementProfile' && (user?.privilage?.includes('Admin')) || user?.privilage?.includes('FeeManager')) ? null :
                        <Button type="primary" icon={<CheckCircleOutlined />} onClick={() => {setMarkAttendanceVisible(true),setIsEdit('')} }>{user?.privilage?.includes('SuperAdmin') ? "MARK ATTENDANCE" : "REQUEST ATTENDANCE"}</Button>
                    }
                </div>
            </div>
            <div className='m-t-20'>
                <Table
                    columns={columns(setDeleteLeaveModal, handleEditLeave, selectedRowKeys, storeSingleId, fromWhere, user, setIsEdit)}
                    dataSource={attendanceRequests?.items}
                    locale={{
                        emptyText: <>
                            <div>
                                <img
                                    width={200}
                                    style={{ marginBottom: "20px" }}
                                    src={require("Assets/test/no-subjects.svg").default}
                                    alt=""
                                />
                            </div>
                            <div style={{ color: "#191919", fontSize: 16, fontWeight: 500 }}>No attendance request!</div>
                            <div style={{ color: "#636363" }}>No data available to show</div>
                        </>,

                    }}
                    pagination={{
                        current: filter?.page,
                        pageSize: filter?.limit,
                        size: 'small',
                        onChange: (page) => setFilter({ ...filter, page }),
                        total: attendanceRequests?.meta?.totalItems
                    }}
                    rowSelection={rowSelection}
                    rowKey={record => record?.id}
                    onRow={onRow}
                />
            </div>
            <MarkAttendanceModal
                visible={markAttendaceVisible}
                onClose={handleCloseEditLeave}
                attendanceFormDetails={attendanceFormDetails}
                setAttendanceFormDetails={setAttendanceFormDetails}
                setPageLoader={setPageLoader}
                filter={filter}
                setFilter={setFilter}
                fromWhere={fromWhere}
                user={user}
                isEdit={isEdit}
            />
            {deleteLeaveModal &&
                <ConfirmLeaveDelete visible={deleteLeaveModal} onClose={() => { setDeleteLeaveModal(false); setSelectedRowKeys([]) }} onDelete={handleDelete} deleteLoader={deleteLoader} />
            }
            {attRequestModal &&
                <AttendanceRequestsModal
                    open={attRequestModal}
                    onClose={() => setAttRequestModal(false)}
                    data={storeRowData}
                />
            }

        </div>
    );
}


const mapStateToProps = (state) => {
    const { user } = state.session;
    const { attendanceRequests } = state.profileNew;
    const { specificUserId } = state.userManagementV2
    return {
        user, attendanceRequests, specificUserId
    }
}

const mapDispatchToProps = (dispatch) => ({
    getAttendanceRequests: (params, callback) => dispatch(getAttendanceRequests(params, callback)),
    deleteHistory: (params, callback, successCallback, fromWhere) => dispatch(deleteHistory(params, callback, successCallback, fromWhere))

})

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceRequests);

