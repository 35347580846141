import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { Button } from 'components/Button';
import { Heading } from 'components/Typography';
import UserAvatar from 'components/userAvatar';
import React from 'react';
import { AiOutlineLogout, AiOutlineSetting } from 'react-icons/ai';
import { useHistory } from 'react-router';
import { Api } from 'services';

const popover = (visible, collapsed) => ({
  position: "absolute",
  bottom: "2%",
  left: collapsed ? `clamp(70px, 2vw, 90px)` : `clamp(110px, 16vw, 200px)`,
  display: visible ? "block" : "none",
  padding: "0 40px",
})

export const ProfileMenu = ({ user, visible, sideMenuWidth, setConfirmLogoutVisible, collapsed }) => {
  const history = useHistory();
  return (
    <div style={popover(visible, collapsed)}>
      <div className="userProfileMenu" >
        <div className="r-c-c">
        <UserAvatar  name={user.name} img={user.image == "default.png" ? "" : `${Api._s3_url}${user.image}`} size={60}/>
        </div>
        <div className="r-c-c userName" style={{ padding: "0 20px", marginLeft: 0 }}>
          Hi,&nbsp;{user?.name}
        </div>
        <div className="m-t-20 cursor-pointer">
          <div className='r-c-c m-b-20'>
            <Button type="secondary"
              icon={user?.privilage?.length ? <AiOutlineSetting /> : <UserOutlined  />}
              style={{ color: "#594099", width: 200, background: "#F6F4FE", border: "none", boxShadow: "none" }}
              onClick={() => history.push("/account-settings")}
            >
              {user?.privilage?.length ? "Account & Settings" : "Account"}
            </Button>
          </div>
          <div className='r-c-c m-b-20'>
            <Button
              icon={<AiOutlineLogout />}
              style={{ border: "1px solid #FF414D", color: "#FF414D", width: 200 }}
              onClick={() => setConfirmLogoutVisible(true)}
            >
              Logout
            </Button>
          </div>
        </div>

      </div>
    </div>
  )
}