import styled, { css } from "styled-components";

const Font13 = styled.span`
  font-size: 0.813rem;
  font-family: Roboto;
  padding: 0;
  color: ${(props) => props.color};
  line-height: ${(props) => (typeof props.lineHeight === 'number' ? `${props.lineHeight}px` : props.lineHeight || "normal")};
  margin:${(props) => props.margin};
  font-weight: ${(props) => (props.bold ? 700 : props.fontWeight || 200)};

  @media (max-width: 1300px) {
    font-size: 0.688rem; 
  }

//   @media (max-width: 1100px) {
//     font-size: 0.563rem; 
//   }

//   @media (max-width: 900px) {
//     font-size: 0.438rem; 
//   }
`;

const Font15 = styled.span`
  font-size: 0.938rem;
  font-family: Roboto;
  padding: 0;
  color: ${(props) => props.color};
  line-height: ${(props) => (typeof props.lineHeight === 'number' ? `${props.lineHeight}px` : props.lineHeight || "normal")};
  margin:${(props) => props.margin || 0};
  font-weight: ${(props) => (props.bold ? 700 : props.fontWeight || 200)};
  z-index:10

  @media (max-width: 1300px) {
    font-size: 0.813rem; 
  }

  @media (max-width: 1100px) {
    font-size: 0.688rem; 
  }

//   @media (max-width: 900px) {
//     font-size: 0.563rem; 
//   }

//   @media (max-width: 700px) {
//     font-size: 0.75rem; 
//     line-height : 16px
//   }
`;

const Font17 = styled.span`
  font-size: 1.063rem;
  font-family: Roboto;
  padding: 0;
  color: ${(props) => props.color || "#191919"};
  line-height: ${(props) => (typeof props.lineHeight === 'number' ? `${props.lineHeight}px` : props.lineHeight || "normal")};
  margin:${(props) => props.margin || 0};
  font-weight: ${(props) => (props.bold ? 700 : props.fontWeight || 200)};
  text-align: ${(props) => (props.noJustify ? "" : "justify")};

  @media (max-width: 1300px) {
    font-size: 0.938rem; 
  }

  @media (max-width: 1100px) {
    font-size: 0.813rem; 
  }

//   @media (max-width: 900px) {
//     font-size: 0.688rem; 
//     line-height : 20px
//   }

//   @media (max-width: 700px) {
//     font-size: 0.875rem;
//   }
`;

const Font19 = styled.span`
  font-size: 1.188rem;
  font-family: Roboto;
  padding: 0;
  color: ${(props) => props.color || "#191919"};
  line-height: ${(props) => (typeof props.lineHeight === 'number' ? `${props.lineHeight}px` : props.lineHeight || "normal")};
  margin:${(props) => props.margin || 0};
  font-weight: ${(props) => (props.bold ? 700 : props.fontWeight || 200)};
  z-index:10;

  @media (max-width: 1300px) {
    font-size: 1.063rem; 
  }
  
  @media (max-width: 1100px) {
    font-size: 0.938rem; 
  }

//   @media (max-width: 900px) {
//     font-size: 0.813rem; 
//     line-height:20px
//   }

//   @media (max-width: 700px) {
//     font-size: 0.875rem; 
//   }
`;

const Font21 = styled.span`
  font-size: 1.313rem;
  font-family: Roboto;
  padding: 0;
  color: ${(props) => props.color || "#191919"};
  line-height: ${(props) => (typeof props.lineHeight === 'number' ? `${props.lineHeight}px` : props.lineHeight || "normal")};
  margin:${(props) => props.margin || 0};
  font-weight: ${(props) => (props.bold ? 700 : props.fontWeight || 200)};

  @media (max-width: 1300px) {
    font-size: 1.188rem; 
  }
  
  @media (max-width: 1100px) {
    font-size: 1.063rem; 
  }

//   @media (max-width: 900px) {
//     font-size: 0.938rem; 
//   }

//   @media (max-width: 700px) {
//     font-size: 0.875rem; 
//   }
`;

const Font23 = styled.span`
  font-size: 1.438rem;
  font-family: Roboto;
  padding: 0;
  color: ${(props) => props.color || "#191919"};
  line-height: ${(props) => (typeof props.lineHeight === 'number' ? `${props.lineHeight}px` : props.lineHeight || "normal")};
  margin:${(props) => props.margin || 0};
  font-weight: ${(props) => (props.bold ? 700 : props.fontWeight || 200)};

  @media (max-width: 1300px) {
    font-size: 1.313rem; 
  }

  @media (max-width: 1100px) {
    font-size: 1.188rem; 
  }

//   @media (max-width: 900px) {
//     font-size: 1.063rem; 
//   }

//   @media (max-width: 700px) {
//     font-size: 0.875rem; 
//   }
`;

const Heading = styled.span`
  font-size: 1.438rem;
  font-family: Roboto;
  padding: 0;
  color: ${(props) => props.color || "#191919"};
  line-height: ${(props) => (typeof props.lineHeight === 'number' ? `${props.lineHeight}px` : props.lineHeight || "normal")};
  margin:${(props) => props.margin || 0};
  font-weight: ${(props) => (props.bold ? 700 : props.fontWeight || 200)};

  @media (max-width: 1300px) {
    font-size: 1.813rem; 
  }

  @media (max-width: 1100px) {
    font-size: 1.313rem; 
  }

  @media (max-width: 900px) {
    font-size: 1.188rem; 
  }

  @media (max-width: 700px) {
    font-size: 1.063rem; 
  }
`;

const Font39 = styled.span`
  font-size: 2.438rem;
  font-family: Roboto;
  padding: 0;
  color: ${(props) => props.color};
  line-height: ${(props) => (typeof props.lineHeight === 'number' ? `${props.lineHeight}px` : props.lineHeight || "normal")};
  margin:${(props) => props.margin || 0};
  font-weight: ${(props) => (props.bold ? 700 : props.fontWeight || 200)};

  @media (max-width: 1300px) {
    font-size: 2.313rem; 
  }

  @media (max-width: 1100px) {
    font-size: 1.563rem;; 
  }

//   @media (max-width: 800px) {
//     font-size: 1.25rem;; 
//   }
`;

export { Font13, Font15, Font17, Font19, Font21, Font23, Heading, Font39 };